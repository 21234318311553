import { ViajeTrasladoCreateModelLegacyDontUse } from 'app/viaje/models';
import { Moment } from 'moment';
import {
  transferReservationBaseFormBuilder,
  TransferReservationBaseFormType,
  transferReservationBaseLocationFormBuilder,
  TransferReservationBaseLocationFormType
} from '../../../viajeTraslado/forms/base/TransferReservationBaseFormTypes';

export interface TransferReservationBatchLocationFormType
  extends TransferReservationBaseLocationFormType {}

/*
 Esta reserva permite crear multiples viajes.
 Cada viaje tiene el tipo TransferReservationBatchTripFormType 
*/
export type TransferReservationBatchTripFormType = {
  puntos: TransferReservationBatchLocationFormType[];
  fechaYHoraDeLlegada: Moment;
  minutosPreviosASalida: Moment;
};

export interface TransferReservationBatchFormType
  extends TransferReservationBaseFormType {
  viajes: TransferReservationBatchTripFormType[];
}

function viajeTrasladoBatchFormToModel(
  form: TransferReservationBatchFormType
): ViajeTrasladoCreateModelLegacyDontUse[] {
  return form.viajes.map((detallesTraslado) => {
    const fechaYHoraDeLlegada = detallesTraslado.fechaYHoraDeLlegada;
    const fechaYHoraDeSalida = detallesTraslado.minutosPreviosASalida;
    return {
      ...transferReservationBaseFormBuilder.toModel(form),
      fechaYHoraDeLlegada: fechaYHoraDeLlegada,
      fechaYHoraDeSalida: fechaYHoraDeSalida,
      puntos: detallesTraslado.puntos.map(
        transferReservationBaseLocationFormBuilder.toModel
      )
    };
  });
}

export const transferReservationBatchFormBuilder = {
  toModel: viajeTrasladoBatchFormToModel
};
