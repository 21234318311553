import React from 'react';
import { Card } from 'shared/components';
import { ClientPrivateForm } from '../forms/ClientPrivateForm';
import { ClientPrivateFormType } from '../forms/ClientPrivateFormType';

interface Props {
  create: (ClientPrivateValues: ClientPrivateFormType) => Promise<void>;
}
export function ClientPrivateCreate({ create }: Props) {
  return (
    <Card title="Nuevo Cliente particular" className="w-full">
      <div className="p-8">
        <ClientPrivateForm onSubmit={create} />
      </div>
    </Card>
  );
}
