import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, TableColumn } from '@vadiun/react-components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { ClientPrivateModel } from '../models/ClientPrivate';

interface Props {
  clientprivates: ClientPrivateModel[];
  selectToEdit: (x: ClientPrivateModel) => void;
  delete: (x: ClientPrivateModel) => void;
  restore: (x: ClientPrivateModel) => void;
}

export const ClientPrivateTable = (props: Props) => {
  const data = props.clientprivates.map((c) => ({
    ...c,
    address: createDomicilioText(c.address)
  }));
  const columns: TableColumn<typeof data[0]>[] = [
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'lastName',
      label: 'Apellido',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'alias',
      label: 'Alias',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'phone1',
      label: 'Teléfono 1',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'phone2',
      label: 'Teléfono 2',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'address',
      label: 'Domicilio',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => props.selectToEdit(props.clientprivates[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table title={'Clientes particulares'} data={data} columns={columns} />
    </div>
  );
};
