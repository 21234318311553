import * as Yup from 'yup';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';

import {
  transferReservationBaseFormValidation,
  transferReservationBaseLocationFormValidation
} from '../../viajeTraslado/forms/base/TransferReservationBaseFormValidation';

export const transferReservationRepetitiveLocationFormValidation =
  transferReservationBaseLocationFormValidation;

export const transferReservationRepetitiveFormValidation = (
  cliente: ClientBusinessModel | undefined
) =>
  transferReservationBaseFormValidation(cliente).shape({
    puntos: Yup.array()
      .min(2, 'Debe haber al menos dos puntos')
      .of(transferReservationRepetitiveLocationFormValidation),
    fechaYHoraDeLlegada: Yup.mixed().required(
      'La fecha de llegada es requerida'
    ),
    minutosPreviosASalida: Yup.string().required(
      'Los minutos de salida son requeridos'
    ),
    repetitionFinishDate: Yup.mixed().required(
      'La fecha de terminación es requerida'
    ),
    repetitionInitDate: Yup.mixed().required('La fecha de inicio es requerida')
  });
