import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { TollFormType } from './TollFormik';
import TollFormik from './TollFormik';

interface Props {
  onSubmit: (value: TollFormType, reset) => void;
  initialValues?: TollFormType;
}

function TollForm({
  onSubmit,
  initialValues = TollFormik.initialValues
}: Props) {
  return (
    <Formik<TollFormType>
      initialValues={initialValues}
      validationSchema={TollFormik.schema}
      enableReinitialize
      onSubmit={(values, helpers) => onSubmit(values, helpers.resetForm)}
    >
      {({ isSubmitting }) => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <Field
            name="price"
            label="Precio *"
            type="number"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default TollForm;
