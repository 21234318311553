import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useState } from 'react';
import useClientBusiness from '../../services/useClientBusiness';
import { ClientBusinessUserFormType } from '../components/forms/ClientBusinessUserFormType';
import { ClientBusinessUserModel } from '../models/ClientBusinessUser';

/** Abstract all the details of how to manage the client business user crud */
export const useClientBusinessUser = (clientId: number) => {
  const clientRepo = useClientBusiness();
  const usersQuery = useSuperQuery(() => clientRepo.getAllUsers(clientId), {
    showSpinner: true
  });

  const addUserMutation = useSuperMutation(clientRepo.addUser);

  const updateUserMutation = useSuperMutation(clientRepo.updateUser, {
    showSpinner: true
  });

  const updateUserCompanyMutation = useSuperMutation(
    clientRepo.updateUserCompany,
    {
      showSpinner: true,
      showSuccessMessage: true
    }
  );

  const changePasswordMutation = useSuperMutation(
    clientRepo.changeUserPassword
  );
  const removeUserMutation = useSuperMutation(clientRepo.removeUser, {
    showSpinner: true
  });
  const importUsersMutation = useSuperMutation(clientRepo.importUsers, {
    showSpinner: true
  });
  const restoreUserMutation = useSuperMutation(clientRepo.restoreUser, {
    showSpinner: true
  });
  const [importUsersOpen, setImportUsersOpen] = useState(false);
  const [file, setfile] = useState<File | null>(null);

  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );

  const [changingPasswordUser, setChangingPasswordUser] =
    useState<ClientBusinessUserModel>();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  async function create(userValues: ClientBusinessUserFormType) {
    await addUserMutation.mutate({ ...userValues, client_id: clientId });
    setVisiblePage('table');
    usersQuery.reload();
  }

  async function update(userValues: {
    id?: number;
    name: string;
    email: string;
  }) {
    await updateUserMutation.mutate({ ...userValues });
    setVisiblePage('table');
    usersQuery.reload();
  }

  async function updateCompany(user_id: string, company_id: string) {
    await updateUserCompanyMutation.mutate(user_id, company_id);
    usersQuery.reload();
  }

  async function remove(id: number) {
    await removeUserMutation.mutate(id);
    usersQuery.reload();
  }

  async function restore(id: number) {
    await restoreUserMutation.mutate(id);
    usersQuery.reload();
  }

  function selectToChangePassword(user: ClientBusinessUserModel) {
    setChangingPasswordUser(user);
    setIsChangePasswordModalOpen(true);
  }

  const onChangePassword = async (values: { password: string }) => {
    await changePasswordMutation.mutate({
      password: values.password,
      id: changingPasswordUser!.id
    });
    setIsChangePasswordModalOpen(false);
    setChangingPasswordUser(undefined);
  };

  const onImportUsers = async (file: File) => {
    await importUsersMutation.mutate({ file, clientId });
    setfile(null);
    setImportUsersOpen(false);
    usersQuery.reload();
  };

  return {
    users: usersQuery.data ?? [],
    create,
    update,
    updateCompany,
    remove,
    restore,
    selectToChangePassword,
    onChangePassword,
    visiblePage,
    isChangePasswordModalOpen,
    setIsChangePasswordModalOpen,
    setVisiblePage,
    file,
    setfile,
    importUsersOpen,
    setImportUsersOpen,
    onImportUsers
  };
};
