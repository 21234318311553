import { ApplicantCrud } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModelCRUD } from 'app/client/clientBusiness/Authorized/models/Authorized';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface CorrectClientBusinessFormType {
  client: ClientBusinessModel | null;
  autorizado: AuthorizedModelCRUD | null;
  solicitante: ApplicantCrud;
  costCenter: string;
  subCostCenter: string;
  sector: string;
  buyOrder: string;
  employeeNumber: string;
  platformApplication: string;
  tripNumber: string;
  data1: string;
  data2: string;
  data3: string;
  hasOutSite: boolean;
}

const correctClientBusinessFormSchema = (
  client: ClientBusinessModel | undefined
): YupShape<CorrectClientBusinessFormType> => {
  return Yup.object().shape({
    client: Yup.mixed().required('El client es obligatorio'),
    autorizado: client?.reservationRequirement?.needsAuthorized
      ? Yup.mixed().required('El autorizado es requerido')
      : Yup.mixed(),
    solicitante: Yup.object().shape({
      name: Yup.string().required('El nombre del solicitante es requerido'),
      phone: Yup.string().nullable(),
      email: Yup.string().nullable()
    }),
    hasOutSite: Yup.boolean(),
    costCenter: client?.reservationRequirement?.needsCostCenter
      ? Yup.string().nullable().required('El centro de costos es requerido')
      : Yup.string().nullable(),
    subCostCenter: client?.reservationRequirement?.needsSubCostCenter
      ? Yup.string().nullable().required('El sub centro de costos es requerido')
      : Yup.string().nullable(),
    sector: client?.reservationRequirement?.needsSector
      ? Yup.string().nullable().required('El sector es requerido')
      : Yup.string().nullable(),
    buyOrder: client?.reservationRequirement?.needsBuyOrder
      ? Yup.string().nullable().required('La orden de compra es requerida')
      : Yup.string().nullable(),
    employeeNumber: client?.reservationRequirement?.needsEmployeeNumber
      ? Yup.string().nullable().required('El legajo es requerido')
      : Yup.string().nullable(),
    platformApplication: client?.reservationRequirement
      ?.needsPlatformApplication
      ? Yup.string()
          .nullable()
          .required('La solicitud en plataforma es requerida')
      : Yup.string().nullable(),
    tripNumber: client?.reservationRequirement?.needsTripNumber
      ? Yup.string().nullable().required('El nro de viaje es requerido')
      : Yup.string().nullable(),
    data1: client?.reservationRequirement?.needsData1
      ? Yup.string().nullable().required('El dato 1 es requerido')
      : Yup.string().nullable(),
    data2: client?.reservationRequirement?.needsData2
      ? Yup.string().nullable().required('El dato 2 es requerido')
      : Yup.string().nullable(),
    data3: client?.reservationRequirement?.needsData3
      ? Yup.string().nullable().required('El dato 3 es requerido')
      : Yup.string().nullable()
  });
};

const correctClientBusinessInitialValues: CorrectClientBusinessFormType = {
  client: null,
  hasOutSite: false,
  solicitante: {
    name: '',
    phone: '',
    email: ''
  },
  costCenter: '',
  subCostCenter: '',
  sector: '',
  buyOrder: '',
  employeeNumber: '',
  platformApplication: '',
  tripNumber: '',
  autorizado: null,
  data1: '',
  data2: '',
  data3: ''
};

const CorrectClientBusinessFormik = {
  schema: correctClientBusinessFormSchema,
  initialValues: correctClientBusinessInitialValues
};

export default CorrectClientBusinessFormik;
