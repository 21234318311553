export enum VehiculoMunicipio {
  VICENTE_LOPEZ = 'Vicente lopez',
  AVELLANEDA = 'Avellaneda',
  CNRT = 'CNRT',
  SAN_ANDRES_DE_LOS_GILES = 'San andres de los giles',
  TRES_DE_FEBRERO = 'Tres de febrero',
  CAMPANA = 'Campana',
  TIGRE = 'Tigre',
  SACTA_CABA = 'SACTA CABA',
  ESCOBAR = 'Escobar',
  PILAR = 'Pilar',
  MALVINAS_ARGENTINAS = 'Malvinas argentinas'
}

export const VEHICULO_MUNICIPIOS = Object.values(VehiculoMunicipio).sort();
