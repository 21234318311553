import { Dialog, IconButton } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';

type Props = {
  onRejectRequest: () => void;
  onCloseModalReject: () => void;
  isLoading: boolean;
  isOpenReject: boolean;
};

const RejectAction = ({
  isOpenReject,
  isLoading,
  onCloseModalReject,
  onRejectRequest
}: Props): JSX.Element => {
  return (
    <Dialog open={isOpenReject} onClose={onCloseModalReject} maxWidth="md">
      <div className="px-4 py-4" style={{ width: 450, overflow: 'hidden' }}>
        <div className="mb-3 flex justify-between">
          <div className="flex items-center gap-2">
            <WarningIcon style={{ color: 'red' }} />
            <p className="text-lg text-gray-800">Rechazar solicitudes</p>
          </div>
          <IconButton
            size="small"
            aria-label="close modal"
            onClick={onCloseModalReject}
          >
            <ClearIcon fontSize="medium" />
          </IconButton>
        </div>
        <div className="mt-3 pb-5 text-gray-600">
          Estás por rechazar las solicitudes que seleccionaste. ¿Seguro que
          quieres hacerlo?
        </div>
        <div className="flex items-center justify-end gap-3 pt-4">
          <Button onClick={onCloseModalReject} variant="light" color="gray">
            Cancelar
          </Button>
          <Button
            onClick={onRejectRequest}
            variant="light"
            color="red"
            isLoading={isLoading}
          >
            Sí, rechazar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RejectAction;
