import {
  ServerSidePagination,
  Table,
  TableColumn
} from '@vadiun/react-components';
import React from 'react';
import { DriverSettlementModel } from '../models/DriverSettlementModel';

interface Props {
  settlementsPagination: ServerSidePagination<DriverSettlementModel[]>;
  settlementDetailLink: (id: number, driverCode: number) => React.ReactNode;
  openMovimientosButton: (id: number) => React.ReactNode;
}

export const DriverSettlementsTableComponent = ({
  settlementsPagination,
  settlementDetailLink,
  openMovimientosButton
}: Props) => {
  const data = settlementsPagination.data ?? [];
  const columns: TableColumn<DriverSettlementModel>[] = [
    {
      name: 'driverCode',
      label: 'Código'
    },
    {
      name: 'driverName',
      label: 'Nombre'
    },
    {
      name: 'date',
      label: 'Fecha',
      options: {
        customBodyRenderLite: (index) => data[index].date.format('DD/MM/YYYY')
      }
    },
    {
      name: 'total_billed',
      label: 'Total a facturar'
    },

    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex gap-4">
            {openMovimientosButton(data[index].id)}
            {settlementDetailLink(data[index].id, data[index].driverCode)}
          </div>
        )
      }
    }
  ];
  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          ...settlementsPagination.serverSideProps.options,
          search: false,
          sort: false
        }}
        title={''}
        columns={columns}
      />
    </div>
  );
};
