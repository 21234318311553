import React from 'react';
import { Card } from 'shared/components';
import TitularForm from './TitularForm';
import { TitularFormSchema } from '../models/TitularFormSchema';

interface Props {
  editar: (TitularValues: TitularFormSchema, reset) => void;
  initialValues: TitularFormSchema;
}
function TitularEdit({ editar, initialValues }: Props) {
  return (
    <Card title="Editar Titular" className="w-full">
      <div className="p-8">
        <TitularForm onSubmit={editar} initialValues={initialValues} />
      </div>
    </Card>
  );
}

export default TitularEdit;
