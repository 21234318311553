import { VehiculoModeloCRUD, VehiculoModeloModel } from './VehiculoModelo';
import { VehiculoFabricanteModel } from '../../fabricanteVehiculo/models/VehiculoFabricante';

export interface VehiculoModeloFormSchema {
  nombre: string;
  fabricante_id: number;
}

export function vehiculoModeloFormSchemaToModel(
  vehiculoModeloForm: VehiculoModeloFormSchema,
  fabricantes: VehiculoFabricanteModel[]
): VehiculoModeloCRUD {
  const fabricante = fabricantes.find(
    (m) => m.id === vehiculoModeloForm.fabricante_id
  )!;
  return {
    ...vehiculoModeloForm,
    fabricante
  };
}

export function vehiculoModeloModelToFormSchema(
  vehiculoModelo: VehiculoModeloModel
): VehiculoModeloFormSchema {
  return {
    nombre: vehiculoModelo.nombre,
    fabricante_id: vehiculoModelo.fabricante.id!
  };
}
