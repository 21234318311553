import { AntenaFormType } from 'app/antena/antena/components/AntenaForm/AntenaFormType';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export const antenaFormValidation: YupShape<AntenaFormType> =
  Yup.object().shape({
    nombre: Yup.string().required('El nombre es requerido'),
    latitud: Yup.string().required('El latitud es requerido'),
    longitud: Yup.string().required('El longitud es requerido'),
    grupo_id: Yup.mixed().required('El grupo es requerido')
  });
