import { Menu, MenuItem } from '@material-ui/core';
import {
  Button,
  ServerSidePagination,
  TableColumn
} from '@vadiun/react-components';
import environment from 'environment/environment';
import React from 'react';
import { Table } from 'shared/components';
import { FacturaModel } from '../models/FacturaModel';

interface Props {
  tableProps: ServerSidePagination<FacturaModel[]>;
  onCrearMovimiento: (id: number) => void;
  onVerMovimientos: (id: number) => void;
  onShowExcell: (id: number) => void;
}

export const FacturacionTableComponent = ({
  tableProps,
  onCrearMovimiento,
  onVerMovimientos,
  onShowExcell
}: Props) => {
  const [reservationButtonElement, setReservationButtonElement] =
    React.useState<(EventTarget & HTMLElement) | null>(null);
  const eventIndex = React.useRef<number | null>(null);
  const { data, serverSideProps } = tableProps;

  const columns: TableColumn<FacturaModel>[] = [
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'client',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index: number) => data[index].client.name
      }
    },
    {
      name: 'date',
      label: 'Fecha',
      options: {
        customBodyRenderLite: (index: number) => 
        data[index].date.format('DD/MM/YYYY')
      }
    },
    {
      name: 'letter',
      label: 'Letra'
    },

    {
      name: 'number',
      label: 'Número'
    },
    {
      name: 'total',
      label: 'Total'
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col space-y-1">
              <Button
                onClick={(ev) => {
                  eventIndex.current = index;
                  setReservationButtonElement(ev.currentTarget);
                }}
                variant="light"
                className="w-full"
              >
                Acciones
              </Button>
              <Menu
                anchorEl={reservationButtonElement}
                open={
                  eventIndex.current === index &&
                  Boolean(reservationButtonElement)
                }
                onClose={() => setReservationButtonElement(null)}
              >
                <MenuItem
                  onClick={() => {
                    onCrearMovimiento(data[index].id);
                    setReservationButtonElement(null);
                  }}
                >
                  Crear movimiento
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onVerMovimientos(data[index].id);
                    setReservationButtonElement(null);
                  }}
                >
                  Ver movimientos ({data[index].movements.length})
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onShowExcell(data[index].id);
                    setReservationButtonElement(null);
                  }}
                >
                  Excel
                </MenuItem>
                <MenuItem>
                  <a
                    href={`${environment.backEnd.replace('api/', '')}invoices/${
                      data[index].id
                    }/export-pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Vouchers
                  </a>
                </MenuItem>
              </Menu>
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={data}
        options={{ print: false, download: false, ...serverSideProps.options }}
        title={''}
        columns={columns}
      />
    </div>
  );
};
