import React, { useState } from 'react';
import { SideStepper } from '@vadiun/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faCheckSquare,
  faUser
} from '@fortawesome/free-solid-svg-icons';

import ClientBusinessFormik, {
  ClientBusinessContactFormType,
  ClientBusinessFormType,
  ClientBusinessPersonalFormType,
  ClientBusinessReservationRequirementType
} from './ClientBusinessForm/ClientBusinessFormik';
import ClientBusinessPersonalForm from './ClientBusinessForm/ClientBusinessPersonalForm';
import ClientBusinessContactForm from './ClientBusinessForm/ClientBusinessContactForm';
import ClientBusinessRequirementsForm from './ClientBusinessForm/ClientBusinessRequirementsForm';

interface Props {
  create: (choferFormSchema: ClientBusinessFormType, reset) => void;
  ivaConditions: string[];
}

const ClientBusinessCreate = ({ create, ivaConditions }: Props) => {
  const [currentStep, setCurrentStep] = useState<
    'personal' | 'contactos' | 'requerimientos'
  >('personal');
  const [clientForm, setClientForm] = useState<ClientBusinessFormType>(
    ClientBusinessFormik.initialValues
  );

  const steps = {
    personal: (
      <ClientBusinessPersonalForm
        ivaConditions={ivaConditions}
        initialValues={clientForm}
        onSubmit={(values: ClientBusinessPersonalFormType) => {
          setClientForm((currentValue) => ({
            ...currentValue,
            ...values
          }));
          setCurrentStep('contactos');
        }}
      />
    ),
    contactos: (
      <ClientBusinessContactForm
        onGoBack={() => setCurrentStep('personal')}
        initialValues={clientForm}
        onSubmit={(values: ClientBusinessContactFormType) => {
          setClientForm((currentValue) => ({
            ...currentValue,
            ...values
          }));
          setCurrentStep('requerimientos');
        }}
      />
    ),
    requerimientos: (
      <ClientBusinessRequirementsForm
        initialValues={clientForm.reservationRequirement}
        onGoBack={() => setCurrentStep('contactos')}
        onSubmit={async (values: ClientBusinessReservationRequirementType) => {
          create(
            {
              ...clientForm,
              reservationRequirement: values
            },
            () => {
              setClientForm(ClientBusinessFormik.initialValues);
              setCurrentStep('personal');
            }
          );
        }}
      />
    )
  };
  return (
    <div className="w-full flex flex-col md:flex-row bg-white">
      <div className="p-8">
        <SideStepper.Container value={currentStep}>
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faUser} />}
            value={'personal'}
            label="Información personal"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faAddressBook} />}
            value={'contactos'}
            label="Contactos"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faCheckSquare} />}
            value={'requerimientos'}
            label="Requerimientos"
          />
        </SideStepper.Container>
      </div>
      <div className="border-l-2 border-gray-200 border-solid p-12 w-full">
        {steps[currentStep]}
      </div>
    </div>
  );
};

export default ClientBusinessCreate;
