import { RateFormType } from './RateForm/RateFormik';
import RateForm from './RateForm/RateForm';

interface Props {
  edit: (rateFormValue: RateFormType) => Promise<void>;
  initialValue: RateFormType;
}

function RateEdit({ edit, initialValue }: Props) {
  return <RateForm onSubmit={edit} initialValues={initialValue} />;
}

export default RateEdit;
