import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { ClientPrivateModel } from 'app/client/private/private/models/ClientPrivate';
import {
  CANALES_DE_VIAJE,
  TIPOS_SERVICIO_VIAJE_TRASLADO
} from 'app/viaje/models';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps
} from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import { AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import {
  KeyboardDatePicker,
  KeyboardTimePicker
} from 'formik-material-ui-pickers';
import { useMemo, useRef } from 'react';
import { StaticMap } from 'shared/components';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import { TransferReservationLocationFormModal } from '../../viajeTraslado/components/TransferReservationLocationFormModal';
import { createTransferReservationRepetitiveInitalValues } from './TransferReservationRepetitivePrivateFormInitialValues';
import { TransferReservationRepetitivePrivateFormType } from './TransferReservationRepetitivePrivateFormType';
import { transferReservationRepetitivePrivateFormValidation } from './TransferReservationRepetitivePrivateFormValidation';

interface Props {
  initialValues?: TransferReservationRepetitivePrivateFormType;
  onSubmit: (
    viajeFormValues: TransferReservationRepetitivePrivateFormType,
    reset
  ) => void;
  getClientesByName: (name: string) => Promise<ClientPrivateModel[]>;
  onCalculateDistanceAndCost: (
    viajeFormValues: TransferReservationRepetitivePrivateFormType
  ) => void;
  onOpenCreatePrivateClient: () => void;
}

export function TransferReservationRepetitiveForm({
  initialValues: _initialValues,
  onSubmit,
  getClientesByName,
  onCalculateDistanceAndCost,
  onOpenCreatePrivateClient
}: Props) {
  const initialValues = useMemo(
    () => _initialValues ?? createTransferReservationRepetitiveInitalValues(),
    [_initialValues]
  );
  const formRef =
    useRef<FormikProps<TransferReservationRepetitivePrivateFormType>>(null);
  const verifyAction = useVerifyAction();

  return (
    <Formik<TransferReservationRepetitivePrivateFormType>
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={transferReservationRepetitivePrivateFormValidation()}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="w-full">
          <FormikOnFieldChange<TransferReservationRepetitivePrivateFormType>
            field="cliente"
            onChange={(
              cliente: TransferReservationRepetitivePrivateFormType['cliente']
            ) => {
              //

              /* 
              formik.values.puntos.length > 1 permite que cuando se esta editando una reserva repetitiva 
              no se sobreescriban los puntos
              */
              if (cliente === null || formik.values.puntos.length > 1) {
                return;
              }
              const puntos = [
                {
                  domicilio: cliente.address,
                  espera: '',
                  observacion: '',
                  pasajeros: []
                }
              ];
              formik.setFieldValue('puntos', puntos);
            }}
          />
          <div className="flex w-full gap-4">
            <Field
              name="cliente"
              component={FormikAutocompleteAsync}
              fetchOptions={getClientesByName}
              fullWidth
              getOptionLabel={(cliente: ClientPrivateModel) =>
                cliente.name + cliente.lastName
              }
              className="w-full"
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial
                  {...params}
                  error={
                    formik.touched['cliente'] && !!formik.errors['cliente']
                  }
                  helperText={formik.errors['cliente']}
                  label="Cliente"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Button
              variant="outlined"
              shape="circle"
              className="mt-2"
              onClick={onOpenCreatePrivateClient}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <div className="grid grid-cols-12 gap-6 border-b-2 border-dashed py-8">
            <FormControl variant="outlined" fullWidth className="col-span-3">
              <InputLabel>Tipo de servicio</InputLabel>
              <Field
                component={Select}
                name="tipoServicio"
                label="Tipo de servicio"
              >
                {TIPOS_SERVICIO_VIAJE_TRASLADO.map((canal) => (
                  <MenuItem value={canal} key={canal}>
                    {canal}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <FormControl variant="outlined" fullWidth className="col-span-3">
              <InputLabel>Canal de ingreso</InputLabel>
              <Field
                component={Select}
                name="canalIngreso"
                label="Canal de ingreso"
              >
                {CANALES_DE_VIAJE.map((canal) => (
                  <MenuItem value={canal} key={canal}>
                    {canal}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="prioridad"
                color="primary"
                Label={{ label: 'Prioritario' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="hasOutSite"
                color="primary"
                Label={{ label: 'Out Site' }}
              />
            </div>
          </div>
          <div className="border-b-2 border-dashed py-8 ">
            <Field
              name="observacionCoordinacion"
              label="Observacion coordinacion"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>
          <div className="my-8 flex space-x-8">
            <div className="flex-grow">
              <div className="flex space-x-8">
                <Field
                  component={KeyboardTimePicker}
                  label="Hora de llegada"
                  name={`fechaYHoraDeLlegada`}
                  ampm={false}
                  inputVariant="outlined"
                  margin="normal"
                  className="flex-1"
                  format="HH:mm"
                />

                <Field
                  component={KeyboardTimePicker}
                  label="Tiempo previo salida"
                  name={`minutosPreviosASalida`}
                  ampm={false}
                  inputVariant="outlined"
                  margin="normal"
                  className="flex-1"
                  format="HH:mm"
                />
              </div>

              <FieldArray
                name={`puntos`}
                render={() => (
                  <TransferReservationLocationFormModal
                    pasajeros={[]}
                    puntos={formik.values.puntos}
                    onEditPuntos={(puntos) =>
                      formik.setFieldValue(`puntos`, puntos)
                    }
                  />
                )}
              />
              <ErrorMessage name={`puntos`}>
                {(msg) => (
                  <p className="mt-4 text-sm text-red-500">
                    {JSON.stringify(msg)}
                  </p>
                )}
              </ErrorMessage>
            </div>
            <div
              className={`w-1/4 transition-all delay-300 duration-300 ease-in-out hover:w-2/5 ${
                formik.values.puntos.length === 0 ? 'opacity-0' : 'opactiy-1'
              }`}
            >
              <StaticMap
                markers={formik.values.puntos.map((punto) => ({
                  lat: punto.domicilio.latitud,
                  lng: punto.domicilio.longitud
                }))}
                size="600x600"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 border-b-2 border-t-2  border-dashed py-8">
            <div className="col-span-3">
              <Field
                component={KeyboardDatePicker}
                label="Fecha de Inicio"
                name={`repetitionInitDate`}
                ampm={false}
                inputVariant="outlined"
                margin="normal"
                className="flex-1"
                format="DD/MM/YYYY"
              />
            </div>
            <div className="col-span-3">
              <Field
                component={KeyboardDatePicker}
                label="Fecha de Finalización"
                name={`repetitionFinishDate`}
                ampm={false}
                inputVariant="outlined"
                margin="normal"
                className="flex-1"
                format="DD/MM/YYYY"
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnMonday"
                color="primary"
                Label={{ label: 'Lunes' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnTuesday"
                color="primary"
                Label={{ label: 'Martes' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnWednesday"
                color="primary"
                Label={{ label: 'Miercoles' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnThursday"
                color="primary"
                Label={{ label: 'Jueves' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnFriday"
                color="primary"
                Label={{ label: 'Viernes' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnSaturday"
                color="primary"
                Label={{ label: 'Sabado' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnSunday"
                color="primary"
                Label={{ label: 'Domingo' }}
                fullWidth
              />
            </div>
          </div>

          <div className="mt-8 flex justify-between">
            <Button
              variant="contained"
              color="primary"
              disabled={formik.values.puntos.length < 2 || !formik.isValid}
              onClick={() => onCalculateDistanceAndCost(formik.values)}
            >
              Calcular costos y distancias
            </Button>

            <Button
              variant="contained"
              color="green"
              className="ml-auto"
              onClick={formik.submitForm}
            >
              Guardar Viaje
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
