import { useHistory } from 'react-router-dom';

import { LoginCredentials } from '../models/User';

import React, { useContext, useEffect, useState } from 'react';
import useAuthRepository from './AuthRepository';
import jwtDecode from 'jwt-decode';
import { UserRole } from 'app/user/models/User';

export interface AuthContextType {
  isAuthenticated: () => boolean;
  forgotPassword: (email: string) => Promise<void>;
  changePassword: (credentials: {
    email: string;
    password: string;
    token: string;
  }) => Promise<void>;
  login: (x: LoginCredentials) => Promise<string>;
  logout: () => void;
  roles: UserRole[];
}
export const AuthContext = React.createContext({} as AuthContextType);
const _isAuthenticated = () => !!localStorage.getItem('token');

export const AuthProvider = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(_isAuthenticated());
  const [roles, setRoles] = useState<UserRole[]>([]);
  const history = useHistory();
  const authRepo = useAuthRepository();

  useEffect(() => {
    setIsAuthenticated(_isAuthenticated());
    const token = localStorage.getItem('token');
    if (token) {
      saveRole(jwtDecode<{ roles: UserRole[] }>(token).roles);
    }
  }, []);

  const saveRole = (roles: UserRole[]) => {
    setRoles(roles);
  };

  const login = async (x: LoginCredentials) => {
    const { token } = await authRepo.login(x);
    localStorage.setItem('token', token);
    localStorage.setItem('email', x.email);
    setIsAuthenticated(true);
    saveRole(jwtDecode<{ roles: UserRole[] }>(token).roles);
    history.push('/main/landing');
    return token;
  };

  const logout = () => {
    localStorage.setItem('token', '');
    setIsAuthenticated(false);
  };

  const forgotPassword = (email: string) => authRepo.forgotPassword(email);

  const changePassword = (credentials: {
    email: string;
    password: string;
    token: string;
  }) => authRepo.changePassword(credentials);

  const value = {
    isAuthenticated,
    forgotPassword,
    changePassword,
    login,
    logout,
    roles
  };
  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth debe estar dentro del proveedor AuthContext');
  }
  return authContext;
};
