import { Avatar, Button } from '@material-ui/core';
import { Modal } from '@vadiun/react-components';
import React, { FunctionComponent } from 'react';
import { ViajeTrasladosGestionEstado } from '../../viajeTraslado/models/TransferReservationPartial';

interface ViajeTrasladoInfoCanceladoProps {
  travelCanceledInfo: ViajeTrasladosGestionEstado;
  onCloseShowInfo: () => void;
  showTravelCanceledInfo: boolean;
  date: string;
  time: string;
}

const ViajeTrasladoInfoCancelado: FunctionComponent<
  ViajeTrasladoInfoCanceladoProps
> = ({
  onCloseShowInfo,
  travelCanceledInfo,
  showTravelCanceledInfo,
  date,
  time
}) => {
  return (
    <Modal
      open={showTravelCanceledInfo}
      onClose={onCloseShowInfo}
      size="sm"
      title={
        <p className="text-lg text-gray-500">Motivo de cancelación de viaje</p>
      }
      body={
        <div>
          <div className="px-8 pt-4">
            <div className="max-w-lg mx-auto border px-6 py-4 rounded-lg">
              <div className="flex items-center mb-6 gap-2">
                <Avatar>{travelCanceledInfo?.user.name.charAt(0)}</Avatar>
                <div>
                  <div className="text-lg font-medium text-gray-800">
                    {travelCanceledInfo?.user.name}
                  </div>
                  <div className="text-gray-400 flex gap-4">
                    <p>{time}</p>
                    <p>{date}</p>
                  </div>
                </div>
              </div>
              <p className="text-lg leading-relaxed mb-6">
                {travelCanceledInfo?.observation.length
                  ? travelCanceledInfo.observation
                  : 'Sin motivo de cancelación.'}
              </p>
            </div>
          </div>
          <div className="flex justify-end mt-10">
            <Button variant="outlined" onClick={onCloseShowInfo}>
              Salir
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default ViajeTrasladoInfoCancelado;
