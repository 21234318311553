import { DriversFiltersType } from 'app/viaje/viajeTraslado/coordinar/components/DriverRecomended/FilterDrivers';
import moment from 'moment';

export const getValidDataFilterDriversRecomended = (
  data: DriversFiltersType
) => {
  const newData: any = {
    vehicle_type: []
  };
  if (data.departure_date) {
    newData.departure_date = moment(data.departure_date).format(
      'YYYY-MM-DDTHH:mm'
    );
  }
  if (data.is_type_service_vip) {
    newData.vehicle_type.push('Vip');
  }
  if (data.is_type_service_executive) {
    newData.vehicle_type.push('Ejecutivo');
  }
  if (data.is_type_service_utilitarian) {
    newData.vehicle_type.push('Utilitario');
  }
  if (data.is_type_service_messaging_utility) {
    newData.vehicle_type.push('Utilitario Mensajería');
  }
  if (data.is_type_service_all) {
    newData.vehicle_type.push('Todos');
  }
  if (data.driver_free) {
    newData.state = 'FREE';
  }
  if (data.driver_busy) {
    newData.state = 'BUSY';
  }

  newData.vehicle_type = newData.vehicle_type.join(',');

  if (newData.vehicle_type === '') {
    delete newData.vehicle_type;
  }

  return newData;
};

export const buildQueryString = (data: any) =>
  Object.entries(data)
    .map((item) => item.join('='))
    .join('&');

export const formatNumberToPrice = (value: number) => {
  return Number(value)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
