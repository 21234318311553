import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useState } from 'react';
import { Card } from 'shared/components';
import AuthorizedCreate from '../components/AuthorizedCreate';
import AuthorizedEdit from '../components/AuthorizedEdit';
import { AuthorizedFormType } from '../components/AuthorizedForm/AuthorizedFormik';
import AuthorizedTable from '../components/AuthorizedTable';
import { AuthorizedModel } from '../models/Authorized';
import useAuthorizeds from '../services/AuthorizedService';

function AuthorizedCrudPage({
  idClientBusiness
}: {
  idClientBusiness: number;
}) {
  const AuthorizedService = useAuthorizeds();
  const authorizedsQuery = useSuperQuery(
    () => AuthorizedService.getAuthorizeds(idClientBusiness),
    {
      showSpinner: true
    }
  );
  const updateAuthorizedMutation = useSuperMutation(
    AuthorizedService.updateAuthorized
  );
  const addAuthorizedMutation = useSuperMutation(
    AuthorizedService.addAuthorized
  );
  const removeAuthorizedMutation = useSuperMutation(
    AuthorizedService.removeAuthorized,
    {
      showSpinner: true
    }
  );
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [updatingAuthorized, setEditingAuthorized] =
    useState<AuthorizedModel>();

  async function update(authorizedValues: AuthorizedFormType, reset) {
    authorizedValues.id = updatingAuthorized!.id;
    await updateAuthorizedMutation.mutate(authorizedValues);
    reset();
    setVisiblePage('table');
    authorizedsQuery.reload();
  }

  async function create(authorizedValues: AuthorizedFormType, reset) {
    await addAuthorizedMutation.mutate(authorizedValues, idClientBusiness);
    reset();
    authorizedsQuery.reload();
  }

  async function remove(authorized: AuthorizedModel) {
    await removeAuthorizedMutation.mutate(authorized);
    authorizedsQuery.reload();
  }

  function selectToEdit(authorized: AuthorizedModel) {
    setEditingAuthorized(authorized);
    setVisiblePage('update');
  }
  return (
    <>
      <Card
        className="flex-grow"
        title="Autorizados"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Autorizados'
            : visiblePage === 'create'
            ? 'Nuevo Autorizado'
            : 'Editar Autorizado'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Autorizado
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      >
        <div className="p-8">
          {visiblePage === 'table' && (
            <AuthorizedTable
              authorizeds={authorizedsQuery.data || []}
              selectToEdit={selectToEdit}
              delete={remove}
            />
          )}

          {visiblePage === 'create' && <AuthorizedCreate create={create} />}
          {visiblePage === 'update' && updatingAuthorized && (
            <AuthorizedEdit
              update={update}
              initialValues={updatingAuthorized}
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default AuthorizedCrudPage;
