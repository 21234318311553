import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import { ChangeEvent, FC, useState } from 'react';

interface MappingDropdownProps {
  label: string;
  value: string;
  options: { value: string, label: string }[];
  onChange: (value: string) => void;
  hasError: boolean;
}

const MappingDropdown: FC<MappingDropdownProps> = ({
  label,
  value,
  options,
  hasError,
  onChange
}) => {
  const [hasFieldError, setHasFieldError] = useState<boolean>(hasError);
  const [currentValue, setCurrentValue] = useState<string>(value);

  const handleOnChange = (e: ChangeEvent<{ value: unknown }>) => {
    const nextValue = e.target.value as string;
    setHasFieldError(false);
    onChange(nextValue);
    setCurrentValue(nextValue);
  }

  return (

    <FormControl fullWidth variant="outlined" style={{ marginTop: '6px' }}>
      <InputLabel error={hasFieldError}>
        {label}
      </InputLabel>
      <Select
        error={hasFieldError}
        style={{
          minWidth: '140px',
          paddingRight: '0px'
        }}
        value={currentValue || ''}
        label={label}
        onChange={handleOnChange}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

};

export default MappingDropdown;