import moment from 'moment';
import { createTransferReservationBaseInitialValues } from '../../../viajeTraslado/forms/base/TransferReservationBaseFormInitialValues';
import { TransferReservationSingleFormType } from './TransferReservationSingleFormType';

export const createTransferReservationSingleFormInitialValues =
  (): TransferReservationSingleFormType => ({
    ...createTransferReservationBaseInitialValues(),
    fechaYHoraDeLlegada: moment(),
    minutosPreviosASalida: moment(),
    puntos: []
  });
