import { useState } from 'react';

import { CostCenterList } from '../components/List'
import CostCenterCreate from '../components/Create'
import CostCenterEdit from '../components/Edit'

import { Card } from 'shared/components';
import { Button } from '@vadiun/react-components';
import { CostCenter } from '../../models/ClientBusiness';
import useCostCenter from '../services/CostCenterService';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { CostCenterFormType } from '../components/CostCenterForm/CostCenterFormik';

interface Props {
  list?: CostCenter[];
  idClientBusiness: number;
  refetch: () => Promise<void>;
}

export const CostCenterCrudPage = ({ list, idClientBusiness, refetch }: Props) => {
  const CostCenterService = useCostCenter();

  const [step, setStep] = useState<'list' | 'create' | 'edit'>('list')
  const [selectCostCenter, setSelectCostCenter] = useState<CostCenter>({
    id: 0,
    name: ''
  })

  function selectToEdit(cost_center: CostCenter) {
    setSelectCostCenter(cost_center);
    setStep('edit');
  }

  const addCostCenterMutation = useSuperMutation(CostCenterService.addCostCenter, {
    onMutationFinish() {
      setStep('list')
    },
  });

  const updateCostCenterMutation = useSuperMutation(
    CostCenterService.updateCostCenter
  );

  const removeCostCenterMutation = useSuperMutation(
    CostCenterService.removeCostCenter,
    {
      showSpinner: true
    }
  );

  async function create(costCenterValues: CostCenterFormType, reset) {
    await addCostCenterMutation.mutate(idClientBusiness, costCenterValues.name);
    reset();
    refetch()
  }

  async function update(costCenterValues: CostCenterFormType, reset) {
    await updateCostCenterMutation.mutate(selectCostCenter?.id!, costCenterValues.name);
    reset();
    refetch()
    setStep('list');
  }

  async function remove(id: number) {
    await removeCostCenterMutation.mutate(id);
    refetch()
  }


  
  return (
    <Card 
      className="flex-grow" 
      title="Centro de costos" 
      subtitle={
        step === 'list' ? 'Listado de centro de costos' 
        : step === 'create' ? 'Crear centro de costos' :
        'Editar centro de costos'
      }
      toolbar={
        step === 'list' ?
        <Button onClick={() => setStep('create')} variant="light">
          Crear
        </Button> :
        <Button onClick={() => setStep('list')} variant="light">
          Volver
        </Button>
      }
    >
      <div className="p-8">
        {step === 'list' ? (
            <CostCenterList 
              data={list} 
              select={selectToEdit} 
              remove={remove}
            />
        ) : step === 'create' ? (
            <CostCenterCreate create={create}/> ) 
        : (
            <CostCenterEdit update={update} initialValues={selectCostCenter} />
        )}
      </div>
    </Card>
  )
}
