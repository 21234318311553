import { useState, useEffect } from 'react';

export const useViewport = (breakpoint: number) => {
    const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
    const breaks = currentWidth < breakpoint
  
    useEffect(() => {
      const handleWindowResize = () => setCurrentWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
  
    return { breaks };
}