import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { AuthorizedFormType } from './AuthorizedFormik';
import AuthorizedFormik from './AuthorizedFormik';

interface Props {
  onSubmit: (value: AuthorizedFormType, reset) => void;
  initialValues?: AuthorizedFormType;
}

function AuthorizedForm({
  onSubmit,
  initialValues = AuthorizedFormik.initialValues
}: Props) {
  return (
    <Formik<AuthorizedFormType>
      initialValues={initialValues}
      validationSchema={AuthorizedFormik.schema}
      enableReinitialize
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
    >
      {({ isSubmitting }) => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <Field
            name="email"
            label="Email"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="phone"
            label="Teléfono"
            variant="outlined"
            className="col-span-3"
            type="number"
            component={TextField}
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AuthorizedForm;
