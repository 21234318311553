import { Button } from '@material-ui/core';
import { Modal } from '@vadiun/react-components';
import React, { FunctionComponent } from 'react';

interface CancelledTravelMessageInfoProps {
  message: string;
  onCloseShowInfo: () => void;
  showTravelCanceledInfo: boolean;
}

const CancelledTravelMessageInfo: FunctionComponent<
  CancelledTravelMessageInfoProps
> = ({ message, onCloseShowInfo, showTravelCanceledInfo }) => {
  return (
    <Modal
      open={showTravelCanceledInfo}
      onClose={onCloseShowInfo}
      size="sm"
      title={
        <p className="text-lg text-gray-500">Motivo de cancelación de viaje</p>
      }
      body={
        <div>
          <div className="px-8 pt-4">
            <div className="max-w-lg mx-auto border px-6 py-4 rounded-lg">
              <p className="text-lg leading-relaxed mb-6">
                {message.length ? message : 'Sin motivo de cancelación.'}
              </p>
            </div>
          </div>
          <div className="flex justify-end mt-10">
            <Button variant="outlined" onClick={onCloseShowInfo}>
              Salir
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default CancelledTravelMessageInfo;
