import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useState } from 'react';
import PageContainer from '../../../../layout/components/PageContainer';
import PageHeader from '../../../../layout/components/PageHeader';
import TitularCreate from '../componentes/TitularCreate';
import TitularEdit from '../componentes/TitularEdit';
import TitularTable from '../componentes/TitularTable';
import { TitularModel } from '../models/Titular';
import {
  TitularFormSchema,
  titularFormSchemaToModel,
  titularModelToFormSchema
} from '../models/TitularFormSchema';
import useTitulares from '../services/TitularService';

function TitularCrudPage() {
  const TitularService = useTitulares();
  const titularesQuery = useSuperQuery(TitularService.getTitularesWithDeleted, {
    showSpinner: true
  });
  const editTitularMutation = useSuperMutation(TitularService.editTitular, {
    showSpinner: true
  });
  const addTitularMutation = useSuperMutation(TitularService.addTitular, {
    showSpinner: true
  });
  const removeTitularMutation = useSuperMutation(TitularService.removeTitular, {
    showSpinner: true
  });
  const restoreTitularMutation = useSuperMutation(
    TitularService.restoreTitular,
    {
      showSpinner: true
    }
  );
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'edit'>(
    'table'
  );
  const [editingTitular, setEditingTitular] = useState<TitularModel>();

  async function edit(titularValues: TitularFormSchema, reset) {
    const titular = titularFormSchemaToModel(titularValues);
    titular.id = editingTitular!.id;
    await editTitularMutation.mutate(titular);
    reset();
    setVisiblePage('table');
    titularesQuery.reload();
  }

  async function create(titularValues: TitularFormSchema, reset) {
    const titular = titularFormSchemaToModel(titularValues);
    await addTitularMutation.mutate(titular);
    reset();
    titularesQuery.reload();
  }

  async function remove(titular: TitularModel) {
    await removeTitularMutation.mutate(titular);
    titularesQuery.reload();
  }

  async function restore(titular: TitularModel) {
    await restoreTitularMutation.mutate(titular);
    titularesQuery.reload();
  }

  function selectToEdit(titular: TitularModel) {
    setEditingTitular(titular);
    setVisiblePage('edit');
  }
  return (
    <>
      <PageHeader
        title="Titulares"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Titulares'
            : visiblePage === 'create'
            ? 'Nuevo Titular'
            : 'Editar Titular'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Titular
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <TitularTable
            titulares={titularesQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && <TitularCreate create={create} />}
        {visiblePage === 'edit' && editingTitular && (
          <TitularEdit
            editar={edit}
            initialValues={titularModelToFormSchema(editingTitular)}
          />
        )}
      </PageContainer>
    </>
  );
}

export default TitularCrudPage;
