import { httpClient } from '../../../shared/services/http/httpClient';
import {
  userFormBuilder,
  UserFormType
} from '../components/UserForm/UserFormType';
import { userBuilder, UserModel, UserRole } from '../models/User';

const useUsersRepository = () => {
  const removeUser = (id: number) => {
    return httpClient.delete('users/' + id);
  };

  const restoreUser = (id: number) => {
    return httpClient.put('users/' + id + '/restore');
  };

  const updateUser = (user: {
    name: string;
    email: string;
    roles: string[];
    id: number;
  }) => {
    return httpClient.put('users/' + user.id, {
      email: user.email,
      name: user.name,
      roles: user.roles
    });
  };

  const getAll = async (
    filters: { roles: string[] } = { roles: [] }
  ): Promise<UserModel[]> => {
    let query = '';
    if (filters.roles.length > 0) {
      query = '?' + filters.roles.map((r) => 'roles[]=' + r).join('&');
    }
    const response = await httpClient.get('users' + query);
    return response.map(userBuilder.fromBackEnd);
  };

  const addUser = (x: UserFormType) => {
    return httpClient.post('users', userFormBuilder.toBackEnd(x));
  };

  const findByName = async (name: string) => {
    return (await getAll({ roles: ['Analista de cuentas a pagar'] })).filter(
      (client) => client.name.toLowerCase().includes(name.toLowerCase())
    );
  };

  const getAllOficialesDeCuenta = () =>
    getAll({ roles: ['Analista de cuentas a pagar'] });

  const getRoles = (): Promise<UserRole[]> => httpClient.get('roles');

  const changePassword = (values: { password: string; id: number }) =>
    httpClient.put(`users/${values.id}/change-password`, {
      password: values.password
    });

  return {
    removeUser,
    getAll,
    restoreUser,
    findByName,
    updateUser,
    addUser,
    getRoles,
    changePassword,
    getAllOficialesDeCuenta
  };
};

export default useUsersRepository;
