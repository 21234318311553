import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import {
  CANALES_DE_VIAJE,
  TIPOS_SERVICIO_VIAJE_TRASLADO
} from 'app/viaje/models';
import { domicilioValidation } from 'shared/components/FormikAddress';
import * as Yup from 'yup';

export const transferReservationBaseFormValidation = (
  cliente: ClientBusinessModel | undefined,
  isEvent = false
) =>
  Yup.object({
    cliente: Yup.mixed().required('El cliente es obligatorio'),
    solicitante: Yup.object().shape({
      name: Yup.string().required('El nombre del solicitante es requerido'),
      phone: Yup.string().nullable(),
      email: Yup.string().nullable()
    }),
    canalIngreso: Yup.string()
      .required('El canal de ingreso es obligatorio')
      .oneOf(CANALES_DE_VIAJE),
    plataformaId: Yup.string().nullable(),
    prioridad: Yup.boolean(),
    hasOutSite: Yup.boolean(),
    fixedRate: Yup.mixed(),
    pay_with_cash: Yup.boolean(),
    tipoServicio: Yup.mixed()
      .required('El tipo de servicio es obligatorio')
      .oneOf(TIPOS_SERVICIO_VIAJE_TRASLADO),
    autorizado: cliente?.reservationRequirement.needsAuthorized
      ? Yup.mixed().when('pay_with_cash', {
          is: false,
          then: Yup.mixed().required('El autorizado es requerido')
        })
      : Yup.mixed(),
    costCenter: cliente?.reservationRequirement.needsCostCenter
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string()
              .nullable()
              .required('El centro de costos es requerido')
          })
      : Yup.string().nullable(),
    subCostCenter: cliente?.reservationRequirement.needsSubCostCenter
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string()
              .nullable()
              .required('El sub centro de costos es requerido')
          })
      : Yup.string().nullable(),
    sector: cliente?.reservationRequirement.needsSector
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string().nullable().required('El sector es requerido')
          })
      : Yup.string().nullable(),
    buyOrder: cliente?.reservationRequirement.needsBuyOrder
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string()
              .nullable()
              .required('La orden de compra es requerida')
          })
      : Yup.string().nullable(),
    employeeNumber: cliente?.reservationRequirement.needsEmployeeNumber
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string().nullable().required('El legajo es requerido')
          })
      : Yup.string().nullable(),
    platformApplication: cliente?.reservationRequirement
      .needsPlatformApplication
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string()
              .nullable()
              .required('La solicitud en plataforma es requerida')
          })
      : Yup.string().nullable(),
    tripNumber: cliente?.reservationRequirement.needsTripNumber
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string()
              .nullable()
              .required('El nro de viaje es requerido')
          })
      : Yup.string().nullable(),
    data1: cliente?.reservationRequirement.needsData1
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string().nullable().required('El dato 1 es requerido')
          })
      : Yup.string().nullable(),
    data2: cliente?.reservationRequirement.needsData2
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string().nullable().required('El dato 2 es requerido')
          })
      : Yup.string().nullable(),
    data3: cliente?.reservationRequirement.needsData3
      ? Yup.string()
          .nullable()
          .when('pay_with_cash', {
            is: false,
            then: Yup.string().nullable().required('El dato 3 es requerido')
          })
      : Yup.string().nullable()
  });

export const transferReservationBaseLocationFormValidation = Yup.object().shape(
  {
    domicilio: domicilioValidation,
    pasajeros: Yup.array().of(
      Yup.object().shape({
        nombreCompleto: Yup.string().required('El nombre es requerido'),
        telefono: Yup.string().nullable(),
        mail: Yup.string().nullable().email('El mail debe ser valido')
      })
    ),
    observacion: Yup.string().nullable(),
    espera: Yup.string().nullable()
  }
);
