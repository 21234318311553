import { DomicilioGoogle } from 'shared/models/DomicilioGoogle';

export interface DomicilioModel
  extends Omit<DomicilioGoogle, 'pais' | 'codigoPostal' | 'text'> {
  departamento: string | null;
  piso: string | null;
}

export const domicilioBuilder = {
  fromBackEnd: (dom): DomicilioModel => {
    return {
      latitud: dom.latitude,
      longitud: dom.longitude,
      calle: dom.street,
      numero: dom.streetNumber,
      partido: dom.district,
      localidad: dom.locality,
      provincia: dom.province,
      departamento: dom.apartment,
      piso: dom.floor
    };
  },
  toBackEnd: (dom: DomicilioModel) => {
    return {
      street: dom.calle,
      street_number: dom.numero === 's/n' ? 0 : Number(dom.numero),
      floor: dom.piso,
      apartment: dom.departamento,
      locality: dom.localidad,
      district: dom.partido,
      province: dom.provincia,
      latitude: dom.latitud,
      longitude: dom.longitud
    };
  }
};
