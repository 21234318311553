import { UserModel } from 'app/user/models/User';

export type UserFormType = {
  name: string;
  email: string;
  password: string;
  roles: string[];
};

export const userFormBuilder = {
  toBackEnd: (user: UserFormType) => {
    return {
      email: user.email,
      name: user.name,
      password: user.password,
      roles: user.roles
    };
  },
  fromModel: (user: UserModel): UserFormType => {
    return {
      ...user
    };
  }
};
