import { Table, TableColumn } from '@vadiun/react-datatable';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useClientInformationRepository } from '../services/clientInformationRepository';

export const PricePerClientPage = () => {
  const repo = useClientInformationRepository();
  const pricePerClient = useSuperQuery(repo.getPricesPerClient);

  if (pricePerClient.data === undefined) {
    return <p>Cargando...</p>;
  }

  const data = pricePerClient.data.map((x) => ({
    ...x,
    ejecutivo: Number(x.ejecutivo),
    mensajeria: Number(x.mensajeria),
    utilitario: Number(x.utilitario),
    flete: Number(x.flete),
    vip: Number(x.vip),
    comisionBase: Number(x.comisionBase),
    comisionExcedente: Number(x.comisionExcedente),
    limiteComisionBase: Number(x.limiteComisionBase),
    kmMinPrice: Number(x.kmMinPrice),
    hourWaitingPrice: Math.round(Number(x.hourWaitingPrice))
  }));
  const columns: TableColumn<typeof data[0]>[] = [
    {
      name: 'client',
      label: 'Cliente',
      options: {
        filterType: 'multiselect'
      }
    },
    {
      name: 'kmMin',
      label: 'Km Mínimo ',
      options: {
        filter: false
      }
    },
    {
      name: 'kmMinPrice',
      label: 'Km Mínimo $',
      options: {
        filter: false
      }
    },
    {
      name: 'hourWaitingPrice',
      label: 'Espera $/h',
      options: {
        filter: false
      }
    },
    {
      name: 'vtoActual',
      label: 'Vencimiento Actual',
      options: {
        filter: false
      }
    },
    {
      name: 'vtoAnterior',
      label: 'Vencimiento Anterior',
      options: {
        filter: false
      }
    },
    {
      name: 'precioOutsite',
      label: 'Precio Outsite',
      options: {
        filter: false
      }
    },
    {
      name: 'item',
      label: 'Item',
      options: {
        filter: false
      }
    },
    {
      name: 'ejecutivo',
      label: 'Ejecutivo',
      options: {
        filter: false
      }
    },
    {
      name: 'vip',
      label: 'VIP',
      options: {
        filter: false
      }
    },
    {
      name: 'utilitario',
      label: 'Utilitario',
      options: {
        filter: false
      }
    },
    {
      name: 'mensajeria',
      label: 'Mensajeria',
      options: {
        filter: false
      }
    },
    {
      name: 'flete',
      label: 'Flete',
      options: {
        filter: false
      }
    },
    {
      name: 'comisionBase',
      label: 'Comisión Base',
      options: {
        filter: false
      }
    },
    {
      name: 'limiteComisionBase',
      label: 'Limite Comisión Base',
      options: {
        filter: false
      }
    },
    {
      name: 'comisionExcedente',
      label: 'Comisión Excedente',
      options: {
        filter: false
      }
    }
  ];

  return (
    <>
      <PageHeader title="Precios por cliente" />
      <PageContainer>
        <div className="h-full w-full">
          <Table
            options={{
              print: false,
              filter: true
            }}
            title={'Tabla de precios por cliente'}
            data={data}
            columns={columns}
          />
        </div>
      </PageContainer>
    </>
  );
};
