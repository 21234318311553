import {
  faAddressCard,
  faCalendarDay,
  faCalendarTimes,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SideStepper } from '@vadiun/react-components';
import { BillerModel } from 'app/biller/models/BillerModel';
import { BusinessUnitType } from 'app/business/services/useBusinessUnit';
import React, { useState } from 'react';
import { VehiculoPartialModel } from '../../vehiculos/models/Vehiculo';
import {
  ChoferFormSchema,
  choferFormSchemaInitialValues
} from '../models/ChoferFormSchema';
import ChoferFormGeneral from './ChoferForm/ChoferFormGeneral';
import ChoferFormPersonal from './ChoferForm/ChoferFormPersonal';
import ChoferFormTurno from './ChoferForm/ChoferFormTurno';
import ChoferFormVencimientos from './ChoferForm/ChoferFormVencimientos';

interface Props {
  vehiculos: VehiculoPartialModel[];
  create: (choferFormSchema: ChoferFormSchema, reset) => void;
  proveedores: BillerModel[];
  businessUnit: BusinessUnitType[];
}

const ChoferCreate = ({ vehiculos, create, proveedores, businessUnit }: Props) => {
  const [currentStep, setCurrentStep] = useState<
    'general' | 'personal' | 'turno' | 'vencimientos'
  >('general');
  const [choferForm, setChoferForm] = useState(
    choferFormSchemaInitialValues(vehiculos, proveedores)
  );

  const steps = {
    general: (
      <ChoferFormGeneral
        submitButtonTitle="Continuar"
        vehiculos={vehiculos}
        proveedores={proveedores}
        businessUnit={businessUnit}
        onSubmit={(values) => {
          setChoferForm((currentValue) => ({
            ...currentValue,
            general: values
          }));
          setCurrentStep('personal');
        }}
        initialValues={choferForm.general}
      />
    ),
    personal: (
      <ChoferFormPersonal
        onSubmit={(values) => {
          setChoferForm((currentValue) => ({
            ...currentValue,
            personal: values
          }));
          setCurrentStep('turno');
        }}
        submitButtonTitle="Continuar"
        onGoBack={() => setCurrentStep('general')}
        initialValues={choferForm.personal}
      />
    ),
    turno: (
      <ChoferFormTurno
        submitButtonTitle="Continuar"
        onSubmit={(values) => {
          setChoferForm((currentValue) => ({ ...currentValue, turno: values }));
          setCurrentStep('vencimientos');
        }}
        onGoBack={() => setCurrentStep('personal')}
        initialValues={choferForm.turno}
      />
    ),
    vencimientos: (
      <ChoferFormVencimientos
        onSubmit={(values) => {
          create({ ...choferForm, vencimientos: values }, () =>
            setChoferForm(choferFormSchemaInitialValues(vehiculos, proveedores))
          );
          setCurrentStep('general');
        }}
        initialValues={choferForm.vencimientos}
        onGoBack={() => setCurrentStep('turno')}
      />
    )
  };
  return (
    <div className="w-full flex flex-col md:flex-row bg-white">
      <div className="p-8">
        <SideStepper.Container value={currentStep}>
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faUser} />}
            value={'general'}
            label="Informacion general"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faAddressCard} />}
            value={'personal'}
            label="Informacion personal"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faCalendarDay} />}
            value={'turno'}
            label="Turno"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faCalendarTimes} />}
            value={'vencimientos'}
            label="Vencimientos"
          />
        </SideStepper.Container>
      </div>
      <div className="border-l-2 border-gray-200 border-solid p-12 w-full">
        {steps[currentStep]}
      </div>
    </div>
  );
};

export default ChoferCreate;
