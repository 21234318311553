import { notDeleted } from 'shared/helpers/deletedHandler';
import { httpClient } from 'shared/services/http/httpClient';
import {
  titularBuilder,
  TitularModel,
  TitularModelCRUD
} from '../models/Titular';

const useTitulares = () => {
  const addTitular = (x: TitularModelCRUD) => {
    return httpClient.post('vehicle-owners', titularBuilder.toBackEnd(x));
  };

  const removeTitular = (titular: TitularModel) => {
    return httpClient.delete('vehicle-owners/' + titular.id);
  };

  const restoreTitular = (titular: TitularModel) => {
    return httpClient.put('vehicle-owners/' + titular.id + '/restore');
  };

  const editTitular = (titular: TitularModelCRUD) => {
    return httpClient.put(
      'vehicle-owners/' + titular.id,
      titularBuilder.toBackEnd(titular)
    );
  };

  const getTitularesWithDeleted = async (): Promise<TitularModel[]> => {
    const response = await httpClient.get('vehicle-owners');
    return response.map(titularBuilder.fromBackEnd);
  };

  const getTitulares = async () =>
    (await getTitularesWithDeleted()).filter(notDeleted);

  return {
    addTitular,
    removeTitular,
    editTitular,
    getTitulares,
    getTitularesWithDeleted,
    restoreTitular
  };
};

export default useTitulares;
