import React from 'react';
import { Card } from 'shared/components';
import VehiculoModeloForm from './VehiculoModeloForm';
import { VehiculoModeloFormSchema } from '../models/VehiculoModeloFormSchema';
import { VehiculoFabricanteModel } from '../../fabricanteVehiculo/models/VehiculoFabricante';

interface Props {
  create: (VehiculoModeloValues: VehiculoModeloFormSchema, reset) => void;
  fabricantes: VehiculoFabricanteModel[];
}
function VehiculoModeloCreate({ create, fabricantes }: Props) {
  return (
    <Card title="Nuevo Modelo" className="w-full">
      <div className="p-8">
        <VehiculoModeloForm onSubmit={create} fabricantes={fabricantes} />
      </div>
    </Card>
  );
}

export default VehiculoModeloCreate;
