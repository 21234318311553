import { useState } from 'react';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { Button } from '@vadiun/react-components';

import moment from 'moment';
import useViajesTraslado from '../../viajeTraslado/services/ViajeTrasladoService';

import { useTableQuery } from '@vadiun/react-datatable';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { useSuperMutation } from '@vadiun/react-hooks';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';

import { TransferReservationManagmentModel } from '../models/TransferReservationManagment';

import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import ViajeTrasladoResumenModal from '../../viajeTraslado/components/ViajeTrasladoResumenModal';
import { ViajeTrasladoDeleteTable } from '../components/ViajeTrasladoDeleteTable';

const ViajeTrasladoDeletePage = () => {

  const ViajeTrasladoService = useViajesTraslado();
  const verifyAction = useVerifyAction()

  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useFilters({
    dateFrom: moment().hour(0).minutes(0),
    dateTo: moment().hour(23).minutes(59),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false
  });

  const [locationsDetail, setLocationsDetail] = useState<
    | {
        isOpen: false;
        details: undefined;
      }
    | {
        isOpen: true;
        details: TransferReservationManagmentModel;
      }
  >({ isOpen: false, details: undefined });

  const tablePagination = useTableQuery(
    [
      'reservas',
      'gestion',
      {
        ...filters,
        dateFrom: filters.dateFrom.format(),
        dateTo: filters.dateTo.format()
      }
    ],
    async (params) => {
      const res = await ViajeTrasladoService.getAll(params, filters);
      return res;
    }
  );
  
  const deleteReservationMutation = useSuperMutation(ViajeTrasladoService.deleteReservation, {
    onSuccess() {
      tablePagination.refetch()
    },
  })

  return (
    <>
      <PageHeader
        title="Eliminar reservas"
        toolbar={
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtros
            </Button>
        }
      />
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          />
        </div>
      </Drawer>
      <PageContainer lessMargin>
        <div className="w-full">
          <ViajeTrasladoDeleteTable
            tableQuery={tablePagination}
            onLocationsDetails={(reservation) =>
              setLocationsDetail({ isOpen: true, details: reservation })
            }
            onDelete={(id) => (
                <Button 
                  onClick={() => 
                    verifyAction({
                      title: 'Estas por eliminar una reserva!',
                      body: 'Esta accion va a eliminar una reserva. ¿Estas seguro?',
                      onAccept: () => deleteReservationMutation.mutate(id)
                    })
                  }
                  variant="light" 
                  color="red"
                  disabled={deleteReservationMutation.isLoading}
                >
                  Eliminar
                </Button>
            )}
          />
        </div>
      </PageContainer>
      {locationsDetail.details && (
        <ViajeTrasladoResumenModal
          isOpen={locationsDetail.isOpen}
          reservation={locationsDetail.details!}
          handleClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
        />
      )}
    </>
  );
};

export default ViajeTrasladoDeletePage;
