import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';
import { ClientBusinessPartialModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { Field, Form, Formik } from 'formik';
import { AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import React from 'react';
import { Moment } from 'moment';
import { TextField } from 'formik-material-ui';

export interface FacturacionTableFiltersType {
  dateFrom: Moment;
  dateTo: Moment;
  client: ClientBusinessPartialModel | null;
  nroFactura: number | string;
  idLiquidacion: string;
}

interface Props {
  onSubmit(filters: FacturacionTableFiltersType);
  initialValues: FacturacionTableFiltersType;
  findClientByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
}

export const FacturacionTableFiltersComponent = ({
  onSubmit,
  initialValues,
  findClientByName
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ touched, errors }) => (
        <Form className="flex max-w-md flex-col gap-2 p-8">
          <Field
            component={KeyboardDatePicker}
            label="Fecha desde"
            name="dateFrom"
            inputVariant="outlined"
            margin="dense"
            format="DD/MM/YYYY"
          />
          <Field
            component={KeyboardDatePicker}
            label="Fecha hasta"
            name="dateTo"
            inputVariant="outlined"
            margin="dense"
            format="DD/MM/YYYY"
          />
          <Field
            name="client"
            component={FormikAutocompleteAsync}
            fetchOptions={findClientByName}
            fullWidth
            getOptionLabel={(client: ClientBusinessPartialModel) =>
              client.fantasyName
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMaterial
                {...params}
                error={touched['client'] && !!errors['client']}
                helperText={errors['client']}
                label="Cliente"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            )}
          />
          <Field
            component={TextField}
            label="Nro factura"
            name="nroFactura"
            variant="outlined"
            margin="dense"
          />
          <Field
            component={TextField}
            label="Id Liquidación"
            name="idLiquidacion"
            variant="outlined"
            type="number"
            margin="dense"
          />
          <div className="flex justify-end">
            <Button type="submit" variant="contained">
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
