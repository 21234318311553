import { Button } from '@vadiun/react-components';
import { Table, TableColumn } from '@vadiun/react-datatable';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { Field, Form, Formik } from 'formik';

import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { TableCard, TableCardItem } from 'shared/components/TableCard';
import { useClientInformationRepository } from '../services/clientInformationRepository';

export const AnalisisComercialPage = () => {
  const repo = useClientInformationRepository();
  const [filters, setFilters] = useState<{
    fromDate: Moment;
    toDate: Moment;
  }>({
    fromDate: moment().startOf('month'),
    toDate: moment()
  });

  const priceFormatter = (num: number) =>
    '$' + new Intl.NumberFormat('de-DE').format(num);

  const analisisComercial = useSuperQuery(
    () =>
      repo.getAnalisisComercial({ from: filters.fromDate, to: filters.toDate }),
    { showSpinner: true },
    [filters]
  );

  if (analisisComercial.data === undefined) {
    return <p>Cargando...</p>;
  }

  const data = analisisComercial.data.clientes.map((x) => ({
    ...x,
    porc_consumo: x.porc_consumo,
    consumo: x.consumo,
    precio_km_chofer: x.precio_km_chofer,
    consumo_viaje_promedio: x.consumo_viaje_promedio,
    precio_comision_agencia: x.precio_comision_agencia,
    comision_admin_base: x.comision_admin_base,
    comision_admin_excedente: x.comision_admin_excedente,
    comision_administrativa: x.comision_administrativa,
    margen_bruto_viaje: x.margen_bruto_viaje,
    margen_bruto_cliente: x.margen_bruto_cliente
  }));

  const columns: TableColumn<typeof data[0]>[] = [
    { name: 'puesto', label: 'Puesto' },
    { name: 'nombre_cliente', label: 'Nombre cliente' },
    {
      name: 'consumo',
      label: 'Consumo',
      options: {
        customBodyRender: (value) => priceFormatter(value)
      }
    },
    { name: 'porc_consumo', label: 'Porc consumo' },
    { name: 'cant_viajes', label: 'Cant viajes' },
    {
      name: 'consumo_viaje_promedio',
      label: 'Consumo viaje prom',
      options: { customBodyRender: (value) => priceFormatter(value) ?? '-' }
    },
    {
      name: 'precio_km_ejecutivo',
      label: 'Precio km ejecutivo',
      options: {
        customBodyRender: (value) => priceFormatter(value)
      }
    },
    { name: 'comision_admin_base', label: 'Comision admin base' },
    { name: 'comision_admin_excedente', label: 'Comision admin excedente' },
    {
      name: 'precio_km_chofer',
      label: 'Precio km chofer',
      options: {
        customBodyRender: (value) => priceFormatter(value)
      }
    },
    {
      name: 'precio_comision_agencia',
      label: 'Precio comision agencia',
      options: {
        customBodyRender: (value) => priceFormatter(value)
      }
    },
    {
      name: 'comision_administrativa',
      label: 'Comision administrativa',
      options: {
        customBodyRender: (value) => priceFormatter(value)
      }
    },
    {
      name: 'margen_bruto_cliente',
      label: 'Margen bruto cliente',
      options: {
        customBodyRender: (value) => priceFormatter(value)
      }
    },
    {
      name: 'margen_bruto_viaje',
      label: 'Margen bruto viaje',
      options: { customBodyRender: (value) => priceFormatter(value) ?? '-' }
    },
    { name: 'vencimiento_tarifa', label: 'Vencimiento tarifa' }
  ];

  return (
    <>
      <PageHeader title="Analisis Comercial" />
      <PageContainer lessMargin>
        <div className="h-full w-full">
          <div className="mb-4 flex gap-4">
            <TableCard title="Contado">
              <TableCardItem
                label={'Total'}
                value={priceFormatter(analisisComercial.data.total_contado)}
              />
              <TableCardItem
                label={'Promedio'}
                value={priceFormatter(
                  analisisComercial.data.viaje_promedio_contado
                )}
              />
              <TableCardItem
                label={'Cantidad'}
                value={analisisComercial.data.cant_viaje_contado}
              />
            </TableCard>
            <TableCard title="Cuenta Corriente">
              <TableCardItem
                label={'Total'}
                value={priceFormatter(
                  analisisComercial.data.total_cuenta_corriente
                )}
              />
              <TableCardItem
                label={'Promedio'}
                value={priceFormatter(
                  analisisComercial.data.viaje_promedio_cuenta_corriente
                )}
              />
              <TableCardItem
                label={'Cantidad'}
                value={analisisComercial.data.cant_viaje_cuenta_corriente}
              />
            </TableCard>
            <TableCard title="General">
              <TableCardItem
                label={'Total'}
                value={priceFormatter(analisisComercial.data.total_general)}
              />
              <TableCardItem
                label={'Promedio'}
                value={priceFormatter(analisisComercial.data.viaje_promedio)}
              />
              <TableCardItem
                label={'Cantidad'}
                value={analisisComercial.data.cant_viaje}
              />
            </TableCard>
          </div>
          <Table
            title={
              <Formik
                onSubmit={async (filters) => {
                  setFilters(filters);
                }}
                initialValues={filters}
              >
                {(formik) => (
                  <Form className="my-4 flex gap-8">
                    <Field
                      name="fromDate"
                      label="Desde*"
                      inputVariant="outlined"
                      component={KeyboardDatePicker}
                      ampm={false}
                      format="DD/MM/YYYY"
                      size="small"
                    />
                    <Field
                      name="toDate"
                      label="Hasta*"
                      inputVariant="outlined"
                      component={KeyboardDatePicker}
                      ampm={false}
                      format="DD/MM/YYYY"
                      size="small"
                    />

                    <Button
                      variant="contained"
                      onClick={formik.submitForm}
                      isLoading={analisisComercial.isLoading}
                    >
                      Buscar
                    </Button>
                  </Form>
                )}
              </Formik>
            }
            data={data}
            columns={columns}
            options={{
              filter: false,
              print: false
            }}
          />
        </div>
      </PageContainer>
    </>
  );
};
