import { httpClient } from 'shared/services/http/httpClient';
import {
  clientprivateBuilder,
  ClientPrivateModel
} from '../models/ClientPrivate';

import {
  clientprivateFormBuilder,
  ClientPrivateFormType
} from '../forms/ClientPrivateFormType';

const useClientPrivates = () => {
  const addClientPrivate = (x: ClientPrivateFormType) => {
    return httpClient.post(
      'private-clients',
      clientprivateFormBuilder.toBackEnd(x)
    );
  };

  const removeClientPrivate = (clientprivate: ClientPrivateModel) => {
    return httpClient.delete('private-clients/' + clientprivate.id);
  };

  const restoreClientPrivate = (clientprivate: ClientPrivateModel) => {
    return httpClient.put('private-clients/' + clientprivate.id + '/restore');
  };

  const updateClientPrivate = (clientprivate: ClientPrivateFormType) => {
    return httpClient.put(
      'private-clients/' + clientprivate.id,
      clientprivateFormBuilder.toBackEnd(clientprivate)
    );
  };

  const getClientPrivate = async (id: number) => {
    const res = await httpClient.get('private-clients/' + id);
    return clientprivateBuilder.fromBackEnd(res);
  };

  const getClientPrivates = async (): Promise<ClientPrivateModel[]> => {
    const response = await httpClient.get('private-clients');
    return response.map(clientprivateBuilder.fromBackEnd);
  };

  const findByName = async (name: string) => {
    return (await getClientPrivates()).filter((client) =>
      `${client.name}${client.lastName}${client.code}`
        .toLowerCase()
        .includes(name.replace(' ', '').toLowerCase())
    );
  };

  return {
    addClientPrivate,
    removeClientPrivate,
    updateClientPrivate,
    getClientPrivates,
    restoreClientPrivate,
    findByName,
    getClientPrivate
  };
};

export default useClientPrivates;
