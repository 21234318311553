import { Moment } from 'moment';
import { httpClient } from 'shared/services/http/httpClient';
import { ManualAndAuditedReservationsPerMonthModel } from '../models/ManualAndAuditedReservationsPerMonthModel';
import { TransferReservationDriverResumeModel } from '../models/TansferReservationDriverResumeModel';
import { TransferReservationAssignedPerDayModel } from '../models/TransferReservationAssignedPerDayModel';
import { TransferReservationPerCoordinatorModel } from '../models/TransferReservationPerCoordinatorModel';
import { TransferReservationPerDayResumeModel } from '../models/TransferReservationPerDayResumeModel';
import { TransferReservationPerOperatorAndClient } from '../models/TransferReservationPerOperatorAndClient';
import { TransferReservationPerOperatorResumeModel } from '../models/TransferReservationPerOperatorResumeModel';

export const useTransferReservationStatisticsRepository = () => {
  const perOperator =
    async (): Promise<TransferReservationPerOperatorResumeModel> => {
      return await httpClient.get(`transfer-reservations/by-operator`);
    };

  const perCoordinator =
    async (): Promise<TransferReservationPerCoordinatorModel> => {
      return await httpClient.get(
        `transfer-reservations/amount-assigned-per-coordinator`
      );
    };

  const perDay = async (): Promise<TransferReservationPerDayResumeModel> => {
    return await httpClient.get(`transfer-reservations/received-per-week`);
  };

  const perMonth = async (): Promise<
    ManualAndAuditedReservationsPerMonthModel[]
  > => {
    return await httpClient.get(`transfer-reservations/percentage-audited`);
  };

  const assignedPerDay =
    async (): Promise<TransferReservationAssignedPerDayModel> => {
      return await httpClient.get(
        `transfer-reservations/amount-assigned-per-day`
      );
    };

  const getPerOperatorAndClient = async (filters: {
    fromDate: Moment;
    toDate: Moment;
  }): Promise<TransferReservationPerOperatorAndClient> => {
    const filterParams = {
      date_from: filters.fromDate.format('YYYY-MM-DD'),
      date_to: filters.toDate.format('YYYY-MM-DD')
    };
    const paramsString = Object.entries(filterParams)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return await httpClient.get(
      `transfer-reservations/amount-of-administrated-by-operator-and-client?${paramsString}`
    );
  };

  const perDriverResume = async (filters: {
    fromDate: Moment;
    toDate: Moment;
  }): Promise<TransferReservationDriverResumeModel[]> => {
    const filterParams: string[] = [];
    if (filters.fromDate) {
      filterParams.push(`from=${filters.fromDate.format('YYYY-MM-DD')}`);
    }
    if (filters.toDate) {
      filterParams.push(`to=${filters.toDate.format('YYYY-MM-DD')}`);
    }
    const res = await httpClient.get(
      `drivers/statistics?${filterParams.join('&')}`
    );
    return res.map((d) => ({
      driverCode: d.code,
      driverName: d.name,
      driverCategory: d.category,
      driverBusinessUnitName: d.business_unit_name,
      carCategory: d.vehicle_category,
      hourPrice: d.valor_minuto * 60,
      kmPrice: d.valor_km,
      kmIdle: d.total_km_sin_pasajero,
      percentageKmIdle: d.porcentaje_km_sin_pasajero,
      kmWithPassanger: d.total_km_trabajado,
      totalKm: d.total_km,
      totalHours: (d.minutos_con_turno_abierto / 60).toFixed(2),
      income: d.total_recaudacion,
      prom_km_ocioso: d.prom_km_ocioso,
      prom_km_pasajero: d.prom_km_pasajero,
      porcentaje_km_pasajero: d.porcentaje_km_pasajero
    }));
  };

  const getDemoraPromedio = async (): Promise<{ demora: number }> => {
    const res = await httpClient.get('puntualidad-choferes-indicador');
    return { demora: Math.round(Number(res)) };
  };

  return {
    perOperator,
    perDay,
    perMonth,
    assignedPerDay,
    perCoordinator,
    getPerOperatorAndClient,
    perDriverResume,
    getDemoraPromedio
  };
};
