import { Divider, Drawer, IconButton, TextField } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { Table, TableColumn } from '@vadiun/react-datatable';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { Field, Form, Formik } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Card } from 'shared/components';
import { LineChartComponent } from '../components/LineChartComponent';
import { useClientInformationRepository } from '../services/clientInformationRepository';

export const ReservationAmountPerClient = () => {
  const repo = useClientInformationRepository();
  const businessClientRepo = useClientBusiness();
  const [filters, setFilters] = useState<{
    fromDate: Moment;
    toDate: Moment;
    clientes: { fantasyName: string }[];
  }>({
    fromDate: moment().add(-1, 'year'),
    toDate: moment(),
    clientes: []
  });
  const [showFilters, setShowFilters] = useState(false);
  const businessClients = useSuperQuery(businessClientRepo.getAll);

  const reservationsPerClient = useSuperQuery(
    () => repo.getReservationAmountPerClient(filters),
    {
      showSpinner: true
    },
    [filters]
  );

  if (reservationsPerClient.data === undefined) {
    return <p>Cargando...</p>;
  }

  const reservations = reservationsPerClient.data ?? [];

  const fechas = reservations[0]?.tripsPerDate?.map((x) => x.fecha) ?? [];

  const data: ({ name: string } & { [key: string]: number | string })[] =
    reservations.map((client) => {
      let row = {
        name: client.name
      };
      client.tripsPerDate.forEach((fecha) => {
        row[fecha.fecha] = fecha.amount;
      });
      return row;
    });

  const labels = fechas;

  const columns: TableColumn<any>[] = [
    {
      name: 'name',
      label: 'Clientes'
    },
    ...fechas.map((fecha) => ({
      name: fecha,
      label: fecha
    }))
  ];

  const lines = reservations.map((item) => ({
    label: item.name,
    values: item.tripsPerDate.map((amount) => ({
      label: amount.fecha,
      value: amount.amount
    }))
  }));

  return (
    <>
      <PageHeader
        title="Viajes por cliente"
        toolbar={
          <Button variant="contained" onClick={() => setShowFilters(true)}>
            Filtrar
          </Button>
        }
      />
      <PageContainer>
        <div className="h-full w-full">
          <Card className="mb-8">
            <div className="p-8" style={{ minHeight: 300 }}>
              {reservations.length > 0 ? (
                <LineChartComponent lines={lines} labels={labels} />
              ) : (
                <p>No hay resultados</p>
              )}
            </div>
          </Card>
          <Table
            title={'Viajes por mes'}
            data={data}
            columns={columns}
            options={{
              search: false,
              viewColumns: false,
              print: false,
              filter: false
            }}
          />
        </div>
      </PageContainer>

      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <Formik
            onSubmit={async (filters) => {
              await setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          >
            {(formik) => (
              <Form className="m-8 grid grid-cols-1 gap-8">
                <Field
                  name="fromDate"
                  label="Desde*"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  ampm={false}
                  format="DD/MM/YYYY"
                  fullWidth
                />
                <Field
                  name="toDate"
                  label="Hasta*"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  ampm={false}
                  format="DD/MM/YYYY"
                  fullWidth
                />
                <Field
                  name="clientes"
                  component={Autocomplete}
                  options={
                    businessClients.data
                      ? [...businessClients.data, { fantasyName: 'privado' }]
                      : []
                  }
                  getOptionLabel={(option: { fantasyName: string }) =>
                    option.fantasyName
                  }
                  multiple
                  filterSelectedOptions
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      name="name"
                      error={
                        formik.touched['clientes'] &&
                        !!formik.errors['clientes']
                      }
                      helperText={formik.errors['clientes']}
                      label="Clientes"
                      variant="outlined"
                    />
                  )}
                />
                <Button variant="contained" onClick={formik.submitForm}>
                  Buscar
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};
