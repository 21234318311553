import { Dialog } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useChoferes from 'app/chofer/services/ChoferService';
import { FacturacionMovementTable } from 'app/facturas/components/FacturacionMovementTable';
import { MovimientoFacturaForm } from 'app/facturas/components/MovimientoFacturaForm';
import { FacturaModel } from 'app/facturas/models/FacturaModel';
import { Field, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { DriverSettlementsCreateTableComponent } from '../components/DriverSettlementsCreateTableComponent';
import { useDriverSettlementRepository } from '../services/driverSettlementsRepository';

const initialValues = {
  date: moment()
};
export const DriverSettlementsCreatePage = () => {
  const driversRepo = useChoferes();
  const settlementRepo = useDriverSettlementRepository();
  const [selectedDrivers, setselectedDrivers] = useState<number[]>([]);
  const [visibleMovimientoDialog, setVisibleMovimientoDialog] =
    useState<boolean>(false);
  const [visibleMovimientoList, setVisibleMovimientoList] =
    useState<boolean>(false);
  const [movements, setMovements] = useState<
    FacturaModel['movements'] | undefined
  >(undefined);
  const [dialogId, setDialogId] = useState<number>(0);

  const driversQuery = useSuperQuery(driversRepo.getChoferesWithDeleted, {
    showSpinner: true
  });

  const createBatchMutation = useSuperMutation(settlementRepo.createBatch, {
    showSpinner: true,
    onMutationFinish: (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  });

  const createBatchSettlements = async ({ date }: { date: Moment }) => {
    await createBatchMutation.mutate({
      date_to: date,
      driver_ids: selectedDrivers
    });
    setselectedDrivers([]);
  };

  async function crearMovimiento(
    dialogId: number,
    values: {
      amountValue: string;
      observationValue: string;
      movementType: string;
    }
  ) {
    const correctValues = {
      amount:
        values.movementType === 'debito'
          ? '-' + values.amountValue
          : values.amountValue,
      observation: values.observationValue
    };
    await /*crearMovimientoMutation.mutate(dialogId, correctValues);*/
    driversQuery.reload();
    setVisibleMovimientoDialog(false);
  }

  return (
    <>
      <PageHeader
        title="Crear liquidación chofer"
        subtitle=""
        toolbar={
          <div className="flex space-x-2">
            <Formik
              initialValues={initialValues}
              onSubmit={createBatchSettlements}
            >
              {(formik) => (
                <div className="flex items-center gap-4">
                  <Field
                    name={`date`}
                    label="Fecha"
                    inputVariant="outlined"
                    component={KeyboardDatePicker}
                    min={moment()}
                    format="DD/MM/YYYY"
                    margin="dense"
                    className="w-48"
                  />
                  <Button
                    color="green"
                    variant="light"
                    onClick={formik.submitForm}
                    disabled={selectedDrivers.length === 0}
                  >
                    Liquidar
                  </Button>
                </div>
              )}
            </Formik>
          </div>
        }
      />

      <PageContainer>
        <DriverSettlementsCreateTableComponent
          drivers={driversQuery.data ?? []}
          onSelectionChange={setselectedDrivers}
          selectedDrivers={selectedDrivers}
          facturacionCrearMovimiento={(id) => (
            <Button
              variant="light"
              color="green"
              onClick={() => {
                setVisibleMovimientoDialog(true);
                setDialogId(id);
              }}
            >
              Crear movimiento
            </Button>
          )}
          facturacionVerMovimientos={(id) => {
            {
              setVisibleMovimientoList(true);
              /*setMovements(
                driversQuery.data.find((factura) => {
                  return factura.id === id;
                })!.movements
              );*/
            }
          }}
        />
      </PageContainer>
      <Dialog
        open={visibleMovimientoDialog}
        onBackdropClick={() => setVisibleMovimientoDialog(false)}
      >
        <MovimientoFacturaForm
          onSubmit={(values) => crearMovimiento(dialogId, values)}
        />
      </Dialog>
      <Dialog
        open={visibleMovimientoList}
        onBackdropClick={() => setVisibleMovimientoList(false)}
      >
        <div>
          {movements == undefined ? null : (
            <FacturacionMovementTable tableProps={movements} />
          )}
        </div>
      </Dialog>
    </>
  );
};
