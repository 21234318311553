import { LaravelPaginated } from 'shared/hooks/useCustomTablePagination';
import { httpClient } from 'shared/services/http/httpClient';
import {
  ReservaTrasladoRepetitivePrivateCreateModel,
  transferReservationRepetitivePrivateBuilder,
  TransferReservationRepetitivePrivateModel
} from '../models/TransferReservationRepetitivePrivateModel';

export const useTransferReservationRepetitivePrivateRepository = () => {
  const create = async (x: ReservaTrasladoRepetitivePrivateCreateModel) =>
    httpClient.post(
      `/repetitive-transfer-reservations`,
      transferReservationRepetitivePrivateBuilder.toBackEnd(x)
    );

  const edit = async (
    x: ReservaTrasladoRepetitivePrivateCreateModel & { id: number }
  ) =>
    httpClient.put(
      `/repetitive-transfer-reservations/${x.id}`,
      transferReservationRepetitivePrivateBuilder.toBackEnd(x)
    );

  const getAll = async (
    params: string
  ): Promise<LaravelPaginated<TransferReservationRepetitivePrivateModel>> => {
    const res = await httpClient.get(
      `repetitive-transfer-reservations?${params}`
    );
    return {
      ...res,
      data: res.data.map(
        transferReservationRepetitivePrivateBuilder.fromBackEnd
      )
    };
  };


  return {
    create,
    edit,
    getAll
  };
};
