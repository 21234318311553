export enum UserRole {
  RESPONSABLE_CHOFERES = 'Responsable de choferes',
  OPERADOR_CALL_CENTER = 'Operador call center',
  COORDINADOR = 'Coordinador',
  SUPERVISOR_COORDINACION = 'Supervisor de coordinacion',
  GERENTE_OPERACIONES = 'Gerente de operaciones',
  ANALISTA_CUENTAS_A_PAGAR = 'Analista cuentas a pagar',
  GERENTE_ADMINISTRACION = 'Gerente de administracion',
  RESPONSABLE_COBRANZA = 'Responsable cobranzas',
  ANALISTA_FACTURACION = 'Analista de facturacion',
  GERENTE_COMERCIAL = 'Gerente comercial',
  ANALISTA_COMERCIAL = 'Analista comercial',
  SUPER_ADMIN = 'Super Admin',
  CHOFER = 'Chofer',
  ADMINISTRADOR_PORTAL = 'administrador_portal'
}

export interface UserModel {
  id: number;
  name: string;
  email: string;
  password: string;
  roles: UserRole[];
  isDeleted: boolean;
}

export const userBuilder = {
  fromBackEnd: (user): UserModel => {
    return {
      ...user
    };
  }
};
