import { httpClient } from 'shared/services/http/httpClient';
import {
  authorizedBuilder,
  AuthorizedModel,
  AuthorizedModelCRUD
} from '../models/Authorized';

const useAuthorizeds = () => {
  const addAuthorized = (x: AuthorizedModelCRUD, idClientBusiness: number) => {
    return httpClient.post(
      `business-clients/${idClientBusiness}/authorized`,
      authorizedBuilder.toBackEnd(x)
    );
  };

  const removeAuthorized = (authorized: AuthorizedModel) => {
    return httpClient.delete('authorized/' + authorized.id);
  };

  const updateAuthorized = (authorized: AuthorizedModelCRUD) => {
    return httpClient.put(
      `authorized/${authorized.id}`,
      authorizedBuilder.toBackEnd(authorized)
    );
  };

  const getAuthorizeds = async (
    idClientBusiness: number
  ): Promise<AuthorizedModel[]> => {
    const response = await httpClient.get(
      `business-clients/${idClientBusiness}/authorized`
    );
    return response.map(authorizedBuilder.fromBackEnd);
  };

  return {
    addAuthorized,
    removeAuthorized,
    updateAuthorized,
    getAuthorizeds
  };
};

export default useAuthorizeds;
