import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { Moment } from 'moment';
import { useRef, useState } from 'react';
import { DirectionsRenderer } from 'react-google-maps';
import { DynamicMap } from 'shared/components';
import { getGoogleDirections } from 'shared/utils/getGoogleDirections';
type Props = {
  recorrido: { lat: number; lng: number }[];
  recorridoGoogle: {
    datetime: Moment;
    puntos: { lat: number; lng: number }[];
  };
};

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];

/**
 * Mapa que permite mostrar un recorrido y su comparativa con el recorrido de google usando la api de directions
 * @param recorrido Array de puntos por los que el chofer fue realmente
 * @param recorridoGoogle.datetime fecha en la que el viaje se inició
 * @param recorridoGoogle.puntos Array de puntos que el viaje debe recorrer y que se usan para generar el recorrido
 */
export const MapaRecorridoComparativaGoogle = ({
  recorridoGoogle,
  recorrido
}: Props) => {
  const googleDirectionsRef = useRef<google.maps.DirectionsResult | null>(null);
  const [googleDirections, setGoogleDirections] = useState<
    google.maps.DirectionsResult | undefined
  >(undefined);

  const toogleRecorridoGoogle = async () => {
    if (googleDirectionsRef.current !== null) {
      setGoogleDirections((x) =>
        x === undefined
          ? (googleDirectionsRef.current as google.maps.DirectionsResult)
          : undefined
      );
      return;
    }
    const googleDirections = await getGoogleDirections(recorridoGoogle);
    googleDirectionsRef.current = googleDirections;
    setGoogleDirections(googleDirections);
  };

  return (
    <div className="relative h-full w-full">
      <div
        className="absolute right-0 top-0 z-10 m-4 "
        title="Trazar recorrido"
      >
        {googleDirections === undefined ? (
          <Button
            variant={'outlined'}
            className={'bg-white shadow-md'}
            shape="circle"
            onClick={toogleRecorridoGoogle}
          >
            <FontAwesomeIcon icon={faRoute} size={'lg'} />
          </Button>
        ) : (
          <Button
            variant={'contained'}
            className={'shadow-md '}
            shape="circle"
            onClick={toogleRecorridoGoogle}
          >
            <FontAwesomeIcon icon={faRoute} size={'lg'} />
          </Button>
        )}
      </div>
      <DynamicMap
        options={{ styles: mapStyles }}
        containerElement={
          <div
            className="overflow-hidden rounded-md"
            style={{ height: `100%`, width: '100%' }}
          />
        }
        mapElement={<div style={{ height: `100%` }} />}
        polyline={{
          path: recorrido
        }}
      >
        {googleDirections ? (
          <DirectionsRenderer directions={googleDirections} />
        ) : null}
      </DynamicMap>
    </div>
  );
};
