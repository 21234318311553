import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useState } from 'react';
import PageContainer from '../../../../../layout/components/PageContainer';
import PageHeader from '../../../../../layout/components/PageHeader';
import { ClientPrivateCreate } from '../components/ClientPrivateCreate';
import { ClientPrivateEdit } from '../components/ClientPrivateEdit';
import { ClientPrivateTable } from '../components/ClientPrivateTable';
import { ClientPrivateFormType } from '../forms/ClientPrivateFormType';
import { ClientPrivateModel } from '../models/ClientPrivate';
import useClientPrivates from '../services/ClientPrivateService';

export function ClientPrivateCrudPage() {
  const ClientPrivateService = useClientPrivates();
  const clientprivatesQuery = useSuperQuery(
    ClientPrivateService.getClientPrivates,
    {
      showSpinner: true
    }
  );
  const updateClientPrivateMutation = useSuperMutation(
    ClientPrivateService.updateClientPrivate
  );
  const addClientPrivateMutation = useSuperMutation(
    ClientPrivateService.addClientPrivate
  );
  const removeClientPrivateMutation = useSuperMutation(
    ClientPrivateService.removeClientPrivate,
    {
      showSpinner: true
    }
  );
  const restoreClientPrivateMutation = useSuperMutation(
    ClientPrivateService.restoreClientPrivate,
    {
      showSpinner: true
    }
  );
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [updatingClientPrivate, setEditingClientPrivate] =
    useState<ClientPrivateModel>();

  async function update(clientprivateValues: ClientPrivateFormType) {
    clientprivateValues.id = updatingClientPrivate!.id;
    await updateClientPrivateMutation.mutate(clientprivateValues);
    setVisiblePage('table');
    clientprivatesQuery.reload();
  }

  async function create(clientprivateValues: ClientPrivateFormType) {
    await addClientPrivateMutation.mutate(clientprivateValues);
    setVisiblePage('table');
    clientprivatesQuery.reload();
  }

  async function remove(clientprivate: ClientPrivateModel) {
    await removeClientPrivateMutation.mutate(clientprivate);
    clientprivatesQuery.reload();
  }

  async function restore(clientprivate: ClientPrivateModel) {
    await restoreClientPrivateMutation.mutate(clientprivate);
    clientprivatesQuery.reload();
  }

  function selectToEdit(clientprivate: ClientPrivateModel) {
    setEditingClientPrivate(clientprivate);
    setVisiblePage('update');
  }
  return (
    <>
      <PageHeader
        title="Cliente particulares"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Clientes particulares'
            : visiblePage === 'create'
            ? 'Nuevo Cliente'
            : 'Editar Cliente'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Cliente
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <ClientPrivateTable
            clientprivates={clientprivatesQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && <ClientPrivateCreate create={create} />}
        {visiblePage === 'update' && updatingClientPrivate && (
          <ClientPrivateEdit
            update={update}
            initialValues={updatingClientPrivate}
          />
        )}
      </PageContainer>
    </>
  );
}
