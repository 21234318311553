import { notDeleted } from 'shared/helpers/deletedHandler';
import { jsonToFormData } from 'shared/helpers/jsonToFormData';
import { httpClient } from 'shared/services/http/httpClient';
import { Bank, BANKS } from '../models/Bank';
import { billerBuilder, BillerCRUD } from '../models/BillerModel';

const useBiller = () => {
  const addBiller = (x: BillerCRUD) => {
    return httpClient.post(
      `billers`,
      jsonToFormData(billerBuilder.toBackEnd(x))
    );
  };

  const removeBiller = (id: number) => {
    return httpClient.delete(`billers/${id}`);
  };
  const restoreBiller = (id: number) => {
    return httpClient.put(`billers/${id}/restore`);
  };

  const editBiller = (biller: BillerCRUD) => {
    return httpClient.post(
      `billers/${biller.id}?_method=PUT`,
      jsonToFormData(billerBuilder.toBackEnd(biller))
    );
  };

  const getBillersWithDeleted = async () => {
    const res = await httpClient.get(`billers`);
    return res.map(billerBuilder.fromBackEnd);
  };

  const getBillers = async () =>
    (await getBillersWithDeleted()).filter(notDeleted);

  const getBiller = async (id: number) => {
    const res = await httpClient.get(`billers/${id}`);
    return billerBuilder.fromBackEnd(res);
  };

  const getBanks = () => {
    return new Promise<Bank[]>((resolve) => resolve(BANKS));
  };

  return {
    addBiller,
    removeBiller,
    editBiller,
    getBiller,
    getBillers,
    getBillersWithDeleted,
    getBanks,
    restoreBiller
  };
};

export default useBiller;
