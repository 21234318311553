import { ButtonGroup } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import { StaticMap } from 'shared/components';
import { TransferReservationLocationFormModal } from '../../../viajeTraslado/components/TransferReservationLocationFormModal';
import { TransferReservationPrivateBatchTripFormType } from './TransferReservationPrivateBatchFormType';

interface Props {
  viaje: TransferReservationPrivateBatchTripFormType;
  index: number;
  clonar: () => void;
  revertir: () => void;
  borrar: () => void;
  previousMinutesError?: boolean;
}

export const TransferReservationPrivateBatchTripForm = ({
  viaje,
  index,
  clonar,
  revertir,
  borrar,
  previousMinutesError
}: Props) => {
  return (
    <div>
      <div className="flex justify-between ">
        <p className="title">Viaje #{index + 1}</p>
        <ButtonGroup color="primary">
          <Button variant="light" onClick={clonar}>
            Clonar
          </Button>
          <Button variant="light" onClick={revertir}>
            Revertir
          </Button>
          <Button variant="light" color="red" onClick={borrar}>
            Borrar
          </Button>
        </ButtonGroup>
      </div>
      <div className="mt-8 flex space-x-8">
        <div className="flex-grow">
          <div className="flex space-x-8">
            <Field
              component={KeyboardDateTimePicker}
              label="Fecha de llegada"
              name={`viajes.${index}.fechaYHoraDeLlegada`}
              ampm={false}
              inputVariant="outlined"
              margin="normal"
              className="flex-1"
              format="DD/MM/YYYY HH:mm"
            />

            <div className="flex flex-1 flex-col">
              <Field
                component={KeyboardDateTimePicker}
                label="Tiempo previo salida"
                name={`viajes.${index}.minutosPreviosASalida`}
                ampm={false}
                error={previousMinutesError}
                inputVariant="outlined"
                margin="normal"
                className="flex-1"
                format="DD/MM/YYYY HH:mm"
              />
              {previousMinutesError && (
                <p className="m-0 text-sm text-red-500">
                  La hora de salida debe ser anterior a la hora de llegada
                </p>
              )}
            </div>
          </div>
          <FieldArray
            name={`viajes.${index}.puntos`}
            render={(helpers) => (
              <TransferReservationLocationFormModal
                pasajeros={[]}
                puntos={viaje.puntos}
                onEditPuntos={(puntos) => {
                  helpers.form.setFieldValue(`viajes.${index}.puntos`, puntos);
                }}
              />
            )}
          />
          <ErrorMessage name={`viajes.${index}.puntos`}>
            {(msg) => <p className="mt-4 text-sm text-red-500">{msg}</p>}
          </ErrorMessage>
        </div>
        <div
          className={`w-1/4 transition-all delay-300 duration-300 ease-in-out hover:w-2/5 ${
            viaje.puntos.length === 0 ? 'opacity-0' : 'opactiy-1'
          }`}
        >
          <StaticMap
            markers={viaje.puntos.map((punto) => ({
              lat: punto.domicilio.latitud,
              lng: punto.domicilio.longitud
            }))}
            size="600x600"
          />
        </div>
      </div>
    </div>
  );
};
