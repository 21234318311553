import { Button } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import useChoferes from 'app/chofer/services/ChoferService';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { DriverExpirationTable } from '../components/DriverExpirationTable';
import { useDriverExpirationRepository } from '../services/DriverExpirationRepository';

export const DriverExpirationPage = () => {
  const driverRepo = useChoferes();
  const driversQuery = useSuperQuery(driverRepo.getChoferes);
  const [showFilters, setShowFilters] = useState(false);
  const driverExpirationRepo = useDriverExpirationRepository();
  const driverExpirationQuery = useSuperQuery(
    () => driverExpirationRepo.getExpirations(),
    { showSpinner: true }
  );

  return (
    <>
      <PageHeader
        title="Vencimientos"
        toolbar={
          <Button variant="light" onClick={() => setShowFilters(true)}>
            Filtros
          </Button>
        }
      />
      <PageContainer>
        <DriverExpirationTable tableProps={driverExpirationQuery.data ?? []} />
      </PageContainer>
    </>
  );
};
