import React from 'react';
import {Field, Form, Formik} from "formik";
import {Button} from "@vadiun/react-components";
import FacturarLiquidacionesFormik, {FacturarLiquidacionesFormType} from "./FacturarLiquidacionesFormik";
import {TextField} from "formik-material-ui";
import { CircularProgress } from '@material-ui/core';

interface Props {
    onSubmit: (values: {number: string, letter:string}) => void;
    isLoading: boolean;
}

function FacturarLiquidacionesForm({
      onSubmit,
      isLoading
    }: Props) {
    return (
        <Formik<FacturarLiquidacionesFormType>
            initialValues={FacturarLiquidacionesFormik.initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={FacturarLiquidacionesFormik.schema}
        >
            {({isSubmitting, touched, errors}) => (
                <Form className="p-8 flex flex-col gap-2 w-60">
                    <Field
                      component={TextField}
                      label="Numero"
                      name="number"
                      inputVariant="outlined"
                      margin="dense"
                      className="col-span-2"
                    />
                    <Field
                      component={TextField}
                      label="Letra"
                      name="letter"
                      inputVariant="outlined"
                      margin="dense"
                      className="col-span-2"
                    />

                    <div className="col-span-1 flex justify-end">
                        <Button type="submit" variant="contained" disabled={isLoading}>
                          {!isLoading ? 
                          'Facturar' : 
                          <CircularProgress size={16} color='inherit' />
                          }
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default FacturarLiquidacionesForm;
