import {
  fabricanteBackModelToModel,
  fabricanteModelToBack,
  VehiculoFabricanteBackModel,
  VehiculoFabricanteCRUD,
  VehiculoFabricanteModel
} from '../models/VehiculoFabricante';
import { httpClient } from 'shared/services/http/httpClient';
import { notDeleted } from 'shared/helpers/deletedHandler';
import { ServerResponse } from 'shared/hooks/useCustomTablePagination';

const useVehiculoFabricantes = () => {
  const addVehiculoFabricante = (x: VehiculoFabricanteCRUD) => {
    const apiModel = fabricanteModelToBack(x);
    return httpClient.post('brands', apiModel);
  };

  const removeVehiculoFabricante = (fabricante: VehiculoFabricanteModel) => {
    const apiModel = fabricanteModelToBack(fabricante);
    return httpClient.delete('brands/' + apiModel.id);
  };

  const editVehiculoFabricante = (fabricante: VehiculoFabricanteCRUD) => {
    const apiModel = fabricanteModelToBack(fabricante);
    return httpClient.put('brands/' + apiModel.id, apiModel);
  };

  const getPaginatedVehiculoFabricantes = async (
    params: string
  ): Promise<ServerResponse<VehiculoFabricanteModel>> => {
    const fabricantes = await httpClient.get<
      ServerResponse<VehiculoFabricanteBackModel>
    >('brands?' + params);
    fabricantes.data = fabricantes.data.map(fabricanteBackModelToModel);
    return fabricantes;
  };

  const getVehiculoFabricantesWithDeleted = async (): Promise<
    VehiculoFabricanteModel[]
  > => {
    const fabricantes = await httpClient.get<VehiculoFabricanteBackModel[]>(
      'brands'
    );
    return fabricantes.map(fabricanteBackModelToModel);
  };

  const getVehiculoFabricantes = async () =>
    (await getVehiculoFabricantesWithDeleted()).filter(notDeleted);

  const restaurarVehiculoFabricante = (fabricante: VehiculoFabricanteModel) =>
    httpClient.put(`brands/${fabricante.id}/restore`);

  return {
    addVehiculoFabricante,
    removeVehiculoFabricante,
    editVehiculoFabricante,
    getVehiculoFabricantes,
    getVehiculoFabricantesWithDeleted,
    getPaginatedVehiculoFabricantes,
    restaurarVehiculoFabricante
  };
};

export default useVehiculoFabricantes;
