import {
  serverSideFilter,
  serverSideHandler,
  serverSideOrder,
  serverSidePaginator,
  useServerSidePagination
} from '@vadiun/react-components';
import { useMessageAsyncError, useSpinner } from '@vadiun/react-hooks-legacy';

export interface ServerResponse<T> {
  total: number;
  data: T[];
}
export interface LaravelPaginated<T> {
  data: T[];
  meta: {
    total: number;
  };
}

const customTableHandler = serverSideHandler({
  paginator: serverSidePaginator({
    label: 'page',
    firstPageNro: 1,
    itemsPerPageLabel: 'limit',
    defaultItemsPerPage: 30
  }),
  filter: serverSideFilter('search'),
  order: serverSideOrder(
    ({ name, direction }) => `orden=${name}&orden_tipo=${direction}`
  )
});

function customRequestAdapter<T>(res: LaravelPaginated<T>): ServerResponse<T> {
  return { total: res.meta.total, data: res.data };
}

export type ServerRequestFn<T> = (
  params: string
) => Promise<LaravelPaginated<T>>;

type PaginationOptions =
  | undefined
  | {
      defaultItemsPerPage: number;
      showSpinner?: boolean;
    };

export function useCustomTablePagination<T>(
  requestFn: ServerRequestFn<T>,
  options: PaginationOptions = {
    defaultItemsPerPage: 10,
    showSpinner: true
  }
) {
  const handleSpinner = useSpinner();
  const handleErrorMessage = useMessageAsyncError();
  const customTableHandler = serverSideHandler({
    paginator: serverSidePaginator({
      label: 'page',
      firstPageNro: 1,
      itemsPerPageLabel: 'page_size',
      defaultItemsPerPage: options.defaultItemsPerPage
    }),
    filter: serverSideFilter('search'),
    order: serverSideOrder(
      ({ name, direction }) => `orden=${name}&orden_tipo=${direction}`
    )
  });

  return useServerSidePagination<T>({
    requestFn: async (params) => {
      const promise = requestFn(params);
      if (options.showSpinner ?? true) {
        handleSpinner(promise);
      }
      handleErrorMessage(promise);
      return customRequestAdapter(await promise);
    },
    tableHandler: customTableHandler
  });
}
