import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import { StaticMap } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';

interface Props {
  locations: {
    address: DomicilioModel;
    observation: string;
    passengers: {
      name: string;
      phone: string | null;
    }[];
  }[];
}

const LocationDetails = ({ locations }: Props) => {
  return (
    <div
      className="flex h-full rounded-md border-gray-200"
      style={{ borderWidth: 1 }}
    >
      <div className="w-1/2 bg-gray-50 p-4">
        {locations.map((location, index) => (
          <div className="flex flex-col items-center">
            <span className="text-sm">
              {createDomicilioText(location.address)}
            </span>
            <span className="text-xs italic text-gray-700">
              {location.observation}
            </span>
            {location.passengers.map(({ name, phone }) => (
              <span className="text-xs text-gray-700">{`${name} ${
                phone ? `- ${phone}` : ''
              }`}</span>
            ))}
            {index < locations.length - 1 && (
              <FontAwesomeIcon
                icon={faArrowDown}
                className="my-4 text-gray-500"
              />
            )}
          </div>
        ))}
      </div>
      <div className="min-h-full w-1/2">
        <StaticMap
          markers={locations.map(({ address: { latitud, longitud } }) => ({
            lat: latitud,
            lng: longitud
          }))}
          size="600x400"
        />
      </div>
    </div>
  );
};

export default LocationDetails;
