import moment from 'moment';
import { createTransferReservationBaseInitialValues } from '../../viajeTraslado/forms/base/TransferReservationBaseFormInitialValues';
import { TransferReservationRepetitiveFormType } from './TransferReservationRepetitiveFormType';

export const createTransferReservationRepetitiveInitalValues: () => TransferReservationRepetitiveFormType =
  () => ({
    ...createTransferReservationBaseInitialValues(),
    fechaYHoraDeLlegada: moment(),
    minutosPreviosASalida: moment(),
    puntos: [],
    repeatOnMonday: false,
    repeatOnTuesday: false,
    repeatOnWednesday: false,
    repeatOnThursday: false,
    repeatOnFriday: false,
    repeatOnSaturday: false,
    repeatOnSunday: false,
    repetitionFinishDate: moment(),
    repetitionInitDate: moment(),
    isEvent: false,
    nameEvent: ''
  });
