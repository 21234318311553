import {
  Dialog,
  Divider,
  Drawer,
  IconButton,
  MenuItem
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { useTableQuery } from '@vadiun/react-datatable';
import {
  useSuperMutation,
  useSuperQuery,
  useVerifyAction
} from '@vadiun/react-hooks-legacy';
import ChoferSearchModal from 'app/chofer/components/ChoferSearchModal';
import { WorkingDriverInfo } from 'app/chofer/components/WorkingDriverInfo';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import useChoferes from 'app/chofer/services/ChoferService';
import { useViajesTraslado } from 'app/viaje/services';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { TransferReservationManagmentModel } from '../../gestion/models/TransferReservationManagment';
import CancelViajeTrasladoModal from '../../viajeTraslado/components/CancelViajeTrasladoModal';
import EndViajeTrasladoModal from '../../viajeTraslado/components/EndViajeTrasladoModal';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import ViajeTrasladoResumenModal from '../../viajeTraslado/components/ViajeTrasladoResumenModal';
import { useTransferReservationStore } from '../../viajeTraslado/services/TransferReservationSingleStore';
import { ViajeTrasladoOpenedTable } from '../components/ViajeTrasladoOpenedTable';
import { TransferReservationOpenedModel } from '../models/TransferReservationOpened';


const ViajeTrasladoOpenedPage = () => {
  const qc = useQueryClient();
  const transferReservationHelpers = useTransferReservationStore();
  const tripRepository = useViajesTraslado();
  const ViajeTrasladoService = useViajesTraslado();
  const [showFilters, setShowFilters] = useState(false);
  const driverRepository = useChoferes();
  const verifyAction = useVerifyAction();
  const [reAssignDriverOpen, setReAssignDriverOpen] = useState(false);
  const workingDriversQuery = useSuperQuery(driverRepository.getWorkingDrivers);
  const reAssignMutation = useSuperMutation(
    ViajeTrasladoService.reAssignDriver,
    {
      showSpinner: true
    }
  );
  const [selectedReservation, setSelectedReservation] = useState<
    TransferReservationOpenedModel | undefined
  >(undefined);
  const driversQuery = useSuperQuery(driverRepository.getDriversThatCanWork);

  const [filters, setFilters] = useFilters({
    clientId: null,
    client_type: 'BusinessClient',
    dateFrom: moment().hour(0).minutes(0).subtract(1, 'y'),
    dateTo: moment().hour(23).minutes(59).add(1, 'd'),
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false
  });

  const tableQuery = useTableQuery(
    [
      'reservas',
      'abiertos',
      {
        ...filters,
        dateFrom: filters.dateFrom.format(),
        dateTo: filters.dateTo.format()
      }
    ],
    async (params) => {
      const res = await tripRepository.getAllOpen(params, filters);
      return res;
    },
    { defaultItemsPerPage: 1000 }
  );

  const [locationsDetail, setLocationsDetail] = useState<
    | {
      isOpen: false;
      details: undefined;
    }
    | {
      isOpen: true;
      details: TransferReservationOpenedModel;
    }
  >({ isOpen: false, details: undefined });
  const [driverDetail, setDriverDetail] = useState<
    | {
      isOpen: false;
      details: undefined;
    }
    | {
      isOpen: true;
      details: ChoferTrabajando;
    }
  >({ isOpen: false, details: undefined });

  const [cancelReservationModal, setCancelReservationModal] = useState<
    | {
      isOpen: false;
      reservationId: undefined;
    }
    | {
      isOpen: true;
      reservationId: number;
    }
  >({ isOpen: false, reservationId: undefined });

  const [endReservationModal, setEndReservationModal] = useState<
    | {
      isOpen: false;
      reservationId: undefined;
    }
    | {
      isOpen: true;
      reservationId: number;
    }
  >({ isOpen: false, reservationId: undefined });

  const assignDriver = async (driver: ChoferPartialModel) => {
    await reAssignMutation.mutate(selectedReservation!.id, driver.id);
    setReAssignDriverOpen(false);
    qc.invalidateQueries(['reservas', 'abiertos']);
  };
  const closeShiftMutation = useSuperMutation(driverRepository.closeShift, {
    showSpinner: true
  });

  const forceBreakMutation = useSuperMutation(driverRepository.forceBreak, {
    showSpinner: true
  });

  const openToPendingMutation = useSuperMutation(
    ViajeTrasladoService.openToPending,
    {
      showSpinner: true,
      onMutationFinish: () => {
        tableQuery.refetch();
      }
    }
  );

  const copyReservation = async (
    reservation: TransferReservationManagmentModel
  ) => {
    transferReservationHelpers.copy(reservation.id);
  };

  const cancel = async (reservationId: number, cancelObservation: string) => {
    setCancelReservationModal({ reservationId: undefined, isOpen: false });
    await cancelReservationMutation.mutate(reservationId, cancelObservation);
    qc.invalidateQueries(['reservas', 'abiertos']);
  };
  const cancelReservationMutation = useSuperMutation(
    ViajeTrasladoService.cancelReservation,
    { showSpinner: true }
  );

  const forceEndReservationMutation = useSuperMutation(
    ViajeTrasladoService.forceEnd,
    { showSpinner: true }
  );

  const notifyDelayMutation = useSuperMutation(
    ViajeTrasladoService.notificarDemora,
    {
      showSpinner: true
    }
  );

  const forceReservationEnd = async (
    reservationId: number,
    endObservation: string
  ) => {
    setEndReservationModal({ reservationId: undefined, isOpen: false });
    await forceEndReservationMutation.mutate(reservationId, endObservation);
    qc.invalidateQueries(['reservas', 'abiertos']);
  };

  return (
    <>
      <PageHeader
        title="Traslados abiertos"
        toolbar={
          <Button variant="light" onClick={() => setShowFilters(true)}>
            Filtros
          </Button>
        }
      />
      <PageContainer lessMargin>
        <ViajeTrasladoOpenedTable
          tableQuery={tableQuery}
          onLocationsDetails={(reservation) =>
            setLocationsDetail({ isOpen: true, details: reservation })
          }
          onReAssignDriver={(travel: TransferReservationOpenedModel) => {
            setSelectedReservation(travel);
            setReAssignDriverOpen(true);
          }}
          onShowDriverDetails={(driver) => {
            setDriverDetail({
              isOpen: true,
              details: workingDriversQuery.data!.find(
                (d) => d.id === driver.id
              )!
            });
          }}
          onReservationCopy={copyReservation}
          setOpenToPending={(id: number) => {
            verifyAction({
              title: '¿Está seguro que desea pasar este viaje a pendiente?',
              onAccept: () => openToPendingMutation.mutate(id)
            });
          }}
          onForceEnd={(reservation: TransferReservationOpenedModel) => {
            setEndReservationModal({
              isOpen: true,
              reservationId: reservation.id
            });
          }}
          onEditReservation={(travel) =>
            transferReservationHelpers.edit(travel.id)
          }
          onCancelReservation={(
            reservation: TransferReservationOpenedModel
          ) => {
            setCancelReservationModal({
              isOpen: true,
              reservationId: reservation.id
            });
          }}
          onShowTravelDetails={(id) => (
            <Link to={'/main/Viaje/traslado/detalle/' + id}>
              <MenuItem>Ver detalle</MenuItem>
            </Link>
          )}
          onDelayNotify={(reservation) =>
            notifyDelayMutation.mutate(reservation.id)
          }
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
            filterByClient
          />
        </div>
      </Drawer>
      <CancelViajeTrasladoModal
        isOpen={cancelReservationModal.isOpen}
        onClose={() =>
          setCancelReservationModal({ reservationId: undefined, isOpen: false })
        }
        cancel={cancel}
        reservationId={cancelReservationModal.reservationId!}
      />
      <EndViajeTrasladoModal
        isOpen={endReservationModal.isOpen}
        onClose={() =>
          setEndReservationModal({ reservationId: undefined, isOpen: false })
        }
        forceEnd={forceReservationEnd}
        reservationId={endReservationModal.reservationId!}
      />
      <ChoferSearchModal
        driversQuery={driversQuery}
        onSelect={(chofer) => {
          setReAssignDriverOpen(false);
          verifyAction({
            title: '¿Está seguro que desea re-asignar este viaje?',
            onDeny: () => setReAssignDriverOpen(false),
            onAccept: () => assignDriver(chofer)
          });
        }}
        isOpen={reAssignDriverOpen}
        handleClose={() => setReAssignDriverOpen(false)}
      />
      {locationsDetail.details && (
        <ViajeTrasladoResumenModal
          isOpen={locationsDetail.isOpen}
          reservation={locationsDetail.details!}
          handleClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
        />
      )}
      <Dialog
        open={driverDetail.isOpen}
        onClose={() => setDriverDetail({ details: undefined, isOpen: false })}
        maxWidth="xs"
      >
        {driverDetail.details && (
          <WorkingDriverInfo
            driver={driverDetail.details}
            isOpened={true}
            forceBreak={() =>
              forceBreakMutation.mutate(driverDetail.details?.id)
            }
            closeShift={() =>
              closeShiftMutation.mutate(driverDetail.details?.id)
            }
          />
        )}
      </Dialog>
    </>
  );
};

export default ViajeTrasladoOpenedPage;
