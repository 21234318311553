import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useBiller from 'app/biller/services/useBiller';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PageContainer from '../../../layout/components/PageContainer';
import { useBusinessUnit } from '../../business/services/useBusinessUnit';
import useVehiculo from '../../vehiculos/services/VehiculoService';
import ChoferCreate from '../components/ChoferCreate';
import ChoferTable from '../components/ChoferTables/ChoferTable';
import {
  ChoferFormSchema,
  choferFormSchemaToModel
} from '../models/ChoferFormSchema';
import useChoferes from '../services/ChoferService';

const ChoferCrudPage = () => {
  const ChoferService = useChoferes();
  const VehiculoService = useVehiculo();
  const ProveedorService = useBiller();
  const BusinessUnitService = useBusinessUnit();

  const choferesQuery = useSuperQuery(ChoferService.getChoferesWithDeleted, {
    showSpinner: true
  });
  const { data: vehiculos } = useSuperQuery(
    VehiculoService.getVehiculosWithDeleted,
    {
      showSpinner: true
    }
  );
  const { data: proveedores } = useSuperQuery(ProveedorService.getBillers, {
    showSpinner: true
  });

  const { data: businessUnit } = useSuperQuery(BusinessUnitService.getBusinessUnit, {
    showSpinner: true
  });
  const addMutation = useSuperMutation(ChoferService.addChofer, {
    messages: {
      success: {
        message: 'El Chofer fue creado con exito'
      }
    }
  });

  const [visiblePage, setVisiblePage] = useState<'table' | 'create'>('table');

  async function create(choferFormValues: ChoferFormSchema, reset) {
    const chofer = choferFormSchemaToModel(choferFormValues);
    await addMutation.mutate(chofer);
    reset();
    choferesQuery.reload();
  }

  return (
    <>
      <PageHeader
        title="Chofer"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Choferes'
            : visiblePage === 'create'
              ? 'Nuevo Chofer'
              : 'Perfil Chofer'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Chofer
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />
      <PageContainer>
        {visiblePage === 'table' && (
          <ChoferTable
            choferes={choferesQuery.data || []}
            onChoferModifyButton={(id) => (
              <Link
                to={'/main/chofer/' + id}
                className="text-xs text-gray-400 hover:underline"
              >
                <FontAwesomeIcon
                  size="2x"
                  color="dodgerblue"
                  icon={faPencilAlt}
                />
              </Link>
            )}
          />
        )}

        {visiblePage === 'create' && (
          <ChoferCreate
            vehiculos={vehiculos!}
            proveedores={proveedores!}
            businessUnit={businessUnit!}
            create={create}
          />
        )}
      </PageContainer>
    </>
  );
};

export default ChoferCrudPage;
