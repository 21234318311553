import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';

export type ClientPrivateFormType = {
  id?: number;
  name: string;
  lastName: string;
  alias: string | null;
  phone1: string | null;
  phone2: string | null;
  email: string | null;
  code: string;
  address: DomicilioModel | null;
};

export const clientprivateFormBuilder = {
  toBackEnd: (clientprivate: ClientPrivateFormType) => {
    return {
      first_name: clientprivate.name,
      last_name: clientprivate.lastName,
      alias: clientprivate.alias,
      phone_1: clientprivate.phone1,
      phone_2: clientprivate.phone2,
      email: clientprivate.email,
      code: clientprivate.code,
      address:
        clientprivate.address &&
        domicilioBuilder.toBackEnd(clientprivate.address)
    };
  }
};
