import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useViajesTraslado } from 'app/viaje/services';
import { useDistanceAndPriceTransferReservationClientBusinessCalculator } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import { ReservaTrasladoFormWrapper } from 'app/viaje/viajeTraslado/viajeTraslado/components/ViajeTrasladoFormWrapper';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'shared/components';
import { TransferReservationSingleForm } from '../../viajeTraslado/forms/single/TransferReservationSingleForm';
import {
  transferReservationSingleFormBuilder,
  TransferReservationSingleFormType
} from '../../viajeTraslado/forms/single/TransferReservationSingleFormType';
import { useTransferReservationFormRepository } from '../../viajeTraslado/services/TransferReservationFormRepository';

export const TransferReservationClientBusinessToValidateModifyPage = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const formRepo = useTransferReservationFormRepository();
  const ViajeTrasladoService = useViajesTraslado();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientBusinessCalculator();

  const editTrasladoMutation = useSuperMutation(
    ViajeTrasladoService.approveTransferReservationToValidate,
    { showSpinner: true }
  );

  const formValueQuery = useSuperQuery(
    () => formRepo.getToValidate(Number(params.id)),
    {
      showSpinner: true
    }
  );

  async function createViajeTraslado(
    trasladoFormValues: TransferReservationSingleFormType
  ) {
    const ViajeTraslado =
      transferReservationSingleFormBuilder.toModel(trasladoFormValues);
    await editTrasladoMutation.mutate(ViajeTraslado, formValueQuery.data!.id);
    history.push('/main/Viaje/traslado/pendiente/coordinar');
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationSingleFormType,
    clientBusinessId: number
  ) {
    const estimationsPromise = calculateDistanceAndCost(
      {
        arrivalDate: trasladoFormValues.fechaYHoraDeLlegada,
        hasOutSite: trasladoFormValues.hasOutSite,
        points: trasladoFormValues.puntos.map((p) => ({
          lat: p.domicilio.latitud,
          lng: p.domicilio.longitud,
          minutesWaiting: Number(p.espera)
        })),
        serviceType: trasladoFormValues.tipoServicio,
        payWithCash: trasladoFormValues.pay_with_cash
      },
      clientBusinessId
    );
    return [await estimationsPromise];
  }

  return (
    <>
      <PageHeader title="Validación" />
      <PageContainer>
        <Card className="w-full" title="Validar traslado">
          <div className="p-8">
            <ReservaTrasladoFormWrapper<TransferReservationSingleFormType>
              onSubmit={createViajeTraslado}
              initialValues={formValueQuery.data!}
              calculateDistanceAndCost={estimatePrice}
            >
              {(params) => <TransferReservationSingleForm {...params} />}
            </ReservaTrasladoFormWrapper>
          </div>
        </Card>
      </PageContainer>
    </>
  );
};
