import {
  CircularProgress,
  FormControlLabel,
  Radio,
  TextField as MUITextField
} from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { Field, Form, Formik } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import { Moment } from 'moment';
import React from 'react';
import * as Yup from 'yup';
import { ChoferModel, ChoferPartialModel } from '../models/Chofer';
import useChoferes from '../services/ChoferService';

interface Props {
  onSubmit: (filters: DriverShiftFiltersFormType) => void;
  initialValues: DriverShiftFiltersFormType;
}

const Schema = Yup.object().shape({
  driver: Yup.mixed(),
  openFrom: Yup.mixed(),
  openTo: Yup.mixed(),
  approval: Yup.string()
});

const DriverShiftsFilters = ({ onSubmit, initialValues }: Props) => {
  const driverRepository = useChoferes();
  const driversQuery = useSuperQuery(driverRepository.getChoferes);
  return (
    <Formik<DriverShiftFiltersFormType>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm, touched, errors }) => (
        <Form className="max-w-md p-8">
          <Field
            component={KeyboardDateTimePicker}
            label="Apertura desde"
            name="openFrom"
            ampm={false}
            inputVariant="outlined"
            margin="normal"
            format="DD/MM/YYYY HH:mm"
          />
          <Field
            component={KeyboardDateTimePicker}
            label="Apertura hasta"
            name="openTo"
            ampm={false}
            inputVariant="outlined"
            margin="normal"
            format="DD/MM/YYYY HH:mm"
          />
          <Field
            name="driver"
            component={Autocomplete}
            noOptionsText="No se han encontrado resultados"
            loadingText="Cargando..."
            fullWidth
            options={driversQuery.data || []}
            getOptionLabel={(option: ChoferPartialModel) =>
              `${option.codigo} - ${option.nombreCompleto}`
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                error={touched['driver'] && !!errors['driver']}
                helperText={errors['driver']}
                label="Chofer"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {driversQuery.isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />

          <p className="mt-4 text-gray-500">Aprobación</p>
          <Field component={RadioGroup} name="approval">
            <FormControlLabel
              value="approved"
              control={<Radio color="primary" />}
              label="Aprobados"
            />
            <FormControlLabel
              value="pending"
              control={<Radio color="primary" />}
              label="Pendientes"
            />
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="Todos"
            />
          </Field>

          <div className="col-span-1 flex justify-end">
            <Button onClick={submitForm} variant="contained">
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export interface DriverShiftFiltersFormType {
  driver: ChoferModel | undefined;
  openFrom: Moment;
  openTo: Moment;
  approval: 'pending' | 'approved' | 'denied' | 'all';
}
export default DriverShiftsFilters;
