import { TransferReservationBaseFiltersFormType } from 'app/viaje/viajeTraslado/viajeTraslado/components/ViajeTrasladoBaseFilters';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useFilters = (
  initialState: TransferReservationBaseFiltersFormType
) => {
  const location = useLocation<
    Omit<TransferReservationBaseFiltersFormType, 'dateFrom' | 'dateTo'> & {
      dateFrom: string;
      dateTo: string;
    }
  >();
  const history = useHistory();
  const lastState = location.state
    ? {
        ...location.state,
        ...((location.state.page_size || initialState.page_size) && {
          page_size: location.state.page_size || initialState.page_size
        }),
        ...((location.state.page || initialState.page) && {
          page: location.state.page || initialState.page
        }),
        dateFrom: moment(location.state.dateFrom),
        dateTo: moment(location.state.dateTo)
      }
    : initialState;
  const [state, setState] =
    useState<TransferReservationBaseFiltersFormType>(lastState);

  useEffect(() => {
    history.replace(location.pathname, {
      ...state,
      dateFrom: state.dateFrom.format(),
      dateTo: state.dateTo.format()
    });
  }, [history, location.pathname, state]);

  return [state, setState] as const;
};
