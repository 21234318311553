import { Dialog, Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useCustomTablePagination } from 'shared/hooks/useCustomTablePagination';
import useClientBusiness from '../../client/clientBusiness/services/useClientBusiness';
import { FacturacionMovementTable } from '../components/FacturacionMovementTable';
import { FacturacionTableComponent } from '../components/FacturacionTableComponent';
import {
  FacturacionTableFiltersComponent,
  FacturacionTableFiltersType
} from '../components/FacturacionTableFilters';
import { MovimientoFacturaForm } from '../components/MovimientoFacturaForm';
import { FacturaModel } from '../models/FacturaModel';
import { useFacturacionRepository } from '../services/FacturacionService';

function FacturadosPage() {
  const facturacionService = useFacturacionRepository();
  const clientBusinessRepository = useClientBusiness();
  const [visibleMovimientoDialog, setVisibleMovimientoDialog] =
    useState<boolean>(false);
  const [visibleMovimientoList, setVisibleMovimientoList] =
    useState<boolean>(false);
  const [dialogId, setDialogId] = useState<number>(0);
  const [movements, setMovements] = useState<
    FacturaModel['movements'] | undefined
  >(undefined);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<FacturacionTableFiltersType>({
    client: null,
    nroFactura: '',
    dateFrom: moment().add(-1, 'years').startOf('d'),
    dateTo: moment(),
    idLiquidacion: ''
  });

  const showFacturaLiquidacionExcellMutation = useSuperMutation(
    facturacionService.getFacturaLiquidacionExcell,
    {
      onMutationFinish: (res) => {
        const filename = res.data?.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');
        const url = window.URL.createObjectURL(new Blob([res.data?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      },
      showSpinner: true
    }
  );

  const crearMovimientoMutation = useSuperMutation(
    facturacionService.crearMovimientoFactura,
    {
      messages: {
        success: { message: 'Se ha realizado el movimiento con éxito' }
      }
    }
  );

  const tablePagination = useCustomTablePagination((params: string) =>
    facturacionService.getFacturas(
      {
        ...filters,
        clientId: filters.client ? filters.client.id : null
      },
      params
    )
  );

  async function crearMovimiento(
    dialogId: number,
    values: {
      amountValue: string;
      observationValue: string;
      movementType: string;
    }
  ) {
    const correctValues = {
      amount:
        values.movementType === 'debito'
          ? '-' + values.amountValue
          : values.amountValue,
      observation: values.observationValue
    };
    await crearMovimientoMutation.mutate(dialogId, correctValues);
    tablePagination.reload();
    setVisibleMovimientoDialog(false);
  }

  useEffect(() => {
    tablePagination.reload();
  }, [filters]);

  return (
    <>
      <PageHeader
        title="Facturados"
        subtitle=""
        toolbar={
          <div className="flex space-x-2">
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtrar
            </Button>
          </div>
        }
      />

      <PageContainer>
        <FacturacionTableComponent
          tableProps={tablePagination}
          onCrearMovimiento={(id) => {
            setVisibleMovimientoDialog(true);
            setDialogId(id);
          }}
          onShowExcell={(id) => showFacturaLiquidacionExcellMutation.mutate(id)}
          onVerMovimientos={(id) => {
            setVisibleMovimientoList(true);
            setMovements(
              tablePagination.data.find((factura) => {
                return factura.id === id;
              })!.movements
            );
          }}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-lg"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <FacturacionTableFiltersComponent
            onSubmit={setFilters}
            initialValues={filters}
            findClientByName={clientBusinessRepository.findByName}
          />
        </div>
      </Drawer>
      <Dialog
        open={visibleMovimientoDialog}
        onBackdropClick={() => setVisibleMovimientoDialog(false)}
      >
        <MovimientoFacturaForm
          onSubmit={(values) => crearMovimiento(dialogId, values)}
        />
      </Dialog>
      <Dialog
        open={visibleMovimientoList}
        onBackdropClick={() => setVisibleMovimientoList(false)}
      >
        <div>
          {movements === undefined ? null : (
            <FacturacionMovementTable tableProps={movements} />
          )}
        </div>
      </Dialog>
    </>
  );
}

export default FacturadosPage;
