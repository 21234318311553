import { mockedViajeMensajeria } from '../helpers/ViajeMensajeriaCreation';
import { ViajeMensajeriaModel } from '../models/ViajeMensajeria/ViajeMensajeria';

let ViajeMensajerias: ViajeMensajeriaModel[] = [
  mockedViajeMensajeria(),
  mockedViajeMensajeria(),
  mockedViajeMensajeria(),
  mockedViajeMensajeria(),
  mockedViajeMensajeria()
];

const createPromise = <T>(x?: any) =>
  new Promise<T>((resolve) => setTimeout(() => resolve(x), 0));

const useViajesMensajeria = () => {
  const addViajeMensajeria = (x: ViajeMensajeriaModel) => {
    ViajeMensajerias.push({ ...x, id: Math.random() });
    return createPromise(x);
  };

  const addViajesMensajeria = (x: ViajeMensajeriaModel[]) => {
    x.forEach((Viaje) =>
      ViajeMensajerias.push({ ...Viaje, id: Math.random() })
    );
    return createPromise(x);
  };

  const editViajeMensajeria = (ViajeMensajeria: ViajeMensajeriaModel) => {
    ViajeMensajerias = ViajeMensajerias.map((x) =>
      x.id === ViajeMensajeria.id ? ViajeMensajeria : x
    );
    return createPromise(ViajeMensajeria);
  };

  const getViajesMensajeria = () => {
    return createPromise<ViajeMensajeriaModel[]>(ViajeMensajerias);
  };

  const getViajeMensajeria = (id: number | string) => {
    return createPromise<ViajeMensajeriaModel>(
      ViajeMensajerias.find((e) => e.id === id)
    );
  };

  return {
    addViajeMensajeria,
    editViajeMensajeria,
    getViajeMensajeria,
    getViajesMensajeria,
    addViajesMensajeria
  };
};

export default useViajesMensajeria;
