export interface TransferReservationPrices {
  expenses_price: number;
  fixed_price_name: string;
  kilometers: number;
  km_price: number;
  night_plus_price: number;
  out_site_price: number;
  rate_type: TransferReservationPricesRateType;
  type: number;
  waiting_minutes: number;
  waiting_minutes_price: number;
  administrative_commission: number;
  driver_commission: number;
  observation: null | string;
  transfer_price: number;
  driver_price: number;
}

export enum TransferReservationPricesRateType {
  KILOMENTO_LINEAL = 'Kilometro lineal',
  PRECIO_FIJO = 'Precio fijo',
  KILOMETRO_CON_REGRESO = 'Kilometro con regreso',
  MINIMUM_PRICE = 'Tarifa mínima',
  CABA_CABA = 'CABA - CABA',
  CABA_CABA_RETURN = 'CABA-CABA CON REG'
}
