import ClearIcon from '@material-ui/icons/Clear';

import lodash from 'lodash';
import { Dialog, Fade, IconButton, Menu, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Button } from '@vadiun/react-components';
import useChoferes from 'app/chofer/services/ChoferService';
import { useSnackbar } from 'notistack';
import ConfirmAction from './ConfirmAction';
import ItemsRequests from './ItemRequest';
import RejectAction from './RejectAction';
import { useNotificationsContext } from 'shared/context/notificationContext';
import { tw } from 'utils/tailwindUtils';

const DriversRequestsMenu = (): JSX.Element => {
  const { driversRequests: requests } = useNotificationsContext();
  const [requestsSelected, setRequestSelected] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const snackbar = useSnackbar();
  const open = Boolean(anchorEl);
  const [typeRequestSelected, setTypeRequestSelected] = useState<
    string | undefined
  >();
  const { approveShiftClosure, denyShiftClosure } = useChoferes();
  const [isOpenRequest, setIsOpenRequest] = useState<boolean>(false);
  const [isOpenApprove, setIsOpenApprove] = useState<boolean>(false);
  const [isLoadingApproveRequests, setLoadingApproveRequests] =
    useState<boolean>(false);
  const [isOpenReject, setIsOpenReject] = useState<boolean>(false);
  const [isLoadingRejectRequests, setLoadingRejectRequests] =
    useState<boolean>(false);

  const { length: sizeDriversPendingShift } = requests || [];
  const driversRequests = lodash.groupBy(requests || [], (d) => {
    return d.close_type;
  });
  const driversRequestGrouped = Object.entries(driversRequests);
  const optionsDriversRequest = driversRequestGrouped.find(
    (item) => item[0] === typeRequestSelected
  )?.[1];
  const hasNotRequests = sizeDriversPendingShift === 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseModalRequest = () => {
    setTypeRequestSelected(undefined);
    setIsOpenRequest(false);
  };

  const onCloseModalConfirm = () => {
    setIsOpenApprove(false);
  };

  const onCloseModalReject = () => {
    setIsOpenReject(false);
  };

  const onSelectRequest = (id: number) => {
    const hasPrevSelect = requestsSelected.some((req) => req === id);
    if (hasPrevSelect) {
      setRequestSelected((prev) => prev.filter((req) => req !== id));
      return;
    }
    setRequestSelected((prev) => [...prev, id]);
  };

  const onApproveRequest = async () => {
    setLoadingApproveRequests(true);
    const responses = await Promise.allSettled(
      requestsSelected.map((requestId) => approveShiftClosure(requestId))
    );
    setLoadingApproveRequests(false);
    onCloseModalRequest();
    onCloseModalConfirm();

    if (responses.some((res) => res.status === 'rejected')) {
      responses.map((res: any) => {
        return snackbar.enqueueSnackbar(res.reason.message, {
          variant: 'error'
        });
      });
      return;
    }

    snackbar.enqueueSnackbar('Las solicitudes fueron aprobadas', {
      variant: 'success'
    });
  };

  const onRejectRequest = async () => {
    setLoadingRejectRequests(true);
    const responses = await Promise.allSettled(
      requestsSelected.map((requestId) => denyShiftClosure(requestId))
    );
    setLoadingRejectRequests(false);
    onCloseModalRequest();
    onCloseModalReject();

    if (responses.some((res) => res.status === 'rejected')) {
      responses.map((res: any) => {
        return snackbar.enqueueSnackbar(res.reason.message, {
          variant: 'error'
        });
      });
      return;
    }

    snackbar.enqueueSnackbar('Las solicitudes fueron rechazadas', {
      variant: 'success'
    });
  };

  const onClickAcceptRequests = () => {
    if (requestsSelected.length === 0) {
      snackbar.enqueueSnackbar('Debes seleccionar una solicitud', {
        variant: 'error'
      });
      return;
    }
    setIsOpenApprove(true);
  };

  const onClickRejectRequests = () => {
    if (requestsSelected.length === 0) {
      snackbar.enqueueSnackbar('Debes seleccionar una solicitud', {
        variant: 'error'
      });
      return;
    }
    setIsOpenReject(true);
  };

  useEffect(() => {
    if (typeRequestSelected === undefined) {
      setRequestSelected([]);
      return;
    }
    setIsOpenRequest(true);
  }, [typeRequestSelected]);

  useEffect(() => {
    if (hasNotRequests) {
      // setIsOpenRequest(false);
      setAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNotRequests]);

  return (
    <>
      <div
        className={tw(
          'flex-1 flex items-center gap-6 justify-between py-0 pl-2 pr-5 rounded-lg duration-500 cursor-pointer hover:bg-gray-100',
          {
            'bg-orange-50': sizeDriversPendingShift
          }
        )}
        onClick={hasNotRequests ? undefined : handleClick}
      >
        <p
          className={tw(
            'duration-500 text-gray-400 text-xs leading-none w-56 flex items-center',
            {
              'text-orange-500': sizeDriversPendingShift
            }
          )}
        >
          {sizeDriversPendingShift ? (
            <span className="duration-300 relative flex h-3 w-3 mr-2">
              <span className="bg-orange-300 animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"></span>
              <span className="bg-orange-400 relative inline-flex rounded-full h-3 w-3"></span>
            </span>
          ) : (
            <div className="h-3 w-3 ml-2" />
          )}
          Apertura / cierre de turnos
        </p>
        <span
          className={tw(
            'text-sm text-gray-700 duration-300 w-6 h-6 rounded-2xl flex justify-center items-center'
          )}
        >
          {sizeDriversPendingShift}
        </span>
      </div>
      <Menu
        elevation={3}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        style={{
          marginLeft: 20,
          marginTop: 40
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {driversRequestGrouped.map((item) => {
          return (
            <MenuItem
              key={item[0]}
              onClick={() => setTypeRequestSelected(item[0])}
              style={{
                height: 30,
                width: '18dvh',
                paddingInline: 10
              }}
            >
              <div
                className={`flex w-full justify-between gap-8 text-sm text-gray-400 rounded-md`}
              >
                <p>{item[0]}</p>
                <p className="text-gray-700">{item[1].length}</p>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
      <Dialog open={isOpenRequest} onClose={onCloseModalRequest} maxWidth="md">
        <div className="px-4 py-4" style={{ width: 450, overflow: 'hidden' }}>
          <div className="mb-3 flex justify-between">
            <p className="text-lg text-gray-800">{typeRequestSelected}</p>
            <IconButton
              size="small"
              aria-label="close modal"
              onClick={onCloseModalRequest}
            >
              <ClearIcon fontSize="medium" />
            </IconButton>
          </div>
          <ItemsRequests
            optionsDriversRequest={optionsDriversRequest ?? []}
            requestsSelected={requestsSelected}
            onChecked={onSelectRequest}
          />
          <div className="flex items-center justify-end gap-3 pt-4">
            <Button variant="light" color="red" onClick={onClickRejectRequests}>
              Rechazar
            </Button>
            <Button
              onClick={onClickAcceptRequests}
              variant="light"
              color="green"
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Dialog>
      <ConfirmAction
        isOpenApprove={isOpenApprove}
        onApproveRequest={onApproveRequest}
        onCloseModalConfirm={onCloseModalConfirm}
        isLoading={isLoadingApproveRequests}
      />
      <RejectAction
        isOpenReject={isOpenReject}
        onRejectRequest={onRejectRequest}
        onCloseModalReject={onCloseModalReject}
        isLoading={isLoadingRejectRequests}
      />
    </>
  );
};

export default DriversRequestsMenu;
