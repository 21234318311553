import { httpClient } from 'shared/services/http/httpClient';
import {
  tollBuilder,
  TollModel,
  TollModelCRUD
} from '../models/Toll';

const useTolls = () => {
  const addToll = (x: TollModelCRUD) => {
    return httpClient.post('tolls', tollBuilder.toBackEnd(x));
  };

  const removeToll = (toll: TollModel) => {
    return httpClient.delete('tolls/' + toll.id);
  };

  const restoreToll = (toll: TollModel) => {
    return httpClient.put('tolls/' + toll.id + '/restore');
  };

  const updateToll = (toll: TollModelCRUD) => {
    return httpClient.put(
      'tolls/' + toll.id,
      tollBuilder.toBackEnd(toll)
    );
  };

  const getTolls = async (): Promise<TollModel[]> => {
    const response = await httpClient.get('tolls');
    return response.map(tollBuilder.fromBackEnd);
  };

  return {
    addToll,
    removeToll,
    updateToll,
    getTolls,
    restoreToll
  };
};

export default useTolls;
