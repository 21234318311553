import useViajesTraslado from '../viajeTraslado/viajeTraslado/services/ViajeTrasladoService';
import { Moment } from 'moment';
import { distanceCalculator } from '.';

export interface TravelDurationEstimations {
  distance: number;
  duration: number;
  durationTraffic: number;
}

export interface TravelEstimationsWithPrice extends TravelDurationEstimations {
  price: number;
}

type PriceEstimationStrategy = (kilometers: number) => Promise<{
  price: number;
}>;

type DistanceEstimationStrategy = (
  points: {
    lat: number;
    lng: number;
    minutesWaiting: number;
  }[],
  arrivalDate: Moment
) => Promise<TravelDurationEstimations>;

interface DistanceAndPriceCalculatorTravel {
  points: { lat: number; lng: number; minutesWaiting: number }[];
  arrivalDate: Moment;
}

const getOrigin = (travel: DistanceAndPriceCalculatorTravel) =>
  travel.points[0];

const getDestination = (travel: DistanceAndPriceCalculatorTravel) =>
  travel.points[travel.points.length - 1];

const getTotalMinutesWaiting = (travel: DistanceAndPriceCalculatorTravel) =>
  travel.points.reduce(
    (acum, { minutesWaiting }) => acum + Number(minutesWaiting),
    0
  );

export const useDistanceAndPriceTransferReservationClientBusinessCalculator =
  () => {
    const { estimateClientBusinessPrice } = useViajesTraslado();

    const priceCalculator = async (
      travel: DistanceAndPriceCalculatorTravel & {
        rateName?: string;
        arrivalDate: Moment;
        serviceType: string;
        hasOutSite: boolean;
        payWithCash: boolean;
      },
      clientID: number,
      kilometers: number
    ) => {
      const firstPoint = getOrigin(travel);
      const lastPoint = getDestination(travel);
      const totalMinutesWaiting = getTotalMinutesWaiting(travel);

      return estimateClientBusinessPrice(
        {
          fixedPriceName: travel.rateName,
          kilometers,
          reservationDatetime: travel.arrivalDate,
          totalMinutesWaiting,
          originLatitude: firstPoint.lat,
          originLongitude: firstPoint.lng,
          finalLatitude: lastPoint.lat,
          finalLongitude: lastPoint.lng,
          categoryName: travel.serviceType,
          hasOutSite: travel.hasOutSite,
          payWithCash: travel.payWithCash
        },
        clientID
      );
    };

    const calculateDistanceAndCost = async (
      travel: DistanceAndPriceCalculatorTravel & {
        rateName?: string;
        arrivalDate: Moment;
        serviceType: string;
        hasOutSite: boolean;
        payWithCash: boolean;
      },
      clientID: number
    ) => {
      const distanceEstimations = await distanceCalculator(
        travel.points,
        travel.arrivalDate
      );
      const priceEstimation = await priceCalculator(
        travel,
        clientID,
        distanceEstimations.distance
      );
      return { ...distanceEstimations, ...priceEstimation };
    };

    return { calculateDistanceAndCost };
  };

export const useDistanceAndPriceTransferReservationClientPrivateCalculator =
  () => {
    const { estimateClientPrivatePrice } = useViajesTraslado();

    const priceCalculator = async (
      travel: DistanceAndPriceCalculatorTravel & {
        serviceType: string;
        hasOutSite: boolean;
      },
      clientID: number,
      kilometers: number
    ) => {
      const firstPoint = getOrigin(travel);
      const lastPoint = getDestination(travel);
      const totalMinutesWaiting = getTotalMinutesWaiting(travel);

      return estimateClientPrivatePrice(
        {
          kilometers,
          reservationDatetime: travel.arrivalDate,
          totalMinutesWaiting,
          originLatitude: firstPoint.lat,
          originLongitude: firstPoint.lng,
          finalLatitude: lastPoint.lat,
          finalLongitude: lastPoint.lng,
          categoryName: travel.serviceType,
          hasOutSite: travel.hasOutSite
        },
        clientID
      );
    };

    const calculateDistanceAndCost = async (
      travel: DistanceAndPriceCalculatorTravel & {
        serviceType: string;
        hasOutSite: boolean;
      },
      clientID: number
    ) => {
      const distanceEstimations = await distanceCalculator(
        travel.points,
        travel.arrivalDate
      );
      const priceEstimation = await priceCalculator(
        travel,
        clientID,
        distanceEstimations.distance
      );
      return { ...distanceEstimations, ...priceEstimation };
    };

    return { calculateDistanceAndCost };
  };
