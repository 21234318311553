import {
  RateCategoryType,
  RateModel
} from 'app/client/clientBusiness/rates/models/RateModel';
import useRate from 'app/client/clientBusiness/rates/services/useRate';
import { Moment } from 'moment';
import { httpClient } from 'shared/services/http/httpClient';
import { AnalisisComercial } from '../models/AnalisisComercial';
import { PricePerClient } from '../models/PricePerClientModel';
import { ReservationAmountPerClientModel } from '../models/ReservationAmountPerClientModel';
import { ReservationPricePerClientModel } from '../models/ReservationPricePerClientModel';
import { SpentPerClientModel } from '../models/SpentPerClientModel';

export const useClientInformationRepository = () => {
  const rateRepo = useRate();

  const getSpentPerClient = async (
    fromDate: Moment,
    toDate: Moment
  ): Promise<SpentPerClientModel[]> => {
    return await httpClient.get(
      `/transfer-reservations/consuption-per-client?from=${fromDate.format(
        'Y-M-D'
      )}&to=${toDate.format('Y-M-D')}`
    );
  };

  const getReservationPricePerClient = async (
    fromDate: Moment,
    toDate: Moment
  ): Promise<ReservationPricePerClientModel[]> => {
    return await httpClient.get(
      `/transfer-reservations/average-trip-value?from=${fromDate.format(
        'Y-M-D'
      )}&to=${toDate.format('Y-M-D')}`
    );
  };

  const getReservationAmountPerClient = async (data: {
    fromDate: Moment;
    toDate: Moment;
    clientes: { fantasyName: string }[];
  }): Promise<ReservationAmountPerClientModel[]> => {
    let res = await httpClient.get<ReservationAmountPerClientModel[]>(
      `transfer-reservations/received-per-client?from=${data.fromDate.format(
        'Y-MM-DD'
      )}&to=${data.toDate.format('Y-MM-DD')}`
    );
    // El back no trae todas las fechas en todos los clientes asique
    // primero busco el rango de fechas maximo para un cliente y uso eso para todas las empresas
    const fechas = (
      res.sort((a, b) => b.tripsPerDate.length - a.tripsPerDate.length)[0]
        ?.tripsPerDate ?? []
    ).map((trip) => trip.fecha);
    const resConFechasCompletas = res.map((x) => {
      return {
        ...x,
        tripsPerDate: fechas.map((fecha) => {
          const amount =
            x.tripsPerDate.find((date) => date.fecha === fecha)?.amount ?? 0;
          return {
            fecha,
            amount
          };
        })
      };
    });

    // Filtro los clientes
    const resFiltrado = resConFechasCompletas.filter((b) => {
      if (data.clientes.length === 0) {
        return true;
      }
      return data.clientes.some((c) => c.fantasyName === b.name);
    });

    return resFiltrado;
  };

  const getPricesPerClient = async (): Promise<PricePerClient[]> => {
    const mapRateToPrice = (rate: RateModel): PricePerClient => {
      const baseInfo: Partial<PricePerClient> = {
        client: rate.fantasyName,
        kmMin: String(rate.minNumberKilometers),
        vtoActual: rate.expirationDate.format('DD/MM/YYYY'),
        vtoAnterior: rate.previous_rate?.format('DD/MM/YYYY'),
        precioOutsite: String(rate.outSitePrice),
        comisionBase: String(rate.baseCommission),
        limiteComisionBase: String(rate.limitPriceBaseCommission),
        comisionExcedente: String(rate.surplusCommission)
      };

      const prices: Partial<PricePerClient>[] = [];
      rate.categories.forEach((cat) => {
        const addOrUpdatePrice = (label: string, price: number | string) => {
          let item = prices.find((price) => price.item?.includes(label));
          if (item === undefined) {
            item = {
              ...baseInfo,
              item: label,
              ejecutivo: '',
              vip: '',
              utilitario: '',
              mensajeria: '',
              flete: ''
            };
            prices.push(item);
          }
          if (cat.name === RateCategoryType.EJECUTIVO) {
            item.ejecutivo = String(price);
            item.kmMinPrice = String(
              (Number(price) * Number(item.kmMin)).toFixed(2)
            );
            item.hourWaitingPrice = String(Number(cat.minutePrice) * 60);
          }
          if (cat.name === RateCategoryType.FLETE) {
            item.flete = String(price);
          }
          if (cat.name === RateCategoryType.UTILITARIO) {
            item.utilitario = String(price);
          }
          if (cat.name === RateCategoryType.UTILITARIO_MENSAJERIA) {
            item.mensajeria = String(price);
          }
          if (cat.name === RateCategoryType.VIP) {
            item.vip = String(price);
          }
        };
        cat.linearKilometerPrices.forEach((km) => {
          addOrUpdatePrice(
            `Km Lineal ${km.numberKilometersFrom} - ${km.numberKilometersTo}`,
            km.price
          );
        });
        cat.returnKilometerPrices.forEach((km) => {
          addOrUpdatePrice(
            `Km con regreso ${km.numberKilometersFrom} - ${km.numberKilometersTo}`,
            km.price
          );
        });
        cat.fixedPrices.forEach((fixedPrice) => {
          addOrUpdatePrice(fixedPrice.name, fixedPrice.price);
        });
        cat.priced.forEach((priced) => {
          addOrUpdatePrice(
            `${priced.address1.calle} ${priced.address1.numero}, ${priced.address1.partido} - ${priced.address2.calle} ${priced.address2.numero}, ${priced.address2.partido}`,
            priced.price
          );
        });
      });

      return prices as unknown as PricePerClient;
    };

    const res = await rateRepo.getAllCurrentRates();
    return res.flatMap(mapRateToPrice);
  };

  const getAnalisisComercial = async (filters: {
    from: Moment;
    to: Moment;
  }): Promise<AnalisisComercial> => {
    const res = await httpClient.get(
      'business-clients/analisis-comercial?from=' +
        filters.from.format() +
        '&to=' +
        filters.to.format()
    );
    return res;
  };

  return {
    getReservationPricePerClient,
    getPricesPerClient,
    getSpentPerClient,
    getAnalisisComercial,
    getReservationAmountPerClient
  };
};
