import { ClientType } from 'app/client/main/models/ClientType';
import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';

export enum IVACondition {
  CONSUMIDOR_FINAL = 'Consumidor final',
  EXENTO = 'Exento',
  RESPONSABLE_INCRIPTO = 'Responsable inscripto',
  RESPONSABLE_MONOTRIBUTO = 'Responsable monotributo'
}

export const IVA_CONDITIONS = Object.values(IVACondition).sort();

export interface CostCenter {
  id: number;
  name: string;
}

export interface ClientBusinessModel {
  id: number;
  code: number | string;
  businessName: string;
  fantasyName: string;
  cuit: number | string;
  ivaCondition: IVACondition;
  phone: number | string;
  mail: string;
  web: string;
  socialMedia: string;
  hasPriority: boolean;
  gross_income: string;
  business_client_cost_centers?: CostCenter[];
  business_client_sub_cost_centers?: {
    business_client_id: number;
    id: number;
    name: string;
  }[];
  billingContact: {
    id: number;
    contactName: string;
    phone: string;
    mail: string;
    observation: string;
    accountOfficer: string;
  };
  collectionContact: {
    id: number;
    contactName: string;
    phone: string;
    mail: string;
    observation: string;
    paymentMethod: string;
  };
  reservationRequirement: {
    id: number;
    needsCostCenter: boolean;
    needsSector: boolean;
    needsAuthorized: boolean;
    needsBuyOrder: boolean;
    needsEmployeeNumber: boolean;
    needsSubCostCenter: boolean;
    needsPlatformApplication: boolean;
    needsTripNumber: boolean;
    needsData1: boolean;
    needsData2: boolean;
    needsData3: boolean;
  };
  salesManager: {
    id: number;
    contactName: string;
    phone: string;
    mail: string;
    observation: string;
  };
  address: DomicilioModel;
  isDeleted: boolean;
  type: ClientType;
}

export interface ClientBusinessCrud
  extends Omit<
    ClientBusinessModel,
    | 'id'
    | 'isDeleted'
    | 'billingContact'
    | 'collectionContact'
    | 'reservationRequirement'
    | 'salesManager'
    | 'type'
  > {
  billingContact: Omit<ClientBusinessModel['billingContact'], 'id'>;
  collectionContact: Omit<ClientBusinessModel['collectionContact'], 'id'>;
  reservationRequirement: Omit<
    ClientBusinessModel['reservationRequirement'],
    'id'
  >;
  salesManager: Omit<ClientBusinessModel['salesManager'], 'id'>;
  id?: number;
}

export const clientBusinessBuilder = {
  fromBackEnd: (client): ClientBusinessModel => {
    return {
      ...client,
      address: domicilioBuilder.fromBackEnd(client.address),
      type: ClientType.business
    };
  },
  toBackEnd: (client: ClientBusinessCrud) => {
    return {
      code: client.code,
      business_name: client.businessName,
      fantasy_name: client.fantasyName,
      cuit: client.cuit,
      iva_condition: client.ivaCondition,
      phone: Number(client.phone),
      mail: client.mail,
      web: client.web,
      social_media: client.socialMedia,
      has_priority: client.hasPriority,
      gross_income: client.gross_income,
      address: domicilioBuilder.toBackEnd(client.address),
      billing_contact: {
        contact_name: client.billingContact.contactName,
        phone: Number(client.billingContact.phone),
        mail: client.billingContact.mail,
        observation: client.billingContact.observation,
        account_officer_id: client.billingContact.accountOfficer
      },
      collection_contact: {
        contact_name: client.collectionContact.contactName,
        phone: Number(client.collectionContact.phone),
        mail: client.collectionContact.mail,
        observation: client.collectionContact.observation,
        payment_method: client.collectionContact.paymentMethod
      },
      sales_manager: {
        contact_name: client.salesManager.contactName,
        phone: Number(client.salesManager.phone),
        mail: client.salesManager.mail,
        observation: client.salesManager.observation
      },
      reservation_requirement: {
        needs_cost_center: client.reservationRequirement.needsCostCenter,
        needs_sub_cost_center: client.reservationRequirement.needsSubCostCenter,
        needs_sector: client.reservationRequirement.needsSector,
        needs_authorized: client.reservationRequirement.needsAuthorized,
        needs_buy_order: client.reservationRequirement.needsBuyOrder,
        needs_employee_number:
          client.reservationRequirement.needsEmployeeNumber,
        needs_platform_application:
          client.reservationRequirement.needsPlatformApplication,
        needs_trip_number: client.reservationRequirement.needsTripNumber,
        needs_data_1: client.reservationRequirement.needsData1,
        needs_data_2: client.reservationRequirement.needsData2,
        needs_data_3: client.reservationRequirement.needsData3
      }
    };
  }
};

export interface ClientBusinessPartialModel {
  id: number;
  code: number;
  businessName: string;
  fantasyName: string;
  cuit: number | string;
  isDeleted: boolean;
}

export const clientBusinessPartialBuilder = {
  fromBackEnd: (client): ClientBusinessPartialModel => {
    return client;
  }
};
