import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { ClientPrivateModel } from 'app/client/private/private/models/ClientPrivate';
import {
  CANALES_DE_VIAJE,
  TIPOS_SERVICIO_VIAJE_TRASLADO
} from 'app/viaje/models';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps
} from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import { AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import {
  TransferReservationPrivateBatchFormType,
  TransferReservationPrivateBatchTripFormType
} from './TransferReservationPrivateBatchFormType';
import { transferReservationPrivateBatchFormValidation } from './TransferReservationPrivateBatchFormValidation';
import { TransferReservationPrivateBatchTripForm } from './TransferReservationPrivateBatchTripForm';
import {
  createTransferReservationPrivateBatchInitialValues,
  createTransferReservationPrivateBatchTripInitialValues
} from './TransferreservationPrivateBatchFormInitialValues';

interface Props {
  initialValues?: TransferReservationPrivateBatchFormType;
  onSubmit: (
    viajeFormValues: TransferReservationPrivateBatchFormType,
    reset
  ) => void;
  onOpenCreatePrivateClient?: () => void;
  getClientesByName: (name: string) => Promise<ClientPrivateModel[]>;
  onCalculateDistanceAndCost: (
    viajeFormValues: TransferReservationPrivateBatchFormType
  ) => void;
}

export function ReservaTrasladoPrivateBatchForm({
  initialValues: _initialValues,
  onSubmit,
  getClientesByName,
  onCalculateDistanceAndCost,
  onOpenCreatePrivateClient
}: Props) {
  const initialValues = useMemo(
    () =>
      _initialValues ?? createTransferReservationPrivateBatchInitialValues(),
    [_initialValues]
  );
  const formRef =
    useRef<FormikProps<TransferReservationPrivateBatchFormType>>(null);
  const verifyAction = useVerifyAction();

  const checkPreviusDate = (viajes) => {
    return viajes.some((viaje) =>
      moment(viaje.fechaYHoraDeLlegada).isBefore(moment())
    );
  };

  const checkMinutesBeforeDeparture = (viajes) => {
    return viajes.some((viaje: TransferReservationPrivateBatchTripFormType) =>
      moment(viaje.fechaYHoraDeLlegada).isBefore(
        moment(viaje.minutosPreviosASalida)
      )
    );
  };

  return (
    <Formik<TransferReservationPrivateBatchFormType>
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={transferReservationPrivateBatchFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ touched, errors, values, submitForm, isValid, setFieldValue }) => (
        <Form className="w-full">
          <FormikOnFieldChange<TransferReservationPrivateBatchFormType>
            field="cliente"
            onChange={(
              cliente: TransferReservationPrivateBatchFormType['cliente']
            ) => {
              if (cliente === null || values.viajes[0].puntos.length > 0) {
                return;
              }
              let [primerViaje, ...viajes] = values.viajes;
              primerViaje.puntos = [
                {
                  domicilio: cliente.address,
                  espera: '',
                  observacion: '',
                  pasajeros: []
                }
              ];
              setFieldValue('viajes', [primerViaje, ...viajes]);
            }}
          />
          <div className="flex w-full gap-4">
            <Field
              name="cliente"
              component={FormikAutocompleteAsync}
              fetchOptions={getClientesByName}
              fullWidth
              getOptionLabel={(cliente: ClientPrivateModel) =>
                cliente.name +
                ' ' +
                cliente.lastName +
                ' (' +
                cliente.code +
                ')'
              }
              className="w-full"
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial
                  {...params}
                  error={touched['cliente'] && !!errors['cliente']}
                  helperText={errors['cliente']}
                  label="Cliente"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Button
              variant="outlined"
              shape="circle"
              className="mt-2"
              onClick={onOpenCreatePrivateClient}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <div className="grid grid-cols-12 gap-6 border-b-2 border-dashed py-8">
            <FormControl variant="outlined" fullWidth className="col-span-3">
              <InputLabel>Tipo de servicio</InputLabel>
              <Field
                component={Select}
                name="tipoServicio"
                label="Tipo de servicio"
              >
                {TIPOS_SERVICIO_VIAJE_TRASLADO.map((canal) => (
                  <MenuItem value={canal} key={canal}>
                    {canal}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <FormControl variant="outlined" fullWidth className="col-span-3">
              <InputLabel>Canal de ingreso</InputLabel>
              <Field
                component={Select}
                name="canalIngreso"
                label="Canal de ingreso"
              >
                {CANALES_DE_VIAJE.map((canal) => (
                  <MenuItem value={canal} key={canal}>
                    {canal}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="prioridad"
                color="primary"
                Label={{ label: 'Prioritario' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="hasOutSite"
                color="primary"
                Label={{ label: 'Out Site' }}
              />
            </div>
          </div>
          <div className="border-b-2 border-dashed py-8 ">
            <Field
              name="observacionCoordinacion"
              label="Observacion coordinacion"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>
          <FieldArray
            name={'viajes'}
            render={(arrayHelpers) => (
              <>
                {values.viajes.map((viaje, index) => (
                  <div
                    className="my-4 border-b-2 border-dashed py-8"
                    key={index}
                  >
                    <TransferReservationPrivateBatchTripForm
                      viaje={viaje}
                      index={index}
                      clonar={() =>
                        arrayHelpers.push({
                          ...values.viajes[index],
                          minutosPreviosASalida: 0
                        })
                      }
                      revertir={() =>
                        arrayHelpers.replace(index, {
                          ...values.viajes[index],
                          puntos: values.viajes[index].puntos.reverse()
                        })
                      }
                      borrar={() =>
                        verifyAction({
                          onAccept: () => arrayHelpers.remove(index),
                          body: '¿Está seguro que desea borrar este viaje?'
                        })
                      }
                      previousMinutesError={checkMinutesBeforeDeparture(
                        values.viajes
                      )}
                    />
                  </div>
                ))}
                <Button
                  onClick={() => {
                    arrayHelpers.push(
                      createTransferReservationPrivateBatchTripInitialValues()
                    );
                  }}
                  variant="light"
                  color="green"
                >
                  Nuevo Viaje
                </Button>
              </>
            )}
          />
          <ErrorMessage name="detallesTraslado">
            {(msg) =>
              typeof msg === 'string' ? (
                <p className="col-span-6 text-sm text-red-500">{msg}</p>
              ) : null
            }
          </ErrorMessage>

          <div className="mt-8 flex justify-between">
            <Button
              variant="contained"
              color="primary"
              disabled={
                values.viajes.some((viaje) => viaje.puntos.length < 2) ||
                !isValid
              }
              onClick={() => onCalculateDistanceAndCost(values)}
            >
              Calcular costos y distancias
            </Button>

            <Button
              variant="contained"
              color="green"
              className="ml-auto"
              onClick={() => {
                if (!checkPreviusDate(values.viajes)) {
                  submitForm();
                } else {
                  verifyAction({
                    onAccept: () => submitForm(),
                    body: '¿Está seguro de crear un viaje con una fecha previa a la de hoy?'
                  });
                }
              }}
            >
              Guardar Viaje
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
