export enum ViajeMinibusTipoServicio {
  M19 = 'Minibus 19',
  M15 = 'Minibus 15',
  BUS = 'Bus',
  MINIVAN = 'Minivan'
}

export function tiposDeServicioViajeMinibus() {
  return Object.values(ViajeMinibusTipoServicio).sort();
}

export function cantidadDePasajerosTipoServicioMinibus(
  tipoServicio: ViajeMinibusTipoServicio
) {
  switch (tipoServicio) {
    case 'Minibus 19':
      return 19;
    case 'Minibus 15':
      return 15;
    case 'Bus':
      return 50;
    case 'Minivan':
      return 7;
    default:
      return 0;
  }
}
