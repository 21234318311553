import { LaravelPaginated } from 'shared/hooks/useCustomTablePagination';
import { httpClient } from 'shared/services/http/httpClient';
import { DriverShiftFiltersFormType } from '../components/DriverShiftsFilters';
import environment from 'environment/environment';

import {
  choferBuilder,
  ChoferCRUD,
  ChoferDeshabilitacion,
  ChoferDesvinculacion,
  ChoferModel,
  choferPartialBuilder,
  ChoferPartialModel
} from '../models/Chofer';
import {
  ChoferTrabajando,
  choferTrabajandoBuilder
} from '../models/ChoferTrabajando';
import {
  DriverShift,
  driverShiftBuilder,
  DriverShiftType
} from '../models/DriverShift';
import { DriversRecomended } from 'app/viaje/viajeTraslado/coordinar/types/Driver';

const createPromise = <T>(x?: any) =>
  new Promise<T>((resolve) => setTimeout(() => resolve(x), 0));

const useChoferes = () => {
  const addChofer = (x: ChoferCRUD) => {
    const chofer = choferBuilder.toBackEnd(x);
    return httpClient.post('drivers', chofer);
  };

  const closeShift = (id: number) => {
    return httpClient.put(`drivers/${id}/close-work-day-shift`);
  };

  const forceBreak = (id: number) => {
    return httpClient.put(`drivers/${id}/force-break`);
  };

  const desvincularChofer = (
    desvinculacion: ChoferDesvinculacion,
    chofer: ChoferModel
  ) => {
    return httpClient.put(`drivers/${chofer.id}/disassociate`, {
      date: desvinculacion.fecha!.format('YYYY-MM-DD'),
      observation: desvinculacion.observacion,
      reason: desvinculacion.motivo
    });
  };

  const revincularChofer = (chofer: ChoferModel) => {
    return httpClient.put(`drivers/${chofer.id}/associate`);
  };

  const restoreChofer = (chofer: ChoferModel) => {
    return httpClient.put(`drivers/${chofer.id}/restore`);
  };

  const deleteChofer = (
    desvinculacion: ChoferDesvinculacion,
    chofer: ChoferModel
  ) => {
    return httpClient.delete(`drivers/${chofer.id}`, {
      data: {
        date: desvinculacion.fecha!.format('YYYY-MM-DD'),
        observation: desvinculacion.observacion,
        reason: desvinculacion.motivo
      }
    });
  };

  const deshabilitarChofer = (
    deshabilitacion: ChoferDeshabilitacion,
    Chofer: ChoferModel
  ) => {
    deshabilitacion.id = Chofer.deshabilitaciones.length;
    Chofer.deshabilitaciones.push({ ...deshabilitacion });
    return createPromise(Chofer);
  };

  const aceptarDeshabilitacion = (
    deshabilitacion: ChoferDeshabilitacion,
    Chofer: ChoferModel
  ) => {
    deshabilitacion.aceptada = true;
    return createPromise(Chofer);
  };

  const rechazarDeshabilitacion = (
    deshabilitacion: ChoferDeshabilitacion,
    Chofer: ChoferModel
  ) => {
    deshabilitacion.aceptada = false;
    return createPromise(Chofer);
  };

  const cancelarDeshabilitacion = (
    deshabilitacion: ChoferDeshabilitacion,
    Chofer: ChoferModel
  ) => {
    deshabilitacion.finalizada = true;
    return createPromise(Chofer);
  };

  const editChofer = (chofer: ChoferCRUD) => {
    return httpClient.post(
      `drivers/${chofer.id}?_method=PUT`,
      choferBuilder.toBackEnd(chofer)
    );
  };

  const getChoferesWithDeleted = async (): Promise<ChoferPartialModel[]> => {
    const res = await httpClient.get('drivers?isDeleted=1');
    return res.map(choferPartialBuilder.fromBackEnd);
  };

  const getChoferes = async (params = ''): Promise<ChoferPartialModel[]> => {
    const res = await httpClient.get('drivers' + params);
    return res
      .map(choferPartialBuilder.fromBackEnd)
      .sort(
        (a: ChoferPartialModel, b: ChoferPartialModel) => a.codigo - b.codigo
      );
  };

  const getDriversRecomended = async (
    idTravel: number,
    filters?: string
  ): Promise<DriversRecomended> => {
    return fetch(
      `${
        environment.matching
      }/transfer-reservations/${idTravel}/recomended-drivers${
        filters?.length ? `?${filters}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Api-Key': environment.matchingKeys
        }
      }
    ).then((res) => res.json());
  };

  const getWorkingDrivers = async (filters?: {
    code?: string;
    free?: boolean;
    onBrake?: boolean;
    busy?: boolean;
    workdayNotStarted?: boolean;
    workdayStarted?: boolean;
  }): Promise<ChoferTrabajando[]> => {
    let params = getQueryOnWorkingDrivers(filters);
    const res = await httpClient.get(`drivers/working?${params}`);
    return res.map(choferTrabajandoBuilder.fromBackEnd);
  };

  const getQueryOnWorkingDrivers = (filters?: {
    code?: string;
    free?: boolean;
    onBrake?: boolean;
    busy?: boolean;
    workdayNotStarted?: boolean;
    workdayStarted?: boolean;
  }): string => {
    let params: string[] = [];
    if (filters?.code) {
      params.push(`driver_code=${filters?.code}`);
    }
    if (filters?.free && !filters?.onBrake) {
      params.push(
        `with_trip_in_course=0&shift_type=${DriverShiftType.SHIFT_OPENED}`
      );
    }
    if (filters?.onBrake && !filters.free) {
      params.push(`shift_type=${DriverShiftType.BREAK}`);
    }
    if (filters?.busy) {
      params.push(
        `with_trip_in_course=1&shift_type=${DriverShiftType.SHIFT_OPENED}`
      );
    }
    if (filters?.workdayNotStarted) {
      params.push(`shift_type=${DriverShiftType.WORKDAY_NOT_STARTED}`);
    }
    if (filters?.workdayStarted) {
      params.push(`shift_type=${DriverShiftType.SHIFT_OPENED}`);
    }
    return params.join('&');
  };

  const getWorkingDriversStatistics = async (filters?: {
    code?: string;
    free?: boolean;
    onBrake?: boolean;
    busy?: boolean;
    workdayNotStarted?: boolean;
    workdayStarted?: boolean;
  }): Promise<any> => {
    let params = getQueryOnWorkingDrivers(filters);
    const res = await httpClient.get(`drivers/working/statistics?${params}`);
    return res;
  };

  const getDriversOnBreak = async (filters?: {
    code?: string;
    free?: boolean;
  }): Promise<ChoferTrabajando[]> => {
    return getWorkingDrivers({ ...filters, onBrake: true });
  };
  // Los choferes que estan en condiciones de trabajar
  const getDriversThatCanWork = () => getChoferes('?canWork=1');

  const getChofer = async (id: number | string): Promise<ChoferModel> => {
    const res = await httpClient.get(`drivers/${id}`);
    return choferBuilder.fromBackEnd(res);
  };

  async function getShifts(
    filters: DriverShiftFiltersFormType
  ): Promise<LaravelPaginated<DriverShift>> {
    const params = {
      driver_id: filters.driver?.id,
      open_from_date_time: filters.openFrom.format('YYYY-MM-DD HH:mm'),
      open_to_date_time: filters.openTo.format('YYYY-MM-DD HH:mm'),
      pending_approval: filters.approval === 'pending' ? 1 : null,
      approved:
        filters.approval === 'approved'
          ? 1
          : filters.approval === 'denied'
          ? 0
          : null
    };
    const paramsString = Object.keys(params)
      .filter((key) => params[key] !== null && params[key] !== undefined)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    const res = (await httpClient.get(
      `opened-shifts?${paramsString}`
    )) as LaravelPaginated<any>;
    return {
      ...res,
      data: res.data.map(driverShiftBuilder.fromBackEnd)
    };
  }

  async function approveShiftClosure(shiftId: number) {
    return httpClient.put(`opened-shifts/${shiftId}/evaluate`, {
      approved: true
    });
  }

  async function denyShiftClosure(shiftId: number) {
    return httpClient.put(`opened-shifts/${shiftId}/evaluate`, {
      approved: false
    });
  }

  async function getWorkingDriversAmountPerState(): Promise<{
    free: number;
    busy: number;
    onBrake: number;
    workdayNotStarted: number;
    workdayStarted: number;
  }> {
    const free = await getWorkingDriversStatistics({ free: true });
    const busy = await getWorkingDriversStatistics({ busy: true });
    const onBrake = await getWorkingDriversStatistics({ onBrake: true });
    const workdayNotStarted = await getWorkingDriversStatistics({
      workdayNotStarted: true
    });
    const workdayStarted = await getWorkingDriversStatistics({
      workdayStarted: true
    });
    return {
      free: free.count,
      busy: busy.count,
      onBrake: onBrake.count,
      workdayNotStarted: workdayNotStarted.count,
      workdayStarted: workdayStarted.count
    };
  }

  return {
    addChofer,
    desvincularChofer,
    deleteChofer,
    restoreChofer,
    editChofer,
    getChofer,
    getChoferes,
    getChoferesWithDeleted,
    revincularChofer,
    deshabilitarChofer,
    cancelarDeshabilitacion,
    aceptarDeshabilitacion,
    rechazarDeshabilitacion,
    getDriversThatCanWork,
    getWorkingDrivers,
    getDriversOnBreak,
    getShifts,
    closeShift,
    forceBreak,
    approveShiftClosure,
    denyShiftClosure,
    getWorkingDriversAmountPerState,
    getWorkingDriversStatistics,
    getDriversRecomended
  };
};

export default useChoferes;
