import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from 'shared/components';
import PageContainer from '../../../layout/components/PageContainer';
import VehiculoCreate from '../components/VehiculoCreate';
import VehiculoTable from '../components/VehiculoTable';
import useVehiculoModelos from '../modeloVehiculo/services/VehiculoModeloService';
import {
  VehiculoFormSchema,
  vehiculoFormSchemaToModel
} from '../models/VehiculoFormSchema';
import useVehiculo from '../services/VehiculoService';
import useTitulares from '../titulares/services/TitularService';

const VehiculoCrudPage = () => {
  const TitularService = useTitulares();
  const VehiculoService = useVehiculo();
  const ModeloService = useVehiculoModelos();
  let history = useHistory();
  const addVehiculoMutation = useSuperMutation(VehiculoService.addVehiculo, {
    showSpinner: true
  });
  const vehiculosQuery = useSuperQuery(
    VehiculoService.getVehiculosWithDeleted,
    {
      showSpinner: true
    }
  );
  const resourcesQuery = useSuperQuery(
    async () => {
      const res = await Promise.all([
        TitularService.getTitulares(),
        ModeloService.getVehiculoModelos(),
        VehiculoService.getVehiculoTipos(),
        VehiculoService.getVehiculoColores(),
        VehiculoService.getVehiculoCompaniasDeSeguro(),
        VehiculoService.getVehiculoMunicipios()
      ]);
      return {
        titulares: res[0],
        modelos: res[1],
        tipos: res[2],
        colores: res[3],
        companiasDeSeguro: res[4],
        municipios: res[5]
      };
    },
    { showSpinner: true }
  );

  const [visiblePage, setVisiblePage] = useState<'table' | 'create'>('table');

  async function create(vehiculoFormValues: VehiculoFormSchema, reset) {
    const vehiculoModel = vehiculoFormSchemaToModel(
      vehiculoFormValues,
      resourcesQuery.data?.titulares!
    );
    await addVehiculoMutation.mutate(vehiculoModel);
    reset();
    vehiculosQuery.reload();
  }

  return (
    <>
      <PageHeader
        title="Vehiculos"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de vehiculos'
            : visiblePage === 'create'
            ? 'Nuevo Vehiculo'
            : 'Editar Vehiculo'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear vehiculo
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />
      <PageContainer>
        {visiblePage === 'table' && (
          <VehiculoTable
            vehiculos={vehiculosQuery.data || []}
            onVehicleModifyButton={(id) => (
              <Link
                to={'/main/vehiculo/' + id}
                className="text-xs text-gray-400 hover:underline"
              >
                <FontAwesomeIcon
                  size="1x"
                  color="dodgerblue"
                  icon={faPencilAlt}
                />
              </Link>
            )}
          />
        )}

        {visiblePage === 'create' &&
          (!resourcesQuery.data ? (
            <Loading />
          ) : (
            <VehiculoCreate
              titulares={resourcesQuery.data.titulares!}
              modelos={resourcesQuery.data.modelos!}
              colores={resourcesQuery.data.colores!}
              tipos={resourcesQuery.data.tipos!}
              companiasDeSeguro={resourcesQuery.data.companiasDeSeguro!}
              municipios={resourcesQuery.data.municipios!}
              create={create}
            />
          ))}
      </PageContainer>
    </>
  );
};

export default VehiculoCrudPage;
