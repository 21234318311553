import React from 'react';

export const DriverPrivacyPolicyPage = () => {
  return (
    <div className="m-24">
      <h1 className="text-lg font-bold">POLÍTICA DE PRIVACIDAD</h1>
      <h2 className="font-bold">POLÍTICA DE PRIVACIDAD de LLT Choferes</h2>

      <p className="mt-12">LLT Choferes no registra direcciones IP.</p>

      <p>LLT Choferes no accede a los datos de contactos del dispositivo.</p>

      <p>LLT Choferes no accede a las cuentas de correos de los usuarios.</p>

      <p>LLT Choferes no guarda datos relativos al dispositivo.</p>

      <p>
        LLT Choferes, no Google, es el único responsable del contenido de LLT
        Choferes.
      </p>

      <p>
        LLT Choferes, no Google, ofrece soporte técnico a través del correo
        docchoferes@lltraslados.com.ar
      </p>

      <p>
        Para más información sobre la presente política de privacidad puede
        dirigirse a docchoferes@lltraslados.com.ar
      </p>
    </div>
  );
};
