import {
  faAddressCard,
  faCalendarTimes,
  faMedal,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import ProfileMenu from 'profile/components/ProfileMenu';
import ProfileMenuItem from 'profile/components/ProfileMenuItem';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from 'shared/components/Card/Card';
import VehiculoFormDesvincular from '../components/VehiculoForm/VehiculoFormDesvincular';
import VehiculoFormInfoGeneral from '../components/VehiculoForm/VehiculoFormInfoGeneral';
import VehiculoFormVencimiento from '../components/VehiculoForm/VehiculoFormVencimiento';
import useVehiculoModelos from '../modeloVehiculo/services/VehiculoModeloService';
import { VehiculoDesvinculacion } from '../models/Vehiculo';
import { mapVehiculoCategoriaToIconColor } from '../models/VehiculoCategoria';
import {
  VehiculoFormSchema,
  vehiculoFormSchemaToModel,
  vehiculoModelToFormSchema
} from '../models/VehiculoFormSchema';
import useVehiculo from '../services/VehiculoService';
import useTitulares from '../titulares/services/TitularService';

function VehiculoProfilePage() {
  const [menuItemSelected, setMenuitemSelected] = useState(1);
  const VehiculoService = useVehiculo();
  const TitularService = useTitulares();
  const ModeloService = useVehiculoModelos();
  const desvincularVehiculoMutation = useSuperMutation(
    VehiculoService.desvincularVehiculo,
    { showSpinner: true }
  );
  const revincularVehiculoMutation = useSuperMutation(
    VehiculoService.revincularVehiculo,
    { showSpinner: true }
  );
  const editVehiculoMutation = useSuperMutation(VehiculoService.editVehiculo, {
    showSpinner: true
  });
  const { id } = useParams<{ id: string }>();
  const vehiculoQuery = useSuperQuery(
    () => VehiculoService.getVehiculo(Number.parseInt(id)),
    { showSpinner: true }
  );

  const resourcesQuery = useSuperQuery(
    async () => {
      const res = await Promise.all([
        TitularService.getTitulares(),
        ModeloService.getVehiculoModelos(),
        VehiculoService.getVehiculoTipos(),
        VehiculoService.getVehiculoColores(),
        VehiculoService.getVehiculoCompaniasDeSeguro(),
        VehiculoService.getVehiculoMunicipios()
      ]);
      return {
        titulares: res[0],
        modelos: res[1],
        tipos: res[2],
        colores: res[3],
        companiasDeSeguro: res[4],
        municipios: res[5]
      };
    },
    { showSpinner: true }
  );

  async function desvincular(desvinculacion: VehiculoDesvinculacion) {
    await desvincularVehiculoMutation.mutate(desvinculacion, vehiculo!);
    vehiculoQuery.reload();
  }

  async function revincular() {
    await revincularVehiculoMutation.mutate(vehiculo!);
    vehiculoQuery.reload();
  }

  async function edit(vehiculoFormValues: VehiculoFormSchema) {
    const _vehiculo = vehiculoFormSchemaToModel(vehiculoFormValues, titulares!);
    _vehiculo.id = vehiculo!.id;
    await editVehiculoMutation.mutate(_vehiculo);
    vehiculoQuery.reload();
  }

  const cardTitle = () => {
    switch (menuItemSelected) {
      case 1:
        return 'Informacion General';
      case 2:
        return 'Vencimientos';
      case 3:
        return 'Desvincular';
    }
  };

  if (!vehiculoQuery.data || !resourcesQuery.data) return null;

  const vehiculoFormValues = vehiculoModelToFormSchema(vehiculoQuery.data);
  const vehiculo = vehiculoQuery.data;
  const { colores, companiasDeSeguro, municipios, titulares, modelos, tipos } =
    resourcesQuery.data;

  return (
    <>
      <PageHeader
        title="Vehiculo"
        subtitle={
          <div className="flex space-x-2">
            <Link
              color="inherit"
              to="/main/vehiculo"
              className="text-xs text-gray-400 hover:underline"
            >
              Vehículos
            </Link>
            <span>/</span>
            <span className="text-xs text-gray-400">{vehiculo.patente}</span>
          </div>
        }
      />
      <PageContainer>
        <Card className="md:w-1/3 2xl:w-1/4">
          <div className="p-8">
            <div className="flex space-x-2">
              <div className="relative mr-2.5">
                <FontAwesomeIcon
                  icon={faMedal}
                  className="absolute top-0 right-1"
                  size="2x"
                  color={mapVehiculoCategoriaToIconColor(vehiculo.categoria)}
                />
                <img
                  src={vehiculo.foto}
                  className="h-24 w-24 rounded-lg object-cover object-center"
                />
              </div>

              <div className="ml-2 overflow-hidden">
                <h1 className="font-medium text-gray-600">
                  {vehiculo.modelo.nombre}
                </h1>
                <h1 className="ellipsis mb-3 text-sm font-medium text-gray-400">
                  {vehiculo.modelo.fabricante.nombre}
                </h1>
                {vehiculo.desvinculacion.fecha === null ? (
                  <span className="rounded-md bg-green-100  px-2 py-1 text-center text-xs font-semibold text-green-400">
                    Activo
                  </span>
                ) : (
                  <span className="rounded-md bg-red-100 px-2 py-1 text-center text-xs font-semibold text-red-400">
                    Desvinculado
                  </span>
                )}
              </div>
            </div>
            <div className="mt-8 flex justify-between">
              <span className="font-medium text-gray-600">Titular</span>
              <span className="text-sm text-gray-400">
                {vehiculo.titular.nombre}
              </span>
            </div>
            <div className="mt-2 flex justify-between">
              <span className="font-medium text-gray-600">Patente</span>
              <span className="text-sm text-gray-400">{vehiculo.patente}</span>
            </div>
            <div className="mt-2 flex justify-between">
              <span className="font-medium text-gray-600">Año</span>
              <span className="text-sm text-gray-400">{vehiculo.anio}</span>
            </div>
            <div className="mt-8">
              <ProfileMenu
                value={menuItemSelected}
                onSelect={(value) => setMenuitemSelected(value)}
              >
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faAddressCard} size="lg" />}
                  label="Información general"
                  value={1}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faCalendarTimes} size="lg" />}
                  label="Vencimientos"
                  value={2}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faMinusCircle} size="lg" />}
                  label="Desvincular"
                  value={3}
                />
              </ProfileMenu>
            </div>
          </div>
        </Card>
        <Card className="flex-grow" title={cardTitle()}>
          <div className="p-12">
            {menuItemSelected === 1 && (
              <VehiculoFormInfoGeneral
                onSubmit={(values) => {
                  return edit({
                    general: { ...values },
                    vencimientos: { ...vehiculoFormValues.vencimientos }
                  });
                }}
                submitButtonTitle="Guardar cambios"
                titulares={titulares}
                modelos={modelos}
                colores={colores}
                tipos={tipos}
                initialValues={vehiculoFormValues.general}
              />
            )}
            {menuItemSelected === 2 && (
              <VehiculoFormVencimiento
                onSubmit={(values) => {
                  return edit({
                    general: { ...vehiculoFormValues.general },
                    vencimientos: { ...values }
                  });
                }}
                submitButtonTitle="Guardar cambios"
                municipios={municipios}
                companiaDeSeguros={companiasDeSeguro}
                initialValues={vehiculoFormValues.vencimientos}
              />
            )}
            {menuItemSelected === 3 && (
              <VehiculoFormDesvincular
                onDesvincular={desvincular}
                onRevincular={revincular}
                initialValues={vehiculo.desvinculacion}
                desvinculado={vehiculo.desvinculacion.fecha !== null}
              />
            )}
          </div>
        </Card>
      </PageContainer>
    </>
  );
}

export default VehiculoProfilePage;
