import ProfileContainer from './ProfileContainer';
import ProfileImage from './ProfileImage';
import ProfileResumeContainer, {
  ProfileResumeTitle,
  ProfileResumeSubtitle,
  ProfileResumeBadge
} from './ProfileResume';
import ProfileDescriptionContainer, {
  ProfileDescriptionItem
} from './ProfileDescription';
import ProfileMenu from 'profile/components/ProfileMenu';
import ProfileMenuItem from 'profile/components/ProfileMenuItem';
import ProfileNavigationContainer from './ProfileNavigation';

const Profile = {
  Container: ProfileContainer,
  Navigation: ProfileNavigationContainer,
  Image: ProfileImage,
  Resume: {
    Container: ProfileResumeContainer,
    Title: ProfileResumeTitle,
    Subtitle: ProfileResumeSubtitle,
    Badge: ProfileResumeBadge
  },
  Description: {
    Container: ProfileDescriptionContainer,
    Item: ProfileDescriptionItem
  },
  Menu: {
    Container: ProfileMenu,
    Item: ProfileMenuItem
  }
};

export default Profile;
