import {
  ClientBusinessCrud,
  IVACondition
} from 'app/client/clientBusiness/models/ClientBusiness';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import {
  domicilioInitialValues,
  domicilioValidation
} from 'shared/components/FormikAddress';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface ClientBusinessFormType extends ClientBusinessCrud {}

export interface ClientBusinessPersonalFormType {
  code: number | string;
  businessName: string;
  fantasyName: string;
  cuit: number | string;
  ivaCondition: IVACondition;
  phone: number | string;
  mail: string;
  web: string;
  socialMedia: string;
  hasPriority: boolean;
  gross_income: string;
  address: DomicilioModel;
}

const clientBusinessPersonalFormSchema: YupShape<ClientBusinessPersonalFormType> =
  Yup.object().shape({
    code: Yup.string().required('El código es requerido'),
    businessName: Yup.string().required('La razón social es requerida'),
    fantasyName: Yup.string().required('El nombre es requerido'),
    cuit: Yup.string().required('El cuit es requerido'),
    ivaCondition: Yup.string().required('La condicion de iva es requerida'),
    phone: Yup.string().required('El teléfono es requerido'),
    mail: Yup.string()
      .required('El mail es requerido')
      .email('El formato es inválido'),
    web: Yup.string().nullable(),
    socialMedia: Yup.string().nullable(),
    hasPriority: Yup.boolean(),
    gross_income: Yup.string().required('Ingresos brutos es requerido'),
    address: domicilioValidation
  });

const clientBusinessPersonalInitialValues: ClientBusinessPersonalFormType = {
  code: '',
  businessName: '',
  fantasyName: '',
  cuit: '',
  ivaCondition: IVACondition.CONSUMIDOR_FINAL,
  phone: '',
  mail: '',
  web: '',
  socialMedia: '',
  hasPriority: false,
  gross_income: '',
  address: domicilioInitialValues
};

export interface ClientBusinessContactFormType {
  billingContact: {
    contactName: string;
    phone: string;
    mail: string;
    observation: string;
    accountOfficer: string;
  };
  collectionContact: {
    contactName: string;
    phone: string;
    mail: string;
    observation: string;
    paymentMethod: string;
  };
  salesManager: {
    contactName: string;
    phone: string;
    mail: string;
    observation: string;
  };
}
const clientBusinessContactFormSchema: YupShape<ClientBusinessContactFormType> =
  Yup.object().shape({
    billingContact: Yup.object().shape({
      contactName: Yup.string().required('El nombre es requerido'),
      phone: Yup.string().nullable(),
      mail: Yup.string().email('El formato es inválido').nullable(),
      observation: Yup.string().nullable(),
      accountOfficer: Yup.string().nullable()
    }),
    collectionContact: Yup.object().shape({
      contactName: Yup.string().required('El contacto es requerido'),
      phone: Yup.string().nullable(),
      mail: Yup.string().email('El formato es inválido').nullable(),
      observation: Yup.string().nullable(),
      paymentMethod: Yup.string().nullable()
    }),
    salesManager: Yup.object().shape({
      contactName: Yup.string().required('El nombre es requerido'),
      phone: Yup.string().required('El teléfono es requerido'),
      mail: Yup.string()
        .required('El mail es requerido')
        .email('El formato es inválido'),
      observation: Yup.string().nullable()
    })
  });

const clientBusinessContactInitialValues: ClientBusinessContactFormType = {
  billingContact: {
    contactName: '',
    phone: '',
    mail: '',
    observation: '',
    accountOfficer: ''
  },
  collectionContact: {
    contactName: '',
    phone: '',
    mail: '',
    observation: '',
    paymentMethod: ''
  },
  salesManager: {
    contactName: '',
    phone: '',
    mail: '',
    observation: ''
  }
};

export interface ClientBusinessReservationRequirementType {
  needsCostCenter: boolean;
  needsSubCostCenter: boolean;
  needsSector: boolean;
  needsAuthorized: boolean;
  needsBuyOrder: boolean;
  needsEmployeeNumber: boolean;
  needsPlatformApplication: boolean;
  needsTripNumber: boolean;
  needsData1: boolean;
  needsData2: boolean;
  needsData3: boolean;
}

const clientBusinessReservationRequirementFormSchema: YupShape<ClientBusinessReservationRequirementType> =
  Yup.object().shape({
    needsCostCenter: Yup.boolean(),
    needsSubCostCenter: Yup.boolean(),
    needsSector: Yup.boolean(),
    needsAuthorized: Yup.boolean(),
    needsBuyOrder: Yup.boolean(),
    needsEmployeeNumber: Yup.boolean(),
    needsPlatformApplication: Yup.boolean(),
    needsTripNumber: Yup.boolean(),
    needsData1: Yup.boolean(),
    needsData2: Yup.boolean(),
    needsData3: Yup.boolean()
  });

const clientBusinessReservationRequirementInitialValues: ClientBusinessReservationRequirementType =
  {
    needsCostCenter: false,
    needsSubCostCenter: false,
    needsSector: false,
    needsAuthorized: false,
    needsBuyOrder: false,
    needsEmployeeNumber: false,
    needsPlatformApplication: false,
    needsTripNumber: false,
    needsData1: false,
    needsData2: false,
    needsData3: false
  };

const ClientBusinessFormik = {
  personal: {
    schema: clientBusinessPersonalFormSchema,
    initialValues: clientBusinessPersonalInitialValues
  },
  contact: {
    schema: clientBusinessContactFormSchema,
    initialValues: clientBusinessContactInitialValues
  },
  reservationRequirements: {
    schema: clientBusinessReservationRequirementFormSchema,
    initialValues: clientBusinessReservationRequirementInitialValues
  },
  initialValues: {
    ...clientBusinessPersonalInitialValues,
    ...clientBusinessContactInitialValues,
    reservationRequirement: clientBusinessReservationRequirementInitialValues
  }
};

export default ClientBusinessFormik;
