import { TextField as MUITextField } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { Moment } from 'moment';
import * as Yup from 'yup';
const Schema = Yup.object().shape({
  from: Yup.mixed(),
  to: Yup.mixed(),
  turnoAbierto: Yup.boolean()
});

export type DriverIncomeFiltersFormType = {
  from: Moment | null;
  to: Moment | null;
  driver_id: number | null;
  turnoAbierto: boolean;
};

interface Props {
  onSubmit: (values: DriverIncomeFiltersFormType) => Promise<any>;
  drivers: ChoferPartialModel[];
  initialValues: DriverIncomeFiltersFormType;
}

export const DriverIncomeFiltersForm = ({
  onSubmit,
  initialValues,
  drivers
}: Props) => {
  return (
    <Formik<DriverIncomeFiltersFormType>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="flex max-w-md flex-col gap-2 p-8">
          <Field
            component={KeyboardDatePicker}
            label="Desde"
            name="from"
            inputVariant="outlined"
            margin="dense"
            className="col-span-2"
            format="DD/MM/YYYY"
          />
          <Field
            component={KeyboardDatePicker}
            label="Hasta"
            name="to"
            inputVariant="outlined"
            margin="dense"
            className="col-span-2"
            format="DD/MM/YYYY"
          />
          <Field
            name="driver_id"
            component={Autocomplete}
            noOptionsText="No se han encontrado resultados"
            loadingText="Cargando..."
            fullWidth
            margin="dense"
            options={drivers.map((d) => d.id)}
            getOptionLabel={(option: number) => {
              const driver = drivers.find((d) => d.id === option)!;
              return `#${driver.codigo} - ${driver.nombreCompleto}`;
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                error={
                  formik.touched['driver_id'] && !!formik.errors['driver_id']
                }
                helperText={formik.errors['driver_id']}
                label="Chofer"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="turnoAbierto"
            color="primary"
            className="col-span-1"
            Label={{ label: 'Turno libre' }}
          />
          <div className="col-span-1 flex justify-end">
            <Button onClick={formik.submitForm} variant="contained">
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
