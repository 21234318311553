import { httpClient } from 'shared/services/http/httpClient';

export const copyReservationPublicLinkToClipboard = async (
  reservationId: number
) => {
  const { encrypted_id } = await httpClient.get(
    `transfer-reservations/${reservationId}/encrypted-id`
  );
  return navigator.clipboard.writeText(
    `https://portal.lltraslados.com.ar/#/public/reservations/${encrypted_id}`
  );
};
