import React from 'react';
import PassengerForm from './PassengerForm/PassengerForm';
import { PassengerFormType } from './PassengerForm/PassengerFormik';

interface Props {
  update: (passengerValues: PassengerFormType, reset) => void;
  initialValues: PassengerFormType;
}
function PassengerEdit({ update, initialValues }: Props) {
  return <PassengerForm onSubmit={update} initialValues={initialValues} />;
}

export default PassengerEdit;
