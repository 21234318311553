import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '@vadiun/react-components';
import { createInitialValues } from './initialValues';
import { RateFastUpdateFormType } from './RateFastUpdateFormType';
import { validation } from './validation';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';

interface Props {
  onSubmit: (value: RateFastUpdateFormType) => Promise<void>;
  initialValues?: RateFastUpdateFormType;
}

export function RateFastUpdateForm({
  onSubmit,
  initialValues = createInitialValues()
}: Props) {
  return (
    <Formik<RateFastUpdateFormType>
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="expirationDate"
            label="Vencimiento*"
            inputVariant="outlined"
            component={KeyboardDatePicker}
            className="col-span-6"
            ampm={false}
            format="DD/MM/YYYY"
            fullWidth
          />
          <Field
            name="porcentage"
            label="Porcentaje de bonificación"
            variant="outlined"
            type="number"
            step="0.01"
            component={TextField}
            className="col-span-6"
            fullWidth
          />

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
