import { TIPOS_SERVICIO_VIAJE_TRASLADO } from 'app/viaje/models';
import {
  ClientRequirementType,
  ColumnDataType,
  ColumnIdType,
  ColumnMapping
} from './types';

const columnMapping: ColumnMapping[] = [
  {
    id: ColumnIdType.ADVANCED_HOURS,
    label: 'Tiempo previo a la salida',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.DROPDOWN_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.COORDINATION_OBSERVATION,
    label: 'Observaciones',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_AREA,
    allowMultiple: true,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.DATE,
    label: 'Fecha (dd/mm/aaaa)',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.DATE_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.DIRECTION_ONE,
    label: 'Dirección (1)',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.ADDRESS_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.DIRECTION_TWO,
    label: 'Dirección (2)',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.ADDRESS_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.DIRECTION_THREE,
    label: 'Dirección (3)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.ADDRESS_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.FIXED_PRICE_NAME,
    label: 'Tarifa Fija',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.HOUR,
    label: 'Horario Reserva',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.DROPDOWN_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PASSANGERS_DIR_ONE,
    label: 'Pasajero (dir 1)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PASSANGERS_DIR_TWO,
    label: 'Pasajero (dir 2)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PASSANGERS_DIR_THREE,
    label: 'Pasajero (dir 3)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PASSANGERS_DIR_ONE_PHONE,
    label: 'Tel Pasajero (dir 1)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PASSANGERS_DIR_TWO_PHONE,
    label: 'Tel Pasajero (dir 2)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PASSANGERS_DIR_THREE_PHONE,
    label: 'Tel Pasajero (dir 3)',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'trip'
  },
  {
    id: ColumnIdType.PAY_WITH_CASH,
    label: 'Pago en efectivo',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.BOOLEAN_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.SERVICE_TYPE,
    label: 'Tipo de servicio',
    inUse: false,
    mandatory: false,
    type: ColumnDataType.DROPDOWN_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  }
];

const columnRequirementMapping: ColumnMapping[] = [
  {
    id: ColumnIdType.AUTHORIZED_ID,
    label: 'Autorizado',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.DROPDOWN_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.BUY_ORDER,
    label: 'Orden de compra',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.COST_CENTER,
    label: 'Centro de costos',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.SUB_COST_CENTER,
    label: 'Sub Centro de costos',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.DROPDOWN_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.DATA_1,
    label: 'Data 1',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.DATA_2,
    label: 'Data 2',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.DATA_3,
    label: 'Data 3',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.EMPLOYEE_NUMBER,
    label: 'Legajo',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.PLATFORM_APPLICATION,
    label: 'Solicitud en plat',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.SECTOR,
    label: 'Sector',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  },
  {
    id: ColumnIdType.TRIP_NUMBER,
    label: 'Nº Viaje',
    inUse: false,
    mandatory: true,
    type: ColumnDataType.TEXT_FIELD,
    allowMultiple: false,
    columnId: [],
    groupType: 'client'
  }
];

export const idColumn: ColumnMapping = {
  id: ColumnIdType.ID,
  label: 'Id de viaje',
  inUse: false,
  mandatory: false,
  type: ColumnDataType.TEXT_FIELD,
  allowMultiple: false,
  columnId: [],
  groupType: 'trip'
};

const isColumnIdType = (data: any): data is ColumnIdType => {
  return Object.values(ColumnIdType).includes(data);
};

export const getColumnMapping = (
  clientRequirement: ClientRequirementType[] = []
) => {
  return clientRequirement.reduce(
    (acc, { type }) => {
      const requirementMapping = columnRequirementMapping.find(
        ({ id }) => isColumnIdType(type) && id === type
      );

      return requirementMapping ? [...acc, requirementMapping] : acc;
    },
    [...columnMapping]
  );
};

export const getDropdownOption = (
  columnId: ColumnIdType,
  clientMoreInfo: Record<string, { label: string; value: string }[]>
) => {
  if (columnId === ColumnIdType.SERVICE_TYPE) {
    return TIPOS_SERVICIO_VIAJE_TRASLADO.map((value) => ({
      label: value,
      value
    }));
  }
  if (columnId === ColumnIdType.AUTHORIZED_ID) {
    return clientMoreInfo[ColumnIdType.AUTHORIZED_ID];
  }
  if (columnId === ColumnIdType.SUB_COST_CENTER) {
    return clientMoreInfo[ColumnIdType.SUB_COST_CENTER];
  }
  if (columnId === ColumnIdType.ADVANCED_HOURS) {
  }
  return [];
};
