import { Button } from '@vadiun/react-components';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import AuthLayout from 'auth/components/AuthLayout';
import { AuthContextType } from 'auth/services/AuthService';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import ChangePasswordIlustration from '../../assets/change-password.svg';
import Logo from '../../assets/logo.png';

interface FormSchema {
  email: string;
  password: string;
  repeatPassword: string;
}

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es invalido')
    .required('El email es requerido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como minimo 4 caracteres'),
  repeatPassword: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como minimo 4 caracteres')
    .oneOf([Yup.ref('password')], 'Las contraseñas no coiciden')
});

const initialValues: FormSchema = {
  email: '',
  password: '',
  repeatPassword: ''
};

interface Props {
  authSrv: AuthContextType;
}

const ChangePasswordPage = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useState('');
  const changePassMutation = useSuperMutation(props.authSrv.changePassword);

  useEffect(() => {
    const routeParts = location.pathname.split('/');
    setToken(routeParts[routeParts.length - 1]);
  }, []);

  const submit = async (
    value: FormSchema,
    formikHelpers: FormikHelpers<FormSchema>
  ) => {
    await changePassMutation.mutate({
      email: value.email,
      password: value.password,
      token
    });
    formikHelpers.resetForm();
  };

  return (
    <AuthLayout
      ilustration={ChangePasswordIlustration}
      title="Cambiar Contraseña"
      subtitle="Sólo un paso más para recuperar tu cuenta."
      logo={Logo}
    >
      <div className="flex max-w-xl flex-col items-center">
        <h1 className="my-4 text-center text-2xl font-bold">
          Cambiar contraseña
        </h1>
        <p className="font-bold text-gray-500">
          Ingresa tu contraseña y repetila
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={submit}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                name="email"
                component={TextField}
              />
              <Field
                label="Contraseña"
                type="password"
                name="password"
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                component={TextField}
              />
              <Field
                label="Repetir Contraseña"
                type="password"
                name="repeatPassword"
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                component={TextField}
              />
              <div className="my-8 w-full">
                <Button
                  className="w-full"
                  variant="contained"
                  isLoading={changePassMutation.isLoading}
                  disabled={changePassMutation.isLoading}
                  onClick={submitForm}
                >
                  Guardar
                </Button>
                <Link to={'/auth/login'}>
                  <Button className="mt-4 w-full">Ir al login</Button>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default ChangePasswordPage;
