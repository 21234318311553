import { Query, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import useApplicant from 'app/client/clientBusiness/Applicant/services/useApplicant';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import useAuthorizeds from 'app/client/clientBusiness/Authorized/services/AuthorizedService';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from 'app/client/clientBusiness/models/ClientBusiness';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import usePassengers from 'app/client/clientBusiness/Passenger/services/PassengerService';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import { TravelEstimationsWithPrice } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import { useState } from 'react';
import { TransferReservationBaseFormType } from '../forms/base/TransferReservationBaseFormTypes';
import ReservaTrasladoEstimationDialog from './ReservaTrasladoEstimationDialog';

type FormParams<T> = (props: {
  initialValues?: T;
  onSubmit: (viajeFormValues: T, reset) => void;
  getClientesByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
  onClienteSelect: (id: ClientBusinessPartialModel) => void;
  solicitantes: ApplicantModel[];
  autorizadosQuery: Query<AuthorizedModel[]>;
  pasajeros: PassengerModel[];
  clientBusiness: ClientBusinessModel | undefined;
  onServiceTypeSelect: (type: ViajeTrasladoTipoServicio) => void;
  onCalculateDistanceAndCost: (trasladoFormValues: T) => void;
  fixedRates: RateModel['categories'][0]['fixedPrices'];
}) => JSX.Element;

interface Props<T extends TransferReservationBaseFormType> {
  children: FormParams<T>;
  initialValues?: T;
  isDraftPage?: boolean;
  onSubmit: (viajeFormValues: T, clientBusinessId: number, reset) => void;
  calculateDistanceAndCost: (
    trasladoFormValues: T,
    clientBusinessId: number
  ) => Promise<
    {
      price: number;
      distance: number;
      duration: number;
      durationTraffic: number;
    }[]
  >;
}

export function ReservaTrasladoFormWrapper<
  T extends TransferReservationBaseFormType
>({
  initialValues,
  children,
  onSubmit,
  calculateDistanceAndCost,
  isDraftPage
}: Props<T>) {
  const ClienteService = useClientBusiness();
  const ApplicantService = useApplicant();
  const AuthorizedService = useAuthorizeds();
  const pasajeroSrv = usePassengers();
  const [idClient, setIdCliente] = useState<number | undefined>(
    initialValues?.cliente?.id
  );
  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [distanceAndCost, setDistanceAndCost] = useState<
    TravelEstimationsWithPrice[]
  >([]);
  const [serviceType, setServiceType] = useState<ViajeTrasladoTipoServicio>(
    initialValues?.tipoServicio || ViajeTrasladoTipoServicio.EJECUTIVO
  );
  const clientQuery = useSuperQuery<ClientBusinessModel | undefined>(
    async () => {
      if (idClient === undefined) return;
      return await ClienteService.get(idClient);
    },
    undefined,
    [idClient]
  );

  const passangersQuery = useSuperQuery<PassengerModel[] | undefined>(
    async () => {
      if (idClient === undefined) return;
      return await pasajeroSrv.getPassengers(idClient);
    },
    undefined,
    [idClient]
  );

  const solicitantesQuery = useSuperQuery<ApplicantModel[] | undefined>(
    async () => {
      if (idClient === undefined) return [];
      return await ApplicantService.getAll(idClient);
    },
    undefined,
    [idClient]
  );
  const ratesQuery = useSuperQuery<RateModel | undefined>(
    async () => {
      if (isDraftPage) {
        return;
      }
      if (idClient === undefined) return;
      return await ClienteService.getCurrentRate(idClient);
    },
    undefined,
    [idClient]
  );
  const autorizadosQuery = useSuperQuery<AuthorizedModel[]>(
    async () => {
      if (
        clientQuery.data === undefined ||
        !clientQuery.data.reservationRequirement.needsAuthorized
      ) {
        return [];
      }

      return await AuthorizedService.getAuthorizeds(clientQuery.data.id!);
    },
    undefined,
    [clientQuery.data]
  );

  return (
    <>
      {children({
        onSubmit: (values, actions) =>
          onSubmit(values, idClient!, actions.reset),
        getClientesByName: ClienteService.findByName,
        onClienteSelect: (client) => setIdCliente(client.id),
        solicitantes: solicitantesQuery.data ?? [],
        autorizadosQuery: autorizadosQuery,
        clientBusiness: clientQuery.data,
        pasajeros: passangersQuery.data ?? [],
        onServiceTypeSelect: setServiceType,
        fixedRates:
          ratesQuery.data && serviceType
            ? ratesQuery.data.categories.find((cat) => cat.name === serviceType)
                ?.fixedPrices || []
            : [],
        onCalculateDistanceAndCost: async (reservation: T) => {
          setDistanceAndCost(
            await calculateDistanceAndCost(reservation, idClient!)
          );
          setIsEstimationOpen(true);
        },
        initialValues: initialValues
      })}
      <ReservaTrasladoEstimationDialog
        isOpen={isEstimationOpen}
        handleClose={() => setIsEstimationOpen(false)}
        distanceAndCost={distanceAndCost}
      />
    </>
  );
}
