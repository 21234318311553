import moment from 'moment';
import { Moment } from 'moment';

export enum DriverShiftType {
  BREAK = 0,
  SHIFT_OPENED = 1,
  WORKDAY_NOT_STARTED = 2
}
export enum ShiftClosePetitionStatus {
  PENDING = 'Pendiente',
  APPROVED = 'Aprobada',
  DENIED = 'Rechazada',
  NO_CLOSE_PETITION = 'Sin Petición'
}

export interface DriverShift {
  //Datetime en el que se inicio el turno
  openDateTime: Moment;
  open_date_time?: Moment;
  close_date_time?: Moment;
  close_type?: string;
  //Tipo de inicio de turno
  openType: 'Inicio jornada' | 'Fin descanso';
  //Datetime en el que se solicitó finalizar el turno.
  //Es null si todavia no se finalizó.
  closeDateTime: Moment | null;
  //Tipo de fin de turno
  //Es null si no se solicitó finalizar el turno
  closeType: 'Inicio descanso' | 'Fin jornada' | null;
  //Si el closeType es 'Inicio descanso' es la cantidad de minutos del descanso
  //Es null si closeType es 'Fin jornada'
  closeMinutes: number | null;
  //Es true si la peticion de cierre de turno esta aprobada
  //Es false si la peticion de cierre de turno esta desaprobada
  //Es null si la peticion de cierre de turno esta pendient de aprobacion o no se solicito un cierre de turno
  approveClosure: boolean | null;
  observation: string | null;
  state: ShiftClosePetitionStatus;
  driverId: number;
  driverName: string;
  driverCode: number;
  driver_name?: string;
  driver_code?: number;
  id: number;
}

export const driverShiftBuilder = {
  fromBackEnd: (shift: any): DriverShift => {
    let state = ShiftClosePetitionStatus.NO_CLOSE_PETITION;
    if (shift.approve_closure === null) {
      state = ShiftClosePetitionStatus.PENDING;
    }
    if (shift.approve_closure === true) {
      state = ShiftClosePetitionStatus.APPROVED;
    }
    if (shift.approve_closure === false) {
      state = ShiftClosePetitionStatus.DENIED;
    }
    if (
      shift.close_date_time === null &&
      shift.close_type === null &&
      shift.approve_closure === null
    ) {
      state = ShiftClosePetitionStatus.NO_CLOSE_PETITION;
    }
    return {
      openDateTime: moment(shift.open_date_time),
      openType: shift.open_type,
      closeDateTime: shift.close_date_time
        ? moment(shift.close_date_time)
        : null,
      closeType: shift.close_type,
      closeMinutes: shift.close_minutes,
      approveClosure: shift.approve_closure,
      observation: shift.observation,
      state,
      driverId: shift.driver_id,
      driverName: shift.driver_name,
      driverCode: shift.driver_code,
      id: shift.id
    };
  }
};
