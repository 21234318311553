import moment, { Moment } from 'moment';

export interface DriverSettlementModel {
  driverCode: number;
  driverName: string;
  date: Moment;
  id: number;
  total_billed: number;
  total_cash: number;
  total_current_account: number;
  total_movimientos: number;
  total_worked: number;
  commission: number;
}

export const driverSettlementBuilder = {
  fromBackEnd: (r: any): DriverSettlementModel => ({
    ...r,
    driverCode: r.driver.code,
    driverName: r.driver.name,
    date: moment(r.date_to),
    id: r.id
  })
};
