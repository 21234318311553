import { useEffect, useRef } from 'react';

export const useCustomInterval = (
  callback: () => void,
  delay: number | null
) => {
  const savedCallback: { current: null | (() => void) } = useRef(null);

  // Remember the latest callback function
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      let interval = setInterval(tick, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
};
