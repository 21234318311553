import React from 'react';

interface Props {
  image?: React.ReactNode;
  resume?: React.ReactNode;
  description?: React.ReactNode;
  menu?: React.ReactNode;
}

const ProfileNavigationContainer = ({
  image,
  resume,
  description,
  menu
}: Props) => {
  return (
    <div className="p-8">
      <div className="flex space-x-4 items-start">
        {image}
        {resume}
      </div>
      {description}
      {menu}
    </div>
  );
};

export default ProfileNavigationContainer;
