import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import moment from 'moment';
import {
  transferReservationSingleFormBuilder,
  TransferReservationSingleFormType
} from '../forms/single/TransferReservationSingleFormType';
import useViajesTraslado from './ViajeTrasladoService';

/**
 * Permite operar mas facil con los formularios, devuelve directamente las estructuras que este necesita
 */
export const useTransferReservationFormRepository = () => {
  const ViajeTrasladoService = useViajesTraslado();
  const clientBusinessRepository = useClientBusiness();

  const get = async (id: number) => {
    const fullReservation = await ViajeTrasladoService.getLegacyDontUse(id);
    const client = await clientBusinessRepository.get(
      fullReservation.cliente.id
    );
    const rate = await clientBusinessRepository.getCurrentRate(client.id);
    const formReservation = transferReservationSingleFormBuilder.toForm(
      fullReservation,
      client,
      rate.categories
        .find((c) => c.name === fullReservation.tipoServicio)
        ?.fixedPrices.find((fp) => fp.name === fullReservation.fixedRateName) ||
        null
    );
    return { ...formReservation, id };
  };

  const getReservationClientBusinessToValidate = async (id: number) => {
    const reservation =
      await ViajeTrasladoService.getTransferReservationToValidate(id);
    const client = await clientBusinessRepository.get(reservation.client.id);
    const formReservation: TransferReservationSingleFormType = {
      cliente: client,
      solicitante: {
        name: reservation.requirements?.applicant?.name ?? '',
        phone: reservation.requirements?.applicant?.phone ?? '',
        email: reservation.requirements?.applicant?.email ?? '',
        id: reservation.requirements?.applicant?.id
      },
      fechaYHoraDeLlegada: reservation.reservationDatetime,
      minutosPreviosASalida: moment(reservation.reservationDatetime).subtract(
        reservation.advanceHours,
        'hours'
      ),
      puntos: reservation.locations.map((p) => ({
        domicilio: p.address,
        espera: p.minutesWaiting ? String(p.minutesWaiting) : '',
        observacion: p.observation,
        pasajeros: p.passengers.map((pas) => ({
          nombreCompleto: pas.name,
          telefono: pas.phone ?? '',
          mail: pas.email ?? ''
        }))
      })),
      autorizado: reservation.requirements?.authorized ?? null,
      costCenter: reservation.requirements?.costCenter ?? '',
      subCostCenter: reservation.requirements?.subCostCenter ?? '',
      sector: reservation.requirements?.sector ?? '',
      buyOrder: reservation.requirements?.buyOrder ?? '',
      employeeNumber: reservation.requirements?.employeeNumber ?? '',
      platformApplication: reservation.requirements?.platformApplication ?? '',
      tripNumber: reservation.requirements?.tripNumber ?? '',
      canalIngreso: reservation.entryChannel,
      plataformaId: reservation.platformId
        ? String(reservation.platformId)
        : '',
      prioridad: reservation.hasPriority,
      tipoServicio: reservation.serviceType,
      observacionCoordinacion: reservation.coordinationObservation,
      fixedRate: null,
      hasOutSite: false,
      data1: reservation.requirements?.data1 ?? '',
      data2: reservation.requirements?.data2 ?? '',
      data3: reservation.requirements?.data3 ?? '',
      pay_with_cash: reservation.pay_with_cash,
      isEvent: reservation.is_event,
      nameEvent: reservation.event_name
    };
    return { ...formReservation, id };
  };

  return {
    get,
    getToValidate: getReservationClientBusinessToValidate
  };
};
