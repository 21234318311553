import React from 'react';
import { TableColumn } from '@vadiun/react-components';
import { Table } from 'shared/components';
import { FacturaModel } from '../models/FacturaModel';

interface Props {
  tableProps: FacturaModel['movements'];
}

export const FacturacionMovementTable = ({ tableProps }: Props) => {
  const data = tableProps;

  const columns: TableColumn<FacturaModel>[] = [
    {
      name: 'movements',
      label: 'Cantidad',
      options: {
        filter: false,

        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            {
              <span
                className={
                  data[index].amount > 0 ? 'text-green-500' : 'text-red-500'
                }
              >
                {Math.abs(data[index].amount)}
              </span>
            }
          </div>
        )
      }
    },
    {
      name: 'movements',
      label: 'Observacion',
      options: {
        filter: false,

        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>{data[index].observation}</div>
        )
      }
    },
    {
      name: 'movements',
      label: 'Tipo',
      options: {
        filter: false,

        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            {data[index].amount < 0 ? 'Débito' : 'Crédito'}
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          download: false,
          viewColumns: false,
          filter: false,
          print: false
        }}
        title={''}
        columns={columns}
      />
    </div>
  );
};
