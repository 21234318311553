import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { Table } from 'shared/components';
import { PassengerModel } from '../models/Passenger';

interface Props {
  passengers: PassengerModel[];
  selectToEdit: (x: PassengerModel) => void;
  delete: (x: PassengerModel) => void;
}

const PassengerTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const columns = [
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'phone',
      label: 'Teléfono',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'has_priority',
      label: 'Prioritario',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => props.selectToEdit(props.passengers[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
              onClick={() =>
                verifyAction({
                  title: '¡Estás por eliminar un pasajero!',
                  body: 'Esta acción va a eliminar el pasajero. ¿Estás seguro?',
                  onAccept: () => props.delete(props.passengers[index])
                })
              }
              shape="circle"
              color="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ];

  const data = props.passengers.map((x) => ({
    ...x,
    has_priority: x.has_priority ? 'Si' : 'No'
  }));

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Pasajeros'}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default PassengerTable;
