import { domicilioGoogleToFieldSchema } from 'shared/components/MUIGooglePlaces';
import { DomicilioGoogle } from 'shared/models/DomicilioGoogle';
import { TitularModel, TitularModelCRUD } from './Titular';

export interface TitularFormSchema {
  nombre: string;
  telefono: string | number;
  domicilio: DomicilioGoogle;
  email: string;
  piso: string;
  departamento: string;
}

export function titularFormSchemaToModel(
  titularForm: TitularFormSchema
): TitularModelCRUD {
  return {
    ...titularForm,
    telefono: Number(titularForm.telefono),
    domicilio: {
      ...titularForm.domicilio,
      piso: titularForm.piso,
      departamento: titularForm.departamento
    }
  };
}

export function titularModelToFormSchema(
  titular: TitularModel
): TitularFormSchema {
  const { departamento, piso, ...domicilio } = titular.domicilio;
  return {
    ...titular,
    domicilio: domicilioGoogleToFieldSchema({
      ...domicilio,
      pais: '',
      codigoPostal: ''
    }),
    departamento: departamento || '',
    piso: piso || ''
  };
}
