import { httpClient } from 'shared/services/http/httpClient';
import {
  transferReservationPrivateBuilder,
  TransferReservationPrivateModel
} from '../../models/TransferReservatinPrivateModel';
import {
  transferReservationPrivateAddMapper,
  transferReservationPrivateModifyMapper
} from './transferReservationPrivateRepositoryMapper';
import {
  TransferReservationPrivateCreateBatchType,
  TransferReservationPrivateCreateType,
  TransferReservationPrivateEditType
} from './transferReservationPrivateRepositoryTypes';

export const useTransferReservationPrivateRepository = () => {
  const createBatch = (x: TransferReservationPrivateCreateBatchType) => {
    return httpClient.post(
      `private-clients/${x.cliente!.id}/transfer-reservations/batch`,
      transferReservationPrivateAddMapper(x)
    );
  };

  const edit = (x: TransferReservationPrivateEditType) => {
    return httpClient.put(
      `transfer-reservations/${x.id}`,
      transferReservationPrivateModifyMapper(x)
    );
  };

  const get = async (id: number): Promise<TransferReservationPrivateModel> => {
    const res = await httpClient.get(`transfer-reservations/${id}`);
    return transferReservationPrivateBuilder.fromBackEnd(res);
  };

  const create = (x: TransferReservationPrivateCreateType) => {
    return httpClient.post(
      `private-clients/${x.clienteId}/transfer-reservations/batch`,
      { reservations: [transferReservationPrivateModifyMapper(x)] }
    );
  };

  const approveTransferReservationToValidate = async (
    x: TransferReservationPrivateEditType
  ) => {
    return httpClient.put(
      `transfer-reservations/${x.id}/approve`,
      transferReservationPrivateModifyMapper(x)
    );
  };

  async function getTransferReservationToValidate(
    id: number | string
  ): Promise<TransferReservationPrivateModel> {
    const res = await httpClient.get(
      `/transfer-reservations/pending-confirmation?reservation_id=${id}`
    );
    return transferReservationPrivateBuilder.fromBackEnd(res.data[0]);
  }

  return {
    edit,
    createBatch,
    get,
    create,
    approveTransferReservationToValidate,
    getTransferReservationToValidate
  };
};
