import { Button } from '@vadiun/react-components';
import { FC, ReactNode, useState } from 'react';
import { getDropdownOption } from "../utils/columnMapping";
import { ColumnDataType, ColumnIdType } from '../utils/types';
import DatePicker from "./DatePicker";
import MappingAdvancedHours from './MappingAdvancedHours';
import MappingCheckbox from "./MappingCheckbox";
import MappingDropdown from "./MappingDropdown";
import MappingGoogleAddress from "./MappingGoogleAddress";
import TextMatchingInput from "./TextMatchingInput";

interface ColumnTypeAssignationProps {
  label: string;
  columnId: ColumnIdType;
  clientMoreInfo: Record<string, {
    label: string;
    value: string;
  }[]>;
  type: ColumnDataType;
  onClose: () => void;
  onAssignValue: (nextColValue: string) => void;
}

const ColumnTypeAssignation: FC<ColumnTypeAssignationProps> = ({
  label,
  columnId,
  clientMoreInfo,
  type,
  onClose,
  onAssignValue
}) => {

  const [nextValue, setNextValue] = useState<string>('');

  const handleAssignValue = () => {
    onAssignValue(nextValue);
  }

  let component: ReactNode;
  switch (type) {
    case ColumnDataType.DATE_FIELD:
      component = <DatePicker
        dateString={nextValue}
        hasError={false}
        onChange={(dateString: string) => {
          setNextValue(dateString);
        }}
      />
      break;
    case ColumnDataType.BOOLEAN_FIELD:
      component = (
        <MappingCheckbox
          checkStatus={nextValue}
          label={label}
          hasError={false}
          onChange={(booleanString: '1' | '0') => {
            setNextValue(booleanString);
          }}
        />
      );
      break;
    case ColumnDataType.DROPDOWN_FIELD:
      component = (ColumnIdType.ADVANCED_HOURS === columnId || ColumnIdType.HOUR === columnId) ?
        (
          <MappingAdvancedHours
            range={ColumnIdType.HOUR === columnId ? 24 : 8}
            className="w-full"
            value={nextValue}
            hasError={false}
            onChange={(value: string) => {
              setNextValue(value);
            }}
          />
        )
        : (
          <MappingDropdown
            value={nextValue}
            label={label}
            hasError={false}
            options={getDropdownOption(columnId, clientMoreInfo)}
            onChange={(value: string) => {
              setNextValue(value);
            }}
          />
        );
      break;
    case ColumnDataType.ADDRESS_FIELD:
      component = (
        <MappingGoogleAddress
          value={nextValue}
          hasError={false}
          onChange={(value: string) => {
            setNextValue(value);
          }}
        />
      );
      break;
    default:
      component =
        <TextMatchingInput
          data={nextValue}
          label={label}
          hasError={false}
          onChange={(e) => {
            setNextValue(e.target.value);
          }}
        />
  }
  return (
    <div className="w-full flex gap-y-6 flex-col">
      {component}
      <div className="w-full flex justify-end items-center gap-x-4">
        <Button
          variant="outlined"
          color="gray"
          onClick={onClose}
        >
          Canelar
        </Button>
        <Button
          disabled={!nextValue}
          variant="contained"
          color="primary"
          onClick={handleAssignValue}
        >
          Asignar
        </Button>
      </div>
    </div>
  );
};

export default ColumnTypeAssignation;