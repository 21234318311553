import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import { useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { createTrasferReservationBaseLocationInitialValues } from '../forms/base/TransferReservationBaseFormInitialValues';
import { TransferReservationBaseLocationFormType } from '../forms/base/TransferReservationBaseFormTypes';
import ViajeTrasladoPuntoForm from '../forms/base/TransferReservationLocationForm';
import { DraggablePoint } from '../forms/batch/draggable/DraggablePoint';

interface Props {
  pasajeros: PassengerModel[];
  puntos: TransferReservationBaseLocationFormType[];
  onEditPuntos: (puntos: TransferReservationBaseLocationFormType[]) => void;
}

export const TransferReservationLocationFormModal = ({
  pasajeros,
  puntos,
  onEditPuntos
}: Props) => {
  const [initialValue, setInitialValue] =
    useState<TransferReservationBaseLocationFormType>({
      ...createTrasferReservationBaseLocationInitialValues()
    });
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const verifyAction = useVerifyAction();

  function editPunto(args: {
    punto: TransferReservationBaseLocationFormType;
    index: number;
  }) {
    setInitialValue(args.punto);
    setEditingIndex(args.index);
    setIsEditing(true);
    setIsOpen(true);
  }

  function createPunto() {
    setInitialValue(createTrasferReservationBaseLocationInitialValues());
    setIsEditing(false);
    setIsOpen(true);
  }

  function removePunto(index: number) {
    const nextPuntos = puntos.filter((punto, i) => i !== index);
    onEditPuntos(nextPuntos);
  }

  let items = puntos.map((punto, index) => ({
    ...punto,
    id: punto.domicilio.calle + punto.domicilio.numero + index
  }));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  );

  function handleDragEnd(e) {
    const { active, over } = e;
    if (active.data.current.sortable) {
      if (active.id !== over.id) {
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);

        const puntoOld = items[oldIndex];
        const puntoNew = items[newIndex];
        items[oldIndex] = puntoNew;
        items[newIndex] = puntoOld;

        onEditPuntos([...items]);
      }
    } else {
      if (active.data.current.externalProp !== over.id) {
        const oldPointIndex = items.findIndex(
          (punto) => punto.id === active.data.current.externalProp
        );

        const newPointIndex = items.findIndex((punto) => punto.id === over.id);

        const pasajerosOld = items[oldPointIndex].pasajeros;
        const pasajerosNew = items[newPointIndex].pasajeros;

        items[oldPointIndex].pasajeros = pasajerosNew;
        items[newPointIndex].pasajeros = pasajerosOld;

        onEditPuntos([...items]);
      }
    }
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md">
        <DialogTitle>
          {isEditing ? 'Nuevo Punto' : 'Modificar Punto'}
        </DialogTitle>
        <DialogContent dividers>
          <ViajeTrasladoPuntoForm
            pasajeros={pasajeros}
            onCancel={() => setIsOpen(false)}
            isEditing={isEditing}
            onContinue={(value) => {
              setIsOpen(false);
              if (isEditing) {
                puntos[editingIndex] = value;
              } else {
                puntos.push(value);
              }
              onEditPuntos(puntos);
            }}
            onMorePoints={(value) => {
              setIsOpen(false);
              if (isEditing) {
                puntos[editingIndex] = value;
              } else {
                puntos.push(value);
              }
              setInitialValue(
                createTrasferReservationBaseLocationInitialValues()
              );
              setTimeout(() => setIsOpen(true), 300);
            }}
            initialValue={initialValue}
          />
        </DialogContent>
      </Dialog>
      <>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((punto, index) => (
              <DraggablePoint
                punto={punto}
                index={index}
                actions={
                  <>
                    <Button
                      className="z-index"
                      onClick={() => {
                        editPunto({
                          punto,
                          index
                        });
                      }}
                      variant="light"
                      color="blue"
                      shape="circle"
                    >
                      <FaPencilAlt />
                    </Button>
                    <Button
                      onClick={() =>
                        verifyAction({
                          onAccept: () => removePunto(index),
                          body: '¿Está seguro que desea borrar este punto?'
                        })
                      }
                      color="red"
                      shape="circle"
                    >
                      <FaTrashAlt />
                    </Button>
                  </>
                }
              />
            ))}
          </SortableContext>
        </DndContext>
        <Button onClick={createPunto} variant="light" color="blue">
          Nuevo Punto
        </Button>
      </>
    </div>
  );
};
