export interface AntenaGrupoModel {
  id: number;
  nombre: string;
}

export interface AntenaGrupoModelBack {
  id: number;
  nombre: string;
}

export const antenagrupoBuilder = {
  fromBackEnd: (antenagrupo: AntenaGrupoModelBack): AntenaGrupoModel => {
    return {
      id: antenagrupo.id,
      nombre: antenagrupo.nombre
    };
  }
};
