import React from 'react';
import { VehiculoModeloFormSchema } from '../models/VehiculoModeloFormSchema';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import * as Yup from 'yup';
import { VehiculoFabricanteModel } from '../../fabricanteVehiculo/models/VehiculoFabricante';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

interface Props {
  onSubmit: (value: VehiculoModeloFormSchema, reset) => void;
  initialValues?: VehiculoModeloFormSchema;
  fabricantes: VehiculoFabricanteModel[];
}

const Schema = Yup.object().shape({
  nombre: Yup.string().required('El nombre es obligatorio')
});

function VehiculoModeloForm({
  onSubmit,
  fabricantes,
  initialValues = {
    nombre: '',
    fabricante_id: fabricantes[0].id!
  }
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={(values, actions) => onSubmit(values, actions.resetForm)}
    >
      {({ submitForm, isSubmitting }) => (
        <Form className="w-full grid gap-6">
          <Field
            name="nombre"
            label="Nombre modelo"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <FormControl variant="outlined">
            <InputLabel>Fabricante</InputLabel>
            <Field component={Select} name="fabricante_id" label="Fabricante">
              {fabricantes.map((fabricante) => (
                <MenuItem value={fabricante.id} key={fabricante.id}>
                  {fabricante.nombre}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <div className="flex justify-end">
            <Button
              variant="contained"
              color="green"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default VehiculoModeloForm;
