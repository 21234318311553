import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import * as Yup from 'yup';
import { Button } from '@vadiun/react-components';
import { VehiculoDesvinculacion } from 'app/vehiculos/models';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/moment';

interface Props {
  onDesvincular: (vehiculoSchema: VehiculoDesvinculacion, reset) => void;
  onRevincular?: () => void;
  desvinculado: boolean;
  initialValues?: VehiculoDesvinculacion;
}

const Schema = Yup.object().shape({
  fecha: Yup.mixed().required('La fecha de desvinculación es obligatoria'),
  observacion: Yup.string().required('La observación es obligatoria').nullable()
});

function VehiculoFormDesvincular({
  onRevincular,
  onDesvincular,
  desvinculado,
  initialValues: _initialValues
}: Props) {
  const initialValues = {
    fecha: _initialValues?.fecha || moment(),
    observacion: _initialValues?.observacion || ''
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      enableReinitialize
      onSubmit={onDesvincular}
    >
      {({ submitForm, touched, errors, values }) => (
        <Form className="w-full grid gap-6">
          <div className="grid grid-cols-1 gap-4">
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="fecha"
                label="Fecha desvinculación"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>
            <Field
              name="observacion"
              label="Observación"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>

          <div className="flex justify-between mt-1">
            <Button
              type="button"
              variant="light"
              onClick={onRevincular}
              color="green"
              disabled={!desvinculado}
            >
              Revincular
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="red"
              disabled={desvinculado}
            >
              Desvincular
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default VehiculoFormDesvincular;
