import { VehiculoTipo, VEHICULO_TIPOS } from '../models/VehiculoTipo';
import { VehiculoColor, VEHICULO_COLORES } from '../models/VehiculoColor';
import {
  VEHICULO_COMPANIAS_SEGURO,
  VehiculoCompaniaSeguro
} from '../models/VehiculoCompaniaSeguro';
import {
  VehiculoMunicipio,
  VEHICULO_MUNICIPIOS
} from '../models/VehiculoMunicipio';
import {
  vehiculoBuilder,
  VehiculoCRUD,
  VehiculoDesvinculacion,
  VehiculoModel,
  vehiculoPartialBuilder,
  VehiculoPartialModel
} from '../models/Vehiculo';
import { httpClient } from 'shared/services/http/httpClient';
import { notDeleted } from 'shared/helpers/deletedHandler';

const createPromise = <T>(x?: any) => new Promise<T>((resolve) => resolve(x));

const useVehiculo = () => {
  const addVehiculo = (x: VehiculoCRUD) => {
    return httpClient.post('vehicles', vehiculoBuilder.toBackEnd(x));
  };

  const desvincularVehiculo = (
    desvinculacion: VehiculoDesvinculacion,
    vehiculo: VehiculoModel
  ) => {
    return httpClient.delete(`vehicles/${vehiculo.id}`, {
      data: {
        elimination_date: desvinculacion.fecha!.format('YYYY-MM-DD'),
        elimination_observation: desvinculacion.observacion
      }
    });
  };

  const revincularVehiculo = (vehiculo: VehiculoModel) => {
    return httpClient.put(`vehicles/${vehiculo.id}/restore`);
  };

  const editVehiculo = (vehiculo: VehiculoCRUD) => {
    return httpClient.post(
      `vehicles/${vehiculo.id}?_method=PUT`,
      vehiculoBuilder.toBackEnd(vehiculo)
    );
  };

  const getVehiculosWithDeleted = async (): Promise<VehiculoPartialModel[]> => {
    const res = await httpClient.get(`vehicles`);
    return res.map(vehiculoPartialBuilder.fromBackEnd);
  };

  const getVehiculos = async () =>
    (await getVehiculosWithDeleted()).filter(notDeleted);

  const getVehiculo = async (id: number): Promise<VehiculoModel> => {
    const res = await httpClient.get(`vehicles/${id}`);
    return vehiculoBuilder.fromBackEnd(res);
  };

  const getVehiculoTipos = () => {
    return createPromise<VehiculoTipo[]>(VEHICULO_TIPOS);
  };

  const getVehiculoColores = () => {
    return createPromise<VehiculoColor[]>(VEHICULO_COLORES);
  };

  const getVehiculoCompaniasDeSeguro = () => {
    return createPromise<VehiculoCompaniaSeguro[]>(VEHICULO_COMPANIAS_SEGURO);
  };

  const getVehiculoMunicipios = () => {
    return createPromise<VehiculoMunicipio[]>(VEHICULO_MUNICIPIOS);
  };

  return {
    addVehiculo,
    desvincularVehiculo,
    revincularVehiculo,
    editVehiculo,
    getVehiculo,
    getVehiculos,
    getVehiculosWithDeleted,
    getVehiculoTipos,
    getVehiculoColores,
    getVehiculoCompaniasDeSeguro,
    getVehiculoMunicipios
  };
};

export default useVehiculo;
