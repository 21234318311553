import { useSuperMutation } from '@vadiun/react-hooks';
import { useViajesTraslado } from 'app/viaje/services';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';

import { Button, Modal, Switch } from '@vadiun/react-components';
import { useTableQuery } from '@vadiun/react-datatable';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { useDistanceAndPriceTransferReservationClientBusinessCalculator } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import LocationDetails from 'app/viaje/viajeTraslado/viajeTraslado/components/LocationDetails';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RepetitiveReservationFilters from '../../viajeTraslado/components/RepetitiveReservationFilters';
import { useRepetitiveFilters } from '../../viajeTraslado/services/useRepetitiveFilters';
import { ReservaTrasladoRepetitiveCreate } from '../components/ReservaTrasladoRepetitiveCreate';
import { ReservaTrasladoRepetitiveEdit } from '../components/ReservaTrasladoRepetitiveEdit';
import { ReservaTrasladoRepetitiveTable } from '../components/ReservaTrasladoRepetitiveTable';
import {
  transferReservationRepetitiveFormBuilder,
  TransferReservationRepetitiveFormType
} from '../form/TransferReservationRepetitiveFormType';
import { TransferReservationRepetitiveModel } from '../models/TransferreservationRepetitiveModel';

export const TransferReservationRepetitivePage = () => {
  const ViajeTrasladoService = useViajesTraslado();
  const clientBusinessRepository = useClientBusiness();
  const verifyAction = useVerifyAction();
  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useRepetitiveFilters({
    client_id: null,
    service_type: '',
    hasPriority: false,
    passangerName: '',
    street: '',
    entry_channel: '',
    phone: '',
    reservationId: '',
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: ''
  });

  const reservaRepetitivePagination = useTableQuery(
    [
      'reservas',
      'repetitivas',
      {
        ...filters
      }
    ],
    async (params) => {
      const res = await ViajeTrasladoService.getViajeTrasladoRepetitivo(
        params,
        filters
      );
      return res;
    }
  );

  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );

  const [editing, setEditing] = useState<
    (TransferReservationRepetitiveFormType & { id: number }) | undefined
  >(undefined);

  const [reservationDetail, setReservationDetail] = useState<{
    isOpen: boolean;
    details: undefined | TransferReservationRepetitiveModel;
  }>({ isOpen: false, details: undefined });

  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientBusinessCalculator();

  const createViaje = useSuperMutation(
    ViajeTrasladoService.addViajeTrasladoRepetitivo
  );
  const editViaje = useSuperMutation(
    ViajeTrasladoService.editViajeTrasladoRepetitivo
  );

  const deleteReservationMutation = useSuperMutation(
    ViajeTrasladoService.deleteRepetitiveReservation,
    {
      onSuccess: () => {
        reservaRepetitivePagination.refetch();
      }
    }
  );

  async function createReservationRepetitive(
    trasladoFormValues: TransferReservationRepetitiveFormType
  ) {
    const ViajeTraslado =
      transferReservationRepetitiveFormBuilder.toModel(trasladoFormValues);
    await createViaje.mutate({
      ...ViajeTraslado,
      repeatOnMonday: trasladoFormValues.repeatOnMonday,
      repeatOnTuesday: trasladoFormValues.repeatOnTuesday,
      repeatOnWednesday: trasladoFormValues.repeatOnWednesday,
      repeatOnThursday: trasladoFormValues.repeatOnThursday,
      repeatOnFriday: trasladoFormValues.repeatOnFriday,
      repeatOnSaturday: trasladoFormValues.repeatOnSaturday,
      repeatOnSunday: trasladoFormValues.repeatOnSunday,
      repetitionFinishDate: trasladoFormValues.repetitionFinishDate,
      repetitionInitDate: trasladoFormValues.repetitionInitDate
    });
    reservaRepetitivePagination.refetch();
    setVisiblePage('table');
  }

  async function editReservationRepetitive(
    trasladoFormValues: TransferReservationRepetitiveFormType
  ) {
    await editViaje.mutate({
      ...transferReservationRepetitiveFormBuilder.toModel(trasladoFormValues),
      id: editing!.id
    });
    reservaRepetitivePagination.refetch();
    setVisiblePage('table');
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationRepetitiveFormType,
    clientBusinessId: number
  ) {
    const estimationsPromise = calculateDistanceAndCost(
      {
        arrivalDate: trasladoFormValues.fechaYHoraDeLlegada,
        hasOutSite: trasladoFormValues.hasOutSite,
        points: trasladoFormValues.puntos.map((p) => ({
          lat: p.domicilio.latitud,
          lng: p.domicilio.longitud,
          minutesWaiting: Number(p.espera)
        })),
        serviceType: trasladoFormValues.tipoServicio,
        payWithCash: trasladoFormValues.pay_with_cash
      },
      clientBusinessId
    );
    return [await estimationsPromise];
  }

  async function selectToEdit(reservation: TransferReservationRepetitiveModel) {
    const client = await clientBusinessRepository.get(reservation.client.id);
    const rate = await clientBusinessRepository.getCurrentRate(client.id);
    const formReservation = transferReservationRepetitiveFormBuilder.toForm(
      reservation,
      client,
      rate.categories
        .find((c) => c.name === reservation.service_type)
        ?.fixedPrices.find((fp) => fp.name === reservation.fixed_price_name) ||
        null
    );
    setEditing({ ...formReservation, id: reservation.id });
    setVisiblePage('update');
  }

  return (
    <>
      <PageHeader
        title="Reservas repetitivas"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de reservas'
            : visiblePage === 'create'
            ? 'Nueva Reserva'
            : 'Editar Reserva'
        }
        toolbar={
          visiblePage === 'table' ? (
            <div className="flex space-x-2">
              <Button
                onClick={() => setVisiblePage('create')}
                variant="light"
                color="green"
              >
                Crear reserva
              </Button>
              <Button variant="light" onClick={() => setShowFilters(true)}>
                Filtros
              </Button>
            </div>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />
      <Switch value={visiblePage}>
        <Switch.Case value="table">
          <>
            <ReservaTrasladoRepetitiveTable
              tableProps={reservaRepetitivePagination}
              onLocationsDetails={(details) =>
                setReservationDetail({ isOpen: true, details })
              }
              selectToEdit={selectToEdit}
              onDelete={(id) => (
                <Button
                  onClick={() =>
                    verifyAction({
                      title: 'Estas por eliminar una reserva!',
                      body: 'Esta accion va a eliminar una reserva. ¿Estas seguro?',
                      onAccept: () => deleteReservationMutation.mutate(id)
                    })
                  }
                  variant="text"
                  shape="circle"
                  color="red"
                  disabled={deleteReservationMutation.isLoading}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            />
            <Drawer
              variant="persistent"
              anchor="right"
              open={showFilters}
              className="max-w-md"
            >
              <div>
                <IconButton onClick={() => setShowFilters(false)}>
                  <ChevronRightIcon />
                </IconButton>
                <Divider />
                <RepetitiveReservationFilters
                  onSubmit={async (filters) => {
                    setFilters(filters);
                    setShowFilters(false);
                  }}
                  initialValues={filters}
                />
              </div>
            </Drawer>
          </>
        </Switch.Case>
        <Switch.Case value="create">
          <ReservaTrasladoRepetitiveCreate
            onSubmit={createReservationRepetitive}
            estimatePrice={estimatePrice}
          />
        </Switch.Case>
        <Switch.Case value="update">
          <ReservaTrasladoRepetitiveEdit
            initialValues={editing!}
            onSubmit={editReservationRepetitive}
            estimatePrice={estimatePrice}
          />
        </Switch.Case>
      </Switch>
      {reservationDetail.details ? (
        <Modal
          open={reservationDetail.isOpen}
          onClose={() =>
            setReservationDetail({ isOpen: false, details: undefined })
          }
          size="md"
          title={reservationDetail.details?.client.name}
          body={
            <LocationDetails
              locations={reservationDetail.details.puntos.map((p) => ({
                address: p.domicilio,
                observation: p.observacion ?? '',
                passengers: p.pasajeros.map((p) => ({
                  name: p.nombreCompleto,
                  phone: String(p.telefono)
                }))
              }))}
            />
          }
        />
      ) : null}
    </>
  );
};
