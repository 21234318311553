import { RateFormType } from './RateForm/RateFormik';
import RateForm from './RateForm/RateForm';

interface Props {
  create: (rateFormValue: RateFormType, reset: () => void) => Promise<void>;
}

function RateCreate({ create }: Props) {
  return <RateForm onSubmit={create as any} />;
}

export default RateCreate;
