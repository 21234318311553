import { Dialog } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useRef, useState } from 'react';
import { Card } from 'shared/components';
import { useClientBusinessUser } from '../hooks/useClientBusinessUser';
import { ClientBusinessUserChangePassword } from './ClientBusinessUserChangePassword';
import { ClientBusinessUserCreate } from './ClientBusinessUserCreate';
import { ClientBusinessUserTable } from './ClientBusinessUserTable';
import { ClientBusinessUserUpdate } from './ClientBusinessUserUpdate';
import { ClientBusinessUserUpdateCompany } from './ClientBusinessUserUpdateCompany';

interface Props extends ReturnType<typeof useClientBusinessUser> {}

export interface BusinessClientUser {
  id: number;
  roles?: string[];
  name: string;
  email: string;
}

export function ClientBusinessUserCrudComponent({
  users,
  create,
  update,
  remove,
  restore,
  visiblePage,
  setVisiblePage,
  onChangePassword,
  updateCompany,
  selectToChangePassword,
  isChangePasswordModalOpen,
  setIsChangePasswordModalOpen,
  onImportUsers,
  setImportUsersOpen,
  file,
  setfile,
  importUsersOpen
}: Props) {
  const initial = {
    id: 0,
    name: '',
    email: ''
  };

  const [companyModal, setCompanyModal] = useState(false);

  const fileInputref = useRef<HTMLInputElement>(null);
  const [editingUser, setEditingUser] = useState<BusinessClientUser>(initial);

  function selectToEdit(user: BusinessClientUser) {
    setEditingUser(user);
    setVisiblePage('update');
  }

  function selectToUpdateCompany(user: BusinessClientUser) {
    setEditingUser(user);
    setCompanyModal(true);
  }

  function onClose() {
    setCompanyModal(false);
    setEditingUser(initial);
  }

  return (
    <>
      <Card
        className="flex-grow"
        title="Usuarios"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Usuarios'
            : visiblePage === 'create'
            ? 'Nuevo Usuario'
            : 'Editar Usuario'
        }
        toolbar={
          visiblePage === 'table' ? (
            <div className="flex gap-4">
              <Button
                onClick={() => setImportUsersOpen(true)}
                variant="light"
                color="green"
              >
                Importar
              </Button>
              <Button onClick={() => setVisiblePage('create')} variant="light">
                Crear Usuario
              </Button>
            </div>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      >
        <div className="p-8">
          {visiblePage === 'table' && (
            <ClientBusinessUserTable
              users={users}
              delete={remove}
              restore={restore}
              changePassword={selectToChangePassword}
              selectToEdit={selectToEdit}
              selectToUpdateCompany={selectToUpdateCompany}
            />
          )}

          {visiblePage === 'create' && (
            <ClientBusinessUserCreate create={create} />
          )}
          {visiblePage === 'update' && (
            <ClientBusinessUserUpdate
              initialValues={editingUser}
              update={update}
            />
          )}
        </div>
      </Card>
      <ClientBusinessUserUpdateCompany
        open={companyModal}
        updateCompany={updateCompany}
        onClose={onClose}
        user={editingUser}
      />
      <Dialog
        open={isChangePasswordModalOpen}
        onBackdropClick={() => setIsChangePasswordModalOpen(false)}
      >
        <ClientBusinessUserChangePassword onSubmit={onChangePassword} />
      </Dialog>
      <Dialog
        open={importUsersOpen}
        onBackdropClick={() => setImportUsersOpen(false)}
        maxWidth="md"
      >
        <div className="m-8">
          <input
            type="file"
            accept=".xlsx,.xls,.csv"
            style={{ display: 'none' }}
            ref={fileInputref}
            id="contained-button-file"
            onChange={(ev) =>
              setfile(ev.target.files ? ev.target.files[0] : null)
            }
          />
          <div className="flex w-full items-center rounded-md border-2 border-dashed border-gray-200">
            <Button
              variant="contained"
              color="primary"
              onClick={() => fileInputref.current?.click()}
            >
              Seleccionar planilla
            </Button>
            <p className="px-4 text-sm text-gray-500">
              {file?.name ?? 'Seleccione un archivo'}
            </p>
          </div>

          <div className="mt-8 flex justify-between gap-8">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setfile(null);
                setImportUsersOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={file === null}
              color="primary"
              onClick={() => onImportUsers(file!)}
            >
              Importar
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
