import { Dispatch, SetStateAction } from 'react';

export const useDragDrop = ({
  onFileAdded,
  onDragEntered
}: {
  onFileAdded: (filelist: FileList) => Promise<void>;
  onDragEntered: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.types.includes('Files')) {
      try {
        return await onFileAdded(e.dataTransfer.files);
      } finally {
        onDragEntered(false);
      }
    }
    onDragEntered(false);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (e.clipboardData?.files?.length) {
      e.preventDefault();
      onFileAdded(e.clipboardData.files);
    }
    onDragEntered(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return {
    handlePaste,
    handleDragOver,
    handleDrop
  };
};
