import { CircularProgress } from '@material-ui/core';
import { useSuperQuery } from '@vadiun/react-hooks';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useLocation, useParams } from 'react-router-dom';
import { Card } from 'shared/components';
import { DriverSettlementDetailResumeComponent } from '../components/DriverSettlementDetailResumeComponent';
import { DriverSettlementDetailTableComponent } from '../components/DriverSettlementDetailTableComponent';
import { DriverSettlementsMovimientosTableComponent } from '../components/DriverSettlementMovimientosTableComponent';
import { useDriverSettlementRepository } from '../services/driverSettlementsRepository';

export const DriverSettlementDetailPage = ({
  asRole
}: {
  asRole: 'driver' | 'admin';
}) => {
  const settlementRepo = useDriverSettlementRepository();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const movimientosQuery = useSuperQuery(['movimientos', id], () =>
    settlementRepo.getSettlementMovimientos(Number(id))
  );

  const createMovimientoMutation = useSuperMutation(
    settlementRepo.createSettlementMovimientos,
    {
      onMutationFinish() {
        movimientosQuery.refetch();
        settlementResumeQuery.refetch();
      }
    }
  );
  const settlementDetailQuery = useSuperQuery(['settlement_detail', id], () =>
    settlementRepo.get(Number(id))
  );
  const settlementResumeQuery = useSuperQuery(['settlement_resume', id], () =>
    settlementRepo.getDriverSettlementDetail(id, params.get('driverCode') || '')
  );

  return (
    <>
      <PageHeader title="Detalle liquidación" subtitle="" />
      <PageContainer>
        <div className="w-full">
          {!settlementResumeQuery.isLoading ? (
            <DriverSettlementDetailTableComponent
              chofer={
                settlementResumeQuery.data
                  ? `${settlementResumeQuery.data.driverName} - ${settlementResumeQuery.data.driverCode}`
                  : ''
              }
              settlementsDetail={settlementDetailQuery.data?.data ?? []}
            />
          ) : (
            <div className="flex h-auto w-full items-center justify-center">
              <CircularProgress />
            </div>
          )}
          <div className="flex flex-col items-center md:mt-8 md:flex-row md:items-start gap-8">
            <Card className="w-full p-8 mt-8 md:mt-0">
              <DriverSettlementsMovimientosTableComponent
                asRole={asRole}
                onSubmit={async (values) =>
                  createMovimientoMutation.mutate({
                    settlementId: Number(id!),
                    ...values
                  })
                }
                movimientos={movimientosQuery.data ?? []}
              />
            </Card>
            <Card className="w-full md:w-1/3 flex flex-shrink-0 justify-center p-8">
              {settlementResumeQuery.data === undefined ? null : (
                <DriverSettlementDetailResumeComponent
                  settlement={settlementResumeQuery.data}
                />
              )}
            </Card>
          </div>
        </div>
      </PageContainer>
    </>
  );
};
