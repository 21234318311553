import { Dialog, Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { ClientType } from 'app/client/main/models/ClientType';
import { useViajesTraslado } from 'app/viaje/services';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCustomTablePagination } from 'shared/hooks/useCustomTablePagination';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import ViajeTrasladoResumenModal from '../../viajeTraslado/components/ViajeTrasladoResumenModal';
import { ViajeAValidarDenyForm } from '../components/ViajeAValidarDenyForm';
import { ViajePendienteSolicitadoTable } from '../components/ViajePendienteSolicitadoTable';
import { TransferReservationToValidate } from '../models/TransferReservationPendingValidation';

export const ViajePendienteSolicitadoPage = () => {
  const history = useHistory();
  const tripRepository = useViajesTraslado();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useFilters({
    dateFrom: moment().subtract(24, 'hours'),
    dateTo: moment().add(1, 'year'),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    departure_time_exceeded: false,
    driver_with_close_shift: false,
    assigned_driver_id: null
  });
  const [locationsDetail, setLocationsDetail] = useState<{
    isOpen: boolean;
    details: TransferReservationToValidate | undefined;
  }>({ isOpen: false, details: undefined });
  const [denyDetails, setDenyDetails] = useState<{
    isOpen: boolean;
    reservationId: number | undefined;
  }>({
    isOpen: false,
    reservationId: undefined
  });

  const tablePagination = useCustomTablePagination((params: string) =>
    tripRepository.getAllTransferReservationToValidate(params)
  );

  const denyMutation = useSuperMutation(
    tripRepository.denyValidationTransferReservation
  );

  useEffect(() => {
    tablePagination.reload();
  }, [filters]);

  const handleDeny = async ({ observation }: { observation: string }) => {
    await denyMutation.mutate({ observation, id: denyDetails.reservationId! });
    setDenyDetails({ isOpen: false, reservationId: undefined });
    tablePagination.reload();
  };

  return (
    <>
      <PageHeader title="Traslados Solicitados" />
      <PageContainer lessMargin>
        <ViajePendienteSolicitadoTable
          tableProps={tablePagination}
          onLocationsDetails={(reservation) =>
            setLocationsDetail({ isOpen: true, details: reservation })
          }
          onDeny={(res) =>
            setDenyDetails({ isOpen: true, reservationId: res.id })
          }
          onValidate={(res) => {
            if (res.clientType === ClientType.business) {
              history.push(
                '/main/solicitados/clientBusiness/aprobacion/' + res.id
              );
            }
            if (res.clientType === ClientType.private) {
              history.push(
                '/main/solicitados/clientPrivate/aprobacion/' + res.id
              );
            }
          }}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          />
        </div>
      </Drawer>
      {locationsDetail.details && (
        <ViajeTrasladoResumenModal
          isOpen={locationsDetail.isOpen}
          reservation={locationsDetail.details!}
          handleClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
        />
      )}
      <Dialog
        open={denyDetails.isOpen}
        onClose={() =>
          setDenyDetails({ isOpen: false, reservationId: undefined })
        }
      >
        <ViajeAValidarDenyForm onSubmit={handleDeny} />
      </Dialog>
    </>
  );
};
