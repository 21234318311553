import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import moment from 'moment';
import { driverStateColors } from '../../models/ChoferEstado';
import { tw } from 'utils/tailwindUtils';

interface Props {
  item: MonitorElemItem;
  showDistrictLocalities: (districtName: string) => void;
  showDriverDetails: (driver: ChoferTrabajando) => void;
  isUnavailableDriver: boolean;
}

export type MonitorElemItem = {
  name: string;
  workingDrivers: ChoferTrabajando[];
};

export function MonitorElem({
  item,
  showDistrictLocalities,
  showDriverDetails,
  isUnavailableDriver
}: Props) {
  return (
    <div
      className="rounded-lg pb-1"
      style={{
        border: '1px solid #f1f1f1'
      }}
    >
      {item.name !== 'Sin abrir turno' ? (
        <h1
          className="text-gray-500 cursor-pointer text-xs hover:underline py-1 px-4"
          style={{
            borderBottom: '1px solid #f1f1f1'
          }}
          onClick={() => showDistrictLocalities(item.name)}
        >
          {item.name}
        </h1>
      ) : (
        <h1 className="text-gray-500 text-xs">{item.name}</h1>
      )}
      <div className="mt-1 flex flex-wrap gap-2 px-4">
        {item.workingDrivers.map((d) => (
          <DriverElem
            key={d.code}
            onClick={() => showDriverDetails(d)}
            driver={d}
            isUnavailableDriver={isUnavailableDriver}
          />
        ))}
      </div>
    </div>
  );
}

interface SinTurnoProps {
  groups: {
    name: string;
    workingDrivers: ChoferTrabajando[];
  }[];
  showDriverDetails: (driver: ChoferTrabajando) => void;
  isUnavailableDriver: boolean;
}

export function SinTurnoAbiertoMonitorElement({
  groups,
  isUnavailableDriver,
  showDriverDetails
}: SinTurnoProps) {
  return (
    <div className="flex flex-wrap gap-3">
      {groups.map((nestedGroup) => (
        <div
          key={nestedGroup.name}
          className="rounded-lg pb-1"
          style={{
            border: '1px solid #f1f1f1'
          }}
        >
          <h1
            className="title text-xs py-1 px-4"
            style={{
              borderBottom: '1px solid #f1f1f1'
            }}
          >
            {nestedGroup.name}
          </h1>
          <div className="mt-1 flex flex-wrap space-x-2 px-4">
            {nestedGroup.workingDrivers.map((d) => (
              <DriverElem
                key={d.code}
                onClick={() => showDriverDetails(d)}
                driver={d}
                isUnavailableDriver={isUnavailableDriver}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function DriverElem({
  driver,
  isUnavailableDriver = false,
  onClick
}: {
  driver: ChoferTrabajando;
  onClick: () => void;
  isUnavailableDriver?: boolean;
}) {
  return (
    <span
      onClick={onClick}
      className={tw(
        'text-' +
          driverStateColors(driver.state) +
          '-500 relative cursor-pointer text-sm hover:underline',
        {
          'text-gray-400': isUnavailableDriver
        }
      )}
    >
      {driver.code}
      {driver.last_finished_reservation_datetime !== null &&
      moment().diff(driver.last_finished_reservation_datetime, 'minutes') <
        30 ? (
        <div
          className="absolute flex h-4 w-4 items-center justify-center rounded-full border-solid border-gray-200 bg-white text-gray-500"
          style={{ fontSize: 9, borderWidth: 1, top: -9, right: -10 }}
          title="Minutos desde el fin del último viaje"
        >
          {moment().diff(driver.last_finished_reservation_datetime, 'minutes')}
        </div>
      ) : null}
    </span>
  );
}
