import { TransferReservationPricesRateType } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPrices';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface CorrectPriceFormType {
  waiting_minutes: number;
  km: number;
  expenses_price: number;
  transfer_price: number;
  observation: string | null;
  rate_type: TransferReservationPricesRateType;
}

const correctPriceFormSchema: YupShape<CorrectPriceFormType> =
  Yup.object().shape({
    waiting_minutes: Yup.number().required('Los minutos son requeridos'),
    km: Yup.number().required('Los kilometros son requeridos'),
    expenses_price: Yup.number().required('Los gastos son requeridos'),
    transfer_price: Yup.number().required('El precio es requerido'),
    observation: Yup.string().required('La observacion es obligatoria'),
    rate_type: Yup.string().required('El tipo de tarifa es obligatoria')
  });

const correctPriceInitialValues: CorrectPriceFormType = {
  waiting_minutes: 0,
  km: 0,
  expenses_price: 0,
  transfer_price: 0,
  observation: '',
  rate_type: TransferReservationPricesRateType.KILOMENTO_LINEAL
};

const CorrectPriceFormik = {
  schema: correctPriceFormSchema,
  initialValues: correctPriceInitialValues
};

export default CorrectPriceFormik;
