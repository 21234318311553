import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useState } from 'react';
import { Card } from 'shared/components';
import PassengerCreate from '../components/PassengerCreate';
import PassengerEdit from '../components/PassengerEdit';
import { PassengerFormType } from '../components/PassengerForm/PassengerFormik';
import PassengerTable from '../components/PassengerTable';
import { PassengerModel } from '../models/Passenger';
import usePassengers from '../services/PassengerService';

function PassengerCrudPage({ idClientBusiness }: { idClientBusiness: number }) {
  const PassengerService = usePassengers();
  const passengersQuery = useSuperQuery(
    () => PassengerService.getPassengers(idClientBusiness),
    {
      showSpinner: true
    }
  );
  const updatePassengerMutation = useSuperMutation(
    PassengerService.updatePassenger
  );
  const addPassengerMutation = useSuperMutation(PassengerService.addPassenger);
  const removePassengerMutation = useSuperMutation(
    PassengerService.removePassenger,
    {
      showSpinner: true
    }
  );
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [updatingPassenger, setEditingPassenger] = useState<PassengerModel>();

  async function update(passengerValues: PassengerFormType, reset) {
    passengerValues.id = updatingPassenger!.id;
    await updatePassengerMutation.mutate(passengerValues);
    reset();
    setVisiblePage('table');
    passengersQuery.reload();
  }

  async function create(passengerValues: PassengerFormType, reset) {
    await addPassengerMutation.mutate(passengerValues, idClientBusiness);
    reset();
    passengersQuery.reload();
  }

  async function remove(passenger: PassengerModel) {
    await removePassengerMutation.mutate(passenger);
    passengersQuery.reload();
  }

  function selectToEdit(passenger: PassengerModel) {
    setEditingPassenger(passenger);
    setVisiblePage('update');
  }
  return (
    <>
      <Card
        className="flex-grow"
        title="Pasajeros"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Pasajeros'
            : visiblePage === 'create'
            ? 'Nuevo Pasajero'
            : 'Editar Pasajero'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Pasajero
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      >
        <div className="p-8">
          {visiblePage === 'table' && (
            <PassengerTable
              passengers={passengersQuery.data || []}
              selectToEdit={selectToEdit}
              delete={remove}
            />
          )}
          {visiblePage === 'create' && <PassengerCreate create={create} />}
          {visiblePage === 'update' && updatingPassenger && (
            <PassengerEdit update={update} initialValues={updatingPassenger} />
          )}
        </div>
      </Card>
    </>
  );
}

export default PassengerCrudPage;
