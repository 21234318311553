import React, { FC } from 'react';

interface Props {
  lessMargin?: boolean;
}

const PageContainer: FC<Props> = (props) => {
  const className =
    'sm:w-full sm:space-y-4 md:flex p-4 md:space-x-4 md:space-y-0 ' +
    (props.lessMargin ? ' 2xl:px-8 pt-6' : ' 2xl:px-40 pt-6');
  return <div className={className}>{props.children}</div>;
};

export default PageContainer;
