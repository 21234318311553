import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import {
  ViajeTrasladoCreateModelLegacyDontUse,
  ViajeTrasladoModelLegacyDontUse
} from 'app/viaje/models';
import { Moment } from 'moment';
import {
  transferReservationBaseFormBuilder,
  TransferReservationBaseFormType,
  transferReservationBaseLocationFormBuilder,
  TransferReservationBaseLocationFormType
} from '../base/TransferReservationBaseFormTypes';

export interface TransferReservationSingleFormType
  extends TransferReservationBaseFormType {
  fechaYHoraDeLlegada: Moment;
  minutosPreviosASalida: Moment;
  puntos: TransferReservationBaseLocationFormType[];
}

function transferReservationSingleFormToModel(
  form: TransferReservationSingleFormType
): ViajeTrasladoCreateModelLegacyDontUse {
  return {
    ...transferReservationBaseFormBuilder.toModel(form),
    puntos: form.puntos.map(transferReservationBaseLocationFormBuilder.toModel),
    fechaYHoraDeLlegada: form.fechaYHoraDeLlegada,
    fechaYHoraDeSalida: form.minutosPreviosASalida
  };
}

function transferReservationModelToSingleForm(
  reserva: ViajeTrasladoModelLegacyDontUse,
  cliente: ClientBusinessModel,
  fixedRate: RateModel['categories'][0]['fixedPrices'][0] | null
): TransferReservationSingleFormType {
  return {
    ...transferReservationBaseFormBuilder.toForm(reserva, cliente, fixedRate),
    puntos: reserva.puntos.map(
      transferReservationBaseLocationFormBuilder.toForm
    ),
    fechaYHoraDeLlegada: reserva.fechaYHoraDeLlegada,
    minutosPreviosASalida: reserva.fechaYHoraDeSalida
  };
}

export const transferReservationSingleFormBuilder = {
  toModel: transferReservationSingleFormToModel,
  toForm: transferReservationModelToSingleForm
};
