import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

type Props = {
  onSubmit(value: { observation: string }): Promise<void>;
};

export const ViajeAValidarDenyForm = ({ onSubmit }: Props) => {
  return (
    <Formik
      initialValues={{ observation: '' }}
      validationSchema={Yup.object({
        observation: Yup.string().required('Requerido')
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="p-8">
          <Field
            name="observation"
            label="Observación*"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <div className="flex justify-end mt-8">
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
              variant="contained"
            >
              Continuar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
