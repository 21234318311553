import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { Table, TableColumn } from '@vadiun/react-datatable';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { Card } from 'shared/components';
import { LineChartComponent } from '../components/LineChartComponent';
import { useClientInformationRepository } from '../services/clientInformationRepository';

export const ReservationPricePerClient = () => {
  const repo = useClientInformationRepository();
  const [filters, setFilters] = useState({
    fromDate: moment(),
    toDate: moment()
  });
  const pricePerClient = useSuperQuery(
    () => repo.getReservationPricePerClient(filters.fromDate, filters.toDate),
    {},
    [filters.fromDate, filters.toDate]
  );
  const [showFilters, setShowFilters] = useState(false);

  if (pricePerClient.data === undefined) {
    return <p>Cargando...</p>;
  }

  const labels = (pricePerClient.data[0]?.amounts ?? []).map((fecha) => {
    return fecha.fecha;
  });
  const columns: TableColumn<any>[] = [
    {
      name: 'name',
      label: 'Clientes'
    }
  ];

  (pricePerClient.data[0]?.amounts ?? []).forEach((column, index) => {
    const temporal = {
      name: 'name',
      label: column.fecha,
      options: {
        customBodyRenderLite: (index2: number) => (
          <div className="flex flex-col">
            {pricePerClient.data![index2].amounts[index].average}
          </div>
        )
      }
    };
    columns.push(temporal);
  });

  const lines = pricePerClient.data.map((data) => ({
    label: data.name,
    values: data.amounts.map((amount) => ({
      label: amount.fecha,
      value: amount.average
    }))
  }));
  return (
    <>
      <PageHeader
        title="Precio de viaje promedio"
        toolbar={
          <Button variant="contained" onClick={() => setShowFilters(true)}>
            Filtrar
          </Button>
        }
      />
      <PageContainer>
        <div className="h-full w-full">
          <Card className="mb-8">
            <div className="p-8" style={{ minHeight: 300 }}>
              <LineChartComponent lines={lines} labels={labels} />
            </div>
          </Card>
          <Table
            options={{
              filter: false
            }}
            title={'Tabla de promedios por mes'}
            data={pricePerClient.data}
            columns={columns}
          />
        </div>
      </PageContainer>

      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <Formik
            onSubmit={async (filters) => {
              await setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          >
            {(formik) => (
              <Form className="m-8 grid grid-cols-1 gap-8">
                <Field
                  name="fromDate"
                  label="Desde*"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  ampm={false}
                  format="DD/MM/YYYY"
                  fullWidth
                />
                <Field
                  name="toDate"
                  label="Hasta*"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  ampm={false}
                  format="DD/MM/YYYY"
                  fullWidth
                />
                <Button variant="contained" onClick={formik.submitForm}>
                  Buscar
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};
