import {
  faAddressCard,
  faCalendarTimes,
  faClock,
  faMedal,
  faMinusCircle,
  faUserAlt,
  faUserSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@vadiun/react-components';
import { FormikHelpers } from 'formik';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import ProfileMenu from 'profile/components/ProfileMenu';
import ProfileMenuItem from 'profile/components/ProfileMenuItem';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'shared/components/Card/Card';
import { mapVehiculoCategoriaToIconColor } from '../../vehiculos/models/VehiculoCategoria';
import ChoferFormDeshabilitacion from '../components/ChoferForm/ChoferFormDeshabilitacion';
import ChoferFormDesvincular from '../components/ChoferForm/ChoferFormDesvinculacion';
import ChoferFormGeneral from '../components/ChoferForm/ChoferFormGeneral';
import ChoferFormNovedades from '../components/ChoferForm/ChoferFormNovedad';
import ChoferFormPersonal from '../components/ChoferForm/ChoferFormPersonal';
import ChoferFormTurno from '../components/ChoferForm/ChoferFormTurno';
import ChoferFormVencimientos from '../components/ChoferForm/ChoferFormVencimientos';
import ChoferTableDeshabilitaciones from '../components/ChoferTables/ChoferTableDeshabilitaciones';
import useChoferProfile from '../hooks/useChoferProfile';
import { ChoferDeshabilitacion, ChoferDesvinculacion, ChoferNovedad } from '../models/Chofer';
import {
  ChoferFormSchema,
  choferFormSchemaToModel,
  choferModelToFormSchema
} from '../models/ChoferFormSchema';


function ChoferProfilePage() {
  const {
    desvincularMutation,
    deshabilitarMutation,
    aceptarDeshabilitacionMutation,
    rechazarDeshabilitacionMutation,
    revincularChoferMutation,
    restoreChoferMutation,
    eliminarMutation,
    cancelarDeshabilitacionMutation,
    editChoferMutation,
    choferQuery,
    vehiculosQuery,
    proveedoresQuery,
    businessUnitQuery
  } = useChoferProfile();

  const [menuItemSelected, setMenuitemSelected] = useState(1);
  const choferFormValues =
    choferQuery.data && choferModelToFormSchema(choferQuery.data);

  async function deshabilitar(deshabilitacion: ChoferDeshabilitacion) {
    await deshabilitarMutation.mutate(deshabilitacion, choferQuery.data!);
    choferQuery.reload();
  }

  async function crearNovedad(novedad: ChoferNovedad) {
    /*await deshabilitarMutation.mutate(deshabilitacion, choferQuery.data!);*/
    choferQuery.reload();
  }

  async function aceptarDeshabilitacion(
    deshabilitacion: ChoferDeshabilitacion
  ) {
    await aceptarDeshabilitacionMutation.mutate(
      deshabilitacion,
      choferQuery.data!
    );
    choferQuery.reload();
  }

  async function rechazarDeshabilitacion(
    deshabilitacion: ChoferDeshabilitacion
  ) {
    await rechazarDeshabilitacionMutation.mutate(
      deshabilitacion,
      choferQuery.data!
    );
    choferQuery.reload();
  }

  async function revincular() {
    await revincularChoferMutation.mutate(choferQuery.data!);
    choferQuery.reload();
  }

  async function restore() {
    await restoreChoferMutation.mutate(choferQuery.data!);
    choferQuery.reload();
  }

  async function desvincular(
    desvinculacion: ChoferDesvinculacion,
    formikhelpers: FormikHelpers<any>
  ) {
    await desvincularMutation.mutate(desvinculacion, choferQuery.data!);
    formikhelpers.resetForm();
    choferQuery.reload();
  }

  async function eliminar(
    desvinculacion: ChoferDesvinculacion,
    formikhelpers: FormikHelpers<any>
  ) {
    await eliminarMutation.mutate(desvinculacion, choferQuery.data!);
    formikhelpers.resetForm();
    choferQuery.reload();
  }



  async function cancelarDeshabilitacion(
    deshabilitacion: ChoferDeshabilitacion
  ) {
    await cancelarDeshabilitacionMutation.mutate(
      deshabilitacion,
      choferQuery.data!
    );
    choferQuery.reload();
  }

  async function edit(ChoferFormValues: ChoferFormSchema) {
    const _chofer = choferFormSchemaToModel(ChoferFormValues);
    _chofer.id = choferQuery.data!.id;
    await editChoferMutation.mutate(_chofer);
    choferQuery.reload();
  }

  const cardTitle = () => {
    switch (menuItemSelected) {
      case 1:
        return 'Información General';
      case 2:
        return 'Información Personal';
      case 3:
        return 'Turnos';
      case 4:
        return 'Vencimientos';
      case 5:
        return 'Desvincular';
      case 6:
        return 'Deshabilitar';
      case 7:
        return 'Novedades'
    }
  };

  if (
    !choferQuery.data ||
    !choferFormValues ||
    !vehiculosQuery.data ||
    !proveedoresQuery.data
  )
    return null;

  return (
    <>
      <PageHeader
        title="Chofer"
        subtitle={
          <div className="flex space-x-2">
            <Link
              color="inherit"
              to="/main/chofer"
              className="text-xs text-gray-400 hover:underline"
            >
              Choferes
            </Link>
            <span>/</span>
            <span className="text-xs text-gray-400">
              {choferQuery.data.general.nombreCompleto}
            </span>
          </div>
        }
      />
      <PageContainer>
        <Card className="md:w-1/3 2xl:w-1/4">
          <div className="p-8">
            <div className="flex space-x-2">
              <div className="relative mr-2.5">
                <FontAwesomeIcon
                  icon={faMedal}
                  className="absolute top-0 right-1"
                  size="2x"
                  color={mapVehiculoCategoriaToIconColor(
                    choferQuery.data.general.categoria
                  )}
                />
                <img
                  src={choferQuery.data.personal.foto}
                  className="h-24 w-24 rounded-lg object-cover object-center"
                />
              </div>

              <div className="ml-2">
                <h1 className="title">
                  {choferQuery.data.general.nombreCompleto}
                </h1>
                <h1 className="subtitle ellipsis mb-3 text-sm">
                  #{choferQuery.data.general.codigo}
                </h1>
                {choferQuery.data.desvinculacion.fecha === null ? (
                  <Chip color="green">Activo</Chip>
                ) : (
                  <Chip color="red">Desvinculado</Chip>
                )}
              </div>
            </div>
            <div className="mt-8 flex justify-between">
              <span className="title">Vehiculo</span>
              <span className="subtitle text-sm hover:underline">
                <Link
                  to={`/main/vehiculo/${choferQuery.data.general.vehiculo.id}`}
                >
                  {choferQuery.data.general.vehiculo.modelo.nombre}
                </Link>
              </span>
            </div>
            <div className="mt-2 flex justify-between">
              <span className="title font-medium">Celular</span>
              <span className="subtitle text-sm">
                {choferQuery.data.personal.celular}
              </span>
            </div>
            <div className="mt-2 flex justify-between">
              <span className="title">Tel. Emergencia</span>
              <span className="subtitle text-sm">
                {choferQuery.data.personal.telefonoEmergencia}
              </span>
            </div>
            <div className="mt-8">
              <ProfileMenu
                value={menuItemSelected}
                onSelect={(value) => setMenuitemSelected(value)}
              >
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faAddressCard} size="lg" />}
                  label="Información general"
                  value={1}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faUserAlt} size="lg" />}
                  label="Información personal"
                  value={2}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faClock} size="lg" />}
                  label="Turnos"
                  value={3}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faCalendarTimes} size="lg" />}
                  label="Vencimientos"
                  value={4}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faUserSlash} size="lg" />}
                  label="Desvinculación"
                  value={5}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faMinusCircle} size="lg" />}
                  label="Deshabilitar"
                  value={6}
                />
                <ProfileMenuItem
                  icon={<FontAwesomeIcon icon={faMinusCircle} size="lg" />}
                  label="Novedades"
                  value={7}
                />
              </ProfileMenu>
            </div>
          </div>
        </Card>
        <div className="flex w-full flex-col space-y-2">
          <Card className="flex-grow" title={cardTitle()}>
            <div className="p-12">
              {menuItemSelected === 1 && (
                <ChoferFormGeneral
                  onSubmit={(values) => {
                    return edit({ ...choferFormValues, general: values });
                  }}
                  submitButtonTitle="Guardar cambios"
                  vehiculos={vehiculosQuery.data}
                  proveedores={proveedoresQuery.data}
                  initialValues={choferFormValues.general}
                  businessUnit={businessUnitQuery.data!}
                />
              )}
              {menuItemSelected === 2 && (
                <ChoferFormPersonal
                  onSubmit={(values) => {
                    return edit({ ...choferFormValues, personal: values });
                  }}
                  submitButtonTitle="Guardar cambios"
                  initialValues={choferFormValues.personal}
                />
              )}
              {menuItemSelected === 3 && (
                <ChoferFormTurno
                  onSubmit={(values) => {
                    return edit({ ...choferFormValues, turno: values });
                  }}
                  submitButtonTitle="Guardar cambios"
                  initialValues={choferFormValues.turno}
                />
              )}
              {menuItemSelected === 4 && (
                <ChoferFormVencimientos
                  onSubmit={(values) => {
                    return edit({ ...choferFormValues, vencimientos: values });
                  }}
                  submitButtonTitle="Guardar cambios"
                  initialValues={choferFormValues.vencimientos}
                />
              )}
              {menuItemSelected === 5 && (
                <div className='flex flex-col gap-y-8 '>

                  <ChoferFormDesvincular
                    onSubmit={(values, x) => {
                      return desvincular(values, x);
                    }}
                    desvinculado={choferQuery.data.preDesvinculacion?.fecha ? true : false}
                    onRevincular={revincular}
                    initialValues={choferQuery.data.preDesvinculacion}
                    title={'Pre Desvinculación'}
                    labelDelete={'Desvincular'}
                    labelRestore={'Revincular'}
                  />


                  <ChoferFormDesvincular
                    onSubmit={(values, x) => {
                      return eliminar(values, x);
                    }}
                    desvinculado={choferQuery.data.isDeleted}
                    onRevincular={restore}
                    initialValues={choferQuery.data.desvinculacion}
                    title={'Desvinculación Definitiva'}
                    labelDelete={'Eliminar'}
                    labelRestore={'Restaurar'}
                  />
                </div>
              )}
              {menuItemSelected === 6 && (
                <ChoferFormDeshabilitacion onSubmit={deshabilitar} />
              )}
              {menuItemSelected === 7 && (
                <ChoferFormNovedades onSubmit={crearNovedad} />
              )}
            </div>
          </Card>
          {menuItemSelected === 6 && (
            <ChoferTableDeshabilitaciones
              deshabilitaciones={choferQuery.data.deshabilitaciones}
              cancelar={cancelarDeshabilitacion}
              aceptarDeshabilitacion={aceptarDeshabilitacion}
              rechazarDeshabilitacion={rechazarDeshabilitacion}
            />
          )}
        </div>
      </PageContainer>
    </>
  );
}

export default ChoferProfilePage;
