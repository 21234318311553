import { domicilioInitialValues } from 'shared/components/FormikAddress';
import { ClientPrivateFormType } from './ClientPrivateFormType';

export const createClientPrivateFormInitialValues =
  (): ClientPrivateFormType => ({
    name: '',
    lastName: '',
    alias: '',
    phone1: '',
    phone2: '',
    email: '',
    address: domicilioInitialValues,
    code: ''
  });
