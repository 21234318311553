import { Button, Table, TableColumn } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

export interface IDriverSettlementMovimiento {
  monto: string;
  observacion: string;
}

interface Props {
  movimientos: IDriverSettlementMovimiento[];
  onSubmit: (values: IDriverSettlementMovimiento) => Promise<any>;
  asRole: 'admin' | 'driver';
}

export const DriverSettlementsMovimientosTableComponent = ({
  movimientos,
  onSubmit,
  asRole
}: Props) => {
  const data = movimientos.map((value) => ({
    ...value,
    monto:
      Number(value.monto) > 0
        ? `$${value.monto}`
        : `- $${Math.abs(Number(value.monto))}`
  }));
  const columns: TableColumn<typeof data[0]>[] = [
    {
      name: 'monto',
      label: 'Monto'
    },
    {
      name: 'observacion',
      label: 'Observación'
    }
  ];
  return (
    <div className="w-full">
      {asRole !== 'driver' && (
        <Formik<IDriverSettlementMovimiento>
          initialValues={{ monto: '', observacion: '' }}
          validationSchema={Yup.object({
            observacion: Yup.string().required('Requerido'),
            monto: Yup.string().required('Requerido')
          })}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="flex items-center gap-4 border-b-2 border-dashed border-gray-100 pb-8">
              <Field
                name="monto"
                label="Monto *"
                variant="outlined"
                component={TextField}
                margin="dense"
              />
              <Field
                name="observacion"
                label="Observación *"
                variant="outlined"
                multiline
                component={TextField}
                fullWidth
                margin="dense"
              />
              <Button
                type="submit"
                variant="contained"
                color="green"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                Crear
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <div className="w-full">
      <Table
        data={data}
        options={{
          search: false,
          sort: false,
          print: false,
          download: false,
          filter: false,
          viewColumns: false
        }}
        title={''}
        columns={columns}
      />
      </div>
    </div>
  );
};
