import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import React from 'react';
import { Table } from 'shared/components';
import { ClientBusinessPartialModel } from '../models/ClientBusiness';

interface Props {
  clients: ClientBusinessPartialModel[];
  delete: (cliente: ClientBusinessPartialModel) => void;
  restore: (cliente: ClientBusinessPartialModel) => void;
  onClientModifyButton: (id: number) => React.ReactNode;
}

const ClientBusinessTable = (props: Props) => {
  const verifyAction = useVerifyAction();
  const columns = [
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: false
      }
    },
    {
      name: 'businessName',
      label: 'Razon Social',
      options: {
        filter: false
      }
    },
    {
      name: 'fantasyName',
      label: 'Nombre fantasía',
      options: {
        filter: false
      }
    },
    {
      name: 'cuit',
      label: 'Cuit',
      options: {
        filter: false
      }
    },
    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,

        customBodyRenderLite: (index: number) =>
          props.clients[index].isDeleted ? (
            <Button onClick={() => props.restore(props.clients[index])}>
              Restaurar
            </Button>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
              }}
            >
              {props.onClientModifyButton(props.clients[index].id)}
              <Button
                onClick={() =>
                  verifyAction({
                    title: '¡Estás por eliminar un cliente!',
                    body: 'Esta acción va a eliminar al cliente. ¿Estás seguro?',
                    onAccept: () => props.delete(props.clients[index])
                  })
                }
                shape="circle"
                color="red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )
      }
    }
  ];

  const data = props.clients.map((x) => ({
    ...x,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          print: false,
          onDownload: () => {
            alert('IMPRIMIR');
            return false;
          }
        }}
        title={'Clientes CC'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
export default ClientBusinessTable;
