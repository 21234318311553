import { Button } from '@vadiun/react-components';
import { Table, TableColumn } from '@vadiun/react-datatable';
import { LiquidacionModel } from '../models/Liquidacion';

interface Props {
  data: LiquidacionModel[];
  setSelectedRows: (x: LiquidacionModel[]) => void;
  selectedLiquidaciones: LiquidacionModel[];
  onDeleteReservations: (id: string) => void;
  onDeleteLiquidacion: (id: string) => void;
}

const LiquidacionTable = (props: Props) => {
  const data = props.data.map((res) => ({
    ...res,
    clientName: res.client.name,
    datetime: res.datetime.format('DD/MM/YYYY')
  }));

  const columns: TableColumn<typeof data[0]>[] = [
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'clientName',
      label: 'Cliente'
    },
    {
      name: 'datetime',
      label: 'Fecha'
    },
    {
      name: 'total_transfer_price',
      label: 'Total viaje'
    },

    {
      name: 'total_expenses',
      label: 'Total gastos'
    },
    {
      name: 'total_price',
      label: 'Precio final'
    },
    {
      label: 'Acciones',
      name: 'id',

      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="space-y-1">
            <Button
              variant="light"
              className="w-full"
              color="green"
              onClick={() =>
                props.onDeleteReservations(String(props.data[index].id))
              }
            >
              Modificar
            </Button>
            <Button
              variant="light"
              className="w-full"
              color="red"
              onClick={() =>
                props.onDeleteLiquidacion(String(props.data[index].id))
              }
            >
              Borrar
            </Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          sort: false,
          selectableRows: 'multiple',
          downloadOptions: {
            filename: 'liquidaciones'
          },
          rowsSelected: props.selectedLiquidaciones.map((liq) =>
            data.findIndex((d) => d.id === liq.id)
          ),
          selectToolbarPlacement: 'none',
          onRowSelectionChange: (_, selection) => {
            props.setSelectedRows(
              selection.map(({ dataIndex }) => props.data[dataIndex])
            );
          }
        }}
        title={''}
        columns={columns}
      />
    </div>
  );
};
export default LiquidacionTable;
