import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@vadiun/react-components';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import { Moment } from 'moment';
import React from 'react';
import LocationDetails from './LocationDetails';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  reservation: {
    reservationDatetime: Moment;
    arrivalDateTime: Moment;
    advanceHours: number;
    preAssignedDriver?: ChoferPartialModel | null;
    client: {
      name: string;
    };
    locations: {
      address: DomicilioModel;
      observation: string;
      passengers: {
        name: string;
        phone: string | null;
      }[];
    }[];
    estimated_prices?: {
      kilometers: number;
    } | null;
  };
  footer?: React.ReactNode;
}

const ViajeTrasladoResumenModal = ({
  isOpen,
  handleClose,
  reservation,
  footer
}: Props) => {
  const data = {
    ...reservation,
    schedule: {
      arrival: reservation.reservationDatetime,
      exit: reservation.reservationDatetime
        .clone()
        .add(-reservation.advanceHours, 'hours'),
      end: reservation.arrivalDateTime
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      size="md"
      title={data.client.name}
      body={
        <div>
          <div className="px-8 pt-4">
            <LocationDetails locations={data.locations} />
            <div className="flex justify-center space-x-16 mt-8 border-gray-200 border-dashed border-t-2  pt-8">
              <div className="flex space-x-4 mb-8">
                <div>
                  <p className="title mr-2">
                    {data.schedule.exit.format('HH:mm')}
                  </p>
                  <p className="text-xs text-gray-400 ">
                    {data.schedule.exit.format('DD/MM')}
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-gray-500"
                />
                <div>
                  <p className="title col-span-1 mr-2">
                    {data.schedule.arrival.format('HH:mm')}
                  </p>
                  <p className="text-xs text-gray-400 col-span-1">
                    {data.schedule.arrival.format('DD/MM')}
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-gray-500"
                />
                <div>
                  <p className="title col-span-1 mr-2">
                    {data.schedule.end.format('HH:mm')}
                  </p>
                  <p className="text-xs text-gray-400 col-span-1">
                    {data.schedule.end.format('DD/MM')}
                  </p>
                </div>
              </div>
              {data.preAssignedDriver && (
                <div className="flex flex-col items-center">
                  <span>
                    #{data.preAssignedDriver.codigo} -{' '}
                    {data.preAssignedDriver.nombreCompleto}
                  </span>
                  <span className="text-xs text-gray-500">
                    Chofer pre-asignado
                  </span>
                </div>
              )}
              {data.estimated_prices ? (
                <div className="flex flex-col items-center">
                  <span>{data.estimated_prices.kilometers} km.</span>
                  <span className="text-xs text-gray-500">Estimación</span>
                </div>
              ) : null}
            </div>
          </div>
          {footer}
        </div>
      }
    />
  );
};

export default ViajeTrasladoResumenModal;
