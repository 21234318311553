import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableColumn } from '@vadiun/react-components';
import React from 'react';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { mapVehiculoCategoriaToIconColor } from '../../../vehiculos/models/VehiculoCategoria';
import { ChoferPartialModel } from '../../models/Chofer';

interface Props {
  choferes: ChoferPartialModel[];
  onChoferModifyButton: (id: number) => React.ReactNode;
}

const ChoferTable = ({ choferes, onChoferModifyButton }: Props) => {
  const columns: TableColumn<ChoferPartialModel>[] = [
    {
      name: 'codigo',
      label: 'Código',
      options: {
        filterType: 'textField'
      }
    },
    {
      name: 'foto',
      label: 'Foto',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => {
          return (
            <div className="relative inline-block">
              <FontAwesomeIcon
                icon={faMedal}
                className="absolute top-0 right-1"
                size="2x"
                color={mapVehiculoCategoriaToIconColor(
                  choferes[index].categoria
                )}
              />
              <img
                src={choferes[index].foto}
                className="h-24 w-24 rounded-lg object-cover object-center"
                alt=""
              />
            </div>
          );
        }
      }
    },
    {
      name: 'nombreCompleto',
      label: 'Nombre',
      options: {
        filter: false
      }
    },
    {
      name: 'tienePosnet',
      label: 'Posnet',
      options: {
        filter: false,
        customBodyRender: (tienePosnet: boolean) =>
          tienePosnet ? (
            <div className="mt-3 w-1/2 rounded-md bg-green-100 p-1 text-center text-xs font-semibold text-green-400">
              Si
            </div>
          ) : (
            <div className="mt-3 w-1/2 rounded-md bg-red-100 p-1 text-center text-xs font-semibold text-red-400">
              No
            </div>
          )
      }
    },
    {
      name: 'vehiculo',
      label: 'Permiso MC',
      options: {
        filter: false,
        customBodyRender: (vehiculo: ChoferPartialModel['vehiculo']) => {
          const status = {
            yes: (
              <div className="mt-3 w-min rounded-md bg-green-100 px-3 py-1 text-center text-xs font-semibold text-green-400">
                Si
              </div>
            ),
            no: (
              <div className="mt-3 w-min rounded-md bg-red-100 px-3 py-1 text-center text-xs font-semibold text-red-400">
                No
              </div>
            )
          };
          return vehiculo.tieneMicrocentro ? status.yes : status.no;
        }
      }
    },
    {
      name: 'vehiculo',
      label: 'Foto',
      options: {
        filter: false,
        customBodyRender: (vehiculo: ChoferPartialModel['vehiculo']) => (
          <div className="relative inline-block">
            <FontAwesomeIcon
              icon={faMedal}
              className="absolute top-0 right-1"
              size="2x"
              color={mapVehiculoCategoriaToIconColor(vehiculo.categoria)}
            />
            <img
              src={vehiculo.foto}
              className="h-24 w-24 rounded-lg object-cover object-center"
              alt=""
            />
          </div>
        )
      }
    },
    {
      name: 'vehiculo',
      label: 'Auto',
      options: {
        filter: false,
        customBodyRender: (vehiculo: ChoferPartialModel['vehiculo']) => (
          <div className="flex flex-col">
            <p className="text-sm font-medium text-gray-700">
              {vehiculo.modelo.nombre + '-' + vehiculo.modelo.fabricante.nombre}
            </p>
            <p className="text-xs font-medium text-gray-400">
              {vehiculo.patente}
            </p>
          </div>
        )
      }
    },
    {
      name: 'turnoDomicilio',
      label: 'Dom. Turno',
      options: {
        filter: false,
        customBodyRender: (
          turnoDomicilio: ChoferPartialModel['turnoDomicilio']
        ) => {
          return (
            <div className="flex flex-col">
              <p className="text-sm font-medium text-gray-700">
                {turnoDomicilio.localidad}
              </p>
              <p className="text-xs font-medium text-gray-400">
                {createDomicilioText(turnoDomicilio)}
              </p>
            </div>
          );
        }
      }
    },
    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRender: (id: number) => (
          <div style={{ display: 'flex' }}>{onChoferModifyButton(id)}</div>
        )
      }
    }
  ];

  const data = choferes.map((x) => ({
    ...x,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          print: false,
          download: false
        }}
        title={'Choferes'}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default ChoferTable;
