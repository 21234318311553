import React from 'react';
import VehiculoModeloForm from './VehiculoModeloForm';
import { VehiculoModeloFormSchema } from '../models/VehiculoModeloFormSchema';
import { Card } from 'shared/components';
import { VehiculoFabricanteModel } from '../../fabricanteVehiculo/models/VehiculoFabricante';

interface Props {
  editar: (VehiculoModeloValues: VehiculoModeloFormSchema, reset) => void;
  initialValues: VehiculoModeloFormSchema;
  fabricantes: VehiculoFabricanteModel[];
}
function VehiculoModeloEdit({ editar, initialValues, fabricantes }: Props) {
  return (
    <Card title="Editar Modelo" className="w-full">
      <div className="p-8">
        <VehiculoModeloForm
          onSubmit={editar}
          initialValues={initialValues}
          fabricantes={fabricantes}
        />
      </div>
    </Card>
  );
}

export default VehiculoModeloEdit;
