// usePageVisibility.js
import { useEffect, useState } from 'react';

export function usePollingInterval(interval: number) {
  const [pollingInterval, setPollingInterval] = useState<number | null>(
    interval
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPollingInterval(null); // Stop polling when the page is not visible
      } else {
        setPollingInterval(interval); // Resume polling when the page is in focus
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { pollingInterval, setPollingInterval };
}
