import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { CostCenterFormType } from './CostCenterFormik';
import CostCenterFormik from './CostCenterFormik';

interface Props {
  onSubmit: (value: CostCenterFormType, reset) => void;
  initialValues?: CostCenterFormType;
}

function CostCenterForm({
  onSubmit,
  initialValues = CostCenterFormik.initialValues
}: Props) {
  return (
    <Formik<CostCenterFormType>
      initialValues={initialValues}
      validationSchema={CostCenterFormik.schema}
      enableReinitialize
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
    >
      {({ isSubmitting }) => (
        <Form className="w-full flex flex-col gap-6">
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CostCenterForm;
