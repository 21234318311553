import { useViajesTraslado } from 'app/viaje/services';
import moment from 'moment';
import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { TransferReservationSingleFormType } from '../forms/single/TransferReservationSingleFormType';
import { isTransferReservationPrivate } from './isTransferReservationPrivate';
import { useTransferReservationFormRepository } from './TransferReservationFormRepository';

interface ProviderInterface {
  reservation: (TransferReservationSingleFormType & { id: number }) | undefined;
  copy(id: number): void;
  edit(id: number): void;
}

const TransferReservationSingleStoreContext =
  React.createContext<ProviderInterface>({} as ProviderInterface);

const getTrasladoFromLocalStorage = ():
  | (TransferReservationSingleFormType & { id: number })
  | undefined => {
  const reservationString = localStorage.getItem('reservationCopy');
  if (reservationString === '' || reservationString === null) return;
  const reservationParsed = JSON.parse(
    reservationString
  ) as TransferReservationSingleFormType & { id: number };
  return {
    ...reservationParsed,
    fechaYHoraDeLlegada: moment(reservationParsed.fechaYHoraDeLlegada)
  };
};

export const TransferReservationSingleStoreProvider: FC<any> = ({
  children
}) => {
  const formRepo = useTransferReservationFormRepository();
  const ViajeTrasladoService = useViajesTraslado();
  const history = useHistory();
  const [reservation, setReservation] = useState<
    (TransferReservationSingleFormType & { id: number }) | undefined
  >(getTrasladoFromLocalStorage());

  const saveToLocalStorage = async (id: number) => {
    const formReservation = await formRepo.get(id);
    setReservation({ ...formReservation, id });
    localStorage.setItem('reservationCopy', JSON.stringify(formReservation));
  };

  const copy = async (id: number) => {
    const reservation = await ViajeTrasladoService.get(id);
    if (isTransferReservationPrivate(reservation)) {
      history.push(`/main/viajes/privado/copiar/${id}`);
    } else {
      await saveToLocalStorage(id);
      history.push('/main/viajes/copiar');
    }
  };

  const edit = async (id: number) => {
    const reservation = await ViajeTrasladoService.get(id);

    if (isTransferReservationPrivate(reservation)) {
      history.push(`/main/viajes/privado/modificar/${id}`);
    } else {
      await saveToLocalStorage(id);
      history.push('/main/viajes/editar');
    }
  };

  const value = {
    copy,
    edit,
    reservation
  };

  return (
    <TransferReservationSingleStoreContext.Provider value={value}>
      {children}
    </TransferReservationSingleStoreContext.Provider>
  );
};

export const useTransferReservationStore = () => {
  const context = useContext(TransferReservationSingleStoreContext);
  if (context.copy === undefined) {
    throw new Error(
      'useTransferReservationStore must be used inside TransferReservationSingleStoreProvider'
    );
  }
  return context;
};
