import { ViajeTrasladoEstado } from 'app/viaje/models';
import moment, { Moment } from 'moment';

export interface TransferReservationState {
  type: ViajeTrasladoEstado;
  datetime: Moment;
  user: {
    id: number;
    name: string;
  };
  observation?: string;
}

export const transferReservationStateBuilder = {
  fromBackEnd: (state): TransferReservationState => ({
    ...state,
    datetime: moment(state.datetime)
  })
};
