import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { RateGeneralFormType } from './RateFormik';
import RateFormik from './RateFormik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';

interface Props {
  onSubmit: (value: RateGeneralFormType, reset?: () => void) => Promise<void>;
  initialValues?: RateGeneralFormType;
}

function RateGeneralForm({
  onSubmit,
  initialValues = RateFormik.initialValues
}: Props) {
  return (
    <Formik<RateGeneralFormType>
      initialValues={initialValues}
      validationSchema={RateFormik.general.schema}
      enableReinitialize
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
    >
      {({ isSubmitting, touched, errors }) => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <Field
            name="baseCommission"
            label="Comisión base *"
            variant="outlined"
            className="col-span-3"
            type="number"
            step="0.01"
            component={TextField}
            fullWidth
          />

          <Field
            name="surplusCommission"
            label="Comisión excedente*"
            variant="outlined"
            className="col-span-3"
            type="number"
            step="0.01"
            component={TextField}
            fullWidth
          />
          <Field
            name="limitPriceBaseCommission"
            label="Limite comisión base*"
            variant="outlined"
            className="col-span-3"
            type="number"
            step="0.01"
            component={TextField}
            fullWidth
          />
          <Field
            name="outSitePrice"
            label="Precio Out Site*"
            variant="outlined"
            className="col-span-3"
            type="number"
            step="0.01"
            component={TextField}
            fullWidth
          />
          <Field
            name="nightPlusPercentage"
            label="Porcentaje plus nocturno*"
            variant="outlined"
            className="col-span-3"
            type="number"
            step="0.01"
            component={TextField}
            fullWidth
          />
          <Field
            name="expirationDate"
            label="Vencimiento*"
            variant="outlined"
            className="col-span-3"
            format="DD/MM/YYYY"
            inputVariant="outlined"
            component={KeyboardDatePicker}
            fullWidth
          />
          <Field
            className="col-span-3"
            name={`minNumberKilometers`}
            label="Km mínimos*"
            variant="outlined"
            type="number"
            component={TextField}
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Continuar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RateGeneralForm;
