import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Button, Modal } from "@vadiun/react-components";

interface Props {
    isOpen: boolean,
    onClose: () => void,
    forceEnd: (reservationId: number, endObservation: string) => void,
    reservationId: number
}

function EndViajeTrasladoModal({
      isOpen,
      onClose,
      forceEnd,
      reservationId
  }: Props) {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            body={
                <Formik
                  initialValues={{endObservation: ""}}
                  onSubmit={async (values, formikHelpers) => {
                      forceEnd(reservationId!, values.endObservation)
                      formikHelpers.resetForm()
                  }}
                  validationSchema={Yup.object().shape({
                      endObservation: Yup.string().required('Ingrese una observacion')
                  })}
                >
                  {() => (
                    <Form>
                        <Field
                          name="endObservation"
                          component={TextField}
                          fullWidth
                          placeholder="Ingrese el motivo de finalización"
                        />
                        <div className="flex justify-end mt-8">
                          <Button type="submit" variant="contained">
                              Guardar
                          </Button>
                        </div>
                    </Form>
                  )}
                </Formik>
            }
        />
    );
}

export default EndViajeTrasladoModal;
