import { Dialog, Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCustomTablePagination } from 'shared/hooks/useCustomTablePagination';
import { DriverSettlementsMovimientosTableComponent } from '../components/DriverSettlementMovimientosTableComponent';
import {
  DriverSettlementListFiltersType,
  DriverSettlementsListFiltersComponent
} from '../components/DriverSettlementsListFiltersComponent';
import { DriverSettlementsTableComponent } from '../components/DriverSettlementsTableComponent';
import { useDriverSettlementRepository } from '../services/driverSettlementsRepository';

export const DriverSettlementsPage = ({
  asRole = 'admin'
}: {
  asRole: 'driver' | 'admin';
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [settlementId, setSettlementId] = useState<number | undefined>(
    undefined
  );
  const [filters, setFilters] = useState<DriverSettlementListFiltersType>({
    biller: '',
    dateFrom: moment().subtract(1, 'week'),
    dateTo: moment(),
    driverCode: ''
  });
  const settlementRepo = useDriverSettlementRepository();
  const settlementsPagination = useCustomTablePagination((params) => {
    return settlementRepo.getAll(params, filters);
  });
  const printMutation = useSuperMutation(settlementRepo.printAll, {
    onMutationFinish: (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data as any]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  });
  const movimientosQuery = useSuperQuery(
    async () => {
      if (settlementId === undefined) {
        return undefined;
      }
      return settlementRepo.getSettlementMovimientos(settlementId);
    },
    undefined,
    [settlementId]
  );
  const createMovimientoMutation = useSuperMutation(
    settlementRepo.createSettlementMovimientos,
    {
      onMutationFinish() {
        movimientosQuery.reload();
      }
    }
  );

  useEffect(() => {
    settlementsPagination.reload();
  }, [filters]);

  const onFilterChange = async (filters: DriverSettlementListFiltersType) => {
    setFilters(filters);
    setShowFilters(false);
  };
    
  return (
    <>
      <PageHeader
        title="Liquidaciones chofer"
        subtitle=""
        toolbar={
          <div className="flex gap-4">
            <Button
              variant="light"
              color="green"
              onClick={() => printMutation.mutate(filters)}
            >
              Imprimir
            </Button>
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtros
            </Button>
          </div>
        }
      />

      <PageContainer>
        <DriverSettlementsTableComponent
          settlementsPagination={settlementsPagination}
          settlementDetailLink={(id, driverCode) => {
            if (asRole === 'driver') {
              return (
                <Link to={`/main/driver/me/settlements/${id}`}>
                  <Button variant="light">Detalle</Button>
                </Link>
              );
            }
            return (
              <Link
                to={`/main/driver/settlements/${id}?driverCode=${driverCode}`}
              >
                <Button variant="light">Detalle</Button>
              </Link>
            );
          }}
          openMovimientosButton={(id: number) => (
            <Button
              variant="light"
              color="green"
              onClick={() => setSettlementId(id)}
            >
              Movimientos
            </Button>
          )}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <DriverSettlementsListFiltersComponent
            asRole={asRole}
            values={filters}
            onSubmit={onFilterChange}
          />
        </div>
      </Drawer>
      <Dialog
        maxWidth="md"
        open={settlementId !== undefined}
        onClose={() => setSettlementId(undefined)}
      >
        <div className="p-8">
          <DriverSettlementsMovimientosTableComponent
            onSubmit={async (values) =>
              createMovimientoMutation.mutate({
                settlementId: settlementId!,
                ...values
              })
            }
            asRole={asRole}
            movimientos={movimientosQuery.data ?? []}
          />
        </div>
      </Dialog>
    </>
  );
};
