import { ClientType } from 'app/client/main/models/ClientType';
import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';

export interface ClientPrivateModel {
  id: number;
  name: string;
  lastName: string;
  alias: string | null;
  phone1: string | null;
  phone2: string | null;
  email: string | null;
  address: DomicilioModel;
  type: ClientType;
  code: string;
}

export interface ClientPrivateModelBack {
  id: number;
  first_name: string;
  last_name: string;
  alias: string | null;
  phone_1: string | null;
  phone_2: string | null;
  email: string | null;
  address: unknown;
  code: string;
}

export const clientprivateBuilder = {
  fromBackEnd: (clientprivate: ClientPrivateModelBack): ClientPrivateModel => {
    return {
      id: clientprivate.id,
      name: clientprivate.first_name,
      lastName: clientprivate.last_name,
      alias: clientprivate.alias,
      phone1: clientprivate.phone_1,
      phone2: clientprivate.phone_2,
      email: clientprivate.email,
      address: domicilioBuilder.fromBackEnd(clientprivate.address),
      type: ClientType.private,
      code: clientprivate.code
    };
  }
};
