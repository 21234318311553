import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { TollModel } from '../models/Toll';

interface Props {
  tolls: TollModel[];
  selectToEdit: (x: TollModel) => void;
  delete: (x: TollModel) => void;
  restore: (x: TollModel) => void;
}

const TollTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const columns = [
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'price',
      label: 'Precio',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => props.selectToEdit(props.tolls[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
              onClick={() =>
                verifyAction({
                  title: 'Estás por eliminar un peaje',
                  body: 'Ésta acción va a eliminar el peaje. ¿Estás seguro?',
                  onAccept: () => props.delete(props.tolls[index])
                })
              }
              shape="circle"
              color="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ];

  const data = props.tolls;

  return (
    <div className="w-full">
      <Table title={'Peajes'} data={data} columns={columns} />
    </div>
  );
};

export default TollTable;
