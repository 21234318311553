import React from 'react';
import VehiculoFabricanteForm from './VehiculoFabricanteForm';
import { VehiculoFabricanteFormSchema } from '../models/VehiculoFabricanteFormSchema';
import { Card } from 'shared/components';

interface Props {
  editar: (
    VehiculoFabricanteValues: VehiculoFabricanteFormSchema,
    reset
  ) => void;
  initialValues: VehiculoFabricanteFormSchema;
}
function VehiculoFabricanteEdit({ editar, initialValues }: Props) {
  return (
    <Card title="Editar Fabricante" className="w-full">
      <div className="p-8">
        <VehiculoFabricanteForm
          onSubmit={editar}
          initialValues={initialValues}
        />
      </div>
    </Card>
  );
}

export default VehiculoFabricanteEdit;
