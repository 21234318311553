import { Moment } from 'moment';

const DirectionsService = new google.maps.DirectionsService();
export const getGoogleDirections = (recorrido: {
  datetime: Moment;
  puntos: { lat: number; lng: number }[];
}): Promise<google.maps.DirectionsResult> => {
  const primerUbicacion = recorrido.puntos[0];
  const ultimaUbicacion = recorrido.puntos[recorrido.puntos.length - 1];
  const ubicacionesIntermedias = recorrido.puntos.slice(
    1,
    recorrido.puntos.length - 1
  );
  return new Promise((res, rej) => {
    DirectionsService.route(
      {
        origin: new google.maps.LatLng(primerUbicacion),
        destination: new google.maps.LatLng(ultimaUbicacion),
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: ubicacionesIntermedias.map((punto) => ({
          location: new google.maps.LatLng(punto),
          stopover: true
        }))
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          res(result);
        } else {
          rej(new Error(`error fetching directions ${result}`));
        }
      }
    );
  });
};
