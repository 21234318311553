import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikAddress from 'shared/components/FormikAddress';
import { createClientPrivateFormInitialValues } from './ClientPrivateFormInitialValues';
import { ClientPrivateFormType } from './ClientPrivateFormType';
import { clientprivateFormValidation } from './ClientPrivateFormValidation';

interface Props {
  onSubmit: (value: ClientPrivateFormType) => Promise<void>;
  initialValues?: ClientPrivateFormType;
}

export function ClientPrivateForm({
  onSubmit,
  initialValues = createClientPrivateFormInitialValues()
}: Props) {
  return (
    <Formik<ClientPrivateFormType>
      initialValues={initialValues}
      validationSchema={clientprivateFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          <Field
            name="lastName"
            label="Apellido *"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          <Field
            name="alias"
            label="Alias"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          <Field
            name="code"
            label="Código *"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          <Field
            name="email"
            label="Email *"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          <Field
            name="phone1"
            label="Teléfono 1"
            variant="outlined"
            className="col-span-1"
            component={TextField}
            fullWidth
          />
          <Field
            name="phone2"
            label="Teléfono 2"
            variant="outlined"
            className="col-span-1"
            component={TextField}
            fullWidth
          />

          <Field
            name="address"
            label="Domicilio"
            variant="outlined"
            className="col-span-6"
            component={FormikAddress}
            fullWidth
          />

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
