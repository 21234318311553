// @flow
import * as React from 'react';
import {DriverShift} from "../models/DriverShift";
import {Button, Chip} from "@vadiun/react-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

type Props = {
    driverShift: DriverShift,
    deny: (id:number) => void,
    approve: (id:number) => void,
};

export function DriverShiftInfo({driverShift, deny, approve}: Props) {

    return (
        <div className="w-full px-6 py-4">
            <div className="flex justify-between space-x-4">
                <p><strong>#{driverShift.driverCode } </strong>{driverShift.driverName}</p>

                <Chip variant="contained" color="orange" size="sm">{driverShift.closeType}</Chip>

            </div>
            <div className="mt-2 flex justify-between">
                <p className="text-xs"><strong className="text-sm">Apertura: </strong>{driverShift.openDateTime.format('DD/MM HH:mm')}</p>
                <p className="text-xs"><strong className="text-sm">Cierre: </strong>{driverShift.closeDateTime?.format('DD/MM HH:mm')}</p>
            </div>
            {(driverShift.closeMinutes !== null && driverShift.closeMinutes > 0)  && <p className="text-xs"><strong className="text-sm">Minutos: </strong>{driverShift.closeMinutes}</p>}
            <p>{driverShift.observation}</p>
            <div className="flex justify-between mt-4">
                <Button variant="light" color="green" onClick={() => approve(driverShift.id)}>
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
                <Button variant="light" color="red" onClick={() => deny(driverShift.id)}>
                    <FontAwesomeIcon icon={faTimes} />

                </Button>
            </div>
        </div>
    );
};
