import {
  VehiculoModeloBackModel,
  vehiculoModeloBackModelToModel,
  VehiculoModeloCRUD,
  VehiculoModeloModel,
  vehiculoModeloModelToBack
} from '../models/VehiculoModelo';
import { httpClient } from 'shared/services/http/httpClient';
import { notDeleted } from 'shared/helpers/deletedHandler';

const useVehiculoModelos = () => {
  const addVehiculoModelo = (x: VehiculoModeloCRUD) => {
    const apiValue = vehiculoModeloModelToBack(x);
    return httpClient.post('vehicle-models', apiValue);
  };

  const removeVehiculoModelo = (modelo: VehiculoModeloModel) => {
    return httpClient.delete('vehicle-models/' + modelo.id);
  };

  const editVehiculoModelo = (modelo: VehiculoModeloCRUD) => {
    const apiValue = vehiculoModeloModelToBack(modelo);
    return httpClient.put('vehicle-models/' + modelo.id, apiValue);
  };

  const getVehiculoModelosWithDeleted = async (): Promise<
    VehiculoModeloModel[]
  > => {
    const modelos = await httpClient.get<VehiculoModeloBackModel[]>(
      'vehicle-models'
    );
    return modelos.map(vehiculoModeloBackModelToModel);
  };

  const getVehiculoModelos = async () =>
    (await getVehiculoModelosWithDeleted()).filter(notDeleted);

  const restoreVehiculoModelo = (modelo: VehiculoModeloModel) => {
    return httpClient.put('vehicle-models/' + modelo.id + '/restore');
  };

  return {
    addVehiculoModelo,
    removeVehiculoModelo,
    editVehiculoModelo,
    getVehiculoModelos,
    getVehiculoModelosWithDeleted,
    restoreVehiculoModelo
  };
};

export default useVehiculoModelos;
