import {
  faCalendarCheck,
  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { Moment } from 'moment';
import { Table } from 'shared/components';
import { ChoferDeshabilitacion } from '../../models/Chofer';

interface Props {
  deshabilitaciones: ChoferDeshabilitacion[];
  cancelar: (x: ChoferDeshabilitacion) => void;
  aceptarDeshabilitacion: (x: ChoferDeshabilitacion) => void;
  rechazarDeshabilitacion: (x: ChoferDeshabilitacion) => void;
}

const ChoferTableDeshabilitaciones = ({
  deshabilitaciones,
  cancelar,
  aceptarDeshabilitacion,
  rechazarDeshabilitacion
}: Props) => {
  const verifyAction = useVerifyAction();
  const find = (id: number) => deshabilitaciones.find((x) => x.id === id);

  const findAndDelete = (id: number) => {
    verifyAction({
      onAccept: () => cancelar(find(id)!),
      title: 'Estas por terminar una deshabilitacion!',
      body: 'Esta accion va a va a habilitar al chofer para trabajar. ¿Estas seguro?'
    });
  };

  const columns = [
    {
      name: 'fechaYHoraInicio',
      label: 'Inicio',
      options: {
        customBodyRender: (fechaYHoraInicio: Moment, { rowData }) => (
          <div className="flex flex-col">
            <p className="title">{fechaYHoraInicio.format('DD/MM/YYYY')}</p>
            <p className="subtitle">{fechaYHoraInicio.format('HH') + ' hs'}</p>
          </div>
        )
      }
    },
    {
      name: 'fechaYHoraFin',
      label: 'Fin',
      options: {
        customBodyRender: (fechaYHoraFin: Moment, { rowData }) => (
          <div className="flex flex-col">
            <p className="title">{fechaYHoraFin.format('DD/MM/YYYY')}</p>
            <p className="subtitle">{fechaYHoraFin.format('HH') + ' hs'}</p>
          </div>
        )
      }
    },
    {
      name: 'motivo',
      label: 'Motivo',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'finalizada',
      label: 'Finalizada',
      options: {
        customBodyRender: (finalizada: boolean, { rowData }) =>
          finalizada ? (
            <Chip color="green">Si</Chip>
          ) : (
            <Chip color="red">No</Chip>
          )
      }
    },
    {
      name: 'aceptada',
      label: 'Aceptada',
      options: {
        customBodyRender: (aceptada: boolean | null, { rowData }) =>
          aceptada !== null ? (
            aceptada ? (
              <FontAwesomeIcon icon={faCheckCircle} color="green" size="lg" />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} color="red" size="lg" />
            )
          ) : (
            ''
          )
      }
    },
    {
      name: 'observacion',
      label: 'Observacion',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      label: 'Actions',
      name: 'id',
      options: {
        customBodyRender: (id: number, { rowData }) => (
          <div style={{ display: 'flex' }}>
            {rowData[4] === null && (
              <>
                <Button
                  onClick={() => {
                    aceptarDeshabilitacion(find(id)!);
                  }}
                  shape="circle"
                  color="green"
                >
                  <FontAwesomeIcon icon={faCheck} size="lg" />
                </Button>
                <Button
                  onClick={() => {
                    rechazarDeshabilitacion(find(id)!);
                  }}
                  shape="circle"
                  color="red"
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </Button>
              </>
            )}
            {rowData[4] !== null && rowData[4] && !rowData[3] && (
              <Button
                onClick={() => {
                  findAndDelete(id);
                }}
                shape="circle"
                color="green"
                disabled={rowData[3]}
              >
                <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
              </Button>
            )}
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Deshabilitaciones'}
        data={deshabilitaciones}
        columns={columns}
      />
    </div>
  );
};
export default ChoferTableDeshabilitaciones;
