import React from 'react';
import Card from 'shared/components/Card/Card';
import { Bank } from '../models/Bank';
import BillerForm, { BillerFormType } from './BillerForm/BillerForm';

interface Props {
  editar: (proveedorValues: BillerFormType, reset) => void;
  initialValues: BillerFormType;
  banks: Bank[];
}
function ProveedorEdit({ editar, initialValues, banks }: Props) {
  return (
    <Card title="Editar Facturador" className="w-full">
      <div className="p-8">
        <BillerForm
          onSubmit={editar}
          banks={banks}
          initialValues={initialValues}
        />
      </div>
    </Card>
  );
}

export default ProveedorEdit;
