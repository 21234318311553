import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTableQuery } from '@vadiun/react-datatable';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import useViajesTraslado from '../../viajeTraslado/services/ViajeTrasladoService';
import { ViajeTrasladoBorradoresTable } from '../components/ViajeTrasladoBorradoresTable';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { ClientBusinessPartialModel } from 'app/client/clientBusiness/models/ClientBusiness';

const ViajeTrasladoBorradoresPage = () => {
  /*
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    Mucho de este componente esta copiado en ViajeTrasladoMapPage.
    Si se hace algun cambio revisar que no se tenga que hacer tambien en ViajeTrasladoMapPage.

    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    */
  const ViajeTrasladoService = useViajesTraslado();
  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useFilters({
    dateFrom: moment().hour(0).minutes(0),
    dateTo: moment().hour(23).minutes(59).add(1, 'day'),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    client_type: 'BusinessClient',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    pre_assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false,
    sent_reservations_platform: false,
    not_sent_reservations_platform: false,
    has_platform_id: false,
    is_event: false
  });

  const travelsDraftQuery = useTableQuery(
    ['reservas', 'pendientes-asignacion'],
    () =>
      ViajeTrasladoService.getDraftsReservations(
        new URLSearchParams('page=1&page_size=200')
      ),
    { defaultItemsPerPage: 200 }
  );

  const assignMutation = useSuperMutation(ViajeTrasladoService.assignDriver, {
    showSpinner: true
  });

  const { getAll } = useClientBusiness();
  const [bussinessClients, setBusinessClient] = useState<
    ClientBusinessPartialModel[] | undefined
  >(undefined);

  useEffect(() => {
    getAll().then((res) => {
      setBusinessClient(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTravel = async (reservation: number, driver: number) => {
    await assignMutation
      .mutate(reservation, driver)
      .catch((e) => console.log(e));
    travelsDraftQuery.refetch();
  };

  return (
    <div>
      <div className="sticky top-0 right-0 left-0" style={{ zIndex: 101 }}>
        <PageHeader title="Traslados en borrador" />
      </div>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
            filterByClient
            filterByAssignedDriver={false}
            filterByPreAssignedDriver
          />
        </div>
      </Drawer>
      <PageContainer lessMargin>
        <div className="w-full">
          <ViajeTrasladoBorradoresTable
            tableQuery={travelsDraftQuery}
            bussinessClients={bussinessClients}
          />
        </div>
      </PageContainer>
    </div>
  );
};

export default ViajeTrasladoBorradoresPage;
