import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { useState } from 'react';
import PageContainer from '../../../layout/components/PageContainer';
import PageHeader from '../../../layout/components/PageHeader';
import BillerCreate from '../components/BillerCreate';
import BillerEdit from '../components/BillerEdit';
import { BillerFormType } from '../components/BillerForm/BillerForm';
import BillerTable from '../components/BillerTable';
import { BillerCRUD, BillerModel } from '../models/BillerModel';
import useBiller from '../services/useBiller';

function ProveedorCrudPage() {
  const BillerService = useBiller();
  const billersQuery = useSuperQuery(BillerService.getBillersWithDeleted);
  const { data: bancos } = useSuperQuery(BillerService.getBanks);
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'edit'>(
    'table'
  );
  const [editingBiller, setEditingBiller] = useState<BillerModel>();
  const editBillerMutation = useSuperMutation(BillerService.editBiller, {
    messages: {
      success: { message: 'El facturador fue editado con éxito' }
    }
  });
  const addBillerMutation = useSuperMutation(BillerService.addBiller, {
    messages: {
      success: { message: 'El facturador fue creado con éxito' }
    }
  });
  const removeBillerMutation = useSuperMutation(BillerService.removeBiller);
  const restoreBillerMutation = useSuperMutation(BillerService.restoreBiller);

  function billerFormToModel(form: BillerFormType): BillerCRUD {
    return {
      ...form,
      cuit: Number(form.cuit)
    };
  }

  function billerModelToForm(biller: BillerModel): BillerFormType {
    return biller;
  }

  async function edit(form: BillerFormType, reset) {
    const biller = billerFormToModel(form);
    biller.id = editingBiller!.id;
    await editBillerMutation.mutate(biller);
    reset();
    setVisiblePage('table');
    billersQuery.reload();
  }

  async function create(form: BillerFormType, reset) {
    const biller = billerFormToModel(form);
    await addBillerMutation.mutate(biller);
    reset();
    billersQuery.reload();
  }

  async function remove(biller: BillerModel) {
    await removeBillerMutation.mutate(biller.id);
    billersQuery.reload();
  }
  async function restore(biller: BillerModel) {
    await restoreBillerMutation.mutate(biller.id);
    billersQuery.reload();
  }

  function selectToEdit(biller: BillerModel) {
    setEditingBiller(biller);
    setVisiblePage('edit');
  }
  return (
    <>
      <PageHeader
        title="Facturadores"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de facturadores'
            : visiblePage === 'create'
            ? 'Nuevo Facturador'
            : 'Editar Facturador'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Facturador
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <BillerTable
            billers={billersQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && (
          <BillerCreate create={create} banks={bancos!} />
        )}
        {visiblePage === 'edit' && editingBiller && (
          <BillerEdit
            editar={edit}
            banks={bancos!}
            initialValues={billerModelToForm(editingBiller)}
          />
        )}
      </PageContainer>
    </>
  );
}

export default ProveedorCrudPage;
