import {
  Dialog,
  Divider,
  Drawer,
  IconButton,
  MenuItem
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import {
  useSuperMutation,
  useSuperQuery,
  useVerifyAction
} from '@vadiun/react-hooks-legacy';
import { WorkingDriverInfo } from 'app/chofer/components/WorkingDriverInfo';
import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import useChoferes from 'app/chofer/services/ChoferService';
import ViajeTrasladoResumenModal from 'app/viaje/viajeTraslado/viajeTraslado/components/ViajeTrasladoResumenModal';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTableQuery } from '@vadiun/react-datatable';
import { useTransferReservationStore } from '../../viajeTraslado/services/TransferReservationSingleStore';
import useViajesTraslado from '../../viajeTraslado/services/ViajeTrasladoService';
import { TransferReservationPendingModel } from '../../pendiente/models/TransferReservationPending';
import { DriverShift } from 'app/chofer/models/DriverShift';
import { TransferReservationManagmentModel } from '../../gestion/models/TransferReservationManagment';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import CancelViajeTrasladoModal from '../../viajeTraslado/components/CancelViajeTrasladoModal';
import { DriverShiftInfo } from 'app/chofer/components/DriverShiftInfo';
import { ViajeTrasladoCoordinarTable } from '../components/ViajeTrasladoCoordinarTable';
import {
  DriverRecomended,
  DriversRecomended,
  MonitorDriversInfo
} from '../types/Driver';
import ConfirmDriverRecomended from '../components/DriverRecomended/ConfirmDriverRecomended';
import AssignOrPreAssignDriver from '../components/DriverRecomended/AssignOrPreAssignDriver';
import FilterDrivers, {
  DriversFiltersType
} from '../components/DriverRecomended/FilterDrivers';
import {
  buildQueryString,
  getValidDataFilterDriversRecomended
} from 'shared/helpers';
import ChoferSearchModal from 'app/chofer/components/ChoferSearchModal';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { ViajeTrasladoTipoServicio } from '../../viajeTraslado/models';
import HeaderPage from '../components/HeaderPage';
import { useNotificationsContext } from 'shared/context/notificationContext';

enum StateActionSelected {
  PRE_ASSIGN = 'PRE-ASSIGN',
  ASSIGN = 'ASSIGN',
  NONE = 'NONE'
}

const ViajeTrasladoCoordinarPage = () => {
  /*
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    Mucho de este componente esta copiado en ViajeTrasladoMapPage.
    Si se hace algun cambio revisar que no se tenga que hacer tambien en ViajeTrasladoMapPage.

    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    */
  const transferReservationHelpers = useTransferReservationStore();

  const ViajeTrasladoService = useViajesTraslado();

  const verifyAction = useVerifyAction();

  const driverRepository = useChoferes();

  const [showFilters, setShowFilters] = useState(false);
  const [showFiltersDriversRecomended, setShowFiltersDriversRecomended] =
    useState<boolean>(false);

  const [isLoadingDriverRecomended, setIsLoadingDriverRecomended] =
    useState(false);

  const [driversRecomended, setDriversRecomended] = useState<
    DriversRecomended | undefined
  >();

  const [idTravelSelected, setIdTravelSelected] = useState<
    number | undefined
  >();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [
    showModalAssignOrPreassignDriver,
    setShowModalAssignOrPreassignDriver
  ] = useState<boolean>(false);

  const [preAssignDriverOpen, setPreAssignDriverOpen] = useState(false);
  const [assignDriverOpen, setAssignDriverOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState<
    TransferReservationPendingModel | undefined
  >(undefined);

  const [assignOrPreassignAction, setAssignOrPreassignAction] =
    useState<StateActionSelected>(StateActionSelected.NONE);

  const [isLoadingDrivers, setLoadingDrivers] = useState<boolean>(false);

  const [isLoadingAssignOrPreAssignDriver, setLoadingAssignOrPreAssignDriver] =
    useState<boolean>(false);

  const [driverSelected, setDriverSelected] = useState<
    DriverRecomended | undefined
  >();

  const onChangeDriverSelected = (driver: DriverRecomended) => {
    setDriverSelected(driver);
  };

  const onOpenModalAssignDriver = () => {
    setShowModal(false);
    setAssignOrPreassignAction(StateActionSelected.ASSIGN);
  };

  const onOpenModalPreassignDriver = () => {
    setShowModal(false);
    setAssignOrPreassignAction(StateActionSelected.PRE_ASSIGN);
  };

  const onCloseModalAssignOrPreassign = () => {
    setAssignOrPreassignAction(StateActionSelected.NONE);
    setShowModal(true);
  };

  const preAssignMutation = useSuperMutation(
    ViajeTrasladoService.preAssignDriver,
    { showSpinner: false }
  );

  const assignMutation = useSuperMutation(ViajeTrasladoService.assignDriver, {
    showSpinner: false
  });

  const driversQuery = useSuperQuery(driverRepository.getDriversThatCanWork, {
    showSpinner: false,
    automaticFetch: false
  });

  const [isLoadingDriversCanWork, setIsLoadingDriversCanWork] =
    useState<boolean>(false);
  const [driversCanWork, setDriversCanWork] = useState<ChoferPartialModel[]>(
    []
  );

  const loadDriversCanWork = async () => {
    setIsLoadingDriversCanWork(true);
    const driversCanWork = await driverRepository.getDriversThatCanWork();
    setDriversCanWork(driversCanWork);
    setIsLoadingDriversCanWork(false);
  };

  useEffect(() => {
    loadDriversCanWork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preAssignDriver = async (driverId: number) => {
    try {
      setLoadingAssignOrPreAssignDriver(true);
      await preAssignMutation.mutate(idTravelSelected ?? 0, driverId);
      setAssignOrPreassignAction(StateActionSelected.NONE);
      pendientesAsignacionQuery.refetch();
    } finally {
      setLoadingAssignOrPreAssignDriver(false);
    }
  };

  const preAssignDriverByAction = async (driver: ChoferPartialModel) => {
    await preAssignMutation.mutate(selectedReservation!.id, driver.id);
    setPreAssignDriverOpen(false);
    pendientesAsignacionQuery.refetch();
  };

  const assignDriverByAction = async (driver: ChoferPartialModel) => {
    await assignMutation.mutate(selectedReservation!.id, driver.id);
    setAssignDriverOpen(false);
    driversAmountQuery.reload();
    loadDriversCanWork();
    pendientesAsignacionQuery.refetch();
  };

  const onRefreshTable = async () => {
    await pendientesAsignacionQuery.refetch();
  };

  const [statusActive, setStatusActive] = useState<string>('FREE');

  const removePreAssignedDriver = async (reservation: number) => {
    await removePreAssignedMutation.mutate(reservation);
    pendientesAsignacionQuery.refetch();
  };

  const assignDriver = async (driverId: number) => {
    try {
      setLoadingAssignOrPreAssignDriver(true);
      await assignMutation.mutate(idTravelSelected ?? 0, driverId);
      setAssignOrPreassignAction(StateActionSelected.NONE);
      driversAmountQuery.reload();
      loadDriversCanWork();
      pendientesAsignacionQuery.refetch();
    } finally {
      setLoadingAssignOrPreAssignDriver(false);
    }
  };

  const startTravel = async (reservation: number, driver: number) => {
    await assignMutation
      .mutate(reservation, driver)
      .catch((e) => console.log(e));
    pendientesAsignacionQuery.refetch();
  };

  const onAcceptAssignOrPreAssignDriver = async () => {
    if (assignActionSelected) {
      assignDriver(driverSelected?.id ?? 0);
    } else {
      preAssignDriver(driverSelected?.id ?? 0);
    }
  };

  useEffect(() => {
    if (assignOrPreassignAction === StateActionSelected.NONE) {
      setShowModalAssignOrPreassignDriver(false);
      return;
    }
    setShowModalAssignOrPreassignDriver(true);
  }, [assignOrPreassignAction]);

  useEffect(() => {
    if (driverSelected === undefined) {
      setShowModal(false);
      return;
    }
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverSelected]);

  const [filters, setFilters] = useFilters({
    dateFrom: moment().hour(0).minutes(0),
    dateTo: moment().hour(23).minutes(59).add(1, 'day'),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    client_type: 'BusinessClient',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    pre_assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false,
    sent_reservations_platform: false,
    not_sent_reservations_platform: false,
    has_platform_id: false,
    is_event: false
  });

  const [driverDetail, setDriverDetail] = useState<{
    isOpen: boolean;
    details: ChoferTrabajando | undefined;
  }>({ isOpen: false, details: undefined });

  const [driverShift, setDriverShift] = useState<{
    isOpen: boolean;
    details: DriverShift | undefined;
  }>({ isOpen: false, details: undefined });

  const [reservationDetail, setReservationDetail] = useState<{
    isOpen: boolean;
    details: TransferReservationPendingModel | undefined;
  }>({ isOpen: false, details: undefined });

  const [cancelReservationModal, setCancelReservationModal] = useState<{
    isOpen: boolean;
    reservationId: number | undefined;
  }>({ isOpen: false, reservationId: undefined });

  const pendientesAsignacionQuery = useTableQuery(
    [
      'reservas',
      'pendientes-asignacion',
      {
        ...filters,
        dateFrom: filters.dateFrom.format(),
        dateTo: filters.dateTo.format()
      }
    ],
    (params: URLSearchParams) =>
      ViajeTrasladoService.getPendingAssigment(params, filters),
    { defaultItemsPerPage: 200 }
  );

  const driversAmountQuery = useSuperQuery(
    driverRepository.getWorkingDriversAmountPerState,
    {
      showSpinner: false,
      automaticFetch: false
    }
  );

  // const driversPendingShiftsQuery = useSuperQuery(
  //   () =>
  //     driverRepository.getShifts({
  //       driver: undefined,
  //       openFrom: moment().subtract(1, 'years'),
  //       openTo: moment().add(1, 'year'),
  //       approval: 'pending'
  //     }),
  //   { showSpinner: false, automaticFetch: false }
  // );

  const removePreAssignedMutation = useSuperMutation(
    ViajeTrasladoService.removePreAssignedDriver,
    { showSpinner: true }
  );

  const closeShiftMutation = useSuperMutation(driverRepository.closeShift, {
    showSpinner: true
  });

  const forceBreakMutation = useSuperMutation(driverRepository.forceBreak, {
    showSpinner: true
  });

  const cancelReservationMutation = useSuperMutation(
    ViajeTrasladoService.cancelReservation,
    { showSpinner: true }
  );

  const approveShiftMutation = useSuperMutation(
    driverRepository.approveShiftClosure,
    { showSpinner: true }
  );
  const denyShiftMutation = useSuperMutation(
    driverRepository.denyShiftClosure,
    { showSpinner: true }
  );

  const notifyDelayMutation = useSuperMutation(
    ViajeTrasladoService.notificarDemora,
    {
      showSpinner: true
    }
  );

  useEffect(() => {
    // Que traiga la informacion del monitor de choferes luego de que la tabla cargó. Sino se hacen demasiadas request al mismo tiempo
    if (pendientesAsignacionQuery.isLoading) return;
    reloadDriverMonitorInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendientesAsignacionQuery.isLoading]);

  const cancel = async (reservationId: number, cancelObservation: string) => {
    setCancelReservationModal({ reservationId: undefined, isOpen: false });
    await cancelReservationMutation.mutate(reservationId, cancelObservation);
    pendientesAsignacionQuery.refetch();
  };

  const copyReservation = async (
    reservation: TransferReservationManagmentModel
  ) => {
    transferReservationHelpers.copy(reservation.id);
  };

  const closeShift = async (id: number) => {
    await closeShiftMutation.mutate(id);
    reloadDriverMonitorInfo();
  };

  const forceBreak = async (id: number) => {
    await forceBreakMutation.mutate(id);
    reloadDriverMonitorInfo();
  };

  const denyShiftChange = async (id: number) => {
    await denyShiftMutation.mutate(id);
    reloadDriverMonitorInfo();
  };

  const aproveShiftChange = async (id: number) => {
    await approveShiftMutation.mutate(id);
    reloadDriverMonitorInfo();
  };

  const loadDriverRecomended = useCallback(
    async (idTravel: number, filters?: any) => {
      setIsLoadingDriverRecomended(true);
      const drivers = await driverRepository.getDriversRecomended(
        idTravel,
        filters
      );
      setDriversRecomended(drivers);
      setIsLoadingDriverRecomended(false);

      if (filters) {
        pendientesAsignacionQuery.refetch();
      }
    },
    [driverRepository, pendientesAsignacionQuery]
  );

  const [filterDriverRecomended, setFilterDriverRecomended] =
    useState<DriversFiltersType>({
      departure_date: new Date().toISOString(),
      is_type_service_vip: false,
      is_type_service_executive: false,
      is_type_service_utilitarian: false,
      is_type_service_messaging_utility: false,
      is_type_service_all: false,
      driver_free: false,
      driver_busy: false
    });

  const onFilterDriversRecomended = async (data) => {
    setFilterDriverRecomended(data);
  };

  useEffect(() => {
    if (idTravelSelected === undefined) {
      return;
    }

    setShowFiltersDriversRecomended(false);
    const newFilters = buildQueryString(
      getValidDataFilterDriversRecomended(filterDriverRecomended)
    );
    loadDriverRecomended(idTravelSelected, newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDriverRecomended]);

  useEffect(() => {
    if (idTravelSelected === undefined) {
      return;
    }
    setShowFiltersDriversRecomended(false);
    setDriversRecomended(undefined);
    loadDriverRecomended(idTravelSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTravelSelected]);

  const assignActionSelected =
    assignOrPreassignAction === StateActionSelected.ASSIGN;
  const preAssignActionSelected =
    assignOrPreassignAction === StateActionSelected.PRE_ASSIGN;

  const initialValuesFilterDriver = pendientesAsignacionQuery.data?.find(
    (travel) => travel.id === idTravelSelected
  );

  const [monitorDriversInfo, setMonitorDriversInfo] =
    useState<MonitorDriversInfo>();

  const [drivers, setDrivers] = useState<ChoferTrabajando[]>([]);

  const onSelectFreeDrivers = useCallback(async () => {
    setLoadingDrivers(true);
    setDrivers(await driverRepository.getWorkingDrivers({ free: true }));
    setLoadingDrivers(false);
  }, [driverRepository]);

  const onSelectBusyDrivers = useCallback(async () => {
    setLoadingDrivers(true);
    setDrivers(await driverRepository.getWorkingDrivers({ busy: true }));
    setLoadingDrivers(false);
  }, [driverRepository]);

  const onSelectUnavailableDrivers = useCallback(async () => {
    setLoadingDrivers(true);
    setDrivers(await driverRepository.getWorkingDrivers({ onBrake: true }));
    setLoadingDrivers(false);
  }, [driverRepository]);

  const onSelectTurnOffDrivers = useCallback(async () => {
    setLoadingDrivers(true);
    setDrivers(
      await driverRepository.getWorkingDrivers({ workdayNotStarted: true })
    );
    setLoadingDrivers(false);
  }, [driverRepository]);

  const onLoadMonitorDriversInfo = async () => {
    setMonitorDriversInfo(
      await driverRepository.getWorkingDriversAmountPerState()
    );
  };

  const onLoadDriversByStatus = async () => {
    if (statusActive === 'FREE') {
      await onSelectFreeDrivers();
    }

    if (statusActive === 'BUSY') {
      await onSelectBusyDrivers();
    }

    if (statusActive === 'UNAVAILABLE') {
      await onSelectUnavailableDrivers();
    }

    if (statusActive === 'ON_BRAKE') {
      await onSelectTurnOffDrivers();
    }
  };

  const reloadDriverMonitorInfo = async () => {
    await onLoadMonitorDriversInfo();
  };

  useEffect(() => {
    if (statusActive === '') {
      return;
    }
    onLoadDriversByStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusActive]);

  console.log('monitorDriversInfo page ->', monitorDriversInfo);

  const { setDriversRequests } = useNotificationsContext();

  const fetchRequestDrivers = async () => {
    const response = await driverRepository.getShifts({
      driver: undefined,
      openFrom: moment().subtract(1, 'days').hours(0).minutes(0).seconds(0),
      openTo: moment().add(1, 'days').hours(23).minutes(59).seconds(59),
      approval: 'pending'
    });
    setDriversRequests(
      response.data.map((d: DriverShift) => ({
        ...d,
        close_type: d.closeType,
        open_date_time: d.openDateTime,
        open_type: d.openType,
        driver_code: d.driverCode,
        driver_name: d.driverName
      }))
    );
  };

  useEffect(() => {
    fetchRequestDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        className="sticky top-0 right-0 left-0 flex flex-col"
        style={{
          zIndex: 101,
          background: '#EAEDF5',
          // border: '1px solid rgba( 255, 255, 255, 0.18 )',
          backdropFilter: 'blur( 4px )'
        }}
      >
        <PageHeader
          title="Traslados pendientes"
          toolbar={
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtros
            </Button>
          }
        />
        <div className="flex justify-between md:flex-col lg:flex-col xl:flex-row 2xl:flex-row gap-1 shadow-sm ">
          <HeaderPage
            setDriverDetail={setDriverDetail}
            drivers={drivers}
            isLoadingDrivers={isLoadingDrivers}
            monitorDriversInfo={
              monitorDriversInfo ?? {
                free: 0,
                busy: 0,
                onBrake: 0,
                workdayNotStarted: 0,
                workdayStarted: 0
              }
            }
            onFilterByStatusDriver={(state) => {
              setStatusActive(state);
            }}
            reloadDriverMonitorInfo={async () => {
              await reloadDriverMonitorInfo();
              await onLoadDriversByStatus();
            }}
            statusActive={statusActive}
          />
        </div>
      </div>

      <ChoferSearchModal
        driversQuery={{
          ...driversQuery,
          data: driversCanWork,
          isLoading: isLoadingDriversCanWork
        }}
        onSelect={(chofer) => {
          setPreAssignDriverOpen(false);
          verifyAction({
            title: '¿Está seguro que desea pre-asignar este viaje?',
            onDeny: () => setPreAssignDriverOpen(false),
            onAccept: () => preAssignDriverByAction(chofer)
          });
        }}
        isOpen={preAssignDriverOpen}
        handleClose={() => setPreAssignDriverOpen(false)}
      />
      <ChoferSearchModal
        driversQuery={{
          ...driversQuery,
          data: driversCanWork,
          isLoading: isLoadingDriversCanWork
        }}
        onSelect={(chofer) => {
          setAssignDriverOpen(false);
          verifyAction({
            title: '¿Está seguro que desea asignar este viaje?',
            onDeny: () => setAssignDriverOpen(false),
            onAccept: () => assignDriverByAction(chofer)
          });
        }}
        isOpen={assignDriverOpen}
        handleClose={() => setAssignDriverOpen(false)}
      />
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
            filterByClient
            filterByAssignedDriver={false}
            filterByPreAssignedDriver
          />
        </div>
      </Drawer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFiltersDriversRecomended}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFiltersDriversRecomended(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <div
            className="px-4 py-3 flex flex-col"
            style={{
              width: '550px',
              height: '95dvh'
            }}
          >
            <div className="flex justify-between">
              <p className="text-xl">Filtrar sugeridos</p>
              {idTravelSelected ? (
                <p className="px-2 py-1 border-2 rounded-lg text-sm text-gray-400">
                  #{idTravelSelected}
                </p>
              ) : null}
            </div>
            <FilterDrivers
              showFilter={showFiltersDriversRecomended}
              initialValues={{
                ...filterDriverRecomended,
                is_type_service_executive:
                  initialValuesFilterDriver?.serviceType ===
                  ViajeTrasladoTipoServicio.EJECUTIVO,
                is_type_service_messaging_utility:
                  initialValuesFilterDriver?.serviceType ===
                  ViajeTrasladoTipoServicio.UTILITARIO_MENSAJERIA,
                is_type_service_utilitarian:
                  initialValuesFilterDriver?.serviceType ===
                  ViajeTrasladoTipoServicio.UTILITARIO,
                is_type_service_vip:
                  initialValuesFilterDriver?.serviceType ===
                  ViajeTrasladoTipoServicio.VIP,
                departure_date:
                  initialValuesFilterDriver?.departureDatetime.toISOString() ??
                  new Date().toISOString()
              }}
              onFilterDriversRecomended={onFilterDriversRecomended}
              onCloseFilterDriver={() => {
                setShowFiltersDriversRecomended(false);
              }}
            />
          </div>
        </div>
      </Drawer>
      <PageContainer lessMargin>
        <div className="w-full">
          <ViajeTrasladoCoordinarTable
            onRefreshTable={onRefreshTable}
            isLoadingDriverRecomended={isLoadingDriverRecomended}
            driversRecomended={driversRecomended ?? []}
            idTravelSelected={idTravelSelected}
            onCloseFilters={() => setShowFiltersDriversRecomended(false)}
            tableQuery={pendientesAsignacionQuery}
            setIdTravelSelected={setIdTravelSelected}
            onShowDriverDetails={(driver) => {
              setDriverDetail({
                isOpen: true,
                details: drivers!.find((d) => d.id === driver.id)!
              });
            }}
            onLocationsDetails={(reservation) =>
              setReservationDetail({ isOpen: true, details: reservation })
            }
            onStartTravel={(selectedReservation: number, chofer: number) => {
              verifyAction({
                title: '¿Está seguro que desea abrir este viaje?',
                onAccept: () => startTravel(selectedReservation!, chofer!)
              });
            }}
            onEditReservation={(travel) =>
              transferReservationHelpers.edit(travel.id)
            }
            onReservationCopy={copyReservation}
            onCancelReservation={(
              reservation: TransferReservationPendingModel
            ) => {
              setCancelReservationModal({
                isOpen: true,
                reservationId: reservation.id
              });
            }}
            onReservationShowDetails={(id) => (
              <Link to={'/main/Viaje/traslado/detalle/' + id}>
                <MenuItem>Ver detalle</MenuItem>
              </Link>
            )}
            onDelayNotify={(reservation) =>
              notifyDelayMutation.mutate(reservation.id)
            }
            onChangeDriverSelected={onChangeDriverSelected}
            onShowFiltersDriversRecomended={() =>
              setShowFiltersDriversRecomended(true)
            }
            onPreAssignDriver={(travel: TransferReservationPendingModel) => {
              setSelectedReservation(travel);
              setPreAssignDriverOpen(true);
            }}
            onRemovePreAssigned={(selectedReservation: number) => {
              verifyAction({
                title: '¿Está seguro que desea remover chofer pre asignado?',
                onAccept: () => removePreAssignedDriver(selectedReservation!)
              });
            }}
            onAssignDriver={(travel: TransferReservationPendingModel) => {
              setSelectedReservation(travel);
              setAssignDriverOpen(true);
            }}
          />
        </div>
      </PageContainer>
      {reservationDetail.details && (
        <ViajeTrasladoResumenModal
          handleClose={() =>
            setReservationDetail({ isOpen: false, details: undefined })
          }
          isOpen={reservationDetail.isOpen}
          reservation={reservationDetail.details}
        />
      )}
      <Dialog
        open={driverDetail.isOpen}
        onClose={() => setDriverDetail({ details: undefined, isOpen: false })}
        maxWidth="xs"
      >
        {driverDetail.details && (
          <WorkingDriverInfo
            driver={driverDetail.details}
            isOpened={false}
            forceBreak={(id) => {
              setDriverDetail({ details: undefined, isOpen: false });
              forceBreak(id);
            }}
            closeShift={(id) => {
              setDriverDetail({ details: undefined, isOpen: false });
              verifyAction({
                onAccept: () => closeShift(id),
                body: '¿Está seguro que desea cerrar el turno de este chofer?'
              });
            }}
          />
        )}
      </Dialog>
      <CancelViajeTrasladoModal
        isOpen={cancelReservationModal.isOpen}
        onClose={() =>
          setCancelReservationModal({
            reservationId: undefined,
            isOpen: false
          })
        }
        cancel={cancel}
        reservationId={cancelReservationModal.reservationId!}
      />
      <Dialog
        open={driverShift.isOpen}
        onClose={() => setDriverShift({ details: undefined, isOpen: false })}
        maxWidth="sm"
      >
        {driverShift.details && (
          <DriverShiftInfo
            driverShift={driverShift.details}
            deny={(id) => {
              setDriverShift({ details: undefined, isOpen: false });
              verifyAction({
                onAccept: () => denyShiftChange(id),
                body: '¿Está seguro que desea rechazar esta solicitud?'
              });
            }}
            approve={(id) => {
              setDriverShift({ details: undefined, isOpen: false });
              verifyAction({
                onAccept: () => aproveShiftChange(id),
                body: '¿Está seguro que desea aprobar esta solicitud?'
              });
            }}
          />
        )}
      </Dialog>

      <ConfirmDriverRecomended
        driverSelected={driverSelected ?? ({} as DriverRecomended)}
        isLoading={false}
        isOpen={showModal}
        onCloseModal={() => {
          setDriverSelected(undefined);
        }}
        onAssignDriver={onOpenModalAssignDriver}
        onPreAssignDriver={onOpenModalPreassignDriver}
      />
      <AssignOrPreAssignDriver
        textOkButton={
          preAssignActionSelected
            ? 'Sí, Pre-asignar'
            : assignActionSelected
            ? 'Sí, Asignar'
            : ''
        }
        title={
          preAssignActionSelected
            ? 'Pre-asignación'
            : assignActionSelected
            ? 'Asignación'
            : ''
        }
        description={
          preAssignActionSelected
            ? `¿Seguro que desea pre-asignar este viaje a ${driverSelected?.code} - ${driverSelected?.name} ?`
            : `¿Seguro que desea asignar este viaje a ${driverSelected?.code} - ${driverSelected?.name} ?`
        }
        isLoading={isLoadingAssignOrPreAssignDriver}
        isOpen={showModalAssignOrPreassignDriver}
        onAccept={onAcceptAssignOrPreAssignDriver}
        onCloseModal={onCloseModalAssignOrPreassign}
      />
    </div>
  );
};

export default ViajeTrasladoCoordinarPage;
