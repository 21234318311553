export enum ChoferEstado {
    AVAILABLE = 'Disponible',
    NOT_AVAILABLE = 'No disponible',
    ON_BREAK = 'En break'
}

export function driverStates() {
    return Object.values(ChoferEstado).sort();
}

export function driverStateColors(state: string): string {
    switch (state)
    {
        case ChoferEstado.AVAILABLE:
            return 'green';
        case ChoferEstado.NOT_AVAILABLE:
            return 'red';
        case ChoferEstado.ON_BREAK:
            return 'orange'
        default:
            return "blue"
    }
}
