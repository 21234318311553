import { httpClient } from 'shared/services/http/httpClient';
import { RateFastUpdateFormType } from '../components/rateFastUpdateForm/RateFastUpdateFormType';
import {
  rateBuilder,
  RateModel,
  RateModelCRUD,
  ratePartialBuilder,
  RatePartialModel
} from '../models/RateModel';

const useRate = () => {  
  function create(rate: RateModelCRUD) {
    return httpClient.post(`rates`, rateBuilder.toBackEnd(rate));
  }
  function edit(rate: RateModelCRUD) {
    return httpClient.put(`rates/${rate.id}`, rateBuilder.toBackEnd(rate));
  }
  function onDeleteRate(id: string) {
    return httpClient.delete(`business-clients/rates/${id}`);
  }
  async function getAllByClient(
    clientBusinessId: number
  ): Promise<RatePartialModel[]> {
    const res = await httpClient.get(
      `business-clients/${clientBusinessId}/rates`
    );
    return res.map(ratePartialBuilder.fromBackEnd);
  }

  async function getAllCurrentRates(): Promise<RateModel[]> {
    const res = await httpClient.get(`/business-clients/current-rates`);
    return res.map(rateBuilder.fromBackEnd);
  }

  async function get(id: number): Promise<RateModel> {
    const res = await httpClient.get(`rates/${id}`);
    return rateBuilder.fromBackEnd(res);
  }

  const rateFastUpdate = (
    values: RateFastUpdateFormType & { clientId: number }
  ) =>
    httpClient.post(`business-clients/${values.clientId}/rates/fast-store`, {
      expiration_date: values.expirationDate.format('YYYY-MM-DD'),
      percentage: values.porcentage
    });

  return {
    create,
    getAllByClient,
    getAllCurrentRates,
    get,
    edit,
    onDeleteRate,
    rateFastUpdate
  };
};

export default useRate;
