import React from 'react';
import { TextField } from '@material-ui/core';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import { FieldProps, useField } from 'formik';
import { ComponentProps } from 'react';
import GooglePlacesInput, {
  domicilioGoogleInitialValues
} from './GooglePlacesInput';
import * as Yup from 'yup';

interface Props<T>
  extends FieldProps<T>,
    ComponentProps<typeof GooglePlacesInput> {
  className: string;
  size?: 'medium' | 'small';
}

function FormikAddress({
  field,
  className,
  size = 'medium',
  ...props
}: Props<DomicilioModel>) {
  const [_, meta] = useField(field.name);
  function handleChange(dom: Partial<DomicilioModel>) {
    props.form.setFieldValue(field.name, {
      ...field.value,
      ...dom
    });
    props.form.setFieldTouched(field.name, true);
  }

  const hasError = () => {
    return !!meta.error && meta.touched;
  };

  const getError = () => {
    if (hasError()) {
      if ((meta.error as any)?.calle) {
        return (meta.error as any)?.calle;
      }
      return 'La dirección no es válida';
    }
    return '';
  };

  return (
    <div className={className}>
      <div className={'flex space-x-4 '}>
        <GooglePlacesInput
          className="w-4/6"
          variant="outlined"
          value={{
            ...field.value,
            pais: '',
            codigoPostal: ''
          }}
          fullWidth
          label={props.label || 'Domicilio'}
          onChange={handleChange}
          error={hasError()}
          helperText={getError()}
          size={size}
        />
        <div className="w-1/6">
          <TextField
            variant="outlined"
            label="Piso"
            fullWidth
            value={field.value.piso}
            onChange={(e) => handleChange({ piso: e.target.value })}
            size={size}
          />
        </div>
        <div className="w-1/6">
          <TextField
            label="Dpto."
            variant="outlined"
            fullWidth
            value={field.value.departamento}
            onChange={(e) => handleChange({ departamento: e.target.value })}
            size={size}
          />
        </div>
      </div>
    </div>
  );
}

export const domicilioInitialValues: DomicilioModel = {
  ...domicilioGoogleInitialValues,
  piso: '',
  departamento: ''
};

export const domicilioValidation = Yup.object().shape({
  latitud: Yup.number(),
  longitud: Yup.number(),
  calle: Yup.string().required('Es necesario que elija una de las opciones')
});

export default FormikAddress;
