import { ClientBusinessUserFormType } from './ClientBusinessUserFormType';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export const userFormValidation: YupShape<ClientBusinessUserFormType> =
  Yup.object().shape({
    email: Yup.string()
      .email('El email es inválido')
      .required('El email es requerido'),
    name: Yup.string().required('El nombre es requerido')
  });
