import * as faker from 'faker/locale/es';
import moment from 'moment';
import { mockedDomicilioGoogle } from 'shared/models/DomicilioGoogle';
import {
  ViajeCanalesDeIngreso,
  ViajeMinibusTipoServicio,
  ViajeMinibusModel,
  ViajeMinibusPunto,
  SolicitanteMinibusModel
} from 'app/viaje/models';

export const mockedViajeMinibus = (): ViajeMinibusModel => ({
  id: faker.random.number(),
  // cliente: mockedCliente(),
  canalIngreso: ViajeCanalesDeIngreso.ABSSA,
  plataformaId: null,
  prioridad: true,
  solicitante: {
    nombreCompleto: faker.name.findName(),
    telefono: faker.random.number(),
    mail: faker.internet.email()
  },
  tipoServicio: ViajeMinibusTipoServicio.MINIVAN,
  puntos: [mockedViajeMinibusPunto(), mockedViajeMinibusPunto()],
  horaSalida: faker.random.number(),
  fechaYHora: moment(),
  observacionCoordinacion: null
});

export function mockedViajeMinibusPunto(): ViajeMinibusPunto {
  return {
    domicilio: mockedDomicilioGoogle(),
    pasajeros: [
      {
        nombreCompleto: faker.name.findName(),
        telefono: faker.random.number(),
        mail: faker.internet.email(),
        dni: faker.random.number(),
        nacionalidad: faker.address.country()
      }
    ],
    observacion: null,
    minutosEspera: null,
    horarioDeBusqueda: null
  };
}

export function mockedSolicitante(): SolicitanteMinibusModel {
  return {
    id: faker.random.number(),
    nombreCompleto: faker.name.findName(),
    telefono: faker.random.number(),
    mail: faker.internet.email()
  };
}
