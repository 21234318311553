import { httpClient } from 'shared/services/http/httpClient';
import { User } from 'auth/models/User';

const useAuthRepository = () => {
  //const users = httpClient.get("users");

  //const permissions = httpClient.get("permissions");

  const reloadUsers = () => {};

  const login = (x: { email: string; password: string }) =>
    httpClient.post('login', x);

  const add = (user: User) => httpClient.post('users', user);

  const remove = (user: User) => httpClient.delete('users/' + user.id);

  const edit = (user: User) => httpClient.put('users/' + user.id, user);

  const forgotPassword = (email: string) =>
    httpClient.post('forgot-password', { email });

  const changePassword = (credentials: {
    email: string;
    password: string;
    token: string;
  }) =>
    httpClient.post('reset-password', {
      ...credentials,
      password_confirmation: credentials.password
    });

  return {
    login,
    add,
    remove,
    edit,
    forgotPassword,
    changePassword
  };
};

export default useAuthRepository;
