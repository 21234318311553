import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  VehiculoFormInfoGeneral,
  VehiculoFormVencimiento
} from 'app/vehiculos/components';
import { SideStepper } from '@vadiun/react-components';
import {
  VehiculoColor,
  VehiculoTipo,
  VehiculoCompaniaSeguro,
  VehiculoMunicipio,
  VehiculoFormSchema,
  vehiculoFormSchemaInitialValues,
  VehiculoModeloModel
} from 'app/vehiculos/models';
import { TitularModel } from 'app/vehiculos/titulares/models';

interface Props {
  titulares: TitularModel[];
  modelos: VehiculoModeloModel[];
  colores: VehiculoColor[];
  tipos: VehiculoTipo[];
  companiasDeSeguro: VehiculoCompaniaSeguro[];
  municipios: VehiculoMunicipio[];
  create: (vehiculoFormSchema: VehiculoFormSchema, reset) => void;
}

function VehiculoCreate({
  titulares,
  modelos,
  tipos,
  colores,
  municipios,
  companiasDeSeguro,
  create
}: Props) {
  const [currentStep, setCurrentStep] = useState<'general' | 'vencimientos'>(
    'general'
  );
  const [vehiculoFormValues, setVehiculoFormValues] =
    useState<VehiculoFormSchema>(
      vehiculoFormSchemaInitialValues(titulares, modelos)
    );

  const steps = {
    general: (
      <VehiculoFormInfoGeneral
        onSubmit={(values) => {
          setVehiculoFormValues({
            general: values,
            vencimientos: vehiculoFormValues.vencimientos
          });
          setCurrentStep('vencimientos');
        }}
        submitButtonTitle="Continuar"
        titulares={titulares}
        modelos={modelos}
        colores={colores}
        tipos={tipos}
        initialValues={vehiculoFormValues.general}
      />
    ),
    vencimientos: (
      <VehiculoFormVencimiento
        onGoBack={() => {
          setCurrentStep('general');
        }}
        onSubmit={(values) => {
          setVehiculoFormValues({
            general: vehiculoFormValues.general,
            vencimientos: values
          });
          create(
            {
              general: vehiculoFormValues.general,
              vencimientos: values
            },
            () =>
              setVehiculoFormValues(
                vehiculoFormSchemaInitialValues(titulares, modelos)
              )
          );
          setCurrentStep('general');
        }}
        submitButtonTitle="Guardar"
        municipios={municipios}
        companiaDeSeguros={companiasDeSeguro}
        initialValues={vehiculoFormValues.vencimientos}
      />
    )
  };
  return (
    <div className="flex flex-col md:flex-row bg-white w-full">
      <div className="p-8">
        <SideStepper.Container value={currentStep}>
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faUser} />}
            value={'general'}
            label="Informacion general"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faCalendarTimes} />}
            value={'vencimientos'}
            label="Vencimientos"
          />
        </SideStepper.Container>
      </div>
      <div className="border-l-2 border-gray-200 border-solid p-12 w-full">
        {steps[currentStep]}
      </div>
    </div>
  );
}

export default VehiculoCreate;
