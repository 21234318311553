export interface PassengerModel {
  id: number;
  name: string;
  phone: string | null;
  email: string | null;
  has_priority: boolean;
  dni: string | null;
  nationality: string | null;
}

export type PassengerModelCRUD = Omit<PassengerModel, 'id'> & {
  id?: number;
};

export const passengerBuilder = {
  fromBackEnd: (passenger): PassengerModel => {
    return {
      id: passenger.id,
      name: passenger.name,
      phone: passenger.phone,
      email: passenger.email,
      has_priority: passenger.hasPriority,
      dni: passenger.dni,
      nationality: passenger.nationality
    };
  },
  toBackEnd: (passenger: PassengerModelCRUD) => {
    return {
      id: passenger.id,
      name: passenger.name,
      phone: passenger.phone,
      email: passenger.email,
      has_priority: passenger.has_priority,
      dni: passenger.dni,
      nationality: passenger.nationality
    };
  }
};
