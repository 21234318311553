// @flow
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { Table, UseTableQueryResult } from '@vadiun/react-datatable';
import PageContainer from 'layout/components/PageContainer';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationRepetitiveModel } from '../models/TransferreservationRepetitiveModel';

const completeHour = (hour: number) => (hour >= 10 ? hour : `0${hour}`);

interface Props {
  tableProps: UseTableQueryResult<
    TransferReservationRepetitiveModel[],
    unknown
  >;
  onLocationsDetails: (reservation: TransferReservationRepetitiveModel) => void;
  selectToEdit: (x: TransferReservationRepetitiveModel) => void;
  onDelete: (id: number) => React.ReactNode;
}

export function ReservaTrasladoRepetitiveTable({
  tableProps,
  onLocationsDetails,
  selectToEdit,
  onDelete
}: Props) {
  const data = tableProps.data ?? [];
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'reservation_hours',
      label: 'Horarios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <span>
              {`${data[index].reservation_hours}:${completeHour(
                data[index].reservation_minutes
              )}`}
            </span>
          );
        }
      }
    },
    {
      name: 'from_date',
      label: 'Desde',
      options: {
        customBodyRenderLite: (index) => {
          return <span>{`${data[index].from_date.format('DD/MM/YYYY')}`}</span>;
        }
      }
    },
    {
      name: 'to_date',
      label: 'Hasta',
      options: {
        customBodyRenderLite: (index) => {
          return <span>{`${data[index].to_date.format('DD/MM/YYYY')}`}</span>;
        }
      }
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => data[index].client.name
      }
    },
    {
      name: 'puntos',
      label: 'Domicilios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
              {data[index].puntos.map((location, index) => (
                <div className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1 text-center">
                    {createDomicilioText(location.domicilio)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationsDetails(data[index])}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },

    {
      name: 'id',
      label: 'ID'
    },

    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex space-x-2">
            <Button
              onClick={() => selectToEdit(data[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            {onDelete(data[index].id)}
          </div>
        )
      }
    }
  ];

  return (
    <PageContainer lessMargin>
      <div className="w-full">
        <Table
          data={data}
          options={{
            elevation: 0,
            selectableRows: 'none',
            search: false,
            filter: false,
            sort: false,
            print: false,
            download: false,
            ...tableProps.serverSideOptions
          }}
          title={''}
          columns={columns}
        />
      </div>
    </PageContainer>
  );
}
