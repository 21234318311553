import { ButtonGroup } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import { ErrorMessage, Field, FieldArray, FormikProps } from 'formik';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import { StaticMap } from 'shared/components';
import { TransferReservationLocationFormModal } from '../../components/TransferReservationLocationFormModal';
import {
  TransferReservationBatchFormType,
  TransferReservationBatchTripFormType
} from './TransferReservationBatchFormType';

import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useVerifyAction } from '@vadiun/react-hooks';

interface Props {
  viaje: TransferReservationBatchTripFormType;
  pasajeros: PassengerModel[];
  formik: FormikProps<TransferReservationBatchFormType>;
  index: number;
  clonar: () => void;
  revertir: () => void;
  borrar: () => void;
  previousMinutesError?: boolean;
}

export const TransferReservationBatchTripForm = ({
  viaje,
  pasajeros,
  index,
  clonar,
  revertir,
  formik,
  borrar,
  previousMinutesError
}: Props) => {
  const verifyAction = useVerifyAction();
  let items = viaje.puntos.map((punto) => ({
    ...punto,
    id: JSON.stringify(punto)
  }));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  );

  function handleDragEnd(e) {
    const { active, over } = e;

    const passengerIsIncluded = (new_list, old_list) => {
      return old_list.some((old_el) =>
        new_list.some(
          (new_el) => new_el.nombreCompleto === old_el.nombreCompleto
        )
      );
    };

    return new Promise((resolve, reject) => {
      if (active.data.current.sortable) {
        if (!active.id !== over.id) {
          const oldIndex = items.findIndex((i) => i.id === active.id);
          const newIndex = items.findIndex((i) => i.id === over.id);

          resolve(
            formik.setFieldValue(
              `viajes.${index}.puntos`,
              arrayMove(items, oldIndex, newIndex)
            )
          );
        }
      } else {
        if (active.data.current.externalProp !== over.id) {
          const oldPointIndex = viaje.puntos.findIndex(
            (punto) =>
              JSON.stringify(punto) === active.data.current.externalProp
          );

          const newPointIndex = viaje.puntos.findIndex(
            (punto) => JSON.stringify(punto) === over.id
          );

          const passengersToMove = viaje.puntos[oldPointIndex].pasajeros;

          !passengerIsIncluded(
            passengersToMove,
            viaje.puntos[newPointIndex].pasajeros
          ) &&
            resolve(
              new Promise((resolve) => {
                resolve(
                  formik.setFieldValue(
                    `viajes.${index}.puntos[${newPointIndex}].pasajeros`,
                    [
                      ...viaje.puntos[newPointIndex].pasajeros,
                      ...passengersToMove
                    ]
                  )
                );
              }).then(() =>
                formik.setFieldValue(
                  `viajes.${index}.puntos[${oldPointIndex}].pasajeros`,
                  []
                )
              )
            );
        }
      }
    });
  }

  return (
    <div>
      <div className="flex justify-between ">
        <p className="title">Viaje #{index + 1}</p>
        <ButtonGroup color="primary">
          <Button variant="light" onClick={clonar}>
            Clonar
          </Button>
          <Button variant="light" onClick={revertir}>
            Revertir
          </Button>
          <Button variant="light" color="red" onClick={borrar}>
            Borrar
          </Button>
        </ButtonGroup>
      </div>
      <div className="mt-8 flex space-x-8">
        <div className="flex-grow">
          <div className="flex space-x-8">
            <Field
              component={KeyboardDateTimePicker}
              label="Fecha de llegada"
              name={`viajes.${index}.fechaYHoraDeLlegada`}
              ampm={false}
              setError={() => console.log('')}
              inputVariant="outlined"
              margin="normal"
              className="flex-1"
              format="DD/MM/YYYY HH:mm"
            />

            <div className="flex flex-1 flex-col">
              <Field
                component={KeyboardDateTimePicker}
                label="Tiempo previo salida"
                name={`viajes.${index}.minutosPreviosASalida`}
                ampm={false}
                error={previousMinutesError}
                inputVariant="outlined"
                margin="normal"
                className="flex-1"
                format="DD/MM/YYYY HH:mm"
              />
              {previousMinutesError && (
                <p className="m-0 text-sm text-red-500">
                  La hora de salida debe ser anterior a la hora de llegada
                </p>
              )}
            </div>
          </div>

          <FieldArray
            name={`viajes.${index}.puntos`}
            render={() => (
              <div className="flex items-center gap-5">
                <div className="flex w-full flex-col">
                  <TransferReservationLocationFormModal
                    pasajeros={pasajeros}
                    puntos={viaje.puntos}
                    onEditPuntos={(puntos) =>
                      formik.setFieldValue(`viajes.${index}.puntos`, puntos)
                    }
                  />
                </div>
              </div>
            )}
          />

          <ErrorMessage name={`viajes.${index}.puntos`}>
            {(msg) => <p className="mt-4 text-sm text-red-500">{msg}</p>}
          </ErrorMessage>
        </div>
        <div
          className={`w-1/4 transition-all delay-300 duration-300 ease-in-out hover:w-2/5 ${
            viaje.puntos?.length === 0 ? 'opacity-0' : 'opactiy-1'
          }`}
        >
          <StaticMap
            markers={viaje.puntos.map((punto) => ({
              lat: punto.domicilio.latitud,
              lng: punto.domicilio.longitud
            }))}
            size="600x600"
          />
        </div>
      </div>
    </div>
  );
};
