import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { useViajesTraslado } from 'app/viaje/services';
import { useDistanceAndPriceTransferReservationClientBusinessCalculator } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { Card } from 'shared/components';
import { CreatedReservationsModal } from '../components/CreatedReservationsModal';
import { ReservaTrasladoFormWrapper } from '../components/ViajeTrasladoFormWrapper';
import TransferReservationBatchForm from '../forms/batch/TransferReservationBatchForm';
import {
  transferReservationBatchFormBuilder,
  TransferReservationBatchFormType
} from '../forms/batch/TransferReservationBatchFormType';
import { deleteDraftTravel } from 'app/viaje/services/draft';

interface TransferReservationClientBusinessBatchPageProps {
  isDraftPage?: boolean;
}

export const TransferReservationClientBusinessBatchPage = ({
  isDraftPage
}: TransferReservationClientBusinessBatchPageProps) => {
  const [createdReservations, setCreatedReservations] = useState<
    number[] | null
  >(null);
  const [modalOpen, setModalOpen] = useState(false);

  const ViajeTrasladoService = useViajesTraslado();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientBusinessCalculator();
  const addTrasladoMutation = useSuperMutation(
    ViajeTrasladoService.addViajesTraslado,
    {
      showSpinner: true,
      onMutationFinish: (response) => {
        setCreatedReservations(response.data.map((el) => el.id));
      }
    }
  );

  async function createViajeTraslado(
    trasladoFormValues: TransferReservationBatchFormType,
    clientBusinessId: number
  ) {
    const ViajeTraslado =
      transferReservationBatchFormBuilder.toModel(trasladoFormValues);
    await addTrasladoMutation
      .mutate(ViajeTraslado, clientBusinessId)
      .then(() => {
        setModalOpen(true);
        if (isDraftPage) {
          const draftTravel = localStorage.getItem('travel-selected-draft');
          if (draftTravel) {
            const travel = JSON.parse(draftTravel);
            deleteDraftTravel(travel._id);
          }
          localStorage.removeItem('travel-selected-draft');
        }
      });
  }

  function estimatePrice(
    trasladoFormValues: TransferReservationBatchFormType,
    clientBusinessId
  ) {
    const estimationsPromise = trasladoFormValues.viajes.map((viaje) =>
      calculateDistanceAndCost(
        {
          arrivalDate: viaje.fechaYHoraDeLlegada,
          hasOutSite: trasladoFormValues.hasOutSite,
          points: viaje.puntos.map((p) => ({
            lat: p.domicilio.latitud,
            lng: p.domicilio.longitud,
            minutesWaiting: Number(p.espera)
          })),
          serviceType: trasladoFormValues.tipoServicio,
          rateName: trasladoFormValues.fixedRate?.name,
          payWithCash: trasladoFormValues.pay_with_cash
        },
        clientBusinessId
      )
    );
    return Promise.all(estimationsPromise);
  }

  return (
    <>
      <PageHeader title="Viajes" subtitle={'Nuevo traslado'} />
      <PageContainer>
        <Card className="w-full" title="Nuevo traslado">
          <div className="p-8">
            <ReservaTrasladoFormWrapper<TransferReservationBatchFormType>
              onSubmit={createViajeTraslado}
              calculateDistanceAndCost={estimatePrice}
              isDraftPage={isDraftPage}
            >
              {(params) => (
                <TransferReservationBatchForm
                  isDraftPage={isDraftPage}
                  {...params}
                />
              )}
            </ReservaTrasladoFormWrapper>
          </div>
          <CreatedReservationsModal
            open={modalOpen}
            setOpen={setModalOpen}
            reservations={createdReservations}
          />
        </Card>
      </PageContainer>
    </>
  );
};
