import {
  createTransferReservationBaseInitialValues,
  createTrasferReservationBaseLocationInitialValues
} from '../../../viajeTraslado/forms/base/TransferReservationBaseFormInitialValues';
import {
  TransferReservationBatchFormType,
  TransferReservationBatchLocationFormType,
  TransferReservationBatchTripFormType
} from './TransferReservationBatchFormType';
import moment from 'moment';

export const createTransferReservationBatchLocationInitialValues: () => TransferReservationBatchLocationFormType =
  createTrasferReservationBaseLocationInitialValues;

export const createTransferReservationBatchTripInitialValues =
  (): TransferReservationBatchTripFormType => ({
    fechaYHoraDeLlegada: moment(),
    minutosPreviosASalida: moment(),
    puntos: []
  });

export const createTransferReservationBatchInitialValues =
  (): TransferReservationBatchFormType => ({
    ...createTransferReservationBaseInitialValues(),
    viajes: [createTransferReservationBatchTripInitialValues()]
  });
