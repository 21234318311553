import {
  Dialog,
  DialogTitle,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { ClientBusinessPartialModel } from '../../models/ClientBusiness';
import useClientBusiness from '../../services/useClientBusiness';

interface Props {
  open: boolean;
  onClose: () => void;
  user: { id: number; name: string; email: string };
  updateCompany: (user_id: string, company_id: string) => Promise<any>;
}

export const ClientBusinessUserUpdateCompany = ({
  open,
  onClose,
  user,
  updateCompany
}: Props) => {
  const repo = useClientBusiness();

  const onSubmit = (company) => {
    onClose();
    updateCompany(String(user.id), company);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <div className="flex flex-col gap-2 px-2 py-1">
        <DialogTitle style={{ paddingBottom: 0 }}>
          Cambiar usuario de empresa
        </DialogTitle>
        <Formik
          initialValues={{ company: { id: '' } }}
          onSubmit={async (values) => {
            onSubmit(values.company?.id!);
          }}
        >
          {(formik) => (
            <Form>
              <div className="px-6 pb-4">
                <Field
                  name="company"
                  component={FormikAutocompleteAsync}
                  fetchOptions={repo.findByName}
                  fullWidth
                  getOptionLabel={(client: ClientBusinessPartialModel) =>
                    client.fantasyName
                  }
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextFieldMaterial
                      {...params}
                      error={
                        formik.touched['company'] && !!formik.errors['company']
                      }
                      helperText={formik.errors['company']}
                      label="Empresa"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-span-6 flex justify-end px-6 pb-4">
                <Button
                  type="submit"
                  variant="contained"
                  color="blue"
                  isLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                >
                  Confirmar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
