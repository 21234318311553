import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useClientPrivates from 'app/client/private/private/services/ClientPrivateService';
import {
  TravelEstimationsWithPrice,
  useDistanceAndPriceTransferReservationClientPrivateCalculator
} from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import ReservaTrasladoEstimationDialog from 'app/viaje/viajeTraslado/viajeTraslado/components/ReservaTrasladoEstimationDialog';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components';
import { CreatedReservationsModal } from '../../viajeTraslado/components/CreatedReservationsModal';
import { ReservaTrasladoPrivateBatchForm } from '../forms/batch/TransferReservationPrivateBatchForm';
import { TransferReservationPrivateBatchFormType } from '../forms/batch/TransferReservationPrivateBatchFormType';
import {
  TransferReservationPrivateSingleFormType,
  transferReservationPrivateSingleFormBuilder
} from '../forms/single/TransferReservationSingleFormType';
import { useTransferReservationPrivateRepository } from '../services/repositories/transferReservationPrivateRepository';

export const TransferReservationPrivateCopyPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [createdReservations, setCreatedReservations] = useState<
    number[] | null
  >(null);

  const transferReservationPrivateRepo =
    useTransferReservationPrivateRepository();
  const clientPrivateRepository = useClientPrivates();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientPrivateCalculator();
  const createTrasladoMutation = useSuperMutation(
    transferReservationPrivateRepo.createBatch,
    {
      showSpinner: true,
      onMutationFinish: (response: any) => {
        setCreatedReservations(response?.data?.map((el) => el.id));
      }
    }
  );
  let { id } = useParams<{ id: string }>();

  const reservationQuery = useSuperQuery(() =>
    transferReservationPrivateRepo.get(Number(id))
  );
  const clientQuery = useSuperQuery(
    async () => {
      if (reservationQuery.data?.client.id === undefined) {
        return;
      }
      return clientPrivateRepository.getClientPrivate(
        reservationQuery.data.client.id
      );
    },
    { showSpinner: true },
    [reservationQuery.data]
  );

  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [distanceAndCost, setDistanceAndCost] = useState<
    TravelEstimationsWithPrice[]
  >([]);

  async function editViajeTraslado(
    trasladoFormValues: TransferReservationPrivateBatchFormType
  ) {
    await createTrasladoMutation
      .mutate(trasladoFormValues)
      .then(() => setModalOpen(true));
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationPrivateBatchFormType
  ) {
    const estimationsPromise = trasladoFormValues.viajes.map((viaje) =>
      calculateDistanceAndCost(
        {
          arrivalDate: viaje.fechaYHoraDeLlegada,
          hasOutSite: trasladoFormValues.hasOutSite,
          points: viaje.puntos.map((p) => ({
            lat: p.domicilio.latitud,
            lng: p.domicilio.longitud,
            minutesWaiting: Number(p.espera)
          })),
          serviceType: trasladoFormValues.tipoServicio
        },
        trasladoFormValues.cliente!.id
      )
    );
    return Promise.all(estimationsPromise);
  }

  if (reservationQuery.data === undefined || clientQuery.data === undefined) {
    return (
      <>
        <PageHeader title="Viaje particular" subtitle={'Nuevo traslado'} />
        <PageContainer>
          <Card className="w-full" title="Nuevo traslado particular">
            <div className="p-8">Cargando...</div>
          </Card>
        </PageContainer>
      </>
    );
  }

  const initialValues: TransferReservationPrivateSingleFormType =
    transferReservationPrivateSingleFormBuilder.fromModel(
      reservationQuery.data,
      clientQuery.data
    );

  const parsedInitialValues2 = {
    ...initialValues,
    viajes: [
      {
        fechaYHoraDeLlegada: initialValues.fechaYHoraDeLlegada!,
        minutosPreviosASalida: initialValues.minutosPreviosASalida!,
        puntos: initialValues.puntos
      }
    ]
  };

  return (
    <>
      <PageHeader title="Viaje particular" subtitle={'Nuevo traslado'} />
      <PageContainer>
        <Card className="w-full" title="Nuevo traslado particular">
          <div className="p-8">
            <ReservaTrasladoPrivateBatchForm
              getClientesByName={clientPrivateRepository.findByName}
              onCalculateDistanceAndCost={estimatePrice}
              initialValues={parsedInitialValues2}
              onSubmit={editViajeTraslado}
            />
          </div>
          <CreatedReservationsModal
            open={modalOpen}
            setOpen={setModalOpen}
            reservations={createdReservations}
          />
        </Card>
      </PageContainer>
      <ReservaTrasladoEstimationDialog
        isOpen={isEstimationOpen}
        handleClose={() => setIsEstimationOpen(false)}
        distanceAndCost={distanceAndCost}
      />
    </>
  );
};
