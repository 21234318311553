import { AntenaGrupoModel } from 'app/antena/grupo/models/AntenaGrupo';

export interface AntenaModel {
  id: number;
  nombre: string;
  latitud: string;
  longitud: string;
  grupo: AntenaGrupoModel;
}

export interface AntenaModelBack {
  id: number;
  nombre: string;
  latitud: string;
  longitud: string;
  grupo: AntenaGrupoModel;
}

export const antenaBuilder = {
  fromBackEnd: (antena: AntenaModelBack): AntenaModel => {
    return {
      id: antena.id,
      nombre: antena.nombre,
      latitud: antena.latitud,
      longitud: antena.longitud,
      grupo: antena.grupo
    };
  }
};
