import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/moment';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import * as Yup from 'yup';
import { Button } from '@vadiun/react-components';
import moment from 'moment';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { ChoferConceptoNovedad, conceptoNovedadChofer } from 'app/chofer/models/ChoferConceptoNovedad';
import { ChoferNovedad } from 'app/chofer/models/Chofer';

interface Props {
  submitButtonTitle?: string;
  onSubmit: (novedadSchema: ChoferNovedad, reset) => void;
  goBackButtonTitle?: string;
  onGoBack?: () => void;
  initialValues?: ChoferNovedad;
}

const Schema = Yup.object().shape({
  fecha: Yup.mixed()
    .required('La fecha es obligatoria'),
  concepto: Yup.string()
    .required('El motivo es obligatorio').oneOf(conceptoNovedadChofer()),
  monto: Yup.string()
});

function ChoferFormNovedades({
  submitButtonTitle = 'Crear Novedad',
  goBackButtonTitle = 'Volver',
  onGoBack,
  onSubmit,
  initialValues = {
    fecha: moment(),
    concepto: ChoferConceptoNovedad.ADELANTO,
    monto: ''
  }
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm, touched, errors, values }) => (
        <Form className="w-full grid gap-6">
          <div className="grid gap-4">
          <FormControl variant="outlined" fullWidth>
              <InputLabel>Concepto</InputLabel>
              <Field component={Select} name="concepto" label="Concepto">
                {conceptoNovedadChofer().map((concepto) => (
                  <MenuItem value={concepto} key={concepto}>
                    {concepto}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="fecha"
                label="Fecha"
                inputVariant="outlined"
                component={KeyboardDateTimePicker}
                fullWidth
                ampm={false}
                format="DD/MM/YYYY HH"
              />
            </MuiPickersUtilsProvider>
            
            <Field
              name="monto"
              label="Monto"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>

          <div className="flex justify-between mt-1">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="green"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button type="submit" variant="contained" color="green">
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ChoferFormNovedades;
