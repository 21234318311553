import React from 'react';
import { Card } from 'shared/components';
import TitularForm from './TitularForm';
import { TitularFormSchema } from '../models/TitularFormSchema';

interface Props {
  create: (TitularValues: TitularFormSchema, reset) => void;
}
function TitularCreate({ create }: Props) {
  return (
    <Card title="Nuevo Titular" className="w-full">
      <div className="p-8">
        <TitularForm onSubmit={create} />
      </div>
    </Card>
  );
}

export default TitularCreate;
