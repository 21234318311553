import {
  Dialog,
  Divider,
  Drawer,
  IconButton,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks';
import { useSuperMutation, useVerifyAction } from '@vadiun/react-hooks-legacy';
import { useFacturacionRepository } from 'app/facturas/services/FacturacionService';
import { useViajesTraslado } from 'app/viaje/services';
import { Field, Form, Formik } from 'formik';
import { AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment, { Moment } from 'moment';
import { useState } from 'react';

import { useTableQuery } from '@vadiun/react-datatable';
import { ClientBusinessPartialModel } from '../../client/clientBusiness/models/ClientBusiness';
import useClientBusiness from '../../client/clientBusiness/services/useClientBusiness';
import { UserModel } from '../../user/models/User';
import useUsersRepository from '../../user/services/UserService';
import FacturarLiquidacionesForm from '../components/FacturarLiquidacionesForm';
import { FacturarLiquidacionesFormType } from '../components/FacturarLiquidacionesFormik';
import LiquidacionTable from '../components/LiquidacionTable';
import { ReservationsFromLiquidacionTable } from '../components/ReservationsFromLiquidacionTable';
import { LiquidacionModel } from '../models/Liquidacion';
import useLiquidacions from '../services/LiquidacionService';

function LiquidacionesPage() {
  const LiquidacionService = useLiquidacions();
  const ViajeTrasladoService = useViajesTraslado();
  const facturacionService = useFacturacionRepository();
  const verifyAction = useVerifyAction();
  const clientBusinessRepository = useClientBusiness();
  const [settlementId, setSettlementId] = useState<string | undefined>(
    undefined
  );
  const [openDeleteReservationsDialog, setOpenDeleteReservationsDialog] =
    useState(false);
  const tableReservationsDialogPagination = useTableQuery(
    ['reservations-liquidacion', settlementId],
    async (params: URLSearchParams) => {
      const res = await ViajeTrasladoService.getReservationbySettlement(
        settlementId!,
        params
      );
      return res;
    },
    { enabled: settlementId !== undefined, defaultItemsPerPage: 100 }
  );
  const userRepository = useUsersRepository();
  const postBillingMutation = useSuperMutation(
    facturacionService.addFacturacion,
    {
      messages: {
        success: { message: 'Se ha facturado con éxito' }
      }
    }
  );

  const deleteReservationsMutation = useSuperMutation(
    LiquidacionService.deleteReservations,
    {
      messages: {
        success: { message: 'Se han borrado con éxito' }
      }
    }
  );

  const deleteSettlementMutation = useSuperMutation(
    LiquidacionService.deleteSettlement,
    {
      messages: {
        success: { message: 'Se han borrado con éxito' }
      }
    }
  );

  const [selectedSettlements, setSelectedSettlements] = useState<
    LiquidacionModel[]
  >([]);
  const [selectedReservations, setSelectedReservations] = useState<number[]>(
    []
  );

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    client: ClientBusinessPartialModel | null;
    oficial_cuenta: UserModel | null;
    date: Moment;
  }>({ client: null, oficial_cuenta: null, date: moment() });
  const [openSendSettlementsDialog, setOpenSendSettlementsDialog] =
    useState(false);

  const liquidacionesQuery = useSuperQuery(
    [
      'liquidaciones',
      {
        clientId: filters.client?.id,
        oficialId: filters.oficial_cuenta?.id,
        data: filters.date.format()
      }
    ],
    async () => {
      const data = await LiquidacionService.getLiquidacions(filters);
      return data.sort((a, b) => (a.datetime > b.datetime ? 1 : -1));
    },
    {
      showSpinner: true
    }
  );

  async function facturarLiquidacion(values: FacturarLiquidacionesFormType) {
    const billingInfo = {
      number: values.number,
      letter: values.letter,
      settlements: selectedSettlements
    };
    await postBillingMutation.mutate(billingInfo);
    setSelectedSettlements([]);
    setOpenSendSettlementsDialog(false);
    liquidacionesQuery.refetch();
  }

  async function borrarReserva(settlementId, reservas: number[]) {
    const info = {
      settlementId,
      reservas
    };
    await deleteReservationsMutation.mutate(info);
    tableReservationsDialogPagination.refetch();
    liquidacionesQuery.refetch();
  }

  async function onDeleteLiquidacion(id: string | undefined) {
    await deleteSettlementMutation.mutate(id);
    liquidacionesQuery.refetch();
  }

  return (
    <>
      <PageHeader
        title="Liquidaciones"
        subtitle="Liquidaciones pendientes"
        toolbar={
          <div className="flex space-x-2">
            <Button
              color="green"
              variant="light"
              disabled={!selectedSettlements.length}
              onClick={() => setOpenSendSettlementsDialog(true)}
            >
              Facturar
            </Button>
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtrar
            </Button>
          </div>
        }
      />

      <PageContainer>
        <LiquidacionTable
          data={liquidacionesQuery.data ?? []}
          setSelectedRows={setSelectedSettlements}
          selectedLiquidaciones={selectedSettlements}
          onDeleteReservations={(id) => {
            setSettlementId(id);
            setOpenDeleteReservationsDialog(true);
          }}
          onDeleteLiquidacion={(id) =>
            verifyAction({
              onAccept: () => onDeleteLiquidacion(id),
              body: '¿Está seguro/a que desea borrar esta liquidación?'
            })
          }
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-lg"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <Formik
            initialValues={{
              client: null,
              authorized: null,
              oficial_cuenta: null,
              date: moment()
            }}
            onSubmit={async (values) => {
              setFilters(values);
              setShowFilters(false);
            }}
          >
            {({ touched, errors }) => (
              <Form className="flex max-w-md flex-col gap-2 p-8">
                <Field
                  component={KeyboardDatePicker}
                  label="Fecha"
                  name="date"
                  inputVariant="outlined"
                  margin="dense"
                  className="col-span-2"
                  format="DD/MM/YYYY"
                />
                <Field
                  name="client"
                  component={FormikAutocompleteAsync}
                  fetchOptions={clientBusinessRepository.findByName}
                  fullWidth
                  getOptionLabel={(client: ClientBusinessPartialModel) =>
                    client.fantasyName
                  }
                  className="col-span-2"
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextFieldMaterial
                      {...params}
                      error={touched['client'] && !!errors['client']}
                      helperText={errors['client']}
                      label="Cliente"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  )}
                />
                <Field
                  name="oficial_cuenta"
                  component={FormikAutocompleteAsync}
                  fetchOptions={userRepository.findByName}
                  fullWidth
                  getOptionLabel={(client: UserModel) => client.name}
                  className="col-span-2"
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextFieldMaterial
                      {...params}
                      error={
                        touched['oficial_cuenta'] && !!errors['oficial_cuenta']
                      }
                      helperText={errors['oficial_cuenta']}
                      label="Oficial cuenta"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  )}
                />
                <div className="col-span-1 flex justify-end">
                  <Button type="submit" variant="contained">
                    Buscar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
      <Dialog
        open={openSendSettlementsDialog}
        onBackdropClick={() => setOpenSendSettlementsDialog(false)}
      >
        <FacturarLiquidacionesForm
          onSubmit={facturarLiquidacion}
          isLoading={postBillingMutation.isLoading}
        />
      </Dialog>
      <Dialog
        maxWidth="lg"
        onBackdropClick={() => {
          setOpenDeleteReservationsDialog(false);
          setSettlementId(undefined);
        }}
        open={openDeleteReservationsDialog}
      >
        <div className="pt-10, pb-10">
          <div className="m-5">
            <Button
              variant="light"
              color="red"
              onClick={() => borrarReserva(settlementId, selectedReservations)}
            >
              Borrar Seleccionados
            </Button>
          </div>
          <ReservationsFromLiquidacionTable
            setSelectedRows={setSelectedReservations}
            selectedRows={selectedReservations}
            tableProps={tableReservationsDialogPagination}
          />
        </div>
      </Dialog>
    </>
  );
}

export default LiquidacionesPage;
