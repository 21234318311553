import React from 'react';
import { Card } from 'shared/components';
import { AntenaForm } from 'app/antena/antena/components/AntenaForm/AntenaForm';
import { AntenaFormType } from 'app/antena/antena/components/AntenaForm/AntenaFormType';
import { AntenaGrupoModel } from 'app/antena/grupo/models/AntenaGrupo';

interface Props {
  update: (AntenaValues: AntenaFormType) => Promise<void>;
  initialValues: AntenaFormType;
  grupos: AntenaGrupoModel[];
}

export function AntenaEdit({ update, initialValues, grupos }: Props) {
  return (
    <Card title="Nuevo Antena" className="w-full">
      <div className="p-8">
        <AntenaForm
          onSubmit={update}
          initialValues={initialValues}
          grupos={grupos}
        />
      </div>
    </Card>
  );
}
