
export enum ChoferCategoria {
  BRONCE = 'Bronce',
  PLATA = 'Plata',
  ORO = 'Oro'
}

export enum DriverCategoryIconColor {
  BRONCE = 'coral',
  PLATA = 'gainsboro',
  ORO = 'gold',
  BASICO = 'black'
}

export function mapDriverCategoryToIconColor(categoria) {
  switch (categoria) {
    case ChoferCategoria.BRONCE:
      return DriverCategoryIconColor.BRONCE;
    case ChoferCategoria.PLATA:
      return DriverCategoryIconColor.PLATA;
    case ChoferCategoria.ORO:
      return DriverCategoryIconColor.ORO;
  }
}

export function categoriasChofer() {
  return Object.values(ChoferCategoria).sort();
}
