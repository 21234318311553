import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { ReactNode } from 'react-transition-group/node_modules/@types/react';

const Loading = ({ label }: { label?: ReactNode }) => {
  return (
    <div className="flex space-x-4 p-4 font-bold text-grey-600 rounded-md items-center z-50">
      <span>{label || 'Cargando'}</span>
      <CircularProgress color="primary" size={30} />
    </div>
  );
};

export default Loading;
