// @flow
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import moment from 'moment';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationManagmentModel } from '../../gestion/models/TransferReservationManagment';
import { copyReservationPublicLinkToClipboard } from '../../viajeTraslado/services/copyReservationPublicLinkToClipboard';
import { TransferReservationPendingModel } from '../../pendiente/models/TransferReservationPending';
import { ViajeTrasladoPendienteTableTimesCell } from '../../pendiente/components/ViajeTrasladoPendienteTableTimesCell';
import { DriverRecomended, DriversRecomended } from '../types/Driver';
import Row from './Row';
import { useResponsiveBreakpoint } from '@vadiun/react-hooks';
import RowDriversRecomended from './RowDriversRecomended';
import {
  TravelTaked,
  useTravelCordinate
} from 'app/viaje/services/TravelsCordinate';
import { formatNumberToPrice } from 'shared/helpers';
import Refresh from '@material-ui/icons/Refresh';
import { usePollingInterval } from 'shared/hooks/usePollingInterval';
import { useCustomInterval } from 'shared/hooks/useCustomInterval';

interface Props {
  tableQuery: UseTableQueryResult<TransferReservationPendingModel[], unknown>;
  driversRecomended: DriversRecomended;
  idTravelSelected: number | undefined;
  isLoadingDriverRecomended: boolean;
  onChangeDriverSelected: (driver: DriverRecomended) => void;
  setIdTravelSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
  onLocationsDetails: (reservation: TransferReservationPendingModel) => void;
  onDelayNotify: (travel: TransferReservationPendingModel) => void;
  onEditReservation: (travel: TransferReservationPendingModel) => void;
  onShowDriverDetails: (driver: ChoferPartialModel) => void;
  onReservationCopy: (reservation: TransferReservationManagmentModel) => void;
  onCancelReservation: (reservation: TransferReservationPendingModel) => void;
  onReservationShowDetails: (id: number) => React.ReactNode;
  onShowFiltersDriversRecomended: () => void;
  onCloseFilters: () => void;
  onStartTravel: (selectedReservation: number, chofer: number) => void;
  onPreAssignDriver: (travel: TransferReservationPendingModel) => void;
  onAssignDriver: (travel: TransferReservationPendingModel) => void;
  onRemovePreAssigned: (selectedReservation: number) => void;
  onRefreshTable: () => Promise<void>;
}

export function ViajeTrasladoCoordinarTable({
  tableQuery,
  driversRecomended,
  idTravelSelected,
  isLoadingDriverRecomended,
  onShowFiltersDriversRecomended,
  onChangeDriverSelected,
  setIdTravelSelected,
  onLocationsDetails,
  onShowDriverDetails,
  onEditReservation,
  onReservationCopy,
  onCancelReservation,
  onReservationShowDetails,
  onDelayNotify,
  onStartTravel,
  onCloseFilters,
  onAssignDriver,
  onPreAssignDriver,
  onRemovePreAssigned,
  onRefreshTable
}: Props) {
  const snackbar = useSnackbar();
  const [driverButtonElement, setDriverButtonElement] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);
  const { getTravelsTaked } = useTravelCordinate();
  const [travelsTaked, setTravelsTaked] = React.useState<TravelTaked[]>([]);
  const display = useResponsiveBreakpoint();
  const isMobileOrTablet =
    display === 'xs' || display === 'sm' || display === 'md';

  const [reservationButtonElement, setReservationButtonElement] =
    React.useState<(EventTarget & HTMLElement) | null>(null);
  const eventIndex = React.useRef<number | null>(null);
  const [isRefreshing, setIsRefreshing] = React.useState<boolean>(false);

  const checkServiceType = (val: string) => (val === 'VIP' ? 1 : 0);

  const data = React.useMemo(
    () =>
      (tableQuery.data ?? [])
        .map((reservation) => ({
          ...reservation,
          schedule: {
            arrival: reservation.reservationDatetime,
            exit: reservation.departureDatetime,
            end: reservation.arrivalDateTime
          },
          passengers: reservation.locations
            .map((location) =>
              location.passengers.map((passenger) => passenger.name)
            )
            .flat()
        }))
        .sort((a: any, b: any) => {
          if (
            moment(a.departureDatetime).format('DD-MM HH:mm') ===
            moment(b.departureDatetime).format('DD-MM HH:mm')
          ) {
            return (
              b.hasPriority - a.hasPriority ||
              checkServiceType(b.serviceType) - checkServiceType(a.serviceType)
            );
          } else {
            return a.departureDatetime - b.departureDatetime;
          }
        }),
    [tableQuery.data]
  );

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'schedule',
      label: isMobileOrTablet ? ' ' : 'Horarios',
      options: {
        customBodyRender: (schedule, { rowIndex: index }) => {
          const travel = data[index];
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>Horarios</p> : null}
              <ViajeTrasladoPendienteTableTimesCell
                schedule={schedule}
                priceDriver={`$${formatNumberToPrice(
                  travel?.estimated_prices?.driver_price ?? 0
                )}`}
              />
            </div>
          );
        }
      }
    },
    {
      name: 'cliente',
      label: isMobileOrTablet ? ' ' : 'Cliente',
      options: {
        customBodyRender: (value, { rowIndex: index }) => {
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>Cliente</p> : null}
              <p
                className={`w-28 text-sm ${
                  !data[index].pay_with_cash ? 'text-blue-500' : 'text-black'
                }`}
              >
                {data[index].client.name}
              </p>
            </div>
          );
        }
      }
    },
    {
      name: 'puntos',
      label: isMobileOrTablet ? ' ' : 'Domicilios',
      options: {
        customBodyRender: (value, { rowIndex: index }) => {
          return (
            <div
              style={{
                width: '220px',
                maxWidth: '220px'
              }}
            >
              <div className="flex justify-between text-sm">
                {isMobileOrTablet ? <p>Domicilios</p> : null}
                <div className="flex flex-col items-start gap-1">
                  {data[index].locations.map((location, index) => (
                    <div className="mb-1 flex items-center gap-1">
                      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 p-2 text-xs text-white">
                        {index + 1}
                      </div>
                      <p className="ml-1 text-xs leading-none text-gray-700">
                        {createDomicilioText(location.address)}
                      </p>
                    </div>
                  ))}
                  <button
                    className="mt-4 rounded-2xl border-2 text-sm leading-6 text-blue-500 hover:bg-gray-100 w-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      onLocationsDetails(data[index]);
                    }}
                  >
                    Info
                  </button>
                </div>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: isMobileOrTablet ? ' ' : 'Obs. Coordinación',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm w-32">
              {isMobileOrTablet ? <p>Obs. Coordinación</p> : null}
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'id',
      label: isMobileOrTablet ? ' ' : 'Sugeridos',
      options: {
        setCellHeaderProps: () => ({
          style: { width: '112px', textAlign: 'center' }
        }),
        customBodyRender: (idTravel) => {
          const isTravelSelected = idTravel === idTravelSelected;
          return (
            <RowDriversRecomended
              driversRecomended={driversRecomended}
              isTravelSelected={isTravelSelected}
              isMobileOrTablet={isMobileOrTablet}
              isLoadingDriverRecomended={isLoadingDriverRecomended}
              onChangeDriverSelected={onChangeDriverSelected}
              onShowFiltersDriversRecomended={onShowFiltersDriversRecomended}
            />
          );
        }
      }
    },
    {
      name: 'serviceType',
      label: isMobileOrTablet ? ' ' : 'Servicio',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <div className="flex justify-between text-sm">
            {isMobileOrTablet ? <p>Servicio</p> : null}
            <div className="flex items-center space-x-2">
              <p className="text-xs">
                {data[index].serviceType === ViajeTrasladoTipoServicio.EJECUTIVO
                  ? null
                  : data[index].serviceType}
              </p>{' '}
              {data[index].hasPriority && (
                <FontAwesomeIcon icon={faStar} color="orange" />
              )}
            </div>
          </div>
        )
      }
    },
    {
      name: 'event_name',
      label: 'Evento',
      options: {
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, { rowIndex: index }) => (
          <p className="text-gray-700 w-36 text-sm text-center">
            {data[index].event_name}
          </p>
        )
      }
    },
    {
      name: 'id',
      label: isMobileOrTablet ? ' ' : 'ID',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>ID</p> : null}
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'platformId',
      label: isMobileOrTablet ? ' ' : 'Plataforma',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>Plataforma</p> : null}
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'preAssignedDriver',
      label: isMobileOrTablet ? ' ' : 'Chofer',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <div className="flex justify-between text-sm">
            {isMobileOrTablet ? <p>Chofer</p> : null}
            <div className="flex flex-col items-center text-sm">
              <p
                className="cursor-pointer hover:underline"
                onClick={() =>
                  onShowDriverDetails(data[index].preAssignedDriver!)
                }
              >
                {data[index].preAssignedDriver?.codigo}
              </p>
              <p className="w-28 text-center text-xs">
                {data[index].preAssignedDriver?.nombreCompleto}
              </p>
            </div>
          </div>
        )
      }
    },
    {
      name: 'passengers',
      label: isMobileOrTablet ? ' ' : 'Pasajeros',
      options: {
        download: false,
        customBodyRender: (value, { rowIndex: index }) => (
          <div className="flex justify-between text-sm">
            {isMobileOrTablet ? <p>Pasajeros</p> : null}
            <div className="flex flex-col">
              {data[index].passengers.map((el) => (
                <span>{el}</span>
              ))}
            </div>
          </div>
        )
      }
    },
    {
      label: isMobileOrTablet ? ' ' : 'Acciones',
      name: 'actions',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <div className="flex flex-col justify-between text-sm">
            {isMobileOrTablet ? <p>Acciones</p> : null}
            <>
              {idTravelSelected === data[index].id ? (
                <Button
                  onClick={(ev) => {
                    ev.stopPropagation();
                    eventIndex.current = index;
                    setDriverButtonElement(ev.currentTarget);
                  }}
                  variant="light"
                  color="green"
                  className="mb-2 w-full"
                >
                  Chofer
                </Button>
              ) : null}
              <Menu
                anchorEl={driverButtonElement}
                open={
                  eventIndex.current === index && Boolean(driverButtonElement)
                }
                onClose={(ev: any) => {
                  ev.stopPropagation();
                  setDriverButtonElement(null);
                }}
              >
                <MenuItem
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setDriverButtonElement(null);
                    onPreAssignDriver(data[index]);
                  }}
                >
                  Pre-asignar
                </MenuItem>
                <MenuItem
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setDriverButtonElement(null);
                    onAssignDriver(data[index]);
                  }}
                >
                  Asignar
                </MenuItem>
                {data[index].preAssignedDriver?.codigo ? (
                  <MenuItem
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setDriverButtonElement(null);
                      onRemovePreAssigned(data[index].id);
                    }}
                  >
                    Remover chofer
                  </MenuItem>
                ) : null}
              </Menu>
              <Button
                onClick={(ev) => {
                  ev.stopPropagation();
                  eventIndex.current = index;
                  setReservationButtonElement(ev.currentTarget);
                }}
                variant="light"
                className="mb-2 w-full"
                color="blue"
              >
                Reserva
              </Button>
              <Menu
                anchorEl={reservationButtonElement}
                open={
                  eventIndex.current === index &&
                  Boolean(reservationButtonElement)
                }
                onClose={() => setReservationButtonElement(null)}
              >
                {onReservationShowDetails(data[index].id)}
                <MenuItem onClick={() => onEditReservation(data[index])}>
                  Modificar
                </MenuItem>
                <MenuItem onClick={() => onCancelReservation(data[index])}>
                  Cancelar
                </MenuItem>
                <MenuItem onClick={() => onReservationCopy(data[index])}>
                  Copiar
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    await await copyReservationPublicLinkToClipboard(
                      data[index].id
                    );
                    setReservationButtonElement(null);
                    snackbar.enqueueSnackbar('Link copiado', {
                      variant: 'info'
                    });
                  }}
                >
                  Copiar Link
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    eventIndex.current = null;
                    setReservationButtonElement(null);
                    onDelayNotify(data[index]);
                  }}
                >
                  Notificar Demora
                </MenuItem>
              </Menu>
              {data[index]?.preAssignedDriver ? (
                <Button
                  onClick={() => {
                    onStartTravel(
                      data[index].id,
                      data[index].preAssignedDriver?.id!
                    );
                  }}
                  variant="light"
                  className="w-full"
                  color="blue"
                >
                  Pasar a Abierto
                </Button>
              ) : null}
            </>
          </div>
        )
      }
    },
    {
      name: 'taken_by_coordination',
      label: ' ',
      options: {
        display: 'excluded'
      }
    }
  ];

  const { pollingInterval, setPollingInterval } = usePollingInterval(4000);

  useCustomInterval(() => {
    const fetchData = async () => {
      try {
        const response = await getTravelsTaked();
        setTravelsTaked(response);
      } catch (error) {
        // Stop polling
        setPollingInterval(null);
        return new Error('Failed to get travels taked, stopping polling');
      }
    };

    fetchData();
  }, pollingInterval);

  return (
    <div className="w-full">
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        components={{
          TableToolbar: () => (
            <div className="flex justify-end py-1 px-4">
              <IconButton
                title="Refrescar tabla"
                onClick={async () => {
                  setIsRefreshing(true);
                  await onRefreshTable();
                  setIsRefreshing(false);
                }}
              >
                {isRefreshing ? (
                  <CircularProgress
                    size={19}
                    style={{
                      color: 'gray',
                      marginTop: 2
                    }}
                    thickness={4}
                  />
                ) : (
                  <Refresh />
                )}
              </IconButton>
            </div>
          )
        }}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          sort: false,
          download: false,
          print: false,
          customRowRender: (data) => {
            const [, , , , , , , idTravel] = data;
            const idTravelRow = idTravel.props.children[1].props.children;
            return (
              <Row
                key={idTravelRow}
                data={data}
                idTravelSelected={idTravelSelected}
                setIdTravelSelected={setIdTravelSelected}
                travelTaked={travelsTaked?.find(
                  (travel) => travel.transfer_reservation_id === idTravelRow
                )}
                onCloseFilters={onCloseFilters}
              />
            );
          },
          ...tableQuery.serverSideOptions
        }}
        columns={columns}
        data={data}
      />
    </div>
  );
}
