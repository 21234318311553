import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useBiller from 'app/biller/services/useBiller';
import { useBusinessUnit } from 'app/business/services/useBusinessUnit';
import useVehiculo from 'app/vehiculos/services/VehiculoService';
import { useParams } from 'react-router-dom';
import { ChoferModel } from '../models/Chofer';
import useChoferes from '../services/ChoferService';

const useChoferProfile = () => {
  const ChoferService = useChoferes();
  const VehiculoService = useVehiculo();
  const ProveedorService = useBiller();
  const BusinessUnitService = useBusinessUnit();
  const { id } = useParams<{ id: string }>();
  const choferQuery = useSuperQuery<ChoferModel>(
    () => ChoferService.getChofer(Number.parseInt(id)),
    { showSpinner: true },
    [id]
  );
  const vehiculosQuery = useSuperQuery(VehiculoService.getVehiculos, {
    showSpinner: true
  });
  const proveedoresQuery = useSuperQuery(ProveedorService.getBillers, {
    showSpinner: true
  });

  const businessUnitQuery = useSuperQuery(BusinessUnitService.getBusinessUnit, {
    showSpinner: true
  });

  const desvincularMutation = useSuperMutation(
    ChoferService.desvincularChofer,
    { showSpinner: true }
  );
  const revincularChoferMutation = useSuperMutation(
    ChoferService.revincularChofer,
    { showSpinner: true }
  );

  const eliminarMutation = useSuperMutation(ChoferService.deleteChofer, {
    showSpinner: true
  });
  const restoreChoferMutation = useSuperMutation(ChoferService.restoreChofer, {
    showSpinner: true
  });

  const deshabilitarMutation = useSuperMutation(
    ChoferService.deshabilitarChofer,
    { showSpinner: true }
  );
  const aceptarDeshabilitacionMutation = useSuperMutation(
    ChoferService.aceptarDeshabilitacion,
    { showSpinner: true }
  );
  const rechazarDeshabilitacionMutation = useSuperMutation(
    ChoferService.rechazarDeshabilitacion,
    { showSpinner: true }
  );
  const cancelarDeshabilitacionMutation = useSuperMutation(
    ChoferService.cancelarDeshabilitacion,
    { showSpinner: true }
  );
  const editChoferMutation = useSuperMutation(ChoferService.editChofer, {
    showSpinner: true
  });

  return {
    desvincularMutation,
    deshabilitarMutation,
    aceptarDeshabilitacionMutation,
    rechazarDeshabilitacionMutation,
    revincularChoferMutation,
    eliminarMutation,
    restoreChoferMutation,
    cancelarDeshabilitacionMutation,
    editChoferMutation,
    choferQuery,
    vehiculosQuery,
    proveedoresQuery,
    businessUnitQuery
  };
};

export default useChoferProfile;
