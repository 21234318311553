import { Chip, Tooltip } from "@material-ui/core";
import RestoreIcon from '@material-ui/icons/Restore';
import { Button, Modal } from '@vadiun/react-components';
import { MUIDataTableColumnDef } from "mui-datatables";
import { ChangeEvent, forwardRef, useImperativeHandle, useMemo, useReducer, useState } from 'react';
import { Loading, Table } from "shared/components";
import { v4 as uuidv4 } from 'uuid';
import { ImperativeStepMovementType } from "../stepper/types";
import HeaderSelector from '../table/HeaderSelector';
import { mappingReducer } from '../utils/mappingReducer';
import { ColumnIdType, ColumnMapping, MappingType } from '../utils/types';

interface TravelFormMappingProps {
  mappingData: Record<string, string>[];
  currentMappingData?: Record<string, string>[];
  currentColSize: number;
  currentColumnMapping?: ColumnMapping[];
  onMappingComplete: (
    data: MappingType[],
    currentColumnMapping: ColumnMapping[],
    currentRawData: Record<string, string>[],
  ) => Promise<void>;
  onResetMapping: (onPerformReset: () => void) => void;
  columnMapping: ColumnMapping[];
}

const TravelFormMapping = forwardRef<ImperativeStepMovementType, TravelFormMappingProps>(({
  mappingData: mp,
  currentColSize,
  onMappingComplete,
  currentColumnMapping,
  currentMappingData,
  onResetMapping,
  columnMapping
}, ref) => {
  const initialColSize = currentColumnMapping?.length || currentColSize;
  const [mappingData, setMappingData] = useState<Record<string, string>[]>(currentMappingData || mp);
  const [colSize, setColSize] = useState<number>(initialColSize);
  const [isMissingColumnOpen, setIsMissingColumnOpen] = useState<boolean>(false);
  const [isBEVerifying, setIsBEVerifying] = useState<boolean>(false);

  const reducerColumns = currentColumnMapping || columnMapping;
  const [mappingState, mappingDispatch] = useReducer(mappingReducer, {
    columns: reducerColumns,
    columnNumber: colSize > reducerColumns.length ? colSize : reducerColumns.length
  });

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>, columnIndex: number) => {
    if (event.target.value === 'delete') {
      setColSize((current) => --current);
      setMappingData((smp) => smp.map((mdData) => {
        const { [`column${columnIndex}`]: _, ...rest } = mdData;
        return Object.values(rest).reduce((acc, v, index) => ({
          ...acc,
          [`column${index}`]: v
        }), {} as Record<string, string>);
      }));
      return mappingDispatch({
        type: 'DELETE_COLUMN',
        payload: {
          columnId: columnIndex
        }
      })
    }
    mappingDispatch({
      type: 'SET_COLUMN_TYPE',
      payload: {
        id: event.target.value as ColumnIdType,
        columnId: columnIndex
      }
    })
  };

  const handleRemoveHeaderMapping = (columnId: ColumnIdType, columnIndex: number) => {
    mappingDispatch({
      type: 'REMOVE_COLUMN_TYPE',
      payload: {
        id: columnId,
        columnId: columnIndex
      }
    })
  }

  const handlePerformResetMapping = () => {
    setMappingData(mp);
    setColSize(currentColSize);
    mappingDispatch({
      type: 'RESET',
      payload: {
        columns: columnMapping,
        columnNumber: currentColSize
      }
    })
  }

  const handleResetMapping = () => {
    onResetMapping(handlePerformResetMapping);
  }

  const handleSubmit = async () => {

    if (mappingState.columns.some(({ mandatory, inUse }) => mandatory && !inUse)) {
      return setIsMissingColumnOpen(true);
    }
    const selectedColumnMapping = mappingState.columns.filter(({ inUse }) => inUse);

    let data = mappingData.map((currentData) => {
      const mappingRecord = Object.values(currentData).reduce((acc, value, index) => {
        const mappedColumn = selectedColumnMapping.find(({ columnId }) => columnId?.includes(index));
        if (mappedColumn) {
          const currentValue = acc[mappedColumn.id];
          return {
            ...acc,
            [mappedColumn.id]: acc[mappedColumn.id] ?
              (Array.isArray(currentValue) ? [...currentValue, value] : [currentValue, value])
              : value
          }
        }
        return acc;

      }, {} as { [key in ColumnIdType]: string | string[] });
      return mappingRecord;
    });

    if (selectedColumnMapping.every(({ id }) => id !== ColumnIdType.ADVANCED_HOURS)) {
      selectedColumnMapping.push({
        ...mappingState.columns.find(({ id }) => id === ColumnIdType.ADVANCED_HOURS)!,
        columnId: [Object.keys(data[0]).length]
      });
      data = data.map((dataElement) => {
        return {
          ...dataElement,
          [ColumnIdType.ADVANCED_HOURS]: '01:00'
        }
      })
    }

    try {
      setIsBEVerifying(true);
      await onMappingComplete(
        data,
        selectedColumnMapping.sort(({ columnId: cidA }, { columnId: cidB }) => {
          if (cidA[0] > cidB[0]) {
            return 1;
          }
          if (cidA[0] === cidB[0]) {
            return 0
          }
          return -1;
        }).map((cm, index) => ({
          ...cm,
          columnId: [index]
        })),
        data.map((dataRecord) => {
          return Object.values(dataRecord).reduce((acc, stringData, index) => {
            if (Array.isArray(stringData)) {
              return {
                ...acc,
                [`column${index}`]: stringData.join(',')
              }
            }
            return {
              ...acc,
              [`column${index}`]: stringData
            }
          }, {} as Record<string, string>)
        })
      );
    } finally {
      setIsBEVerifying(false);
    }
  }

  const columns = useMemo(() => {
    const mappingDataColumns = Array.from({
      length: mappingState.columnNumber
    }).map((_, index): MUIDataTableColumnDef => {
      return {
        name: `column${index}`,
        label: mappingState.columns.find(({ columnId }) => columnId?.includes(index))?.label || `Sin Asignar`,
        options: {
          customBodyRender: (info) => {
            return <div>{info}</div>;
          },
          customHeadRender: () => {
            const currentMapping = mappingState.columns.find(({ columnId }) => columnId?.includes(index));
            return (
              <HeaderSelector
                key={uuidv4()}
                currentMapping={currentMapping}
                currentColIndex={index}
                onChange={handleChange}
                onRemoveHeaderMapping={handleRemoveHeaderMapping}
                columns={mappingState.columns}
              />
            )
          }
        }
      }
    });
    return mappingDataColumns;
  }, [mappingState.columnNumber, mappingState.columns])

  useImperativeHandle(ref, () => ({
    onClick: async () => {
      await handleSubmit();
    }
  }));

  const travelDataReduced = useMemo(() => mappingData.slice(0, 5), [mappingData]);

  return (
    <>
      <Table
        title="Mapeo de columnas"
        options={{
          elevation: 0,
          selectableRows: 'none',
          selectToolbarPlacement: 'none',
          search: false,
          filter: false,
          sort: false,
          download: false,
          print: false,
          fixedSelectColumn: true,
          pagination: false,
          rowsPerPageOptions: [5],
          customToolbar: () => {
            return (
              <div className="custom-toolbar">
                <Button
                  onClick={handleResetMapping}
                  shape="circle"
                  color="gray"
                  className="hover:bg-primary-400"
                >
                  <Tooltip title="Descartar todo">
                    <RestoreIcon fontSize="medium" />
                  </Tooltip>
                </Button>
              </div>
            )
          }
        }}
        columns={columns}
        data={travelDataReduced}
      />
      {isMissingColumnOpen && (
        <Modal
          open
          size="sm"
          subtitle="Asigne las siguientes columnas para continuar"
          title="Error de Mapeo"
          onClose={() => setIsMissingColumnOpen(false)}
          body={(
            <div className="p-4 flex flex-wrap gap-x-4 gap-y-6">
              {mappingState.columns.filter(({ mandatory, inUse }) => mandatory && !inUse).map(({ label }) => (
                <Chip label={label} variant="outlined" color="secondary" key={label} />
              ))}
            </div>
          )}
        />
      )}
      {isBEVerifying &&
        <div className="fixed w-full h-full flex top-0 left-0 justify-center items-center" style={{
          backgroundColor: '#cbc3c3a1',
          zIndex: 1000,
        }}>
          <div className="bg-white rounded px-4">
            <Loading label={(
              <div className="mr-4">
                <p className="text-black">Validando datos</p>
                <p className="text-sm">El proceso puede demorar unos instantes</p>
              </div>
            )} />
          </div>
        </div>}
    </>
  )
});

export default TravelFormMapping;