import {
  faAddressBook,
  faCheckSquare,
  faCoins,
  faList,
  faUser,
  faUserCheck,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Card } from 'shared/components';
import Profile from 'shared/components/Profile';
import AuthorizedCrudPage from '../Authorized/pages/AuthorizedCrudPage';
import PassengerCrudPage from '../Passenger/pages/PassengerCrudPage';
import ClientBusinessContactForm from '../components/ClientBusinessForm/ClientBusinessContactForm';
import {
  ClientBusinessContactFormType,
  ClientBusinessPersonalFormType,
  ClientBusinessReservationRequirementType
} from '../components/ClientBusinessForm/ClientBusinessFormik';
import ClientBusinessPersonalForm from '../components/ClientBusinessForm/ClientBusinessPersonalForm';
import ClientBusinessRequirementsForm from '../components/ClientBusinessForm/ClientBusinessRequirementsForm';
import useClientBusiness from '../services/useClientBusiness';
import { ClientBusinessUserCrudComponent } from '../user/components/ClientBusinessUserCrudComponent';
import { useClientBusinessUser } from '../user/hooks/useClientBusinessUser';
import { CostCenterCrudPage } from '../CostCenters/pages/CostCenterCrudPage';

const ClientBusinessProfilePage = () => {
  const ClientBusinessService = useClientBusiness();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<
    | 'personal'
    | 'contactos'
    | 'requerimientos'
    | 'pasajeros'
    | 'authorized'
    | 'rates'
  >('personal');
  const { id } = useParams<{ id: string }>();
  const clientBusinessUserSrv = useClientBusinessUser(Number(id));
  const clienteQuery = useSuperQuery(
    () => ClientBusinessService.get(Number.parseInt(id)),
    { showSpinner: true }
  );
  const ivaConditionsQuery = useSuperQuery(
    ClientBusinessService.getIvaConditions
  );
  const clienteEditMutation = useSuperMutation(ClientBusinessService.edit, {
    showSpinner: true
  });

  useEffect(() => {
    if (currentStep === 'rates') {
      history.push(`/main/clientBusiness/${id}/rates`);
    }
  }, [id, currentStep, history]);

  if (!clienteQuery.data) {
    return null;
  }

  const steps = {
    personal: (
      <Card className="flex-grow" title="Informacón Personal">
        <div className="p-8">
          <ClientBusinessPersonalForm
            ivaConditions={ivaConditionsQuery.data || []}
            initialValues={clienteQuery.data}
            onSubmit={async (values: ClientBusinessPersonalFormType) => {
              await clienteEditMutation.mutate({
                ...clienteQuery.data!,
                ...values
              });
              clienteQuery.reload();
            }}
            submitLabel="Guardar cambios"
          />
        </div>
      </Card>
    ),
    contactos: (
      <Card className="flex-grow" title="Contactos">
        <div className="p-8">
          <ClientBusinessContactForm
            initialValues={clienteQuery.data}
            onSubmit={async (values: ClientBusinessContactFormType) => {
              await clienteEditMutation.mutate({
                ...clienteQuery.data!,
                ...values
              });
              clienteQuery.reload();
            }}
            submitLabel="Guardar cambios"
          />
        </div>
      </Card>
    ),
    requerimientos: (
      <Card className="flex-grow" title="Requerimientos">
        <div className="p-8">
          <ClientBusinessRequirementsForm
            initialValues={clienteQuery.data.reservationRequirement}
            onSubmit={async (
              values: ClientBusinessReservationRequirementType
            ) => {
              await clienteEditMutation.mutate({
                ...clienteQuery.data!,
                reservationRequirement: values
              });
              clienteQuery.reload();
            }}
            submitLabel="Guardar cambios"
          />
        </div>
      </Card>
    ),
    pasajeros: <PassengerCrudPage idClientBusiness={clienteQuery.data.id} />,
    authorized: <AuthorizedCrudPage idClientBusiness={clienteQuery.data.id} />,
    users: <ClientBusinessUserCrudComponent {...clientBusinessUserSrv} />,
    cost_center: (
      <CostCenterCrudPage 
        list={clienteQuery.data.business_client_cost_centers!} 
        refetch={() => clienteQuery.reload()}
        idClientBusiness={clienteQuery.data.id}
      />
    ),
  };

  return (
    <>
      <PageHeader
        title="Clientes CC"
        subtitle={
          <div className="flex space-x-2">
            <Link
              color="inherit"
              to="/main/clientBusiness"
              className="text-xs text-gray-400 hover:underline"
            >
              Clientes CC
            </Link>
            <span>/</span>
            <span className="text-xs text-gray-400">
              {clienteQuery.data.fantasyName}
            </span>
          </div>
        }
      />
      <PageContainer>
        <Profile.Container
          navigation={
            <Profile.Navigation
              image={
                <div className="flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-md bg-blue-100">
                  <h1 className="text-2xl font-bold text-blue-500">
                    {clienteQuery.data.fantasyName
                      .split(' ')
                      .map((name) => name[0])
                      .join('')
                      .toUpperCase()}
                  </h1>
                </div>
              }
              resume={
                <Profile.Resume.Container>
                  <Profile.Resume.Title>
                    {' '}
                    {clienteQuery.data.fantasyName}
                  </Profile.Resume.Title>
                  <Profile.Resume.Subtitle>
                    {' '}
                    {clienteQuery.data.businessName}
                  </Profile.Resume.Subtitle>
                  {clienteQuery.data.isDeleted ? (
                    <Profile.Resume.Badge color="red">
                      {' '}
                      Eliminado
                    </Profile.Resume.Badge>
                  ) : (
                    <Profile.Resume.Badge color="green">
                      Activo
                    </Profile.Resume.Badge>
                  )}
                </Profile.Resume.Container>
              }
              description={
                <Profile.Description.Container>
                  <Profile.Description.Item
                    label="Código"
                    value={clienteQuery.data.code}
                  />
                  <Profile.Description.Item
                    label="Cuit"
                    value={clienteQuery.data.cuit}
                  />
                  <Profile.Description.Item
                    label="Mail"
                    value={clienteQuery.data.mail}
                  />
                  <Profile.Description.Item
                    label="Teléfono"
                    value={clienteQuery.data.phone}
                  />
                </Profile.Description.Container>
              }
              menu={
                <Profile.Menu.Container
                  value={currentStep}
                  onSelect={setCurrentStep}
                >
                  <Profile.Menu.Item
                    value="personal"
                    label="Personal"
                    icon={<FontAwesomeIcon icon={faUser} />}
                  />
                  <Profile.Menu.Item
                    value="contactos"
                    label="Contactos"
                    icon={<FontAwesomeIcon icon={faAddressBook} />}
                  />
                  <Profile.Menu.Item
                    value="requerimientos"
                    label="Requerimientos"
                    icon={<FontAwesomeIcon icon={faCheckSquare} />}
                  />
                  <Profile.Menu.Item
                    value="pasajeros"
                    label="Pasajeros"
                    icon={<FontAwesomeIcon icon={faUserFriends} />}
                  />
                  <Profile.Menu.Item
                    value="authorized"
                    label="Autorizados"
                    icon={<FontAwesomeIcon icon={faUserCheck} />}
                  />
                  <Profile.Menu.Item
                    value="rates"
                    label="Tarifas"
                    icon={<FontAwesomeIcon icon={faCoins} />}
                  />
                  <Profile.Menu.Item
                    value="users"
                    label="Usuarios"
                    icon={<FontAwesomeIcon icon={faUserFriends} />}
                  />
                  <Profile.Menu.Item
                    value="cost_center"
                    label="Centro de Costos"
                    icon={<FontAwesomeIcon icon={faList} />}
                  />
                </Profile.Menu.Container>
              }
            />
          }
          body={steps[currentStep]}
        />
      </PageContainer>
    </>
  );
};

export default ClientBusinessProfilePage;
