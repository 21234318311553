import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { Moment } from 'moment';

interface Props {
  values: DriverSettlementListFiltersType;
  onSubmit: (x: DriverSettlementListFiltersType) => Promise<any>;
  asRole: 'driver' | 'admin';
}

export interface DriverSettlementListFiltersType {
  driverCode: string;
  dateFrom: Moment;
  dateTo: Moment;
  biller: string;
}

export const DriverSettlementsListFiltersComponent = ({
  values,
  onSubmit,
  asRole
}: Props) => {
  return (
    <div>
      <Formik<DriverSettlementListFiltersType>
        initialValues={values}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form className="flex max-w-md flex-col gap-2 p-8">
            <Field
              component={KeyboardDatePicker}
              label="Desde"
              name="dateFrom"
              inputVariant="outlined"
              margin="dense"
              className="col-span-2"
              format="DD/MM/YYYY"
            />
            <Field
              component={KeyboardDatePicker}
              label="Hasta"
              name="dateTo"
              inputVariant="outlined"
              margin="dense"
              className="col-span-2"
              format="DD/MM/YYYY"
            />
            {asRole !== 'driver' && (
              <Field
                name="driverCode"
                label="Código chofer"
                variant="outlined"
                component={TextField}
                className="col-span-2"
                margin="dense"
                fullWidth
              />
            )}
            <Field
              name="biller"
              label="Facturador"
              variant="outlined"
              component={TextField}
              className="col-span-2"
              margin="dense"
              fullWidth
            />
            <div className="col-span-1 flex justify-end">
              <Button onClick={formik.submitForm} variant="contained">
                Buscar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
