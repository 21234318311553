import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';

type Props = {
  title: string;
  message: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  isDriverBusy: boolean;
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #f1f1f1',
    maxWidth: '340px',
    fontSize: 14,
    padding: '16px',
    borderRadius: 12,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 5px'
  }
}))(Tooltip);

const DriverBusyMessage = ({
  title,
  message,
  icon,
  children,
  isDriverBusy
}: Props) => {
  return (
    <CustomTooltip
      title={
        isDriverBusy ? (
          <div className="flex w-full flex-col gap-1 bg-white">
            <div className="flex gap-2">
              {icon}
              <p className="text-md text-gray-700">{title}</p>
            </div>
            <div className="flex gap-2">
              <div className="w-10" />
              <p className="text-sm text-gray-500">{message}</p>
            </div>
          </div>
        ) : (
          ''
        )
      }
    >
      <div>{children}</div>
    </CustomTooltip>
  );
};

export default DriverBusyMessage;
