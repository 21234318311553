import React from 'react';
import { Card } from 'shared/components';
import { AntenaGrupoForm } from './AntenaGrupoForm/AntenaGrupoForm';
import { AntenaGrupoFormType } from './AntenaGrupoForm/AntenaGrupoFormType';

interface Props {
  create: (AntenaGrupoValues: AntenaGrupoFormType) => Promise<void>;
}
export function AntenaGrupoCreate({ create }: Props) {
  return (
    <Card title="Nuevo Grupo de antenas" className="w-full">
      <div className="p-8">
        <AntenaGrupoForm onSubmit={create} />
      </div>
    </Card>
  );
}
