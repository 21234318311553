import { mockedViajeMinibus } from '../helpers/ViajeMinibusCreation';
import { ViajeMinibusModel } from '../models/ViajeMinibus/ViajeMinibus';

let ViajeMinibuss: ViajeMinibusModel[] = [
  mockedViajeMinibus(),
  mockedViajeMinibus(),
  mockedViajeMinibus(),
  mockedViajeMinibus(),
  mockedViajeMinibus()
];

const createPromise = <T>(x?: any) =>
  new Promise<T>((resolve) => setTimeout(() => resolve(x), 0));

const useViajesMinibus = () => {
  const addViajeMinibus = (x: ViajeMinibusModel) => {
    ViajeMinibuss.push({ ...x, id: Math.random() });
    return createPromise(x);
  };

  const addViajesMinibus = (x: ViajeMinibusModel[]) => {
    x.forEach((Viaje) => ViajeMinibuss.push({ ...Viaje, id: Math.random() }));
    return createPromise(x);
  };

  const editViajeMinibus = (ViajeMinibus: ViajeMinibusModel) => {
    ViajeMinibuss = ViajeMinibuss.map((x) =>
      x.id === ViajeMinibus.id ? ViajeMinibus : x
    );
    return createPromise(ViajeMinibus);
  };

  const getViajesMinibus = () => {
    return createPromise<ViajeMinibusModel[]>(ViajeMinibuss);
  };

  const getViajeMinibus = (id: number | string) => {
    return createPromise<ViajeMinibusModel>(
      ViajeMinibuss.find((e) => e.id === id)
    );
  };

  return {
    addViajeMinibus,
    editViajeMinibus,
    getViajeMinibus,
    getViajesMinibus,
    addViajesMinibus
  };
};

export default useViajesMinibus;
