import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { TransferReservationBatchLocationFormType } from '../TransferReservationBatchFormType';

interface Props {
  punto: TransferReservationBatchLocationFormType & { id: string };
  index: number;
}

export const DraggablePassengers = ({ punto, index }: Props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${JSON.stringify(punto.pasajeros)} - ${index}`,
    data: {
      type: 'type1',
      externalProp: punto.id
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform)
  };

  return (
    <ul
      id={`${JSON.stringify(punto.pasajeros)} - ${index}`}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className="m-8 rounded-md border-l-4 border-primary-400 bg-gray-50 p-2"
    >
      {punto.pasajeros.map((pasajero) => (
        <li className="mt-2 grid grid-cols-3">
          <span className="border-r-2 text-center  text-sm">
            {pasajero.nombreCompleto}
          </span>
          <span className="border-r-2 text-center  text-sm">
            {pasajero.telefono}
          </span>
          <span className=" text-center text-sm">{pasajero.mail}</span>
        </li>
      ))}
    </ul>
  );
};
