import { TollModelCRUD } from '../../models/Toll';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface TollFormType extends TollModelCRUD {}

const tollFormSchema: YupShape<TollFormType> = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  price: Yup.string().required('El precio es requerido')
});

const tollInitialValues: TollFormType = {
  name: '',
  price: ''
};

const TollFormik = {
  schema: tollFormSchema,
  initialValues: tollInitialValues
};

export default TollFormik;
