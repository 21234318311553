import { useCallback } from 'react';
import { z } from 'zod';
import environment from '../../../environment/environment';

const BusinessUnitTypeSchema = z.object({
  name: z.string(),
  id: z.coerce.number(),
  description: z.string(),
})

export type BusinessUnitType = z.infer<typeof BusinessUnitTypeSchema>;

export const useBusinessUnit = () => {

  const getBusinessUnit = useCallback(async (): Promise<BusinessUnitType[]> => {
    const result = await fetch(`${environment.backEnd}business-units`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    });
    if (!result.ok) {
      //TODO fix this later
      throw new Error('No se puede cargar la unidad de negocio');
    }
    const response = await result.json();
    return response;
  }, []);

  return {
    getBusinessUnit
  };
};

