import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { Table } from 'shared/components';
import { CostCenter } from '../../models/ClientBusiness';

interface Props {
  data?: CostCenter[];
  select: (x: CostCenter) => void;
  remove: (id: number) => Promise<void>;
}
export const CostCenterList = ({ data, select, remove }: Props) => {
  const verifyAction = useVerifyAction();

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => select(data![index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
              onClick={() =>
                verifyAction({
                  title: '¡Estás por eliminar un centro de costos!',
                  body: 'Esta acción va a eliminar el centro de costos. ¿Estás seguro?',
                  onAccept: () => remove(data![index].id)
                })
              }
              shape="circle"
              color="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ];
  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          download: false,
          print: false,
          filter: false
        }}
        title={'Centro de costos'}
        data={data!}
        columns={columns}
      />
    </div>
  );
};
