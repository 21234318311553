import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'shared/components';
import { Button } from '@vadiun/react-components';
import { RatePartialModel } from '../models/RateModel';
import { Moment } from 'moment';
import { useVerifyAction } from '@vadiun/react-hooks';

interface Props {
  rates: RatePartialModel[];
  selectToEdit: (id: number) => void;
  onDelete: (id: any) => Promise<void>
}

const RateTable = (props: Props) => {
  const verifyAction = useVerifyAction()

  const columns = [
    {
      name: 'expirationDate',
      label: 'Vencimiento',
      options: {
        filter: false,
        customBodyRender: (expirationDate: Moment) =>
          expirationDate.format('DD/MM/YYYY')
      }
    },
    {
      name: 'baseCommission',
      label: 'Comisión base',
      options: {
        filter: false
      }
    },
    {
      name: 'surplusCommission',
      label: 'Comisión excedente',
      options: {
        filter: false
      }
    },
    {
      name: 'limitPriceBaseCommission',
      label: 'Límite precio base',
      options: {
        filter: false
      }
    },
    {
      name: 'kilometerPrice',
      label: 'Precio Km Lineal',
      options: {
        filter: false
      }
    },
    {
      name: 'minute_price',
      label: 'Precio hora',
      options: {
        filter: false,
        customBodyRender: (price: RatePartialModel['minute_price']) =>
          (price * 60).toFixed(2)
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,

        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => props.selectToEdit(props.rates[index].id)}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
                onClick={() =>
                  verifyAction({
                    title: '¡Estás por eliminar una tarifa!',
                    body: 'Esta acción va a eliminar la tarifa. ¿Estás seguro?',
                    onAccept: () => props.onDelete(props.rates[index].id)
                  })
                }
                shape="circle"
                color="red"
              >
                <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ];

  const data = props.rates.map((x) => ({
    ...x
  }));

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Clientes CC'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
export default RateTable;
