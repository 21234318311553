import { Dialog, IconButton } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';

type Props = {
  onApproveRequest: () => void;
  onCloseModalConfirm: () => void;
  isLoading: boolean;
  isOpenApprove: boolean;
};

const ConfirmAction = ({
  isOpenApprove,
  isLoading,
  onCloseModalConfirm,
  onApproveRequest
}: Props): JSX.Element => {
  return (
    <Dialog open={isOpenApprove} onClose={onCloseModalConfirm} maxWidth="md">
      <div className="px-4 py-4" style={{ width: 450, overflow: 'hidden' }}>
        <div className="mb-3 flex justify-between">
          <div className="flex items-center gap-2">
            <WarningIcon style={{ color: '#69d6c0' }} />
            <p className="text-lg text-gray-800">Aceptar solicitudes</p>
          </div>
          <IconButton
            size="small"
            aria-label="close modal"
            onClick={onCloseModalConfirm}
          >
            <ClearIcon fontSize="medium" />
          </IconButton>
        </div>
        <div className="mt-3 pb-5 text-gray-600">
          Estás por aceptar las solicitudes que seleccionaste. ¿Seguro que
          quieres hacerlo?
        </div>
        <div className="flex items-center justify-end gap-3 pt-4">
          <Button onClick={onCloseModalConfirm} variant="light" color="gray">
            Cancelar
          </Button>
          <Button
            onClick={onApproveRequest}
            variant="light"
            color="green"
            isLoading={isLoading}
          >
            Sí, aceptar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmAction;
