// Cuidado que esto se usa en varios lados como por ejemplo en las tarifas del cliente de empresa.
// Si se cambia aca revisar que nada rompa
export enum ViajeTrasladoTipoServicio {
  EJECUTIVO = 'Ejecutivo',
  VIP = 'VIP',
  UTILITARIO = 'Utilitario',
  UTILITARIO_MENSAJERIA = 'Utilitario mensajeria'
}

export const TIPOS_SERVICIO_VIAJE_TRASLADO = Object.values(
  ViajeTrasladoTipoServicio
).sort();

export const advancedHoursOptions = [
  {
    value: '0.25',
    text: '15 minutos'
  },
  {
    value: '0.5',
    text: '30 minutos'
  },
  {
    value: '0.75',
    text: '45 minutos'
  },
  {
    value: '1',
    text: '1 hora'
  }
];
