// @flow
import {
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMoneyBill,
  faPencilAlt,
  faThumbtack,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';

import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'shared/components';
import Profile from 'shared/components/Profile';
import LocationDetails from '../components/LocationDetails';
import { MapaRecorridoComparativaGoogle } from '../components/MapaRecorridoComparativaGoogle';
import SendReceiptModal from '../components/SendReceiptModal';
import { ViajeTrasladoModificacionesTable } from '../components/ViajeTrasladoModificacionesTable';
import { ViajeTrasladoStatesTable } from '../components/ViajeTrasladoStatesTable';
import { ViajeTrasladoExpensesType } from '../models/ViajeTrasladoExpenses';
import useViajesTraslado from '../services/ViajeTrasladoService';
import { isTransferReservationPrivate } from '../services/isTransferReservationPrivate';
import { downloadRecipiet } from '../services/recipietService';

export function ViajeTrasladoDetailPage() {
  const history = useHistory();
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const viajeTrasladoRepository = useViajesTraslado();
  /*  const settlementRepo = useDriverSettlementRepository(); */

  const [currentStep, setCurrentStep] = useState<
    | 'personal'
    | 'general'
    | 'ubicaciones'
    | 'recorrido'
    | 'importes'
    | 'modificaciones'
  >('general');

  const viajeQuery = useSuperQuery(
    () => viajeTrasladoRepository.get(Number.parseInt(id)),
    { showSpinner: true }
  );

  const downloadVoucher = () => {
    return downloadRecipiet(id)
      .then((data: any) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Voucher-ID-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => alert(error));
  };

  /*   const settlementResumeQuery = useQuery(['settlement_resume'], () =>
    settlementRepo.getDriverSettlementDetail(id, viajeQuery.data?.assigned_driver?.codigo!), {
      enabled: !!viajeQuery.data?.id
    }
  ); */

  const geolocationsQuery = useSuperQuery(() =>
    viajeTrasladoRepository.getRoute(Number.parseInt(id))
  );
  const expensesQuery = useSuperQuery(() =>
    viajeTrasladoRepository.getExpenses(Number.parseInt(id))
  );
  const modificacionesQuery = useSuperQuery(() =>
    viajeTrasladoRepository.getModificaciones(Number(id))
  );

  if (!viajeQuery.data) {
    return null;
  }

  const locations = viajeQuery.data.locations.map((p) => ({
    address: p.address,
    observation: p.observation ?? '',
    passengers: p.passengers.map((pa) => ({
      name: pa.name,
      phone: pa.phone ? pa.phone.toString() : ''
    }))
  }));

  const allowedClientIds = [2030];

  const steps = {
    general: (
      <Card className="flex-grow" title="Informacion general">
        <div className="p-8">
          {isTransferReservationPrivate(viajeQuery.data) ? null : (
            <div className="border-b-2 border-dashed pb-8">
              <strong className="text-lg">Solicitante</strong>
              <p className="subtitle mt-5 space-x-10">
                <strong className="title">Nombre:</strong>{' '}
                {viajeQuery.data.requirements?.applicant?.name}
                <strong className="title">Telefono:</strong>{' '}
                {viajeQuery.data.requirements?.applicant?.phone}
                <strong className="title">Mail:</strong>{' '}
                {viajeQuery.data.requirements?.applicant?.email}
              </p>
            </div>
          )}
          <div className="mt-8 border-b-2 border-dashed pb-8 flex flex-col gap-10">
            <p className="space-x-10">
              <strong className="title">
                Tiene prioridad:{' '}
                {viajeQuery.data.hasPriority ? (
                  <Chip color="green">Si</Chip>
                ) : (
                  <Chip color="red">No</Chip>
                )}
              </strong>
              <strong className="title">
                Tiene Out Site:{' '}
                {viajeQuery.data.has_out_site ? (
                  <Chip color="green">Si</Chip>
                ) : (
                  <Chip color="red">No</Chip>
                )}
              </strong>
            </p>
            <div className="flex justify-start gap-6">
              <strong className="title">
                Es evento:{' '}
                {viajeQuery.data.is_event ? (
                  <Chip color="green">Si</Chip>
                ) : (
                  <Chip color="red">No</Chip>
                )}
              </strong>

              <strong className="title">
                {viajeQuery.data.is_event ? (
                  <span className="text-gray-400">
                    Nombre:{' '}
                    <span className="text-gray-500">
                      {viajeQuery.data.event_name}{' '}
                    </span>
                  </span>
                ) : null}
              </strong>
            </div>
          </div>
          <div className="mt-8 border-b-2 pb-8 border-dashed">
            <strong className="text-lg">Observacion Coordinacion</strong>
            <p className="subtitle">
              {viajeQuery.data.coordinationObservation}
            </p>
          </div>
          <div className="flex justify-end gap-2">
            {allowedClientIds.includes(viajeQuery.data.client.id) && (
              <div className="mt-8 flex justify-end">
                <Button
                  onClick={() => setIsInvoiceModalOpen(true)}
                  variant="contained"
                  className="w-fit"
                  color="blue"
                >
                  Enviar Recibo
                </Button>
              </div>
            )}
            <div className="mt-8 flex justify-end">
              <Button
                onClick={() => downloadVoucher()}
                variant="contained"
                className="w-fit"
                color="blue"
              >
                Descargar Recibo
              </Button>
            </div>
          </div>
        </div>
      </Card>
    ),
    ubicaciones: (
      <Card title="Ubicaciones">
        <div className="p-8">
          <LocationDetails locations={locations} />
        </div>
      </Card>
    ),
    recorrido: (
      <Card className="h-full w-full" title="Recorrido">
        <div className="h-5/6 w-full p-8">
          <MapaRecorridoComparativaGoogle
            recorrido={(geolocationsQuery.data ?? []).map((pos) => ({
              lat: pos.latitude,
              lng: pos.longitude
            }))}
            recorridoGoogle={{
              datetime: viajeQuery.data.departureDatetime,
              puntos: viajeQuery.data.locations.map((pos) => ({
                lat: pos.address.latitud,
                lng: pos.address.longitud
              }))
            }}
          />
        </div>
      </Card>
    ),
    importes: (
      <Card className="flex-grow" title="Importes">
        <div className="p-8">
          {!viajeQuery.data.estimated_prices ? null : (
            <div className="border-b-2 border-dashed pb-8 ">
              <div className="flex items-center gap-4">
                <strong className="text-lg">Estimados</strong>
                <Chip>{viajeQuery.data.estimated_prices.rate_type}</Chip>
                {viajeQuery.data.estimated_prices.fixed_price_name ? (
                  <Chip color="blue">
                    {viajeQuery.data.estimated_prices.fixed_price_name}
                  </Chip>
                ) : null}
                <div className="ml-auto">
                  <Chip color="green" size="md">
                    ${viajeQuery.data.estimated_prices.transfer_price}
                  </Chip>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="mt-5 flex items-center space-x-4">
                  <strong className="title w-1/4">Kilometros:</strong>
                  <Chip color="green">
                    ${viajeQuery.data.estimated_prices.km_price}
                  </Chip>
                  <Chip color="gray">
                    {viajeQuery.data.estimated_prices.kilometers} km.
                  </Chip>
                </div>
                <div className="mt-5 flex items-center space-x-4">
                  <strong className="title w-1/4">Espera:</strong>
                  <Chip color="green">
                    ${viajeQuery.data.estimated_prices.waiting_minutes_price}
                  </Chip>
                  <Chip color="gray">
                    {viajeQuery.data.estimated_prices.waiting_minutes} m.
                  </Chip>
                </div>
                <div className="mt-5 flex items-center space-x-4">
                  <strong className="title w-1/4">Out-site:</strong>
                  <Chip color="green">
                    ${viajeQuery.data.estimated_prices.out_site_price}
                  </Chip>
                </div>
                <div className="mt-5 flex items-center space-x-4">
                  <strong className="title w-1/4">Night-plus:</strong>
                  <Chip color="green">
                    ${viajeQuery.data.estimated_prices.night_plus_price}
                  </Chip>
                </div>
              </div>
            </div>
          )}
          <div>
            {!viajeQuery.data.calculated_prices ? (
              <div className="mt-4">
                <strong className="text-lg">Realizados</strong>
                <p>Aún no se ha realizado el viaje</p>
              </div>
            ) : (
              <div>
                <div className="border-b-2 border-dashed pb-8 pt-8">
                  <div className="flex items-center gap-4">
                    <strong className="text-lg">Realizados</strong>
                    <Chip>{viajeQuery.data.calculated_prices.rate_type}</Chip>
                    {viajeQuery.data.calculated_prices.fixed_price_name ? (
                      <Chip color="blue">
                        {viajeQuery.data.calculated_prices.fixed_price_name}
                      </Chip>
                    ) : null}
                    <div className="ml-auto">
                      <Chip color="green" size="md">
                        ${viajeQuery.data.calculated_prices.transfer_price}
                      </Chip>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="mt-5 flex items-center space-x-4">
                      <strong className="title w-1/4">Kilometros:</strong>
                      <Chip color="green">
                        ${viajeQuery.data.calculated_prices.km_price}
                      </Chip>
                      <Chip color="gray">
                        {viajeQuery.data.calculated_prices.kilometers} km.
                      </Chip>
                    </div>
                    <div className="mt-5 flex items-center space-x-4">
                      <strong className="title w-1/4">Espera:</strong>
                      <Chip color="green">
                        $
                        {
                          viajeQuery.data.calculated_prices
                            .waiting_minutes_price
                        }
                      </Chip>
                      <Chip color="gray">
                        {viajeQuery.data.calculated_prices.waiting_minutes} m.
                      </Chip>
                    </div>
                    <div className="mt-5 flex items-center space-x-4">
                      <strong className="title w-1/4">Out-site:</strong>
                      <Chip color="green">
                        ${viajeQuery.data.calculated_prices.out_site_price}
                      </Chip>
                    </div>
                    <div className="mt-5 flex items-center space-x-4">
                      <strong className="title w-1/4">Plus-nocturno:</strong>
                      <Chip color="green">
                        ${viajeQuery.data.calculated_prices.night_plus_price}
                      </Chip>
                    </div>
                  </div>
                </div>
                {!viajeQuery.data.final_prices ? (
                  <div>
                    <strong className="text-lg">Precio Final</strong>
                    <p>Aún no hay datos finales</p>
                  </div>
                ) : (
                  <div className="border-b-2 border-dashed pb-8 pt-8">
                    <div className="flex items-center gap-4">
                      <strong className="text-lg">Precio Final</strong>
                      <Chip>{viajeQuery.data.final_prices.rate_type}</Chip>
                      {viajeQuery.data.final_prices.fixed_price_name ? (
                        <Chip color="blue">
                          {viajeQuery.data.final_prices.fixed_price_name}
                        </Chip>
                      ) : null}
                      <div className="ml-auto flex flex-col space-y-1">
                        <Chip color="green" size="md">
                          ${viajeQuery.data.final_prices.transfer_price}
                        </Chip>
                        <Chip color="red" size="md">
                          $ {viajeQuery.data.final_prices.driver_price}
                        </Chip>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="mt-5 flex items-center space-x-4">
                        <strong className="title w-1/4">Kilometros:</strong>
                        <Chip color="green">
                          ${viajeQuery.data.final_prices.km_price}
                        </Chip>
                        <Chip color="gray">
                          {viajeQuery.data.final_prices.kilometers} km.
                        </Chip>
                      </div>
                      <div className="mt-5 flex items-center space-x-4">
                        <strong className="title w-1/4">Espera:</strong>
                        <Chip color="green">
                          ${viajeQuery.data.final_prices.waiting_minutes_price}
                        </Chip>
                        <Chip color="gray">
                          {viajeQuery.data.final_prices.waiting_minutes} m.
                        </Chip>
                      </div>
                      <div className="mt-5 flex items-center space-x-4">
                        <strong className="title w-1/4">Out-site:</strong>
                        <Chip color="green">
                          ${viajeQuery.data.final_prices.out_site_price}
                        </Chip>
                      </div>
                      <div className="mt-5 flex items-center space-x-4">
                        <strong className="title w-1/4">Plus-nocturno:</strong>
                        <Chip color="green">
                          ${viajeQuery.data.final_prices.night_plus_price}
                        </Chip>
                      </div>
                      <div className="mt-5 flex items-center space-x-4">
                        <strong className="title w-1/4">Gastos:</strong>
                        <Chip color="green">
                          ${viajeQuery.data.final_prices?.expenses_price}
                        </Chip>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-8 border-b-2 border-dashed pb-8">
                  <strong className="text-lg">Gastos</strong>
                  {expensesQuery.data
                    ?.filter((e) => e.type === ViajeTrasladoExpensesType.GASTO)
                    .map((e) => (
                      <p className="subtitle mt-5 space-x-10">
                        <a className="title">Nombre: </a>
                        {e.name}
                        <strong className="title">
                          Precio: <Chip color="red">${e.price} </Chip>
                        </strong>
                        <a
                          href={e.photo!}
                          target="_blank"
                          rel="noreferrer"
                          className="cursor-pointer hover:underline"
                        >
                          Ver Imagen
                        </a>
                      </p>
                    ))}
                </div>
                <div className="mt-8 border-b-2 border-dashed pb-8">
                  <strong className="text-lg">Peajes</strong>
                  {expensesQuery.data
                    ?.filter((e) => e.type === ViajeTrasladoExpensesType.PEAJE)
                    .map((e) => (
                      <p className="subtitle mt-5 space-x-10">
                        <strong className="title">Nombre: </strong>
                        {e.name}
                        <strong className="title">
                          Precio: <Chip color="red">${e.price} </Chip>
                        </strong>
                      </p>
                    ))}
                </div>
                <div className="mt-8">
                  <strong className="text-lg">Observacion Desvio</strong>
                  <p className="subtitle">
                    {viajeQuery.data.deviation_observation}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    ),
    estados: (
      <Card className="flex-grow" title="Estados">
        <div className="p-8">
          <ViajeTrasladoStatesTable
            reservationStates={viajeQuery.data.states}
          />
        </div>
      </Card>
    ),
    modificaciones: (
      <Card className="flex-grow" title="Modificaciones">
        <div className="p-8">
          <ViajeTrasladoModificacionesTable
            data={modificacionesQuery.data ?? []}
          />
        </div>
      </Card>
    )
  };

  return (
    <>
      <PageHeader
        title="Traslados"
        subtitle={
          <div className="flex space-x-2">
            <span
              className="cursor-pointer text-xs text-gray-400 hover:underline"
              onClick={history.goBack}
            >
              Traslados
            </span>
            <span>/</span>
            <span className="text-xs text-gray-400">
              {viajeQuery.data.client.name}
            </span>
          </div>
        }
      />
      <PageContainer>
        <Profile.Container
          navigation={
            <Profile.Navigation
              image={
                <div className="flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-md bg-blue-100">
                  <h1 className="text-2xl font-bold text-blue-500">
                    {viajeQuery.data.client.name
                      .split(' ')
                      .map((name) => name[0])
                      .join('')
                      .toUpperCase()}
                  </h1>
                </div>
              }
              resume={
                <Profile.Resume.Container>
                  <Profile.Resume.Title>
                    {viajeQuery.data.client.name}
                  </Profile.Resume.Title>
                  <Profile.Resume.Subtitle>
                    {viajeQuery.data.reservationDatetime.format(
                      'DD/MM/YYYY HH:mm'
                    )}
                  </Profile.Resume.Subtitle>
                  <Profile.Resume.Badge color="green">
                    {viajeQuery.data.state}
                  </Profile.Resume.Badge>
                </Profile.Resume.Container>
              }
              description={
                <Profile.Description.Container>
                  <Profile.Description.Item
                    label="ID"
                    value={viajeQuery.data.id}
                  />
                  <Profile.Description.Item
                    label="Servicio"
                    value={viajeQuery.data.serviceType}
                  />
                  <Profile.Description.Item
                    label="Canal de ingreso"
                    value={viajeQuery.data.entryChannel}
                  />
                  <Profile.Description.Item
                    label="Chofer"
                    value={
                      viajeQuery.data.assigned_driver
                        ? '#' +
                          viajeQuery.data.assigned_driver.codigo +
                          ' - ' +
                          viajeQuery.data.assigned_driver.nombreCompleto
                        : 'Sin asignar'
                    }
                  />
                </Profile.Description.Container>
              }
              menu={
                <Profile.Menu.Container
                  value={currentStep}
                  onSelect={setCurrentStep}
                >
                  <Profile.Menu.Item
                    value="general"
                    label="General"
                    icon={<FontAwesomeIcon icon={faUser} />}
                  />
                  <Profile.Menu.Item
                    value="ubicaciones"
                    label="Ubicaciones"
                    icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                  />
                  <Profile.Menu.Item
                    value="recorrido"
                    label="Recorrido"
                    icon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
                  />
                  <Profile.Menu.Item
                    value="importes"
                    label="Importes"
                    icon={<FontAwesomeIcon icon={faMoneyBill} />}
                  />
                  <Profile.Menu.Item
                    value="estados"
                    label="Estados"
                    icon={<FontAwesomeIcon icon={faThumbtack} />}
                  />
                  <Profile.Menu.Item
                    value="modificaciones"
                    label="Modificaciones"
                    icon={<FontAwesomeIcon icon={faPencilAlt} />}
                  />
                </Profile.Menu.Container>
              }
            />
          }
          body={
            <>
              {currentStep !== 'recorrido' && steps[currentStep]}
              <div
                className={currentStep === 'recorrido' ? 'flex-1' : 'hidden'}
              >
                {steps.recorrido}
              </div>
            </>
          }
        />
      </PageContainer>
      {isInvoiceModalOpen && (
        <SendReceiptModal
          clientId={viajeQuery.data.client.id}
          onClose={() => setIsInvoiceModalOpen(false)}
          reserveId={id}
        />
      )}
      {/*       <div className="my-4 flex items-start gap-8 px-4">
        <Card className="flex w-1/3 flex-shrink-0 justify-center p-8">
            {settlementResumeQuery.data === undefined ? null : (
              <DriverSettlementDetailResumeComponent
                settlement={settlementResumeQuery.data}
              />
            )}
        </Card>
      </div> */}
    </>
  );
}
