import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Query } from '@vadiun/react-hooks-legacy';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { Field, FormikErrors } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import React from 'react';

type Props = {
  client: ClientBusinessModel | undefined;
  errors: FormikErrors<any>;
  autorizadosQuery: Query<AuthorizedModel[]>;
};

export const TransferReservationClientRequeriments = ({
  client,
  autorizadosQuery,
  errors
}: Props) => {
  if (client === undefined) {
    return null;
  }
  return (
    <>
      {(client?.reservationRequirement?.needsBuyOrder ||
        client?.reservationRequirement?.needsCostCenter ||
        client?.reservationRequirement?.needsEmployeeNumber ||
        client?.reservationRequirement?.needsPlatformApplication ||
        client?.reservationRequirement?.needsSector ||
        client?.reservationRequirement?.needsTripNumber ||
        client?.reservationRequirement?.needsData1 ||
        client?.reservationRequirement?.needsData2 ||
        client?.reservationRequirement?.needsAuthorized ||
        client?.reservationRequirement?.needsSubCostCenter ||
        client?.reservationRequirement?.needsData3) && (
        <div className="grid grid-cols-2 gap-6 border-b-2 border-dashed py-8">
          {client?.reservationRequirement?.needsAuthorized && (
            <Field
              name="autorizado"
              component={Autocomplete}
              fullWidth
              getOptionLabel={(autorizado: AuthorizedModel) => autorizado.name}
              options={autorizadosQuery.data || []}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial
                  {...params}
                  error={!!errors.autorizado}
                  helperText={errors.autorizado}
                  label="Autorizado"
                  variant="outlined"
                  disabled={autorizadosQuery.isLoading}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {autorizadosQuery.isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  fullWidth
                />
              )}
            />
          )}
          {client?.reservationRequirement?.needsCostCenter &&
            (!client?.business_client_cost_centers?.length ? (
              <Field
                name="costCenter"
                label="Centro de costos"
                variant="outlined"
                component={TextField}
                className="col-span-1"
                fullWidth
              />
            ) : (
              <FormControl variant="outlined" fullWidth className="col-span-1">
                <InputLabel>Centro de costos</InputLabel>
                <Field
                  component={Select}
                  name="costCenter"
                  label="Centro de costos"
                >
                  {client?.business_client_cost_centers?.map((center) => (
                    <MenuItem value={center.name}>{center.name}</MenuItem>
                  ))}
                </Field>
              </FormControl>
            ))}
          {client?.reservationRequirement?.needsSubCostCenter && (
            <FormControl variant="outlined" fullWidth className="col-span-1">
              <InputLabel>Sub Centro de costos</InputLabel>
              <Field
                component={Select}
                name="subCostCenter"
                label="Sub Centro de costos"
                error={!!errors.subCostCenter}
                helperText={errors.subCostCenter}
              >
                {client?.business_client_sub_cost_centers?.map((center) => (
                  <MenuItem value={center.name}>{center.name}</MenuItem>
                ))}
              </Field>
              {errors.subCostCenter && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.subCostCenter}
                </p>
              )}
            </FormControl>
          )}
          {client?.reservationRequirement?.needsSector && (
            <Field
              name="sector"
              label="Sector"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsBuyOrder && (
            <Field
              name="buyOrder"
              label="Orden de compra"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsEmployeeNumber && (
            <Field
              name="employeeNumber"
              label="Legajo"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsPlatformApplication && (
            <Field
              name="platformApplication"
              label="Solicitud en plataforma"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsTripNumber && (
            <Field
              name="tripNumber"
              label="Número de viaje"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsData1 && (
            <Field
              name="data1"
              label="Dato 1"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsData2 && (
            <Field
              name="data2"
              label="Dato 2"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
          {client?.reservationRequirement?.needsData3 && (
            <Field
              name="data3"
              label="Dato 3"
              variant="outlined"
              component={TextField}
              className="col-span-1"
              fullWidth
            />
          )}
        </div>
      )}
    </>
  );
};
