import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { RepetitiveReservationFiltersFormType } from '../components/RepetitiveReservationFilters';

export const useRepetitiveFilters = (
  initialState: RepetitiveReservationFiltersFormType
) => {
  const location = useLocation<
    Omit<RepetitiveReservationFiltersFormType, 'client_id'> & {
      client_id: number;
    }
  >();
  const history = useHistory();
  const lastState = location.state
    ? {
        ...location.state,
      }
    : initialState;
  const [state, setState] =
    useState<RepetitiveReservationFiltersFormType>(lastState);

  useEffect(() => {
    history.replace(location.pathname, {
      ...state,
    });
  }, [state]);

  return [state, setState] as const;
};
