import { ClientPrivateRateForm } from './forms/ClientPrivateRateForm';
import { ClientPrivateRateFormType } from './forms/ClientPrivateRateFormType';

interface Props {
  edit: (rateFormValue: ClientPrivateRateFormType) => Promise<void>;
  initialValue: ClientPrivateRateFormType;
}

export function ClientPrivateRateEdit({ edit, initialValue }: Props) {
  return <ClientPrivateRateForm onSubmit={edit} initialValues={initialValue} />;
}
