import { FunctionComponent } from 'react';
import FirstPanelStatusDrivers from './FirstPanelStatusDrivers';
import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import { DriversMonitor } from '../NewDriversMonitor';
import { MonitorDriversInfo } from '../../types/Driver';

interface ContainerMonitorProps {
  setDriverDetail: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      details: ChoferTrabajando | undefined;
    }>
  >;
  monitorDriversInfo: MonitorDriversInfo;
  statusActive: string;
  onFilterByStatusDriver: (state: string) => void;
  isLoadingDrivers: boolean;
  reloadDriverMonitorInfo: () => Promise<void>;
  drivers: ChoferTrabajando[];
}

const ContainerMonitor: FunctionComponent<ContainerMonitorProps> = ({
  setDriverDetail,
  drivers,
  monitorDriversInfo,
  statusActive,
  onFilterByStatusDriver,
  isLoadingDrivers,
  reloadDriverMonitorInfo
}) => {
  return (
    <>
      <FirstPanelStatusDrivers
        statusActive={statusActive}
        onFilterByStatusDriver={onFilterByStatusDriver}
        amountPerState={
          monitorDriversInfo ?? {
            busy: 0,
            free: 0,
            onBrake: 0,
            workdayNotStarted: 0,
            workdayStarted: 0
          }
        }
        isLoadingDrivers={isLoadingDrivers}
        reloadDriverMonitorInfo={reloadDriverMonitorInfo}
      />

      {isLoadingDrivers ? null : (
        <DriversMonitor
          statusActive={statusActive}
          isActive={statusActive.length !== 0}
          showDriversSleeping={statusActive === 'ON_BRAKE'}
          workingDrivers={drivers}
          driversWorkingDayNotStarted={[]}
          groupType="current_district"
          showDriverDetails={(driver) =>
            setDriverDetail({ isOpen: true, details: driver })
          }
        />
      )}
    </>
  );
};

export default ContainerMonitor;
