import { AntenaModel } from 'app/antena/antena/models/Antena';

export type AntenaFormType = Omit<AntenaModel, 'id' | 'grupo'> & {
  id?: number;
  grupo_id: string;
};

export const antenaFormBuilder = {
  toBackEnd: (antena: AntenaFormType) => {
    return {
      id: antena.id,
      nombre: antena.nombre,
      latitud: antena.latitud,
      longitud: antena.longitud,
      grupo_id: antena.grupo_id
    };
  },
  fromModel: (antena: AntenaModel): AntenaFormType => {
    return {
      ...antena,
      grupo_id: String(antena.grupo.id)
    };
  }
};
