import { Moment } from 'moment';

type Props = {
  schedule: {
    arrival: Moment;
    exit: Moment;
    end: Moment;
  };
};

export const TransferReservationTableTimesCell = ({ schedule }: Props) => {
  return (
    <div className="grid w-32 grid-cols-2 items-center">
      <span className={'title col-span-1 mr-4'} title="Salida de chofer">
        {schedule.exit.format('HH:mm')}
      </span>
      <span
        className={'col-span-1 text-xs text-gray-400 '}
        title="Salida de chofer"
      >
        {schedule.exit.format('DD/MM')}
      </span>
      <span
        className="title col-span-1 mr-4 font-bold"
        style={{ fontSize: 16 }}
        title="Llegada a origen"
      >
        {schedule.arrival.format('HH:mm')}
      </span>
      <span
        className="col-span-1 text-xs font-bold text-gray-700"
        style={{ fontSize: 13 }}
        title="Llegada a origen"
      >
        {schedule.arrival.format('DD/MM')}
      </span>
      <span
        className="title col-span-1 mr-4 text-gray-400"
        title="Llegada a destino"
      >
        {schedule.end.format('HH:mm')}
      </span>
      <span
        className="col-span-1 text-xs text-gray-400"
        title="Llegada a destino"
      >
        {schedule.end.format('DD/MM')}
      </span>
    </div>
  );
};
