import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Divider,
  Drawer,
  IconButton
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@vadiun/react-components';
import { useTableQuery } from '@vadiun/react-datatable';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { ClientType } from 'app/client/main/models/ClientType';
import { useViajesTraslado } from 'app/viaje/services';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import CancelViajeTrasladoModal from '../../viajeTraslado/components/CancelViajeTrasladoModal';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import ViajeTrasladoResumenModal from '../../viajeTraslado/components/ViajeTrasladoResumenModal';
import { TransferReservationModel } from '../../viajeTraslado/models/TransferReservation';
import {
  TransferReservationPrices,
  TransferReservationPricesRateType
} from '../../viajeTraslado/models/TransferReservationPrices';
import CorrectPriceForm from '../components/forms/CorrectPriceForm';
import { CorrectPriceFormType } from '../components/forms/CorrectPriceFormik';
import TarifaDetalle from '../components/TarifaDetalle';
import { ViajeTrasladoPendienteAuditoriaTable } from '../components/ViajeTrasladoPendienteAuditoriaTable';

export const ViajeTrasladoPendienteAuditoriaContadoPage = () => {
  const tripRepository = useViajesTraslado();
  const qc = useQueryClient();
  const ViajeTrasladoService = useViajesTraslado();
  const [showFilters, setShowFilters] = useState(false);
  const clientBusinessRepository = useClientBusiness();
  const approveFinalPrice = useSuperMutation(
    ViajeTrasladoService.approveFinalPriceContado
  );
  const correctFinalPrice = useSuperMutation(
    ViajeTrasladoService.correctFinalPriceAuditoriaContado
  );

  const [filters, setFilters] = useFilters({
    dateFrom: moment().add(-1, 'years').startOf('d'),
    dateTo: moment().hour(23).minutes(59),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false
  });

  const tableQuery = useTableQuery(
    [
      'reservas',
      'a-auditar-contado',
      {
        ...filters,
        dateFrom: filters.dateFrom.format(),
        dateTo: filters.dateTo.format()
      }
    ],
    (params: URLSearchParams) =>
      tripRepository.getPendingAuditContado(params, filters)
  );

  const [locationsDetail, setLocationsDetail] = useState<{
    isOpen: boolean;
    details: TransferReservationModel | undefined;
  }>({ isOpen: false, details: undefined });
  const [transferReservationAuditando, setTransferReservationAuditando] =
    useState<TransferReservationModel | undefined>(undefined);
  const [isCorrectPriceModalOpen, setIsCorrectPriceModalOpen] = useState(false);

  // 2- Se buscan las tarifas del cliente de la reserva para mostrarlas en el modal de correccion de precios
  const tarifaQuery = useSuperQuery(
    async () => {
      if (
        transferReservationAuditando === undefined ||
        transferReservationAuditando.clientType === ClientType.private
      ) {
        return undefined;
      }
      return await clientBusinessRepository.getCurrentRate(
        transferReservationAuditando.client.id
      );
    },
    undefined,
    [transferReservationAuditando]
  );

  const approvePrices = async (reservation: TransferReservationModel) => {
    await approveFinalPrice.mutate(reservation);
    setIsCorrectPriceModalOpen(false);
    tableQuery.refetch();
  };

  const [pricingInfo, setPricingInfo] = useState<
    TransferReservationPrices | undefined
  >(undefined);

  // 3- Cada vez que se cambia algo en el formulario de correccion de precios se le pega al back para que me diga cuales serian los precios despues del cambio
  const calculateNewPrices = async (form: CorrectPriceFormType) => {
    const data = {
      id: transferReservationAuditando!.id,
      waiting_minutes: form.waiting_minutes,
      km: form.km,
      transfer_price: 0,
      expenses_price: form.expenses_price,
      observation: form.observation,
      rate_type: form.rate_type
    };

    const pricingQuery = await ViajeTrasladoService.getReservationPricing(data);

    setPricingInfo(pricingQuery);
  };

  const correctTransferPrice = async (form: CorrectPriceFormType) => {
    const data = {
      id: transferReservationAuditando?.id ?? 0,
      waiting_minutes: form.waiting_minutes,
      km: form.km,
      expenses_price: form.expenses_price,
      transfer_price: form.transfer_price,
      observation: form.observation,
      rate_type: form.rate_type
    };
    await correctFinalPrice.mutate(data);
    setIsCorrectPriceModalOpen(false);
    setTransferReservationAuditando(undefined);
    setPricingInfo(undefined);
    tableQuery.refetch();
  };

  // Si se tiene pricingInfo quiere decir que algun campo del formulario de correccion de precios se cambió,
  // sino por defecto se toman los precios calculados y sino esta el otro return que en realidad es imposible que llegue pero porq las dudas está
  const getDefaultPrices = () => {
    if (pricingInfo) {
      return {
        waiting_minutes: pricingInfo.waiting_minutes,
        km: pricingInfo.kilometers,
        expenses_price: pricingInfo.expenses_price,
        transfer_price: pricingInfo.transfer_price,
        observation: pricingInfo.observation,
        rate_type: pricingInfo.rate_type
      };
    }
    const calculated_prices = transferReservationAuditando?.calculated_prices;
    if (calculated_prices) {
      return {
        waiting_minutes: calculated_prices.waiting_minutes,
        km: calculated_prices.kilometers,
        expenses_price: calculated_prices.expenses_price,
        transfer_price: calculated_prices.transfer_price,
        observation: calculated_prices.observation ?? '',
        rate_type: calculated_prices.rate_type
      };
    }

    return {
      waiting_minutes: 0,
      km: 0,
      expenses_price: 0,
      transfer_price: 0,
      observation: '',
      rate_type: TransferReservationPricesRateType.KILOMENTO_LINEAL
    };
  };

  const tarifaDelViajeSeleccionado = (tarifaQuery.data?.categories ?? []).find(
    (cat) => cat.name === transferReservationAuditando?.serviceType
  );

  const availableRateTypes: TransferReservationPricesRateType[] = [
    TransferReservationPricesRateType.KILOMENTO_LINEAL,
    TransferReservationPricesRateType.KILOMETRO_CON_REGRESO,
    TransferReservationPricesRateType.MINIMUM_PRICE,
    TransferReservationPricesRateType.CABA_CABA,
    TransferReservationPricesRateType.CABA_CABA_RETURN
  ];

  const originalRate =
    transferReservationAuditando?.calculated_prices?.rate_type;

  if (
    originalRate !== undefined &&
    !availableRateTypes.includes(originalRate)
  ) {
    availableRateTypes.push(originalRate);
  }

  const [cancelReservationModal, setCancelReservationModal] = useState<
    | {
        isOpen: false;
        reservationId: undefined;
      }
    | {
        isOpen: true;
        reservationId: number;
      }
  >({ isOpen: false, reservationId: undefined });

  const cancel = async (reservationId: number, cancelObservation: string) => {
    setCancelReservationModal({ reservationId: undefined, isOpen: false });
    await cancelReservationMutation.mutate(reservationId, cancelObservation);
    qc.invalidateQueries(['reservas', 'a-auditar-contado']);
  };
  const cancelReservationMutation = useSuperMutation(
    ViajeTrasladoService.cancelReservation,
    { showSpinner: true }
  );

  return (
    <>
      <PageHeader
        title="Traslados Contado pendientes de auditoria"
        toolbar={
          <Button variant="light" onClick={() => setShowFilters(true)}>
            Filtros
          </Button>
        }
      />
      <PageContainer lessMargin>
        <ViajeTrasladoPendienteAuditoriaTable
          tableQuery={tableQuery}
          isAdminPage={false}
          onLocationsDetails={(reservation) =>
            setLocationsDetail({ isOpen: true, details: reservation })
          }
          onApproveReservationPrices={approvePrices}
          onCancelReservation={(reservation: TransferReservationModel) => {
            setCancelReservationModal({
              isOpen: true,
              reservationId: reservation.id
            });
          }}
          onCorrectReservationPrices={(reservation) => {
            setIsCorrectPriceModalOpen(true);
            // 1- Se setea la reserva que se esta auditando
            setTransferReservationAuditando(reservation);
          }}
          onReservationShowDetails={(id) => (
            <Link to={'/main/Viaje/traslado/detalle/' + id}>
              <Button variant="light" className="w-full" color="orange">
                Detalle
              </Button>
            </Link>
          )}
        />
        <CancelViajeTrasladoModal
          isOpen={cancelReservationModal.isOpen}
          onClose={() =>
            setCancelReservationModal({
              reservationId: undefined,
              isOpen: false
            })
          }
          cancel={cancel}
          reservationId={cancelReservationModal.reservationId!}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-lg"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />

          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          />
        </div>
      </Drawer>

      {locationsDetail.details && (
        <ViajeTrasladoResumenModal
          isOpen={locationsDetail.isOpen}
          reservation={locationsDetail.details!}
          handleClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
        />
      )}
      <Dialog
        open={isCorrectPriceModalOpen}
        onClose={() => {
          setIsCorrectPriceModalOpen(false);
          setPricingInfo(undefined);
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <div className="flex gap-4 p-8">
          <div className="flex-1">
            <Accordion
              elevation={0}
              style={{ borderWidth: 1 }}
              defaultExpanded
              className=" border-dashed border-gray-300"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                Precio sugerido
              </AccordionSummary>
              <AccordionDetails>
                <TarifaDetalle tarifaInfo={pricingInfo} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              defaultExpanded
              style={{ borderWidth: 1 }}
              className=" border-dashed border-gray-300"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Tarifas fijas (
                {tarifaDelViajeSeleccionado?.fixedPrices?.length ?? 0})
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {tarifaDelViajeSeleccionado?.fixedPrices.map((price) => (
                    <p key={price.name} className="text-sm">
                      {price.name}:{' '}
                      <span className="text-green-500">${price.price}</span>
                    </p>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="flex-1">
            <CorrectPriceForm
              onSubmit={correctTransferPrice}
              onChange={calculateNewPrices}
              initialValues={getDefaultPrices()}
              availableRateTypes={availableRateTypes}
              originalRate={originalRate}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
