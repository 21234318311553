import moment, { Moment } from 'moment';

export interface DriverExpirationModel {
  name: string;
  code: string;
  id: string;
  expirados: {
    documento: string,
    vencimiento: Moment
  }[]
}

export const driverExpirationBuilder = {
  fromBackEnd: (driverExpiration): DriverExpirationModel => {
    return {
      ...driverExpiration,
      expirados: driverExpiration.expirados.map((documento) => ({documento: documento.documento, vencimiento: moment(documento.vencimiento)})  )
    }
  }
};
