import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ServerSidePagination,
  Table,
  TableColumn
} from '@vadiun/react-components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationToValidate } from '../models/TransferReservationPendingValidation';

interface Props {
  onLocationsDetails: (reservation: TransferReservationToValidate) => void;
  onDeny(reservation: TransferReservationToValidate): void;
  onValidate(reservation: TransferReservationToValidate): void;
  tableProps: ServerSidePagination<TransferReservationToValidate[]>;
}

export function ViajePendienteSolicitadoTable({
  tableProps,
  onLocationsDetails,
  onDeny,
  onValidate
}: Props) {
  const data = tableProps.data;
  const columns: TableColumn<TransferReservationToValidate>[] = [
    {
      name: 'client',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <span
              className={
                !data[index].pay_with_cash ? 'text-blue-500' : 'text-black'
              }
            >
              {data[index].client.name}
            </span>
          );
        }
      }
    },
    {
      name: 'locations',
      label: 'Domicilios ',
      options: {
        customBodyRenderLite: (index) => {
          const firstPoint = data[index].locations[0];
          const lastPoint =
            data[index].locations[data[index].locations.length - 1];
          return (
            <div className="flex w-52 flex-col items-center">
              <span className="title text-xs ">
                {createDomicilioText(firstPoint.address)}
              </span>
              <Button
                shape="circle"
                className="p-2"
                onClick={() => onLocationsDetails(data[index])}
                color={data[index].locations.length > 2 ? 'blue' : 'gray'}
              >
                <div className="flex gap-1">
                  <FontAwesomeIcon icon={faArrowDown} />
                  {data[index].locations.length > 2
                    ? data[index].locations.length
                    : null}
                </div>
              </Button>

              <span className="title text-xs ">
                {createDomicilioText(lastPoint.address)}
              </span>
            </div>
          );
        }
      }
    },
    {
      name: 'reservationDatetime',
      label: 'Fecha',
      options: {
        customBodyRenderLite: (index) =>
          data[index].reservationDatetime.format('DD/MM')
      }
    },
    {
      name: 'reservationDatetime',
      label: 'Hora',
      options: {
        customBodyRenderLite: (index) =>
          data[index].reservationDatetime.format('HH:mm')
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex flex-col gap-2">
            <Button
              variant="contained"
              color="green"
              className="w-24"
              onClick={() => onValidate(data[index])}
            >
              Validar
            </Button>
            <Button
              variant="contained"
              color="red"
              className="w-24"
              onClick={() => onDeny(data[index])}
            >
              Rechazar
            </Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          ...tableProps.serverSideProps.options,
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false
        }}
        title={''}
        columns={columns}
      />
    </div>
  );
}
