import { Button } from '@vadiun/react-components';
import { Query, useVerifyAction } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { ErrorMessage, FieldArray, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TransferReservationBaseForm } from '../base/TransferReservationBaseForm';
import {
  TransferReservationBatchFormType,
  TransferReservationBatchTripFormType
} from './TransferReservationBatchFormType';
import { transferReservationBatchFormValidation } from './TransferReservationBatchFormValidation';
import { TransferReservationBatchTripForm } from './TransferReservationBatchTripForm';
import {
  createTransferReservationBatchInitialValues,
  createTransferReservationBatchTripInitialValues
} from './TransferreservationBatchFormInitialValues';
import { ReserveDraft } from 'app/viaje/viajeTraslado/borradores/types/TravelDraft';

interface Props {
  initialValues?: TransferReservationBatchFormType;
  onSubmit: (viajeFormValues: TransferReservationBatchFormType, reset) => void;
  getClientesByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
  onClienteSelect: (cliente: ClientBusinessPartialModel) => void;
  solicitantes: ApplicantModel[];
  autorizadosQuery: Query<AuthorizedModel[]>;
  pasajeros: PassengerModel[];
  clientBusiness: ClientBusinessModel | undefined;
  fixedRates: RateModel['categories'][0]['fixedPrices'];
  onServiceTypeSelect: (type: ViajeTrasladoTipoServicio) => void;
  onCalculateDistanceAndCost: (
    viajeFormValues: TransferReservationBatchFormType
  ) => void;
  isDraftPage?: boolean;
}

export default function ReservaTrasladoBatchForm({
  initialValues: _initialValues,
  onSubmit,
  getClientesByName,
  solicitantes,
  pasajeros,
  autorizadosQuery,
  onClienteSelect,
  clientBusiness,
  onServiceTypeSelect,
  onCalculateDistanceAndCost,
  fixedRates,
  isDraftPage
}: Props) {
  const formRef = useRef<FormikProps<TransferReservationBatchFormType>>(null);
  const verifyAction = useVerifyAction();
  const initialValues = useMemo(
    () => _initialValues ?? createTransferReservationBatchInitialValues(),
    [_initialValues]
  );

  const [draftSelected, setDraftSelected] = useState<ReserveDraft>();

  useEffect(() => {
    const draftTravel = localStorage.getItem('travel-selected-draft');
    setDraftSelected(draftTravel ? JSON.parse(draftTravel) : undefined);
  }, []);

  useEffect(() => {
    if (clientBusiness === undefined) return;
    formRef.current?.setFieldValue('prioridad', clientBusiness.hasPriority);
    formRef.current?.setFieldValue('autorizado', undefined);
  }, [clientBusiness]);

  const checkPreviusDate = (viajes) => {
    return viajes.some((viaje: TransferReservationBatchTripFormType) =>
      moment(viaje.fechaYHoraDeLlegada).isBefore(moment())
    );
  };

  const checkMinutesBeforeDeparture = (viajes) => {
    return viajes.some((viaje: TransferReservationBatchTripFormType) =>
      moment(viaje.fechaYHoraDeLlegada).isBefore(
        moment(viaje.minutosPreviosASalida)
      )
    );
  };

  const authorizedDraftTravel = !draftSelected?.authorized_id?.have_error
    ? autorizadosQuery.data?.find(
        (autorizado) =>
          autorizado.name.toLowerCase() ===
          draftSelected?.authorized_id?.data.toLowerCase()
      )
    : undefined;

  const authorizedDefault = authorizedDraftTravel?.id
    ? {
        id: authorizedDraftTravel?.id,
        name: authorizedDraftTravel?.name
      }
    : null;

  const draftClientBussiness = !draftSelected?.sub_cost_center?.have_error
    ? clientBusiness?.business_client_sub_cost_centers?.find(
        (client) => client.name === draftSelected?.sub_cost_center?.data ?? ''
      )
    : undefined;

  const draftClientBussinessSelected = draftClientBussiness?.id
    ? {
        id: draftClientBussiness?.id,
        name: draftClientBussiness?.name
      }
    : null;

  if (draftSelected?.authorized_id?.have_error) {
    formRef.current?.setFieldError(
      'autorizado',
      `Nombre del autorizado: ${draftSelected?.authorized_id?.data}`
    );
  }

  if (draftSelected?.sub_cost_center?.have_error) {
    formRef.current?.setFieldError(
      'subCostCenter',
      `Sub Centro de costo: ${draftSelected?.sub_cost_center?.data}`
    );
  }

  return (
    <Formik<TransferReservationBatchFormType>
      innerRef={formRef}
      initialValues={
        isDraftPage
          ? ({
              autorizado: authorizedDefault,
              buyOrder: '',
              canalIngreso: ViajeCanalesDeIngreso.WHATSAPP,
              cliente: {
                id: draftSelected?.clientable_id,
                fantasyName: draftSelected?.client
              },
              subCostCenter: draftClientBussinessSelected?.name ?? '',
              costCenter: draftSelected?.cost_center?.have_error
                ? ''
                : draftSelected?.cost_center?.data,
              data1: draftSelected?.data_1?.data ?? '',
              data2: draftSelected?.data_2?.data ?? '',
              data3: draftSelected?.data_3?.data ?? '',
              employeeNumber: draftSelected?.employee_number?.have_error
                ? ''
                : draftSelected?.employee_number?.data,
              fixedRate: null,
              hasOutSite: false,
              isEvent: false,
              observacionCoordinacion:
                draftSelected?.coordination_observation?.data ?? '',
              pay_with_cash: false,
              prioridad: false,
              platformApplication: '',
              sector: draftSelected?.sector?.have_error
                ? ''
                : draftSelected?.sector?.data,
              solicitante: {
                name: draftSelected?.applicant_name ?? '',
                phone: draftSelected?.applicant_phone ?? '',
                email: ''
              },
              tripNumber: '',
              viajes: [
                {
                  minutosPreviosASalida:
                    moment(
                      `${draftSelected?.date?.data} ${
                        draftSelected?.hour?.have_error
                          ? '00:00'
                          : draftSelected?.hour?.data
                      }`,
                      'DD-MM-YYYYTH:mm'
                    )
                      .subtract(
                        parseInt(
                          draftSelected?.advance_hours.data.split(':')[0] ?? '0'
                        ),
                        'hour'
                      )
                      .subtract(
                        parseInt(
                          draftSelected?.advance_hours.data.split(':')[1] ?? '0'
                        ),
                        'minutes'
                      ) ?? moment(),
                  fechaYHoraDeLlegada:
                    moment(
                      `${draftSelected?.date?.data} ${
                        draftSelected?.hour?.have_error
                          ? '00:00'
                          : draftSelected?.hour?.data
                      }`,
                      'DD-MM-YYYYTH:mm'
                    ) ?? moment(),
                  puntos:
                    draftSelected?.locations
                      ?.filter((loc) => loc.address.latitude)
                      .map(({ address: { ...rest }, passengers }, index) => {
                        const {
                          latitude,
                          longitude,
                          province,
                          street,
                          street_number,
                          locality
                        } = rest;

                        if (!latitude) {
                          return [];
                        }

                        return {
                          pasajeros: passengers.map(
                            ({ name, phone, email }) => ({
                              nombreCompleto: name,
                              telefono: phone,
                              mail: email
                            })
                          ),
                          domicilio: {
                            latitud: latitude,
                            longitud: longitude,
                            calle: street,
                            numero: street_number,
                            partido: locality,
                            localidad: locality,
                            provincia: province,
                            departamento: '',
                            piso: ''
                          },
                          espera: '0',
                          observacion:
                            index === 0
                              ? draftSelected?.direction_one_observation
                                  ?.data ?? ''
                              : index === 1
                              ? draftSelected?.direction_two_observation
                                  ?.data ?? ''
                              : draftSelected?.direction_three_observation
                                  ?.data ?? ''
                        };
                      }) ?? []
                }
              ],
              plataformaId: null,
              tipoServicio: draftSelected?.service_type?.data ?? '',
              nameEvent: undefined
            } as any)
          : initialValues
      }
      validationSchema={transferReservationBatchFormValidation(
        clientBusiness,
        formRef.current?.values.isEvent ?? false
      )}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form className="w-full">
            <TransferReservationBaseForm
              {...formik}
              getClientesByName={getClientesByName}
              solicitantes={solicitantes}
              autorizadosQuery={autorizadosQuery}
              onClienteSelect={onClienteSelect}
              clientBusiness={clientBusiness}
              onServiceTypeSelect={onServiceTypeSelect}
              fixedRates={fixedRates}
            />
            <FieldArray
              name={'viajes'}
              render={(arrayHelpers) => (
                <>
                  {formik.values.viajes.map((viaje, index) => (
                    <div
                      className="my-4 border-b-2 border-dashed py-8"
                      key={index}
                    >
                      <TransferReservationBatchTripForm
                        viaje={viaje}
                        index={index}
                        formik={formik}
                        pasajeros={pasajeros}
                        clonar={() => {
                          arrayHelpers.push({
                            ...formik.values.viajes[index]
                          });
                        }}
                        revertir={() =>
                          arrayHelpers.replace(index, {
                            ...formik.values.viajes[index],
                            puntos: formik.values.viajes[index].puntos.reverse()
                          })
                        }
                        borrar={() =>
                          verifyAction({
                            onAccept: () => arrayHelpers.remove(index),
                            body: '¿Está seguro que desea borrar este viaje?'
                          })
                        }
                        previousMinutesError={checkMinutesBeforeDeparture(
                          formik.values.viajes
                        )}
                      />
                    </div>
                  ))}

                  <Button
                    onClick={() => {
                      arrayHelpers.push(
                        createTransferReservationBatchTripInitialValues()
                      );
                    }}
                    variant="light"
                    color="green"
                  >
                    Nuevo Viaje
                  </Button>
                </>
              )}
            />
            <ErrorMessage name="detallesTraslado">
              {(msg) =>
                typeof msg === 'string' ? (
                  <p className="col-span-6 text-sm text-red-500">{msg}</p>
                ) : null
              }
            </ErrorMessage>

            <div className="mt-8 flex justify-between">
              <Button
                variant="contained"
                color="primary"
                disabled={
                  formik.values.viajes.some(
                    (viaje) => viaje?.puntos?.length < 2
                  ) || formik.values.cliente === null
                }
                onClick={() => onCalculateDistanceAndCost(formik.values)}
              >
                Calcular costos y distancias
              </Button>

              <Button
                variant="contained"
                color="green"
                className="ml-auto"
                onClick={() => {
                  if (!checkPreviusDate(formik.values.viajes)) {
                    formik.submitForm();
                  } else {
                    verifyAction({
                      onAccept: () => formik.submitForm(),
                      body: '¿Está seguro de crear un viaje con una fecha previa a la de hoy?'
                    });
                  }
                }}
              >
                Guardar Viaje
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
