import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useRef } from 'react';
import { KeyboardTimePicker } from 'formik-material-ui-pickers';
import { CheckboxWithLabel } from 'formik-material-ui';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Button } from '@vadiun/react-components';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';

type Props = {
  onFilterDriversRecomended: any;
  initialValues: DriversFiltersType;
  showFilter: boolean;
  onCloseFilterDriver: () => void;
};

export type DriversFiltersType = {
  departure_date: string;
  is_type_service_vip: boolean;
  is_type_service_executive: boolean;
  is_type_service_utilitarian: boolean;
  is_type_service_messaging_utility: boolean;
  is_type_service_all: boolean;
  driver_free: boolean;
  driver_busy: boolean;
};

export const DriversFiltersSchema = Yup.object({
  departure_date: Yup.string(),
  is_type_service_vip: Yup.boolean(),
  is_type_service_executive: Yup.boolean(),
  is_type_service_utilitarian: Yup.boolean(),
  is_type_service_messaging_utility: Yup.boolean(),
  is_type_service_all: Yup.boolean(),
  driver_free: Yup.boolean(),
  driver_busy: Yup.boolean()
});

const FilterDrivers = ({
  onFilterDriversRecomended,
  initialValues,
  showFilter,
  onCloseFilterDriver
}: Props): JSX.Element => {
  const formRef = useRef<FormikProps<DriversFiltersType>>(null);

  useEffect(() => {
    formRef.current?.resetForm({
      values: {
        departure_date: initialValues.departure_date,
        driver_busy: false,
        driver_free: false,
        is_type_service_all: false,
        is_type_service_executive: false,
        is_type_service_messaging_utility: false,
        is_type_service_utilitarian: false,
        is_type_service_vip: false
      }
    });
  }, [initialValues.departure_date]);

  return (
    <div className="mt-8 h-full">
      <Formik<DriversFiltersType>
        initialValues={initialValues}
        innerRef={formRef}
        enableReinitialize
        validationSchema={DriversFiltersSchema}
        onSubmit={onFilterDriversRecomended}
      >
        {({ submitForm, resetForm, setFieldValue, ...formik }) => (
          <Form className="h-full flex flex-col">
            <div>
              <Field
                className="w-full"
                component={KeyboardTimePicker}
                label="Hora de disponibilidad"
                name="departure_date"
                ampm={false}
                inputVariant="outlined"
                margin="normal"
                format="HH:mm"
              />
              <div className="flex gap-1 rounded-md bg-gray-100 px-4 py-2">
                <p className="text-xs text-gray-400">
                  Recuerda que al editar el horario de disponibilidad de los
                  "choferes sugeridos" se modificará en la reserva el horario de
                  "salida del chofer" <QueryBuilderIcon fontSize="small" />
                </p>
              </div>

              <div className="flex flex-col gap-1 mt-6">
                <p className="text-gray-400">Reserva</p>
                <div className="grid grid-cols-2 grid-rows-3">
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="is_type_service_vip"
                    color="primary"
                    Label={{ label: 'VIP' }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="is_type_service_executive"
                    color="primary"
                    Label={{ label: 'Ejecutivo' }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="is_type_service_utilitarian"
                    color="primary"
                    Label={{ label: 'Utilitario' }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="is_type_service_messaging_utility"
                    color="primary"
                    Label={{ label: 'Utilitario Mensajería' }}
                  />
                  {/* <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="is_type_service_all"
                    color="primary"
                    Label={{ label: 'Todos' }}
                  /> */}
                  <div />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-gray-400">Estado de ocupación</p>
                <div className="grid grid-cols-2">
                  <FormikOnFieldChange<{
                    driver_free: boolean;
                  }>
                    field="driver_free"
                    onChange={(driver_free) => {
                      setFieldValue('driver_free', driver_free);
                      formik.values.driver_busy &&
                        driver_free &&
                        setFieldValue('driver_busy', false);
                    }}
                  />
                  <FormikOnFieldChange<{
                    driver_busy: boolean;
                  }>
                    field="driver_busy"
                    onChange={(driver_busy) => {
                      setFieldValue('driver_busy', driver_busy);
                      formik.values.driver_free &&
                        driver_busy &&
                        setFieldValue('driver_free', false);
                    }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="driver_free"
                    color="primary"
                    Label={{ label: 'Chofer libre' }}
                  />
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="driver_busy"
                    color="primary"
                    Label={{ label: 'Chofer por liberarse' }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-auto flex gap-6 justify-end">
              <Button
                onClick={onCloseFilterDriver}
                variant="contained"
                color="gray"
              >
                Cancelar
              </Button>
              <Button
                onClick={() =>
                  resetForm({
                    values: {
                      departure_date: initialValues.departure_date,
                      driver_busy: false,
                      driver_free: false,
                      is_type_service_all: false,
                      is_type_service_executive: false,
                      is_type_service_messaging_utility: false,
                      is_type_service_utilitarian: false,
                      is_type_service_vip: false
                    }
                  })
                }
                variant="outlined"
              >
                Borrar filtros
              </Button>
              <Button onClick={submitForm} variant="contained">
                Filtrar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FilterDrivers;
