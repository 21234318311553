import { useResponsiveBreakpoint } from '@vadiun/react-hooks';
import React from 'react';

type CustomRowProps = {
  isRowSelected: boolean;
  children: React.ReactNode;
};

const CustomRow = ({
  isRowSelected,
  children
}: CustomRowProps): JSX.Element => {
  const display = useResponsiveBreakpoint();
  const isMobileXs = display === 'xs';
  const isMobileSm = display === 'sm';
  const isMobileMd = display === 'md';

  return (
    <td
      style={{
        paddingTop: '5px',
        paddingBottom: '10px',
        paddingInline: 15,
        // background: isRowSelected ? '#FAE3E6' : '#FFFFFF',
        borderBottom: '1px solid #e9e9e9',
        width: isMobileXs || isMobileSm || isMobileMd ? '100%' : '100dvh'
      }}
    >
      {children}
    </td>
  );
};

export default CustomRow;
