import React from 'react';
import { ClientBusinessUserFormType } from './forms/ClientBusinessUserFormType';
import { ClientBusinessUserUpdateForm } from './forms/EditForm/ClientBusinessUserUpdateForm';
import { BusinessClientUser } from './ClientBusinessUserCrudComponent';

interface ClientBusinessUserUpdateFormType extends ClientBusinessUserFormType {
    id?: number;
}

interface Props {
  update: (UserValues: ClientBusinessUserUpdateFormType) => Promise<void>;
  initialValues: BusinessClientUser;
}
export function ClientBusinessUserUpdate({ update, initialValues }: Props) {
  return <ClientBusinessUserUpdateForm initialValues={initialValues} onSubmit={update} />;
}