import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Table } from 'shared/components';
import { VehiculoFabricanteModel } from '../models/VehiculoFabricante';

interface Props {
  VehiculoFabricantes: VehiculoFabricanteModel[];
  selectToEdit: (x: VehiculoFabricanteModel) => void;
  delete: (x: VehiculoFabricanteModel) => void;
  restaurar: (x: VehiculoFabricanteModel) => void;
}

const VehiculoFabricanteTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.VehiculoFabricantes[index].isDeleted ? (
            <Button
              onClick={() => props.restaurar(props.VehiculoFabricantes[index])}
            >
              Restaurar
            </Button>
          ) : (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() =>
                  props.selectToEdit(props.VehiculoFabricantes[index])
                }
                shape="circle"
                color="primary"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                onClick={() =>
                  verifyAction({
                    title: 'Estas por eliminar un fabricante!',
                    body: 'Esta accion va a eliminar el fabricante. ¿Estas seguro?',
                    onAccept: () =>
                      props.delete(props.VehiculoFabricantes[index])
                  })
                }
                shape="circle"
                color="red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )
      }
    }
  ];

  const data = props.VehiculoFabricantes.map((x) => ({
    ...x,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Fabricantes'}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default VehiculoFabricanteTable;
