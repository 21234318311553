export enum ChoferConceptoNovedad {
  ADELANTO = 'Adelanto',
  DESCUENTO = 'Descuento de beneficios',
  CANON = 'Canon de ingreso',
  OTROS = 'Otros',
}


export function conceptoNovedadChofer() {
  return Object.values(ChoferConceptoNovedad).sort();
}
