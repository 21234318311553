import { Dialog } from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@vadiun/react-components';
import { useHistory } from 'react-router-dom';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  reservations: number[] | null;
}

export const CreatedReservationsModal: React.FC<Props> = ({
  open,
  setOpen,
  reservations
}: Props) => {
  const history = useHistory();

  const onFinish = () => {
    setOpen(false);
    setTimeout(() => {
      history.push('/main/Viaje/traslado/pendiente/coordinar');
    }, 300);
  };

  return (
    <Dialog open={open} onClose={onFinish} maxWidth="lg">
      {reservations?.length && (
        <div className="p-5 min-h-[150px] flex flex-col gap-y-5">
          <h3>
            Se han creado exitosamente las reservas con los siguientes IDs:
          </h3>

          <div className="flex w-full">
            {reservations.map((r) => (
              <span className="px-2 py-1 mr-2 bg-primary-400 text-white rounded-xl">
                {r}
              </span>
            ))}
          </div>

          <div className="flex justify-end col-span-6">
            <Button variant="contained" color="blue" onClick={onFinish}>
              Aceptar
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};
