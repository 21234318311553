import { TextField } from '@material-ui/core';
import { Button, Modal } from '@vadiun/react-components';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import React from 'react';
import * as Yup from 'yup';

interface Props {
  solicitantes: ApplicantModel[];
  onSelect: (solicitante: ApplicantModel) => void;
  isOpen: boolean;
  handleClose: () => void;
}

const ReservaTrasladoSearchSolicitante = (props: Props) => {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      body={
        <Formik
          initialValues={{ nombre: '' }}
          onSubmit={async (
            { nombre },
            formikHelpers: FormikHelpers<{ nombre: string }>
          ) => {
            props.onSelect(props.solicitantes.find((s) => s.name === nombre)!);
            formikHelpers.resetForm();
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().required('Ingrese un solicitante')
          })}
        >
          {({ touched, errors, submitForm }) => (
            <Form>
              <Field
                name="nombre"
                component={Autocomplete}
                className="col-span-2"
                noOptionsText="No se han encontrado resultados"
                loadingText="Cargando..."
                fullWidth
                options={props.solicitantes.map((s) => s.name)}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    error={touched['nombre'] && !!errors['nombre']}
                    helperText={errors['nombre']}
                    label="Solicitante"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <div className="flex justify-end mt-8">
                <Button onClick={submitForm} variant="contained">
                  Continuar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default ReservaTrasladoSearchSolicitante;
