import environment from 'environment/environment';

export const downloadRecipiet = async (reserveId: string) => {
  const result = await fetch(`${environment.pricing}/invoices/download`, {
    method: 'POST',
    headers: {
      'X-Api-Key': environment.pricingKeys,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      reservation: {
        id: reserveId
      },
    }),

  });
  if (result.ok) {
    try {
      return result.blob();
    } catch {
      throw new Error('Error donwnload recip');
    }
  }
};

export const sendRecipietToEmails = async (
  emails: string[],
  clientId: number,
  reserveId: string
) => {
  const result = await fetch(`${environment.pricing}/invoices`, {
    method: 'POST',
    headers: {
      'X-Api-Key': environment.pricingKeys,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      reservation: {
        id: reserveId,
        clientable_id: clientId
      },
      emails
    })
  });

  if (!result.ok) {
    try {
      console.log(await result.json());
      throw new Error('Error sending invoices');
    } catch {
      throw new Error('Error sending invoices');
    }
  }
};
