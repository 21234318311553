import {
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, FC } from 'react';


interface DropzoneProps {
  onDragEntered: Dispatch<boolean>;
}

const Dropzone: FC<DropzoneProps> = ({ onDragEntered }) => {
  return (
    <div
      onDragLeave={() => onDragEntered(false)}
      id="dropzone"
      style={{
        borderWidth: '3px',
        borderStyle: 'dashed',
      }}
      className="absolue top-0 z-10 h-full w-full, rounded-lg"
    >
      <div className="flex w-full h-full items-center justify-center bg-white opacity-80">
        <FontAwesomeIcon icon={faPlusCircle} size="2x" color="#0284c7" />
      </div>
    </div>
  );
};

export default Dropzone;
