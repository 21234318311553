
import { httpClient } from 'shared/services/http/httpClient';
import { driverExpirationBuilder, DriverExpirationModel } from '../models/DriverExpirationModel';

export const useDriverExpirationRepository = () => {

  const getExpirations = async (
  ): Promise<DriverExpirationModel[]> => {
    const res = await httpClient.get(
      `drivers/documents-close-to-expiration`
    );

    return res.map(driverExpirationBuilder.fromBackEnd)
  }

  return { getExpirations };
};
