import React from 'react';
import { Bar } from 'react-chartjs-2';
import { withColor } from './chartHelper';

export const options = {
  responsive: true
};

interface Props {
  bars: {
    label: string;
    value: number;
  }[];
}

export const BarChartComponent = ({ bars }: Props) => {
  if (bars.length === 0) {
    return null;
  }

  const labels = bars.map((b) => b.label);
  const data = {
    labels,
    datasets: withColor<'bar'>([
      {
        label: 'cantidad',
        data: bars.map((b) => b.value)
      }
    ])
  };

  return <Bar options={options} data={data} />;
};
