import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/moment';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import * as Yup from 'yup';
import { Button } from '@vadiun/react-components';
import { ChoferDesvinculacion } from '../../models/Chofer';
import moment from 'moment';
import {
  ChoferMotivoDesvinculacion,
  motivosDesvinculacionChofer
} from '../../models/ChoferMotivoDesvinculacion';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

interface Props {
  onSubmit: (choferSchema: ChoferDesvinculacion, reset) => void;
  onRevincular: () => void;
  desvinculado: boolean;
  initialValues: ChoferDesvinculacion;
  title: string;
  labelDelete: string;
  labelRestore: string;
}

const Schema = Yup.object().shape({
  fecha: Yup.mixed().required('La fecha de desvinculacion es obligatoria'),
  observacion: Yup.string()
    .required('La observacion es obligatoria')
    .nullable(),
  motivo: Yup.string()
    .required('El motivo es obligatorio')
    .oneOf(motivosDesvinculacionChofer())
});

function ChoferFormDesvincular({
  onRevincular,
  onSubmit,
  desvinculado,
  initialValues,
  title,
  labelDelete,
  labelRestore
}: Props) {
  return (
    <Formik
      initialValues={{
        fecha: initialValues.fecha || moment(),
        observacion: initialValues.observacion || '',
        motivo:
          initialValues.motivo || ChoferMotivoDesvinculacion.CAMBIO_ACTIVIDAD
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form className="w-full grid gap-6">
          <h1>{title}</h1>
          <div className="grid grid-cols-1 gap-4">
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="fecha"
                label="Fecha desvinculacion"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>

            <FormControl variant="outlined" fullWidth>
              <InputLabel>Motivo</InputLabel>
              <Field component={Select} name="motivo" label="Motivo">
                {motivosDesvinculacionChofer().map((motivo) => (
                  <MenuItem value={motivo} key={motivo}>
                    {motivo}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Field
              name="observacion"
              label="Observacion"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>

          <div className="flex justify-between mt-1">
            <Button
              type="button"
              variant="light"
              onClick={onRevincular}
              color="green"
              disabled={!desvinculado}
            >
              {labelRestore}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="red"
              disabled={desvinculado}
            >
              {labelDelete}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ChoferFormDesvincular;
