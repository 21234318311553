import { httpClient } from 'shared/services/http/httpClient';
import { antenaBuilder, AntenaModel } from 'app/antena/antena/models/Antena';

import {
  antenaFormBuilder,
  AntenaFormType
} from 'app/antena/antena/components/AntenaForm/AntenaFormType';

const useAntenas = () => {
  const addAntena = (x: AntenaFormType) => {
    return httpClient.post('/antenas', antenaFormBuilder.toBackEnd(x));
  };

  const removeAntena = (antena: AntenaModel) => {
    return httpClient.delete('/antenas/' + antena.id);
  };

  const restoreAntena = (antena: AntenaModel) => {
    return httpClient.put('/antenas/' + antena.id + '/restore');
  };

  const updateAntena = (antena: AntenaFormType) => {
    return httpClient.put(
      '/antenas/' + antena.id,
      antenaFormBuilder.toBackEnd(antena)
    );
  };

  const getAntenas = async (): Promise<AntenaModel[]> => {
    const response = await httpClient.get('/antenas');
    return response.map(antenaBuilder.fromBackEnd);
  };

  return {
    addAntena,
    removeAntena,
    updateAntena,
    getAntenas,
    restoreAntena
  };
};

export default useAntenas;
