import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faUser } from '@fortawesome/free-solid-svg-icons';
import { SideStepper } from '@vadiun/react-components';
import RateFormik, { RateFormType } from './RateFormik';
import RateGeneralForm from './RateGeneralForm';
import RateCategoriesForm from './RateCategoriesForm';

interface Props {
  onSubmit: (rateFormValue: RateFormType, reset?: () => void) => Promise<void>;
  initialValues?: RateFormType;
}

function RateForm({
  onSubmit,
  initialValues = RateFormik.initialValues
}: Props) {
  const [currentStep, setCurrentStep] = useState<'general' | 'categories'>(
    'general'
  );
  const [rateFormValues, setRateFormValues] =
    useState<RateFormType>(initialValues);

  const { categories, ...otherFormFields } = rateFormValues;

  const steps = {
    general: (
      <RateGeneralForm
        onSubmit={async (values) => {
          setRateFormValues({
            ...values,
            categories
          });
          setCurrentStep('categories');
        }}
        initialValues={otherFormFields}
      />
    ),
    categories: (
      <RateCategoriesForm
        onSubmit={(values) =>
          onSubmit(
            {
              ...otherFormFields,
              categories: values.categories
            },
            () => setRateFormValues(RateFormik.initialValues)
          )
        }
        onGoBack={() => setCurrentStep('general')}
        initialValues={{ categories }}
      />
    )
  };
  return (
    <div className="flex flex-col md:flex-row bg-white w-full">
      <div className="p-8">
        <SideStepper.Container value={currentStep}>
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faUser} />}
            value={'general'}
            label="Informacion general"
          />
          <SideStepper.Step
            icon={<FontAwesomeIcon icon={faCoins} />}
            value={'categories'}
            label="Categorías"
          />
        </SideStepper.Container>
      </div>
      <div className="border-l-2 border-gray-200 border-solid p-12 w-full">
        {steps[currentStep]}
      </div>
    </div>
  );
}

export default RateForm;
