import React from 'react';
import Card from 'shared/components/Card/Card';

interface Props {
  navigation: React.ReactNode;
  body: React.ReactNode;
}
function ProfileContainer({ navigation, body }: Props) {
  return (
    <>
      <Card className="md:w-1/3 2xl:w-1/4">{navigation}</Card>
      {body}
    </>
  );
}

export default ProfileContainer;
