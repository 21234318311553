export enum ViajeCanalesDeIngreso {
  TELEFONO = 'Telefono',
  WHATSAPP = 'Whatsapp',
  MAIL = 'Mail',
  APLICACION = 'Aplicacion',
  VIAPOOL = 'Viapool',
  SINCROPOOL = 'Sincropool',
  MAB = 'Mab',
  ABSSA = 'ABSSA',
  SPC = 'SPC',
  OTRA = 'Otra',
  WEB = 'Web',
  TAXI = 'Taxi',
  EXCEL = 'Excel'
}

export const CANALES_DE_VIAJE = Object.values(ViajeCanalesDeIngreso).sort();
