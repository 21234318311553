// import { Spinner } from '@vadiun/react-components';
import { CircularProgress } from '@material-ui/core';
import { tw } from 'utils/tailwindUtils';

const ItemStatusDriver = ({
  label,
  value,
  color,
  borderColor,
  bgColor,
  isActive,
  status,
  isLoadingDrivers,
  loadingColor,
  onFilterByStatusDriver
}: {
  label: string;
  value: number | string;
  color: string;
  borderColor: string;
  bgColor: string;
  isActive: boolean;
  status: string;
  isLoadingDrivers: boolean;
  loadingColor: string;
  onFilterByStatusDriver: (state: string) => void;
}): JSX.Element => {
  const isEmpty = typeof value === 'string';
  return (
    <div
      className={tw(
        `flex text-xs ${color} hover:${
          !isLoadingDrivers ? bgColor : ''
        } duration-300 w-36 cursor-pointer items-center gap-2 rounded-lg py-1 px-3`,
        {
          [`${bgColor}`]: isActive,
          'cursor-not-allowed': !isActive && isLoadingDrivers
        }
      )}
      style={{
        width: isEmpty ? '100%' : 'fit-content',
        border: '0px solid'
      }}
      onClick={() =>
        isLoadingDrivers ? null : onFilterByStatusDriver(isActive ? '' : status)
      }
    >
      {isLoadingDrivers && isActive ? (
        <CircularProgress
          size={12}
          style={{
            color: loadingColor
          }}
          thickness={11}
        />
      ) : (
        <div
          className={tw(
            'hover fade w-3 h-3 border-2 rounded-lg bg-white duration-100',
            {
              [`${borderColor} ${bgColor} border-4`]: isActive
            }
          )}
        />
      )}
      <div className="flex gap-1">
        <p className="w-auto">{label}</p>
        {value}
      </div>
    </div>
  );
};

export default ItemStatusDriver;
