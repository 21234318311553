import React from 'react';

import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { FormikImageUpload, Button } from '@vadiun/react-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/moment';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { Select } from 'formik-material-ui';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import {
  VehiculoCompaniaSeguro,
  VehiculoMunicipio,
  VehiculoFormSchemaVencimiento,
  vehiculoFormSchemaVencimientosInitialValues
} from 'app/vehiculos/models';
import { YupShape } from 'shared/models/YupShape';
import { Moment } from 'moment';
import moment from 'moment';

interface Props {
  submitButtonTitle?: string;
  onSubmit: (vehiculoSchema: VehiculoFormSchemaVencimiento, reset) => void;
  goBackButtonTitle?: string;
  onGoBack?: () => void;
  initialValues?: VehiculoFormSchemaVencimiento;
  companiaDeSeguros: VehiculoCompaniaSeguro[];
  municipios: VehiculoMunicipio[];
}

const Schema: YupShape<VehiculoFormSchemaVencimiento> = Yup.object().shape({
  vencimientoVTV: Yup.mixed()
    .required('La fecha de vencimiento es obligatoria')
    .test({
      test: (date: Moment | undefined | null) => {
        if (date === undefined || date === null) {
          return true;
        }
        return date.isAfter(moment());
      },
      message: 'La fecha debe ser posterior la fecha actual',
      name: 'invalidDate'
    }),
  fotoVTV: Yup.mixed().required('La foto de la VTV es obligatoria'),
  companiaSeguro: Yup.string().required('La compania de seguro es obligatoria'),
  fotoCuotaSeguro: Yup.mixed().required('La foto del seguro es obligatoria'),
  vencimientoCuotaSeguro: Yup.mixed()
    .required('La fecha de vencimiento es obligatoria')
    .test({
      test: (date: Moment | undefined | null) => {
        if (date === undefined || date === null) {
          return true;
        }
        return date.isAfter(moment());
      },
      message: 'La fecha debe ser posterior la fecha actual',
      name: 'invalidDate'
    }),
  fotoPoliza: Yup.mixed().required('La foto de la poliza es obligatoria'),
  vencimientoPoliza: Yup.mixed()
    .required('La fecha de vencimiento es obligatoria')
    .test({
      test: (date: Moment | undefined | null) => {
        if (date === undefined || date === null) {
          return true;
        }
        return date.isAfter(moment());
      },
      message: 'La fecha debe ser posterior la fecha actual',
      name: 'invalidDate'
    }),
  vencimientoHabilitacion: Yup.mixed()
    .required('La fecha de vencimiento es obligatoria')
    .test({
      test: (date: Moment | undefined | null) => {
        if (date === undefined || date === null) {
          return true;
        }
        return date.isAfter(moment());
      },
      message: 'La fecha debe ser posterior la fecha actual',
      name: 'invalidDate'
    }),
  fotoHabilitacion: Yup.mixed().required(
    'La foto de la habilitacion es obligatoria'
  ),
  vencimientoMicrocentro: Yup.mixed().test({
    test: (date: Moment | undefined | null) => {
      if (date === undefined || date === null) {
        return true;
      }
      return date.isAfter(moment());
    },
    message: 'La fecha debe ser posterior la fecha actual',
    name: 'invalidDate'
  }),
  fotoMicrocentro: Yup.mixed().when('vencimientoMicrocentro', {
    is: (valueVencimientoMicrocentro) => !!valueVencimientoMicrocentro,
    then: Yup.mixed().required(
      'Debe insertar una foto del permiso de microcentro'
    )
  }),
  municipio: Yup.string().required('El municipio es obligatorio')
});

function VehiculoFormVencimiento({
  submitButtonTitle = 'Guardar',
  goBackButtonTitle = 'Volver',
  onGoBack,
  onSubmit,
  companiaDeSeguros,
  municipios,
  initialValues = vehiculoFormSchemaVencimientosInitialValues()
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ submitForm, values }) => (
        <Form className="grid w-full gap-6">
          <div className="grid grid-cols-2 gap-6">
            <p className="col-start-1 col-end-3 text-lg font-semibold text-gray-600">
              VTV
            </p>
            <div className="col-span-2 w-full">
              <Field
                component={FormikImageUpload}
                className="aspect-w-3 aspect-h-1"
                name="fotoVTV"
              />
            </div>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="vencimientoVTV"
                className="col-span-2"
                label="Fecha vencimiento VTV"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>

            <p className="col-start-1 col-end-3 text-lg font-semibold text-gray-600">
              Seguro
            </p>

            <FormControl variant="outlined" fullWidth className="col-span-2">
              <InputLabel>Compania de seguro</InputLabel>
              <Field
                component={Select}
                name="companiaSeguro"
                label="Compania de seguro"
              >
                {companiaDeSeguros.map((compania) => (
                  <MenuItem value={compania} key={compania}>
                    {compania}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>

            <div className="col-span-1">
              <Field
                component={FormikImageUpload}
                className="aspect-w-3 aspect-h-1"
                name="fotoCuotaSeguro"
              />
            </div>
            <div className="col-span-1">
              <Field
                component={FormikImageUpload}
                className="aspect-w-3 aspect-h-1"
                name="fotoPoliza"
              />
            </div>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="vencimientoCuotaSeguro"
                label="Fecha vencimiento Seguro"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="vencimientoPoliza"
                label="Fecha vencimiento Poliza"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>

            <p className="col-start-1 col-end-3 text-lg font-semibold text-gray-600">
              Habilitación
            </p>

            <div className="col-span-2">
              <Field
                component={FormikImageUpload}
                className="aspect-w-3 aspect-h-1"
                name="fotoHabilitacion"
              />
            </div>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="vencimientoHabilitacion"
                className="col-span-2"
                label="Fecha vencimiento Habilitacion"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>

            <FormControl variant="outlined" fullWidth className="col-span-2">
              <InputLabel>Municipio</InputLabel>
              <Field component={Select} name="municipio" label="Municipio">
                {municipios.map((municipio) => (
                  <MenuItem value={municipio} key={municipio}>
                    {municipio}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>

            <p className="col-start-1 col-end-3 text-lg font-semibold text-gray-600">
              Microcentro
            </p>

            <div className="col-span-2">
              <Field
                component={FormikImageUpload}
                className="aspect-w-3 aspect-h-1"
                name="fotoMicrocentro"
              />
            </div>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="vencimientoMicrocentro"
                className="col-span-2"
                label="Fecha vencimiento Microcentro"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                fullWidth
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="mt-8 flex justify-between">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button variant="contained" color="green" onClick={submitForm}>
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default VehiculoFormVencimiento;
