import React from 'react';
import { Card } from 'shared/components';
import { AntenaGrupoForm } from './AntenaGrupoForm/AntenaGrupoForm';
import { AntenaGrupoFormType } from './AntenaGrupoForm/AntenaGrupoFormType';

interface Props {
  update: (AntenaGrupoValues: AntenaGrupoFormType) => Promise<void>;
  initialValues: AntenaGrupoFormType;
}

export function AntenaGrupoEdit({ update, initialValues }: Props) {
  return (
    <Card title="Edición grupo de antenas" className="w-full">
      <div className="p-8">
        <AntenaGrupoForm onSubmit={update} initialValues={initialValues} />
      </div>
    </Card>
  );
}
