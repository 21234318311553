import { FormControlLabel, Radio } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { Table, TableColumn } from '@vadiun/react-datatable';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { Field, Form, Formik } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Card } from 'shared/components';
import { LineChartComponent } from '../components/LineChartComponent';
import { useDriverInformationRepository } from '../services/driverInformationRepository';
import { useTransferReservationStatisticsRepository } from '../services/transferReservationStatisticsRepository';

export const TransferReservationDriverResumeTablePage = () => {
  const statisticsRepo = useTransferReservationStatisticsRepository();
  const repo = useDriverInformationRepository();
  const turnosPerDay = useSuperQuery(repo.getTurnosPerDay);
  const demoraPromedioQuery = useSuperQuery(statisticsRepo.getDemoraPromedio);
  const [filters, setFilters] = useState({
    fromDate: moment().subtract(1, 'month'),
    toDate: moment()
  });
  const [promedioFilters, setPromedioFilters] = useState<{
    fromDate: Moment;
    toDate: Moment;
    groupBy: 'day' | 'month';
  }>({
    fromDate: moment().subtract(1, 'month'),
    toDate: moment(),
    groupBy: 'month'
  } as const);
  const kmOfDrivers = useSuperQuery(
    () => repo.getKmAverageOfAllDriversModel(promedioFilters),
    undefined,
    [promedioFilters]
  );

  const labels = (kmOfDrivers.data ?? []).map((fecha) => {
    return fecha.periodo;
  });
  const lines = [
    {
      label: 'Kilometros Ociosos',
      values: (kmOfDrivers.data ?? []).map((fecha) => ({
        label: fecha.periodo,
        value: fecha.cant_ociosos
      }))
    },
    {
      label: 'Kilometros Pasajero',
      values: (kmOfDrivers.data ?? []).map((fecha) => ({
        label: fecha.periodo,
        value: fecha.cant_con_pasajero
      }))
    }
  ];

  const perDriverResumeQuery = useSuperQuery(
    () => statisticsRepo.perDriverResume(filters),
    {
      showSpinner: true
    },
    [filters]
  );

  const data = perDriverResumeQuery.data ?? [];

  const columns: TableColumn<typeof data[0]>[] = [
    {
      label: 'Código',
      name: 'driverCode'
    },
    {
      label: 'Nombre',
      name: 'driverName'
    },
    {
      label: 'Unidad de Negocio',
      name: 'driverBusinessUnitName'
    },
    {
      label: 'Categoría',
      name: 'driverCategory'
    },
    {
      label: 'Cat. Auto',
      name: 'carCategory'
    },
    {
      label: '$/Hora',
      name: 'hourPrice'
    },
    {
      label: '$/Km',
      name: 'kmPrice'
    },
    {
      label: 'Km. Ocioso',
      name: 'kmIdle'
    },
    {
      label: 'Porc. Km. Ocioso',
      name: 'percentageKmIdle',
      options: {
        customBodyRender: (value) => `${value}%`
      }
    },
    {
      label: 'Prom. Km. Ocioso',
      name: 'prom_km_ocioso'
    },
    {
      label: 'Km. Con Pasajeros',
      name: 'kmWithPassanger'
    },
    {
      label: 'Prom. Km. Pasajero',
      name: 'prom_km_pasajero'
    },
    {
      label: 'Porc. Km Pasajero ',
      name: 'porcentaje_km_pasajero',
      options: {
        customBodyRender: (value) => `${value}%`
      }
    },
    {
      label: 'Total Km',
      name: 'totalKm'
    },
    {
      label: 'Total Horas',
      name: 'totalHours'
    },
    {
      label: 'Recaudación',
      name: 'income'
    }
  ];

  return (
    <>
      <PageHeader title="Por chofer" />
      <PageContainer>
        <div className="h-full w-full">
          <div className="mb-8 flex gap-4">
            <div className="flex flex-col gap-4">
              <Card>
                <div className="p-4 px-8">
                  <p className="text-gray-500">Turno abierto</p>
                  <p className="text-bold my-4 flex-1 text-center text-2xl">
                    {turnosPerDay.data?.amount}
                  </p>
                  <p className="text-xs text-gray-400">
                    Choferes que abrieron turno hoy
                  </p>
                </div>
              </Card>
              <Card>
                <div className="flex h-full flex-col justify-between p-4 px-8">
                  <p className="text-gray-500">Puntualidad</p>
                  <p className="text-bold my-4 text-center text-5xl">
                    {(demoraPromedioQuery.data?.demora ?? 0) <= 5 ? (
                      <span>😃</span>
                    ) : (demoraPromedioQuery.data?.demora ?? 0) <= 10 ? (
                      <span>😐</span>
                    ) : (
                      <span>💀</span>
                    )}
                  </p>
                  <p className="text-xs text-gray-400">
                    {demoraPromedioQuery.data?.demora ?? 0} minutos de demora
                  </p>
                </div>
              </Card>
            </div>
            <Card className="w-full">
              <div className="p-4">
                <Formik
                  onSubmit={async (filters) => {
                    setPromedioFilters(filters);
                  }}
                  initialValues={promedioFilters}
                >
                  {(formik) => (
                    <Form className="my-4 flex items-center gap-4">
                      <Field
                        name="fromDate"
                        label="Desde*"
                        inputVariant="outlined"
                        component={KeyboardDatePicker}
                        ampm={false}
                        size="small"
                        format="DD/MM/YYYY"
                      />
                      <Field
                        name="toDate"
                        label="Hasta*"
                        inputVariant="outlined"
                        component={KeyboardDatePicker}
                        ampm={false}
                        size="small"
                        format="DD/MM/YYYY"
                      />

                      <Field
                        component={RadioGroup}
                        name="groupBy"
                        row
                        label="Agrupación"
                      >
                        <FormControlLabel
                          value="day"
                          control={<Radio color="primary" />}
                          label="Día"
                        />
                        <FormControlLabel
                          value="month"
                          control={<Radio color="primary" />}
                          label="Mes"
                        />
                      </Field>

                      <Button variant="contained" onClick={formik.submitForm}>
                        Buscar
                      </Button>
                    </Form>
                  )}
                </Formik>
                <div style={{ minHeight: 300 }}>
                  <LineChartComponent lines={lines} labels={labels} />
                </div>
              </div>
            </Card>
          </div>
          <Table
            options={{
              filter: false
            }}
            title={
              <>
                <Formik
                  onSubmit={async (filters) => {
                    setFilters(filters);
                  }}
                  initialValues={filters}
                >
                  {(formik) => (
                    <Form className="m-4 flex items-center gap-4">
                      <Field
                        name="fromDate"
                        label="Desde*"
                        inputVariant="outlined"
                        component={KeyboardDatePicker}
                        ampm={false}
                        size="small"
                        format="DD/MM/YYYY"
                      />
                      <Field
                        name="toDate"
                        label="Hasta*"
                        inputVariant="outlined"
                        component={KeyboardDatePicker}
                        ampm={false}
                        size="small"
                        format="DD/MM/YYYY"
                      />
                      <Button variant="contained" onClick={formik.submitForm}>
                        Buscar
                      </Button>
                    </Form>
                  )}
                </Formik>
              </>
            }
            data={data}
            columns={columns}
          />
        </div>
      </PageContainer>
    </>
  );
};
