// @flow
import { CircularProgress, IconButton } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import { MUIDataTableColumnDef } from 'mui-datatables';
import * as React from 'react';
import { Table } from 'shared/components';
import { ReserveDraft } from '../types/TravelDraft';
import moment from 'moment';
import { ClientBusinessPartialModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { useHistory } from 'react-router-dom';
import environment from '../../../../../environment/environment';
import CancelTravel from './CancelTravel';
import { useSnackbar } from 'notistack';
import CancelledTravelMessageInfo from './InfoTravelCancelled';
import InfoIcon from '@material-ui/icons/Info';
import { deleteDraftTravel } from 'app/viaje/services/draft';

interface Props {
  tableQuery: UseTableQueryResult<ReserveDraft[], unknown>;
  bussinessClients: ClientBusinessPartialModel[] | undefined;
}

export function ViajeTrasladoBorradoresTable({
  tableQuery,
  bussinessClients
}: Props) {
  const eventIndex = React.useRef<number | null>(null);

  const data = (tableQuery.data ?? []).sort((a: any, b: any) => {
    return a?.date?.data - b?.date?.data;
  });

  const { push } = useHistory();

  const getClientName = (clientableId: number) => {
    return bussinessClients?.find((client) => client.id === clientableId)
      ?.businessName;
  };

  const [travelDeleting, setTravelDeleting] = React.useState<string | null>();
  const [travelCanceling, setTravelCanceling] = React.useState<string | null>();
  const toast = useSnackbar();

  const [showCancelTravel, setShowCancelTravel] =
    React.useState<boolean>(false);
  const [showCancelMessage, setShowCancelMessage] =
    React.useState<boolean>(false);

  const cancelDraftTravel = async (idTravel: string, reason: string) => {
    const response = await fetch(
      `${environment.booking}/reserves-drafts/${idTravel}/cancel`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ reason })
      }
    );
    return response;
  };

  const onDeleteDraftTravel: (idTravel: string) => Promise<void> =
    React.useCallback(
      async (idTravel) => {
        try {
          setTravelDeleting(idTravel);
          const response = await deleteDraftTravel(idTravel);
          if (response.ok) {
            tableQuery.refetch();
          }
        } catch (error) {
          console.log(error);
        }
      },
      [tableQuery]
    );

  const onCancelDraftTravel: (data: any) => Promise<void> = async (data) => {
    try {
      if (data.reason === '') {
        toast.enqueueSnackbar('Debe agregar una razón', {
          variant: 'error',
          autoHideDuration: 2000
        });
        return;
      }
      const response = await cancelDraftTravel(
        travelCanceling ?? '',
        data.reason
      );

      if (response.ok) {
        toast.enqueueSnackbar('Cancelado correctamente', {
          variant: 'success',
          autoHideDuration: 2000
        });
      }
      setShowCancelTravel(false);
      tableQuery.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'date',
      label: 'Horarios',
      options: {
        customBodyRender: (date, { rowIndex }: { rowIndex: number }) => {
          const dateTravel = date?.data ?? null;
          const time = data[rowIndex]?.hour?.data ?? '';
          return (
            <div className="flex gap-3 items-center">
              {time ? <p className="text-gray-600 text-lg">{time}</p> : null}
              <p className="text-md text-gray-500">
                {moment(dateTravel, 'DD-MM-YYYY').format('DD/MM')}
              </p>
            </div>
          );
        }
      }
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => {
          const travel = data[index];
          const clientName = getClientName(travel.clientable_id);
          return (
            <span
              className={
                data[index]?.clientable_id ? 'text-blue-500' : 'text-black'
              }
            >
              {clientName}
            </span>
          );
        }
      }
    },
    {
      name: '',
      label: 'Domicilios',
      options: {
        customBodyRender: (value, { rowIndex: index }) => {
          const travel = data[index];
          const directionOne = travel?.direction_one?.data ?? '';
          const directionTwo = travel?.direction_two?.data ?? '';
          const directionThree = travel?.direction_three?.data ?? '';
          return (
            <div className="flex flex-col items-start gap-1">
              {directionOne ? (
                <div className="mb-1 flex items-center">
                  <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    1
                  </div>
                  <span className="ml-2">{directionOne}</span>
                </div>
              ) : null}
              {directionTwo ? (
                <div className="mb-1 flex items-center">
                  <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    2
                  </div>
                  <span className="ml-2">{directionTwo}</span>
                </div>
              ) : null}
              {directionThree ? (
                <div className="mb-1 flex items-center">
                  <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    3
                  </div>
                  <span className="ml-2">{directionThree}</span>
                </div>
              ) : null}
            </div>
          );
        }
      }
    },
    {
      name: 'coordination_observation',
      label: 'Obs. Coordinación',
      options: {
        customBodyRender: (value) => {
          return <p className="text-xs text-gray-500">{value?.data ?? ''}</p>;
        }
      }
    },
    {
      name: 'service_type',
      label: 'Servicio',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex items-center space-x-2">
            <p>{data[index]?.service_type?.data}</p>
          </div>
        )
      }
    },
    {
      name: 'applicant_name',
      label: 'Solicitante',
      options: {
        customBodyRender: (val) => (
          <p className="text-sm text-gray-500">{val}</p>
        )
      }
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRenderLite: (index) => {
          const draft = data[index];
          const isDraftCancelled = draft.status === 'CANCELLED';
          return (
            <div className="flex gap-2">
              <p className="bg-gray-100 rounded-md text-center text-sm text-gray-500 py-1 px-3">
                {isDraftCancelled ? 'CANCELADO' : 'BORRADOR'}
              </p>
              {isDraftCancelled ? (
                <IconButton
                  onClick={() => {
                    setTravelCanceling(draft._id);
                    setShowCancelMessage(true);
                  }}
                  aria-label="Refresh"
                  size="small"
                >
                  <InfoIcon className="text-gray-300" />
                </IconButton>
              ) : null}
            </div>
          );
        }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => {
          // const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
          const travel = data[index];
          const traveWithClientName = {
            ...travel,
            client: getClientName(travel.clientable_id)
          };
          return (
            <div className="flex gap-1">
              <Button
                variant="light"
                className="mb-2 w-fit"
                color="blue"
                onClick={() => {
                  localStorage.setItem(
                    'travel-selected-draft',
                    JSON.stringify(traveWithClientName)
                  );
                  setTimeout(() => {
                    push(`/main/Viaje/create/${travel?._id}`);
                  }, 300);
                }}
              >
                Crear
              </Button>
              <Button
                variant="text"
                className="mb-2"
                color="blue"
                onClick={() => {
                  setTravelCanceling(travel?._id ?? null);
                  setShowCancelTravel(true);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="text"
                className="mb-2"
                color="blue"
                onClick={() => onDeleteDraftTravel(travel?._id ?? 0)}
                isLoading={travelDeleting === travel?._id}
              >
                Eliminar
              </Button>
            </div>
          );
        }
      }
    }
  ];

  return (
    <>
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false,
          download: false,
          print: false,
          ...tableQuery.serverSideOptions
        }}
        columns={columns}
        data={data}
      />
      <CancelTravel
        initialValues={{ reason: '' }}
        isOpen={showCancelTravel}
        onCloseShowCancelTravel={() => {
          setShowCancelTravel(false);
          setTravelCanceling(null);
        }}
        onCancelTravel={onCancelDraftTravel}
      />
      <CancelledTravelMessageInfo
        message={
          tableQuery.data?.find((el) => el._id === travelCanceling)
            ?.observation ?? ''
        }
        onCloseShowInfo={() => setShowCancelMessage(false)}
        showTravelCanceledInfo={showCancelMessage}
      />
    </>
  );
}
