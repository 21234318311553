import {
  choferPartialBuilder,
  ChoferPartialModel
} from 'app/chofer/models/Chofer';
import {
  transferReservationPartialBuilder,
  TransferReservationPartialModel
} from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPartial';

export interface TransferReservationPendingModel
  extends TransferReservationPartialModel {
  preAssignedDriver: ChoferPartialModel | null;
}

export const transferReservationPendingBuilder = {
  fromBackEnd: (reservation): TransferReservationPendingModel => ({
    ...transferReservationPartialBuilder.fromBackEnd(reservation),
    preAssignedDriver: reservation.pre_assigned_driver
      ? choferPartialBuilder.fromBackEnd(reservation.pre_assigned_driver)
      : null
  })
};
