import { Button, Modal } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import RateCreate from '../components/RateCreate';
import RateEdit from '../components/RateEdit';
import { RateFastUpdateForm } from '../components/rateFastUpdateForm/RateFastUpdateForm';
import { RateFormType } from '../components/RateForm/RateFormik';
import RateTable from '../components/RateTable';
import { RateModel } from '../models/RateModel';
import useRate from '../services/useRate';

const RateCrudPage = () => {
  const RateService = useRate();
  const ClientBusinessService = useClientBusiness();
  const { clientBusinessId } = useParams<{ clientBusinessId: string }>();
  const ratesQuery = useSuperQuery(
    () => RateService.getAllByClient(Number(clientBusinessId)),
    {
      showSpinner: true
    }
  );
  const clientBusinessQuery = useSuperQuery(
    () => ClientBusinessService.get(Number(clientBusinessId)),
    {
      showSpinner: true
    }
  );
  const createMutation = useSuperMutation(RateService.create);
  const editMutation = useSuperMutation(RateService.edit);
  const deleteMutation = useSuperMutation(RateService.onDeleteRate, {
    showSpinner: true
  });
  const fastUpdateRateMutation = useSuperMutation(RateService.rateFastUpdate);

  const [selectedRateId, setSelectedRateId] = useState<number | undefined>(
    undefined
  );
  const [fastUpdateOpen, setfastUpdateOpen] = useState(false);
  const rateQuery = useSuperQuery<RateModel | undefined>(
    async () => {
      if (!selectedRateId) return undefined;
      return await RateService.get(selectedRateId);
    },
    {
      showSpinner: true
    },
    [selectedRateId]
  );
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'edit'>(
    'table'
  );

  async function create(rateFormValues: RateFormType, reset: () => void) {
    await createMutation.mutate({
      ...rateFormValues,
      businessClientId: Number(clientBusinessId)
    });
    reset();
    ratesQuery.reload();
  }

  async function edit(rateFormValues: RateFormType) {
    await editMutation.mutate({
      ...rateFormValues,
      businessClientId: Number(clientBusinessId),
      id: selectedRateId
    });
    ratesQuery.reload();
    setSelectedRateId(undefined);
    setVisiblePage('table');
  }

  async function selectToEdit(id: number) {
    setSelectedRateId(id);
    setVisiblePage('edit');
  }

  return (
    <>
      <PageHeader
        title="Tarifas"
        subtitle={
          <div className="flex space-x-2">
            <Link
              color="inherit"
              to="/main/clientBusiness"
              className="text-xs text-gray-400 hover:underline"
            >
              Clientes CC
            </Link>
            <span>/</span>
            <Link
              color="inherit"
              to={`/main/clientBusiness/${clientBusinessId}`}
              className="text-xs text-gray-400 hover:underline"
            >
              {clientBusinessQuery.data?.fantasyName || '...'}
            </Link>
            <span>/</span>
            <span className="text-xs text-gray-400">
              {visiblePage === 'table'
                ? 'Listado de tarifas'
                : visiblePage === 'create'
                ? 'Nueva Tarifa'
                : 'Editar Tarifa'}
            </span>
          </div>
        }
        toolbar={
          visiblePage === 'table' ? (
            <div className="flex gap-4">
              <Button
                onClick={() => setfastUpdateOpen(true)}
                variant="light"
                color="green"
              >
                Edición rapida
              </Button>
              <Button onClick={() => setVisiblePage('create')} variant="light">
                Crear tarifa
              </Button>
            </div>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />
      <PageContainer>
        {visiblePage === 'table' && (
          <RateTable
            rates={ratesQuery.data || []}
            selectToEdit={selectToEdit}
            onDelete={async (id) => {
              await deleteMutation.mutate(id) 
              ratesQuery.reload()
            }}
          />
        )}

        {visiblePage === 'create' && <RateCreate create={create} />}
        {visiblePage === 'edit' && rateQuery.data ? (
          <RateEdit
            edit={edit}
            initialValue={{
              ...rateQuery.data,
              categories: rateQuery.data.categories.map((cat) => ({
                ...cat,
                hourPrice: (Number(cat.minutePrice) * 60).toFixed(2)
              }))
            }}
          />
        ) : null}
      </PageContainer>
      <Modal
        onClose={() => setfastUpdateOpen(false)}
        open={fastUpdateOpen}
        body={
          <RateFastUpdateForm
            onSubmit={async (values) => {
              await fastUpdateRateMutation.mutate({
                ...values,
                clientId: Number(clientBusinessId!)
              });
              setfastUpdateOpen(false);
              ratesQuery.reload();
            }}
          />
        }
      />
    </>
  );
};

export default RateCrudPage;
