import {
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, ListSubheader, MenuItem } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import { ChangeEvent, FC, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ColumnIdType, ColumnMapping } from '../utils/types';

interface HeaderSelectorProps {
  currentMapping?: ColumnMapping;
  onChange: (event: ChangeEvent<{ name?: string; value: unknown }>, columnIndex: number) => void;
  currentColIndex: number;
  onRemoveHeaderMapping: (columnId: ColumnIdType, columnIndex: number) => void;
  columns: ColumnMapping[];
}

const HeaderSelector: FC<HeaderSelectorProps> = ({
  currentMapping,
  onChange,
  currentColIndex,
  onRemoveHeaderMapping,
  columns
}) => {

  const visualLabel = currentMapping?.label || 'Sin Asignar';

  const { clientColumns, tripColumns } = columns
    .sort(({ label: aM }, { label: bM }) => {
      if (aM < bM) {
        return -1
      }
      if (aM === bM) {
        return 0
      }
      return 1;
    })
    .reduce((acc, {
      groupType,
      allowMultiple,
      id,
      label,
      columnId,
      mandatory,
      inUse
    }) => {
      const nextColumn = groupType === 'client' ? 'clientColumns' : 'tripColumns';

      const nextItem = !(!allowMultiple && inUse && !columnId.includes(currentColIndex)) ? (
        <MenuItem key={uuidv4()} value={id}>
          <span>
            {label} {mandatory && <span className="text-red-500">*</span>}
          </span>
        </MenuItem>
      ) : undefined;

      return nextItem ? {
        ...acc,
        [nextColumn]: [
          ...acc[nextColumn],
          nextItem
        ]
      } : acc;
    }, {
      clientColumns: [], tripColumns: []
    } as { clientColumns: ReactNode[], tripColumns: ReactNode[] })

  return (
    <th style={{ paddingRight: '20px', textAlign: 'left', minWidth: '90px', paddingBottom: '10px' }} key={uuidv4()}>
      <FormControl fullWidth variant="outlined" style={{ marginTop: '6px' }}>
        <InputLabel>
          {visualLabel} {currentMapping?.mandatory && <span className="text-red-500">*</span>}
        </InputLabel>
        <Select
          style={{
            minWidth: '140px',
            paddingRight: '0px'
          }}
          value={currentMapping?.id || ''}
          label={(
            <span>
              {visualLabel} {currentMapping?.mandatory && <span className="text-red-500">*</span>}
            </span>
          )}
          onChange={(e) => onChange(e, currentColIndex)}
          endAdornment={currentMapping && (
            <button className="bg-white z-10 absolute right-2 top-5" onClick={() => { onRemoveHeaderMapping(currentMapping.id, currentColIndex) }}>
              <FontAwesomeIcon icon={faTimesCircle} size="1x" color="grey" />
            </button>
          )}
        >
          <ListSubheader color="primary" style={{ background: 'white' }}>Acciones</ListSubheader>
          <MenuItem key="delete" value="delete" style={{ color: '#be123c' }}>
            Eliminar
          </MenuItem>

          {clientColumns.length > 0 && (
            <ListSubheader color="primary" style={{ background: 'white' }}>Datos de cliente</ListSubheader>
          )}
          {clientColumns}

          {tripColumns.length > 0 && (
            <ListSubheader color="primary" style={{ background: 'white' }}>Datos de viaje</ListSubheader>
          )}
          {tripColumns}

        </Select>
      </FormControl>
    </th>
  )
};

export default HeaderSelector;