export interface TollModel {
  id: number;
  name: string;
  price: number;
}

export type TollModelCRUD = Omit<TollModel, 'id' | 'price'> & {
  id?: number;
  price: number | string;
};

export const tollBuilder = {
  fromBackEnd: (toll): TollModel => {
    return {
      id: toll.id,
      name: toll.name,
      price: toll.price
    };
  },
  toBackEnd: (toll: TollModelCRUD) => {
    return {
      id: toll.id,
      name: toll.name,
      price: toll.price
    };
  }
};
