import moment, { Moment } from 'moment';

export interface FacturaModel {
  client: { id: number; name: string; hasPriority: boolean };
  date: Moment;
  id: number;
  letter: string;
  number: string;
  total: number;
  movements: {
    amount: number;
    observation: string;
  }[];
}

export const facturaBuilder = {
  fromBackEnd: (factura): FacturaModel => {
    return {
      ...factura,
      date: moment(factura.date)
    };
  }
};
